import React, { useState, Dispatch, SetStateAction } from "react";

import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalColumn } from "src/app/components/Modal/Modal";
import { TextBoxWrapper } from "src/app/components/TextBoxWrapper/TextBoxWrapper";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { IconSelector } from "src/app/components/IconSelector/IconSelector";
import { PictogramVariants } from "src/app/components/Pictogram/Pictogram";
import { Icon } from "src/app/components/IconSelector/types";
import { Dictionary } from "../types";
import { handleUpdateDictionary } from "../utils";

interface DictionaryManagerGeneralTabProps {
  editedDictionary: Dictionary;
  setEditedDictionary: Dispatch<SetStateAction<Dictionary | null>>;
  syncLocalDictionaryWithServer: (uuid: string) => Promise<void>;
}

function DictionaryManagerGeneralTab({
  editedDictionary,
  setEditedDictionary,
  syncLocalDictionaryWithServer,
}: DictionaryManagerGeneralTabProps) {
  const [elementName, setElementName] = useState(editedDictionary.elementName);
  const [description, setDescription] = useState(editedDictionary.description);
  const [selectedIcon, setSelectedIcon] = useState<Icon>({
    value: editedDictionary.icon.replace(".svg", ""),
    label: editedDictionary.icon.replace(".svg", ""),
  });

  const handleUpdateDictionaryField = (updatedFields: Partial<Dictionary>) => {
    setEditedDictionary((prev) => {
      if (!prev) return null;

      handleUpdateDictionary({
        data: {
          ...prev,
          ...updatedFields,
        },
        catchCallback: () =>
          syncLocalDictionaryWithServer(editedDictionary.uuid),
      });

      return { ...prev, ...updatedFields };
    });
  };

  const handleUpdateDescription = (newDescription: string) => {
    const descriptionTrimmed = newDescription.trim();
    if (descriptionTrimmed !== editedDictionary.description) {
      handleUpdateDictionaryField({ description: descriptionTrimmed });
    }
  };

  const handleUpdateObjectName = () => {
    const elementNameTrimmed = elementName.trim();
    if (elementNameTrimmed !== editedDictionary.elementName) {
      handleUpdateDictionaryField({ elementName: elementNameTrimmed });
    }
  };

  return (
    <div className="dictionary-manager__content-wrapper">
      <ModalColumn>
        <CustomInputLabel htmlFor="elementName">
          <IDHFormattedMessage
            id="ws_object_name"
            defaultMessage="Object name"
          />
        </CustomInputLabel>

        <CustomInput
          id="elementName"
          type="text"
          value={elementName}
          onChange={(e) => setElementName(e.target.value)}
          onBlur={handleUpdateObjectName}
          data-qa-input="inputWsDictionaryElementName"
        />
      </ModalColumn>

      <ModalColumn>
        <CustomInputLabel>
          <IDHFormattedMessage
            id="ws_description"
            defaultMessage="Description"
          />
        </CustomInputLabel>

        <TextBoxWrapper
          className="ws-input dictionary-manager__text-area"
          value={description}
          onChange={setDescription}
          onBlur={handleUpdateDescription}
        />
      </ModalColumn>
      <div className="dictionary-manager__section">
        <div>
          <div className="dictionary-manager__section-label">
            <IDHFormattedMessage
              id="ws_visibility"
              defaultMessage="Visibility"
            />
          </div>

          <CustomCheckbox
            label={
              <IDHFormattedMessage id="ws_sidebar" defaultMessage="Sidebar" />
            }
            id="sidebar"
            name="sidebar"
            checked={editedDictionary.showInSidebar}
            data-qa-input="toggleWsDictionaryShowInSidebar"
            onChange={(e) => {
              handleUpdateDictionaryField({
                showInSidebar: e.target.checked,
              });
            }}
          />

          <CustomCheckbox
            label={
              <IDHFormattedMessage
                id="ws_creator_crm"
                defaultMessage="Creator CRM"
              />
            }
            id="creator-crm"
            name="creator-crm"
            checked={editedDictionary.showInGlobalCreator}
            data-qa-input="toggleWsDictionaryShowInGlobalCreator"
            onChange={(e) => {
              handleUpdateDictionaryField({
                showInGlobalCreator: e.target.checked,
              });
            }}
          />
        </div>

        <div>
          <div className="dictionary-manager__section-label">
            <IDHFormattedMessage
              id="ws_sidebar_icon"
              defaultMessage="Sidebar icon"
            />
          </div>
          <IconSelector
            selectedIcon={selectedIcon}
            setSelectedIcon={(newIcon: Icon) => {
              handleUpdateDictionaryField({ icon: newIcon.value });
              setSelectedIcon(newIcon);
            }}
            selectedColor={PictogramVariants.Black}
          />
        </div>
      </div>
    </div>
  );
}

export default DictionaryManagerGeneralTab;
