import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import SdkConfigurationApi from './SdkConfigurationApi';
import PaywallApi from './PaywallApi';
import CustomersApi from './CustomersApi';
import CouponsApi from './CouponsApi';
import SubscriptionsApi from './SubscriptionsApi';
import SubscriptionEstimationsApi from './SubscriptionEstimationsApi';
import MockPaywallApi from './MockPaywallApi';
import { EdgeApiClient } from './EdgeApiClient';
import { UsageHistoryApi } from './UsageHistoryApi';

class ApiGateway {
  sdkConfiguration: SdkConfigurationApi;
  paywall: PaywallApi;
  subscriptions: SubscriptionsApi;
  mockPaywall: MockPaywallApi;
  customers: CustomersApi;
  coupons: CouponsApi;
  subscriptionEstimations: SubscriptionEstimationsApi;
  usageHistoryApi: UsageHistoryApi;

  constructor(client: ApolloClient<NormalizedCacheObject>, edgeApiClient: EdgeApiClient | null) {
    this.sdkConfiguration = new SdkConfigurationApi(client, edgeApiClient);
    this.paywall = new PaywallApi(client, edgeApiClient);
    this.subscriptions = new SubscriptionsApi(client);
    this.mockPaywall = new MockPaywallApi(client);
    this.customers = new CustomersApi(client, edgeApiClient);
    this.coupons = new CouponsApi(client);
    this.subscriptionEstimations = new SubscriptionEstimationsApi(client);
    this.usageHistoryApi = new UsageHistoryApi(client);
  }
}

export default ApiGateway;
