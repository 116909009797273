export const GET_TRANSLATIONS = "GET_TRANSLATIONS";
export const GET_TRANSLATIONS_SUCCESS = "GET_TRANSLATIONS_SUCCESS";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const GET_IDENTIFY = "GET_IDENTIFY";
export const GET_IDENTIFY_SUCCESS = "GET_IDENTIFY_SUCCESS";
export const GET_IDENTIFY_FAIL = "GET_IDENTIFY_FAIL";
export const GET_IS_LOGGED_IN = "GET_IS_LOGGED_IN";
export const GET_IS_LOGGED_IN_SUCCESS = "GET_IS_LOGGED_IN_SUCCESS";
export const GET_IS_LOGGED_IN_FAIL = "GET_IS_LOGGED_IN_FAIL";
export const SET_ACTIVE_WORKSPACE_UUID = "SET_ACTIVE_WORKSPACE_UUID";
export const SET_INTERCOM_UNREAD_MESSAGES = "SET_INTERCOM_UNREAD_MESSAGES";
export const GET_WORKSPACES_LIST = "GET_WORKSPACES_LIST";
export const GET_WORKSPACES_LIST_SUCCESS = "GET_WORKSPACES_LIST_SUCCESS";
export const SET_WEBSOCKET = "SET_WEBSOCKET";
export const CLEAR_WEBSOCKET = "CLEAR_WEBSOCKET";
export const GET_CREATOR_DISCOVERY_TOOL_URL = "GET_CREATOR_DISCOVERY_TOOL_URL";
export const GET_CREATOR_DISCOVERY_TOOL_URL_SUCCESS =
  "GET_CREATOR_DISCOVERY_TOOL_URL_SUCCESS";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const PDF_READY = "PDF_READY";
export const PDF_CLOSE_READY = "PDF_CLOSE_READY";
export const DROP_NOTIFICATION = "DROP_NOTIFICATION";
export const SET_SETTINGS_MODAL_DATA = "SET_SETTINGS_MODAL_DATA";
export const SET_HOW_IT_WORKS_MODAL_DATA = "SET_HOW_IT_WORKS_MODAL_DATA";

export const GET_WORKSPACE_TEAMS_LIST = "GET_WORKSPACE_TEAMS_LIST";

export const GET_WORKSPACE_TEAMS_LIST_SUCCESS =
  "GET_WORKSPACE_TEAMS_LIST_SUCCESS";

export const GET_WORKSPACE_TEAMS_LIST_FAIL = "GET_WORKSPACE_TEAMS_LIST_FAIL";

export const SET_CREATE_NEW_PROJECT_DATA = "SET_CREATE_NEW_PROJECT_DATA";
export const CLEAR_CREATE_NEW_PROJECT_DATA = "CLEAR_CREATE_NEW_PROJECT_DATA";
export const TASK_META_FIELD_CREATED = "TASK_META_FIELD_CREATED";

export const SET_STIGG_PAYMENT_ERRORS = "SET_STIGG_PAYMENT_ERRORS";
