import React from "react";
import "./EmptyDictionaryTab.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as EmptyDictionaryTabImage } from "src/images/empty-dictionary-tab.svg";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { Button } from "src/app/components/Button/Button";
import { DictionaryItem } from "../../DictionaryTab";

interface Props {
  showDictionaryModal: () => void;
  dictionaryData: DictionaryItem | undefined;
}

export function EmptyDictionaryTab(props: Props) {
  const { showDictionaryModal, dictionaryData } = props;

  return (
    <AnimatedDiv className="empty-dictionary-tab">
      <EmptyDictionaryTabImage />
      <div className="empty-dictionary-tab__text">
        <IDHFormattedMessage
          id="ws_no_elements_have_been_assigned_to_this_creator"
          defaultMessage="No elements have been assigned to this creator.\n Add new or assign already added elements."
        />
      </div>
      <Button variant="blue" size="small" onClick={showDictionaryModal}>
        <PlusIcon />
        <span>
          <IDHFormattedMessage id="ws_add" defaultMessage="Add" />{" "}
          {dictionaryData?.wsDictionaryElementName.toLowerCase()}
        </span>
      </Button>
    </AnimatedDiv>
  );
}
