import React, { Dispatch, DispatchWithoutAction } from "react";
import "./MetaDataOptionsFieldSwitch.scss";

import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { toggleProjectsListColumnVisibility } from "src/redux";
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import classNames from "classnames";
import { ReactComponent as GridIcon } from "src/images/grid.svg";
import { toggleWSTasksColumnVisibility } from "src/redux/workspaceTaskFilters/workspaceTaskFiltersActions";
import { toggleDictionaryColumnVisibility } from "../../DictionaryFilters/redux/dictionaryFiltersActions";
import { MetaDataOptionsContext } from "../MetaDataOptionsDropdown";

interface Props {
  context: MetaDataOptionsContext;
  index: number;
  field: {
    name: string;
    uuid: string;
  };
  hiddenColumns: string[];
  dispatchFunction: DispatchWithoutAction | Dispatch<any>;
}

export const MetaDataOptionsFieldSwitch: React.FC<Props> = (props) => {
  const { context, index, hiddenColumns, field, dispatchFunction } = props;

  const toggleVisibility = () => {
    switch (context) {
      case MetaDataOptionsContext.CreatorDatabase:
      case MetaDataOptionsContext.Dictionary:
        dispatchFunction(toggleDictionaryColumnVisibility(field.uuid));
        break;

      case MetaDataOptionsContext.ProjectList:
        dispatchFunction(toggleProjectsListColumnVisibility(field.uuid));
        break;

      case MetaDataOptionsContext.DataHub:
        dispatchFunction(toggleWSTasksColumnVisibility(field.uuid));
        break;
    }
  };

  const getElementTop = (
    style: DraggingStyle | NotDraggingStyle | undefined,
  ) => {
    if (style && "top" in style) {
      switch (context) {
        case MetaDataOptionsContext.DataHub:
          return style.top - 268;

        case MetaDataOptionsContext.Dictionary:
        case MetaDataOptionsContext.CreatorDatabase:
          return style.top - 216;

        case MetaDataOptionsContext.ProjectList:
          return style.top - 154;

        default:
          return style.top;
      }
    }
    return "auto";
  };

  return (
    <Draggable key={field.uuid} draggableId={field.uuid} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className={classNames("metadata-options-field-switch", {
            "metadata-options-field-switch--dragging": snapshot.isDragging,
          })}
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
            top: getElementTop(provided.draggableProps.style),
          }}
        >
          <div className="metadata-options-field-switch__name">
            <div
              {...provided.dragHandleProps}
              className="metadata-options-field-switch__drag"
            >
              <GridIcon />
            </div>
            {field.name}
          </div>
          <CustomSwitch
            checked={!hiddenColumns?.includes(field.uuid)}
            onChange={toggleVisibility}
          />
        </div>
      )}
    </Draggable>
  );
};
