import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ImageOption } from "src/app/components/CustomSelect/CustomSelect";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";

import { ReactComponent as RectangleCheckIcon } from "src/images/rectangle-check.svg";
import { ReactComponent as TickIcon } from "src/images/tick-grey.svg";
import { ReactComponent as StarIcon } from "src/images/star.svg";
import { ReactComponent as RocketIcon } from "src/images/rocket-grey.svg";
import { ReactComponent as DateIcon } from "src/images/date-icon.svg";
import { ReactComponent as PercentIcon } from "src/images/percent.svg";
import { ReactComponent as CurrencyIcon } from "src/images/currency.svg";
import { ReactComponent as HashIcon } from "src/images/hash.svg";
import { ReactComponent as AvatarIcon } from "src/images/avatar.svg";
import { ReactComponent as TextIcon } from "src/images/text.svg";
import { ReactComponent as CreatorIcon } from "src/images/creator.svg";
import { ReactComponent as MessageIcon } from "src/images/menu-icons/message.svg";
import { ReactComponent as PictureIcon } from "src/images/picture.svg";
import { ReactComponent as SingleSelectIcon } from "src/images/metafield-types/single-select.svg";
import { ReactComponent as MultiSelectIcon } from "src/images/metafield-types/multi-select.svg";
import { ReactComponent as DeliverablesIcon } from "src/images/metafield-types/deliverables.svg";
import { ReactComponent as MetricsIcon } from "src/images/metafield-types/metrics.svg";
import { ReactComponent as FormulaIcon } from "src/images/metafield-types/formula.svg";
import { ReactComponent as FileIcon } from "src/images/metafield-types/file.svg";
import { ReactComponent as CouponIcon } from "src/images/metafield-types/coupon.svg";
import { ReactComponent as SalesTracingIcon } from "src/images/metafield-types/sales-tracing.svg";
import { ReactComponent as LinkIcon } from "src/images/metafield-types/link.svg";
import { ReactComponent as EMVIcon } from "src/images/stacked-coins.svg";

export const formDataInitialState = {
  selectedCurrency: null,
  wsTeamUuids: [],
  wsDictionarySubField: null,
  selectedDictionaryMetaField: null,
  selectedFilterMetaFieldTarget: null,
  selectedFilterByMetaField: null,
};

export const additionalOptionsInitialState = {
  enableFilterMemberByTeam: false,
  enableDictionarySupportingField: false,
  enableFilterElementsFields: false,
};

export const allMetaFieldTypeOptions = [
  {
    type: MetaFieldType.Formula,
    value: MetaFieldType.Formula,
    label: (
      <ImageOption icon={FormulaIcon}>
        <IDHFormattedMessage id="ws_formula" defaultMessage="Formula" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Member,
    value: MetaFieldType.Member,
    label: (
      <ImageOption icon={AvatarIcon}>
        <IDHFormattedMessage id="ws_member" defaultMessage="Member" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Text,
    value: MetaFieldType.Text,
    label: (
      <ImageOption icon={TextIcon}>
        <IDHFormattedMessage id="ws_text" defaultMessage="Text" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Number,
    value: MetaFieldType.Number,
    label: (
      <ImageOption icon={HashIcon}>
        <IDHFormattedMessage id="ws_number" defaultMessage="Number" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Percent,
    value: MetaFieldType.Percent,
    label: (
      <ImageOption icon={PercentIcon}>
        <IDHFormattedMessage id="ws_percent" defaultMessage="Percent" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Currency,
    value: MetaFieldType.Currency,
    label: (
      <ImageOption icon={CurrencyIcon}>
        <IDHFormattedMessage id="ws_currency" defaultMessage="Currency" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Date,
    value: MetaFieldType.Date,
    label: (
      <ImageOption icon={DateIcon}>
        <IDHFormattedMessage id="ws_date" defaultMessage="Date" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.SingleSelect,
    value: MetaFieldType.SingleSelect,
    label: (
      <ImageOption icon={SingleSelectIcon}>
        <IDHFormattedMessage
          id="ws_single_select"
          defaultMessage="Single-select"
        />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.MultiSelect,
    value: MetaFieldType.MultiSelect,
    label: (
      <ImageOption icon={MultiSelectIcon}>
        <IDHFormattedMessage
          id="ws_multi_select"
          defaultMessage="Multi-select"
        />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.AggregatedSelect,
    value: MetaFieldType.AggregatedSelect,
    label: (
      <ImageOption icon={DeliverablesIcon}>
        <IDHFormattedMessage
          id="ws_deliverables"
          defaultMessage="Deliverables"
        />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.BoolVal,
    value: MetaFieldType.BoolVal,
    label: (
      <ImageOption icon={TickIcon}>
        <IDHFormattedMessage id="ws_checkbox" defaultMessage="Checkbox" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.File,
    value: MetaFieldType.File,
    label: (
      <ImageOption icon={FileIcon}>
        <IDHFormattedMessage id="ws_file" defaultMessage="File" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Metrics,
    value: MetaFieldType.Metrics,
    label: (
      <ImageOption icon={MetricsIcon}>
        <IDHFormattedMessage id="ws_metrics" defaultMessage="Metrics" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Creator,
    value: MetaFieldType.Creator,
    label: (
      <ImageOption icon={CreatorIcon}>
        <IDHFormattedMessage id="ws_creator" defaultMessage="Creator" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.PublicationRelated,
    value: MetaFieldType.PublicationRelated,
    label: (
      <ImageOption icon={MessageIcon}>
        <IDHFormattedMessage id="ws_publication" defaultMessage="Publication" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Content,
    value: MetaFieldType.Content,
    label: (
      <ImageOption icon={PictureIcon}>
        <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.EMV,
    value: MetaFieldType.EMV,
    label: (
      <ImageOption icon={EMVIcon}>
        <IDHFormattedMessage id="ws_emv" defaultMessage="EMV" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Coupon,
    value: MetaFieldType.Coupon,
    label: (
      <ImageOption icon={CouponIcon}>
        <IDHFormattedMessage id="ws_coupon" defaultMessage="Coupon" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.SalesMetrics,
    value: MetaFieldType.SalesMetrics,
    label: (
      <ImageOption icon={SalesTracingIcon}>
        <IDHFormattedMessage
          id="ws_sales_tracing_metric"
          defaultMessage="Sales tracing metric"
        />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.RelatedField,
    value: MetaFieldType.RelatedField,
    label: (
      <ImageOption icon={LinkIcon}>
        <IDHFormattedMessage
          id="ws_related_field"
          defaultMessage="Related field"
        />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.AccessLink,
    value: MetaFieldType.AccessLink,
    label: (
      <ImageOption icon={RectangleCheckIcon}>
        <IDHFormattedMessage id="ws_form" defaultMessage="Form" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Rating,
    value: MetaFieldType.Rating,
    label: (
      <ImageOption icon={StarIcon}>
        <IDHFormattedMessage id="ws_rating" defaultMessage="Rating" />
      </ImageOption>
    ),
  },
  {
    type: MetaFieldType.Project,
    value: MetaFieldType.Project,
    label: (
      <ImageOption icon={RocketIcon}>
        <IDHFormattedMessage id="ws_project" defaultMessage="Project" />
      </ImageOption>
    ),
  },
];
