import React from "react";
import "./StiggUsageModal.scss";

import {
  CustomerPortalProvider,
  CustomerUsageData,
  StiggProvider,
} from "@stigg/react-sdk";

import Modal, {
  ModalScroller,
  ModalText,
  ModalTitle,
} from "src/app/components/Modal/Modal";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as WarningIcon } from "src/images/warning-orange.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import classNames from "classnames";
import { setStiggPaymentErrors } from "src/redux/main/mainActions";

export enum StiggUsageModalMode {
  Warning = "warning",
  Info = "info",
}

interface Props {
  onClose: () => void;
  mode: StiggUsageModalMode;
}

function StiggUsageModal(props: Props) {
  const { onClose, mode } = props;

  const identity = useSelector(
    (state: RootState) => state.mainReducer.identity,
  );
  const stiggPaymentErrors = useSelector(
    (state: RootState) => state.mainReducer.stiggPaymentErrors,
  );

  const { subscriptionData } = identity;

  const dispatch = useDispatch();

  const renderModalInfo = () => {
    switch (mode) {
      case StiggUsageModalMode.Info:
        return (
          <ModalTitle>
            <IDHFormattedMessage id="ws_usage" defaultMessage="Usage" />
          </ModalTitle>
        );

      case StiggUsageModalMode.Warning:
        return (
          <>
            <ModalTitle>
              <WarningIcon />
              <IDHFormattedMessage id="ws_warning" defaultMessage="Warning" />
            </ModalTitle>

            <ModalText>
              {stiggPaymentErrors.length ? (
                <>
                  <IDHFormattedMessage
                    id="ws_you_cannot_perform_this_action_you_do_not_have_enough_limits_in"
                    defaultMessage="You cannot perform this action. You do not have enough limits in:"
                  />
                  <ul className="plan-limit-reached-modal__highlighted-text">
                    {stiggPaymentErrors.map((key) => (
                      <li>
                        <IDHFormattedMessage
                          id={`ws_${key}`}
                          defaultMessage={key.replaceAll("_", " ")}
                        />
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  <IDHFormattedMessage
                    id="ws_you_have_used_your_trial"
                    defaultMessage="You've already used up your trial period."
                  />
                  &nbsp;
                </>
              )}
              <IDHFormattedMessage
                id="ws_please_contact_your_workspace_admin_to_upgrade_plan"
                defaultMessage="To upgrade plan and increase your limits please contact"
              />
              :
              <span className="contact-support-modal__email">
                &nbsp;
                <a href={`mailto:${subscriptionData?.bdWsMemberEmail}`}>
                  {subscriptionData?.bdWsMemberEmail}
                </a>
              </span>
            </ModalText>
          </>
        );
    }
  };

  const handleClose = () => {
    dispatch(setStiggPaymentErrors([]));
    onClose();
  };

  return (
    <Modal
      className={classNames(
        "plan-limit-reached-modal",
        `plan-limit-reached-modal--${mode}`,
      )}
      onClose={handleClose}
      displayCancelButton
      closeButtonText="Close"
      closeButtonVariant="white-with-grey-border"
      variant="small"
    >
      {renderModalInfo()}

      <ModalScroller maxHeight={260}>
        <StiggProvider
          apiKey={subscriptionData?.clientApiKey}
          customerId={subscriptionData?.customerId}
        >
          <CustomerPortalProvider>
            <CustomerUsageData />
          </CustomerPortalProvider>
        </StiggProvider>
      </ModalScroller>
    </Modal>
  );
}

export default StiggUsageModal;
