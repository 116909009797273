import React from "react";
import "./MemberTeams.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { tableDataType } from "src/app/components/Table/Table";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { MFState, MFStateEntries } from "../../types";
import MemberTeamItem from "./MemberTeamItem";

interface Props {
  dataType: tableDataType;
  disabled?: boolean;
  getByCode?: string;
  state: MFState;
  setState: (type: MFStateEntries, value: any) => void;
}

export interface TeamOption {
  key: string;
  value: string;
  label: JSX.Element;
}

function MemberTeams(props: Props) {
  const {
    state: { teamsData, wsTeamUuids, filterMemberByTeam },
    disabled,
    setState,
  } = props;

  const addElementIfNotExists = (newElement: string) => {
    if (!wsTeamUuids.includes(newElement)) {
      const updatedArray = [...wsTeamUuids, newElement];
      setState("wsTeamUuids", updatedArray);
    }
  };

  const removeElementIfExists = (elementToRemove: string) => {
    if (wsTeamUuids.includes(elementToRemove)) {
      const updatedArray = wsTeamUuids.filter(
        (element: string) => element !== elementToRemove,
      );
      setState("wsTeamUuids", updatedArray);
    }
  };

  if (teamsData.length > 0) {
    return (
      <div className="member-team-wrapper">
        <div className="member-team-wrapper__column">
          <div className="member-team-wrapper__row">
            <div className="member-team-wrapper__switch">
              <CustomSwitch
                disabled={disabled}
                checked={filterMemberByTeam}
                onChange={() => {
                  setState("filterMemberByTeam", !filterMemberByTeam);
                }}
              />
            </div>
            <div className="member-team-wrapper__switch-info">
              <div className="member-team-wrapper__switch-info-header">
                <IDHFormattedMessage
                  id="ws_team_members_selection"
                  defaultMessage="Team Members Selection"
                />
              </div>
              <div className="member-team-wrapper__switch-info-description">
                <IDHFormattedMessage
                  id="ws_team_members_selection_description"
                  defaultMessage="Allows you to limit the member selection list to selected teams."
                />
              </div>
            </div>
          </div>
          {filterMemberByTeam && (
            <div className="member-team-list__table">
              <div className="member-team-list__table-head-row">
                <span className="member-team-list__table-head-row-cell-name">
                  <IDHFormattedMessage id="ws_team" defaultMessage="Team" />
                </span>
                <span className="member-team-list__table-head-row-cell-members">
                  <IDHFormattedMessage
                    id="ws_members"
                    defaultMessage="Members"
                  />
                </span>
              </div>
              <div className="member-team-list__table-body">
                {teamsData?.map((team: any) => (
                  <MemberTeamItem
                    key={team.uuid}
                    team={team}
                    disabled={disabled}
                    wsTeamUuids={wsTeamUuids}
                    onCheckboxChange={(checked: boolean, teamUuid: string) => {
                      if (checked) {
                        addElementIfNotExists(teamUuid);
                      } else {
                        removeElementIfExists(teamUuid);
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
}

export default MemberTeams;
