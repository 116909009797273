import { EntitlementResetPeriod } from '@stigg/api-client-js/src/generated/sdk';
import dayjs, { ManipulateType } from 'dayjs';

function getPeriodUnit(resetPeriod: EntitlementResetPeriod): ManipulateType {
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      return 'years';
    case EntitlementResetPeriod.Month:
      return 'months';
    case EntitlementResetPeriod.Week:
      return 'weeks';
    case EntitlementResetPeriod.Day:
      return 'days';
    case EntitlementResetPeriod.Hour:
      return 'hours';
    default:
      throw new Error('Unsupported reset period');
  }
}

export function calculateUsagePeriod(usagePeriodAnchor: Date, baseDate: Date, resetPeriod: EntitlementResetPeriod) {
  const now = dayjs(baseDate);
  const anchor = dayjs(usagePeriodAnchor);
  const period = getPeriodUnit(resetPeriod);

  const numberOfCompletePeriodCycles = Math.floor(now.diff(anchor, period, true));
  const usagePeriodStart = anchor.clone().add(numberOfCompletePeriodCycles, period);
  const usagePeriodEnd = anchor.clone().add(numberOfCompletePeriodCycles + 1, period);

  return {
    usagePeriodStart: usagePeriodStart.toDate(),
    usagePeriodEnd: usagePeriodEnd.toDate(),
  };
}
