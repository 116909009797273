export const GET_DICTIONARY_LIST = "GET_DICTIONARY_LIST";
export const GET_DICTIONARY_LIST_SUCCESS = "GET_DICTIONARY_LIST_SUCCESS";
export const GET_DICTIONARY_LIST_FAIL = "GET_DICTIONARY_LIST_FAIL";
export const CLEAR_DICTIONARY_LIST = "CLEAR_DICTIONARY_LIST";
export const UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD =
  "UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD";
export const SET_DICTIONARY_ELEMENT_META_VALUE =
  "SET_DICTIONARY_ELEMENT_META_VALUE";
export const UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD_SUCCESS =
  "UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD_SUCCESS";
export const UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD_FAIL =
  "UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD_FAIL";
export const GET_DICTIONARY_DETAILS = "GET_DICTIONARY_DETAILS";
export const GET_DICTIONARY_DETAILS_SUCCESS = "GET_DICTIONARY_DETAILS_SUCCESS";
export const CLEAR_DICTIONARY_DETAILS = "CLEAR_DICTIONARY_DETAILS";
export const SET_DICTIONARY_ELEMENT_META_VALUE_SUCCESS =
  "SET_DICTIONARY_ELEMENT_META_VALUE_SUCCESS";
export const SET_DICTIONARY_ELEMENT_META_VALUE_FAIL =
  "SET_DICTIONARY_ELEMENT_META_VALUE_FAIL";
export const REMOVE_DICTIONARY_ELEMENT = "REMOVE_DICTIONARY_ELEMENT";
export const REMOVE_DICTIONARY_ELEMENT_FAIL = "REMOVE_DICTIONARY_ELEMENT_FAIL";
export const REMOVE_DICTIONARY_ELEMENT_SUCCESS =
  "REMOVE_DICTIONARY_ELEMENT_SUCCESS";
export const GET_DICTIONARY_LIST_SINGLE_ELEMENT =
  "GET_DICTIONARY_LIST_SINGLE_ELEMENT";
export const GET_DICTIONARY_LIST_SINGLE_ELEMENT_SUCCESS =
  "GET_DICTIONARY_LIST_SINGLE_ELEMENT_SUCCESS";
export const GET_DICTIONARY_LIST_SINGLE_ELEMENT_FAIL =
  "GET_DICTIONARY_LIST_SINGLE_ELEMENT_FAIL";
export const CREATE_DICTIONARY_LIST_ELEMENT = "CREATE_DICTIONARY_LIST_ELEMENT";
export const CREATE_DICTIONARY_LIST_ELEMENT_SUCCESS =
  "CREATE_DICTIONARY_LIST_ELEMENT_SUCCESS";
export const CREATE_DICTIONARY_LIST_ELEMENT_FAIL =
  "CREATE_DICTIONARY_LIST_ELEMENT_FAIL";
export const SET_SHOULD_CLOSE_ADD_NEW_DICTIONARY_ELEMENT_MODAL =
  "SET_SHOULD_CLOSE_ADD_NEW_DICTIONARY_ELEMENT_MODAL";

export const GET_DICTIONARY_AUTOCOMPLETE = "GET_DICTIONARY_AUTOCOMPLETE";
export const GET_DICTIONARY_AUTOCOMPLETE_SUCCESS =
  "GET_DICTIONARY_AUTOCOMPLETE_SUCCESS";
export const SET_DICTIONARY_AUTOCOMPLETE_LOADING =
  "SET_DICTIONARY_AUTOCOMPLETE_LOADING";
export const UPDATE_DICTIONARY_META_FIELD_SELECT_OPTIONS =
  "UPDATE_DICTIONARY_META_FIELD_SELECT_OPTIONS";
export const CLEAR_DICTIONARY_META_FIELD_SELECT_OPTIONS =
  "CLEAR_DICTIONARY_META_FIELD_SELECT_OPTIONS";
export const SET_SHOULD_UPDATE_DICTIONARY_DATA =
  "SET_SHOULD_UPDATE_DICTIONARY_DATA";
