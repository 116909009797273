import React, { useMemo } from "react";

import { TaskType } from "src/types";
import { Column } from "src/redux/dictionary/types";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/reducers";
import { mockWorkspaceTasks } from "src/redux/task/mockTasks";
import { IndeterminateCheckbox } from "../../project/SingleProject/TasksTable/IndeterminateCheckbox";
import WorkspaceTasksTable from "./WorkspaceTasksTable";

interface WorkspaceTasksTableWrapperProps {
  taskType: TaskType | undefined;
  columns: Column[];
  noColumns: boolean;
}

export default function WorkspaceTasksTableWrapper({
  taskType,
  columns,
  noColumns,
}: WorkspaceTasksTableWrapperProps) {
  const { workspaceTasks, workspaceTasksTotalRow, areWorkspaceTasksLoading } =
    useSelector((state: RootState) => state.taskReducer);

  const visibleColumns = useMemo(() => {
    return columns.map((column) => {
      return {
        id: column.uuid,
        header: column.name,
        footer: column.name,
        accessorKey: column.uuid,
        size: 180,
        columnFromApi: column,
        totalRow: workspaceTasksTotalRow[column.uuid],
      };
    });
  }, [columns, workspaceTasksTotalRow]);

  const columnsForTable = useMemo(() => {
    if (!taskType) return [];
    return [
      {
        id: "drag-handle",
        accessorKey: "drag-handle",
        header: "",
        size: 48,
      },
      {
        id: "select",
        header: ({ table }: any) => (
          <IndeterminateCheckbox
            {...{
              table,
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row, table }: any) => (
          <IndeterminateCheckbox
            {...{
              row,
              table,
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
        size: 48,
      },
      {
        id: taskType,
        header: taskType,
        footer: taskType,
        accessorKey: taskType,
        size: 240,
      },
      ...visibleColumns,
    ];
  }, [taskType, visibleColumns]);

  const getTasks = () => {
    if (areWorkspaceTasksLoading) return mockWorkspaceTasks.tasks;
    if (noColumns) return mockWorkspaceTasks.manyTasks;
    return workspaceTasks;
  };

  return (
    <WorkspaceTasksTable
      workspaceTasks={getTasks()}
      columns={columnsForTable}
      tableContext="workspaceTasksList"
      loading={areWorkspaceTasksLoading || noColumns}
    />
  );
}
