import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import {
  DictionaryAutoCompletesGeneric,
  MetaData,
} from "src/redux/dictionary/types";
import { getDictionaryAutoComplete } from "src/redux";
import { RootState } from "src/redux/reducers";
import { DictionaryElementsFilter } from "src/app/Task/Subtask/Subtask";
import { tableDataType } from "src/app/components/Table/Table";
import { MFState, MFStateEntries } from "../../types";
import { CustomSwitch } from "../../../../components/CustomSwitch/CustomSwitch";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import { skeletonOptions } from "../../../../dropdowns/DictionaryElementSelector/utils";
import { SelectOption } from "../../SocialMetrics";
import { CustomInputLabel } from "../../../../components/CustomInput/CustomInput";
import { MetaFieldType } from "../../../../methods/getMetaFieldTypeOptions";

import "./DictionarySubFields.scss";

interface Props {
  isLoading: boolean;
  state: MFState;
  dictionaryUuid?: string;
  dictionaryAutoCompletesColumns: DictionaryAutoCompletesGeneric<MetaData>;
  setState: (type: MFStateEntries, value: any) => void;
  isEdited: boolean;
  isGlobalMode: boolean | undefined;
  dataType: tableDataType;
}

const createOption = ({ uuid, name }: { uuid: string; name: string }) => {
  return {
    value: uuid,
    label: (
      <span className="image-select-option dictionary-element-option">
        {name}
      </span>
    ),
    name,
  };
};

const DICTIONARY_META_FIELDS_WHITELIST = [
  MetaFieldType.Date,
  MetaFieldType.Member,
  MetaFieldType.Text,
  MetaFieldType.Number,
  MetaFieldType.Percent,
  MetaFieldType.Currency,
  MetaFieldType.DictionaryElement,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.AggregatedSelect,
];

const VENDOR_DICTIONARY_META_FIELDS_WHITELIST = [
  ...DICTIONARY_META_FIELDS_WHITELIST,
  MetaFieldType.GlobalCreator,
];

function DictionarySubFields(props: Props) {
  const {
    state: {
      wsDictionarySubFieldUuid,
      enableDictionarySubField,
      selectedOption,
      selectedTaskType,
      filterElementsFields,
      areFilterElementsEnabled,
    },
    dictionaryUuid,
    dictionaryAutoCompletesColumns,
    isLoading,
    setState,
    isEdited,
    isGlobalMode,
    dataType,
  } = props;
  const [subFieldOptions, setSubFieldOptions] = useState<SelectOption[]>([]);
  const [dictionaryMetaFieldOptions, setDictionaryMetaFieldOptions] = useState<
    SelectOption[]
  >([]);
  const [selectedSubField, setSelectedSubField] = useState<
    Partial<SelectOption>
  >({});

  const {
    settingsReducer: { globalTaskMetaFields },
  } = useSelector((state: RootState) => state);

  const tasksColumns = useSelector(
    (state: RootState) => state.taskReducer.tasksColumns,
  );

  const dispatch = useDispatch();

  const handleAddToFilterElements = ({
    option,
    fieldName,
  }: {
    option: SelectOption;
    fieldName: keyof DictionaryElementsFilter;
  }) => {
    setState("filterElementsFields", {
      ...filterElementsFields,
      [fieldName]: option.value,
    });
  };

  const fields = isGlobalMode
    ? globalTaskMetaFields
    : tasksColumns.map((taskColumn) => ({
        uuid: taskColumn.metaFieldId,
        name: taskColumn.metaFieldName,
        taskType: taskColumn.metaFieldTaskType,
      }));

  const taskMetaFieldsOptions = fields
    .filter((field) => {
      if (selectedTaskType.value === null) {
        return true;
      }
      return field.taskType === selectedTaskType.value;
    })
    .map(createOption);

  const selectedDictionaryMetaFieldOption = dictionaryMetaFieldOptions.find(
    (option) => {
      if (filterElementsFields) {
        return option.value === filterElementsFields.wsDictionaryMetaFieldUuid;
      }
      return false;
    },
  );

  const selectedGlobalTaskOption = taskMetaFieldsOptions.find((option) => {
    if (filterElementsFields) {
      return option.value === filterElementsFields.wsTaskMetaFieldUuid;
    }
    return false;
  });

  const isWsDictionaryMetaFieldTypeSelected =
    typeof selectedOption?.isWsDictionaryMetaField !== "undefined" &&
    selectedOption?.isWsDictionaryMetaField;

  useEffect(() => {
    if (
      !dictionaryAutoCompletesColumns[`dictionaryType_${dictionaryUuid}`] &&
      dictionaryUuid
    ) {
      dispatch(getDictionaryAutoComplete(dictionaryUuid));
    }

    if (dictionaryAutoCompletesColumns[`dictionaryType_${dictionaryUuid}`]) {
      const columns =
        dictionaryAutoCompletesColumns[`dictionaryType_${dictionaryUuid}`];

      const subFieldOptions = columns
        .filter((item) => DICTIONARY_META_FIELDS_WHITELIST.includes(item.type))
        .map(createOption);

      setSubFieldOptions(subFieldOptions);

      if (isWsDictionaryMetaFieldTypeSelected) {
        const dictionaryMetaFieldOptions = columns
          .filter((item) =>
            VENDOR_DICTIONARY_META_FIELDS_WHITELIST.includes(item.type),
          )
          .map(createOption);
        setDictionaryMetaFieldOptions(dictionaryMetaFieldOptions);
      }

      if (wsDictionarySubFieldUuid) {
        setSelectedSubField(
          subFieldOptions.find(
            (item) => item.value === wsDictionarySubFieldUuid,
          ) ?? {},
        );
      }
    }
  }, [
    dictionaryAutoCompletesColumns,
    dictionaryUuid,
    wsDictionarySubFieldUuid,
    isWsDictionaryMetaFieldTypeSelected,
  ]);

  useEffect(() => {
    if (!areFilterElementsEnabled) {
      setState("filterElementsFields", null);
    }
  }, [areFilterElementsEnabled]);

  return (
    <div className="dictionary-sub-field-wrapper">
      <div className="dictionary-sub-field-wrapper__column">
        <div className="dictionary-sub-field-wrapper__row">
          <div
            className="dictionary-sub-field-wrapper__switch"
            data-qa="dictionary-supporting-field-switch"
          >
            <CustomSwitch
              checked={enableDictionarySubField}
              onChange={() => {
                setState("enableDictionarySubField", !enableDictionarySubField);
              }}
            />
          </div>
          <div className="dictionary-sub-field-wrapper__switch-info">
            <div className="dictionary-sub-field-wrapper__switch-info-header">
              <IDHFormattedMessage
                id="ws_supporting_field_selection"
                defaultMessage="Supporting field"
              />
            </div>
            <div className="dictionary-sub-field-wrapper__switch-info-description">
              <IDHFormattedMessage
                id="ws_supporting_field_selection_description"
                defaultMessage="Allows you to display additional information from other fields related to each other."
              />
            </div>
          </div>
        </div>
        {enableDictionarySubField && (
          <div className="dictionary-sub-field-wrapper__row">
            <div className="dictionary-sub-field-wrapper__sub-fields">
              <CustomInputLabel htmlFor="wsDictionarySubFieldUuid">
                <IDHFormattedMessage
                  id="ws_select_field"
                  defaultMessage="Select field"
                />
              </CustomInputLabel>
              <CustomSelect
                id="wsDictionarySubFieldUuid"
                options={isLoading ? skeletonOptions : subFieldOptions}
                value={selectedSubField}
                onChange={(newOption: SelectOption) => {
                  setSelectedSubField(newOption);
                  setState("wsDictionarySubFieldUuid", newOption.value);
                }}
                isSearchable
              />
            </div>
          </div>
        )}

        {isWsDictionaryMetaFieldTypeSelected &&
          dataType === tableDataType.Task && (
            <>
              <div
                className="dictionary-sub-field-wrapper__row"
                data-qa="dictionary-sub-field-filter-elements"
              >
                <div
                  className="dictionary-sub-field-wrapper__switch"
                  data-qa="dictionary-filter-elements-switch"
                >
                  <CustomSwitch
                    checked={areFilterElementsEnabled}
                    onChange={(e) => {
                      setState("areFilterElementsEnabled", e.target.checked);
                    }}
                    disabled={isEdited}
                  />
                </div>

                <div className="dictionary-sub-field-wrapper__switch-info">
                  <div className="dictionary-sub-field-wrapper__switch-info-header">
                    <IDHFormattedMessage
                      id="ws_filter_elements"
                      defaultMessage="Filter elements"
                    />
                  </div>
                </div>
              </div>

              {areFilterElementsEnabled && (
                <>
                  <div className="dictionary-sub-field-wrapper__row">
                    <div className="dictionary-sub-field-wrapper__sub-fields">
                      <CustomInputLabel>
                        <IDHFormattedMessage
                          id="ws_select_field"
                          defaultMessage="Select field"
                        />
                      </CustomInputLabel>
                      <CustomSelect
                        options={taskMetaFieldsOptions}
                        value={selectedGlobalTaskOption}
                        onChange={(newOption: SelectOption) => {
                          handleAddToFilterElements({
                            option: newOption,
                            fieldName: "wsTaskMetaFieldUuid",
                          });
                        }}
                        disabled={isEdited}
                        isSearchable
                      />
                    </div>
                  </div>

                  <div className="dictionary-sub-field-wrapper__row">
                    <div className="dictionary-sub-field-wrapper__sub-fields">
                      <CustomInputLabel>
                        <IDHFormattedMessage
                          id="ws_dictionary_meta_field"
                          defaultMessage="Dictionary meta field"
                        />
                      </CustomInputLabel>
                      <CustomSelect
                        options={
                          isLoading
                            ? skeletonOptions
                            : dictionaryMetaFieldOptions
                        }
                        value={selectedDictionaryMetaFieldOption}
                        onChange={(newOption: SelectOption) => {
                          handleAddToFilterElements({
                            option: newOption,
                            fieldName: "wsDictionaryMetaFieldUuid",
                          });
                        }}
                        disabled={isEdited}
                        isSearchable
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
      </div>
    </div>
  );
}

export default DictionarySubFields;
