import React, { useState } from "react";
import "./SaveTableViewModal.scss";

import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalText, ModalTitle } from "src/app/components/Modal/Modal";

export function SaveTableViewModal(props) {
  const { onClose, name, setName, saveFunction } = props;

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setName("");
    onClose();
  };

  const handleConfirm = () => {
    if (name.length <= 2) return;

    setLoading(true);
    saveFunction(name);
    setLoading(false);
    handleClose();
  };

  return (
    <Modal
      onClose={handleClose}
      className="save-table-view-modal"
      overlayClassName="save-table-view-modal__overlay"
      variant="medium"
      displayCancelButton
      closeButtonVariant="white-with-border"
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      confirmButtonText={
        <IDHFormattedMessage id="ws_save" defaultMessage="Save" />
      }
      confirmButtonVariant="blue"
      onConfirmClick={handleConfirm}
      confirmButtonDisabled={name?.length <= 2}
      confirmButtonLoading={loading}
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_create_table_view"
          defaultMessage="Create Table View"
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_save_table_view_modal_text"
          defaultMessage="Workspace members will be able to select view under provided name"
        />
      </ModalText>
      <CustomInputLabel>
        <IDHFormattedMessage id="ws_name" defaultMessage="Name" />
      </CustomInputLabel>
      <CustomInput value={name} onChange={(e) => setName(e.target.value)} />
    </Modal>
  );
}
