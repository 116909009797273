import React, { SetStateAction, Dispatch } from "react";
import axios from "axios";

import FieldManagerModal from "src/app/modals/FieldManagerModal/FieldManagerModal";
import responseCodes from "src/utils/responseCodes";
import { API_URLS } from "src/utils/API_URLS";
import { showErrorToast } from "src/utils/methods";
import { FormDataSubmitPayload } from "src/app/modals/FieldManagerModal/types";
import { tableDataType } from "src/app/components/Table/Table";
import { DictionaryField } from "../types";

interface UpdateDictionaryFieldModalProps {
  editedDictionaryUuid: string;
  setIsEditFieldDropdownOpen: Dispatch<SetStateAction<boolean>>;
  editedField: DictionaryField;
  syncLocalDictionaryWithServer: (uuid: string) => Promise<void>;
}

export default function UpdateDictionaryFieldModal({
  editedDictionaryUuid,
  setIsEditFieldDropdownOpen,
  editedField,
  syncLocalDictionaryWithServer,
}: UpdateDictionaryFieldModalProps) {
  const handleFormDataSubmit = async ({
    formData,
  }: {
    formData: FormDataSubmitPayload;
  }) => {
    try {
      const response = await axios.put(API_URLS.updateDictionaryMetaField, {
        wsWorkspaceUuid: formData.wsWorkspaceUuid,
        metaFieldUuid: formData.uuid,
        metaFieldName: formData.fieldName,
        metaFieldType: formData.selectedMetaFieldTypeOption.type,
        metaFieldCurrency: formData.selectedCurrency?.value ?? null,
        wsTeamUuids:
          formData.wsTeamUuids.length === 0 ? null : formData.wsTeamUuids,
        metaFieldWsDictionaryUuid: editedDictionaryUuid,
        wsDictionarySubFieldUuid: formData.wsDictionarySubField?.value ?? null,
        wsDictionaryUuid: formData.wsDictionaryUuid,
        wsDictionaryElementsFilter: {
          wsDictionaryMetaFieldTargetUuid:
            formData.selectedFilterMetaFieldTarget?.value ?? null,
          wsDictionaryMetaFieldFilterByUuid:
            formData.selectedFilterByMetaField?.value ?? null,
        },
      });
      if (response.status >= responseCodes["400_BAD_REQUEST"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      syncLocalDictionaryWithServer(editedDictionaryUuid);
      return true;
    } catch (error) {
      showErrorToast();
      console.error(error);
      return false;
    }
  };

  return (
    <FieldManagerModal
      editedField={editedField}
      handleFormDataSubmit={handleFormDataSubmit}
      onClose={() => setIsEditFieldDropdownOpen(false)}
      dataType={tableDataType.DictionaryManager}
    />
  );
}
