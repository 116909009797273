import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { valueExists } from "src/app/methods/valueExists";

export const sortTableData = ({
  sortingProps,
  sortingDirection,
  membersList,
  tasksAutocomplete,
  dictionaryAutoCompletes,
}) => {
  const [rowA, rowB, id] = sortingProps;
  const metaA = rowA?.original?.metadata?.find((field) => field?.uuid === id);
  const metaB = rowB?.original?.metadata?.find((field) => field?.uuid === id);

  if (!valueExists(metaA?.value ?? null)) {
    return sortingDirection === "sortingDescending" ? -1 : 1;
  }
  if (!valueExists(metaB?.value ?? null)) {
    return sortingDirection === "sortingDescending" ? 1 : -1;
  }

  if (metaA?.value && metaB.value) {
    switch (metaA?.type) {
      case MetaFieldType.Text:
        return metaA?.value.localeCompare(metaB?.value);

      case MetaFieldType.Date: {
        const dateA = new Date(metaA?.value?.date).getTime();
        const dateB = new Date(metaB?.value?.date).getTime();
        return dateA - dateB;
      }
      case MetaFieldType.Member: {
        const memberA = membersList.find((member) => member.id === metaA.value);
        const memberB = membersList.find((member) => member.id === metaB.value);
        if (memberA && memberB) {
          return memberA.name.localeCompare(memberB.name);
        }
      }
      case MetaFieldType.BoolVal:
        return metaA.value * metaB.value;
      case MetaFieldType.SingleSelect: {
        const nameA = metaA.data.singleSelectOptions?.find(
          (select) => select.value === metaA.value,
        ).name;
        const nameB = metaB.data.singleSelectOptions?.find(
          (select) => select.value === metaB.value,
        ).name;
        return nameA.localeCompare(nameB);
      }
      case MetaFieldType.MultiSelect:
      case MetaFieldType.AggregatedSelect: {
        const nameA =
          metaA.data.singleSelectOptions?.find(
            (select) => select.value === metaA.value[0],
          )?.name || "";
        const nameB =
          metaB.data.singleSelectOptions?.find(
            (select) => select.value === metaB.value[0],
          )?.name || "";
        return nameA.localeCompare(nameB);
      }
      case MetaFieldType.DictionaryElement: {
        const { wsDictionaryUuid } = metaA?.data || {};
        const list =
          dictionaryAutoCompletes[`dictionaryType_${wsDictionaryUuid}`] || [];

        const nameA =
          list.find((item) => item.uuid === metaA.value)?.title || "";
        const nameB =
          list.find((item) => item.uuid === metaB.value)?.title || "";

        return nameA.localeCompare(nameB);
      }
      case MetaFieldType.Creator:
      case MetaFieldType.Content:
      case MetaFieldType.PublicationRelated: {
        const taskA = (
          tasksAutocomplete[rowA.original.wsProjectUuid] ?? []
        ).find((task) => task.taskId === metaA.value);
        const taskB = (
          tasksAutocomplete[rowB.original.wsProjectUuid] ?? []
        ).find((task) => task.taskId === metaB.value);

        if (taskA && taskB) {
          return taskA.title.localeCompare(taskB.title);
        }
      }
      default:
        return metaA?.value - metaB?.value;
    }
  }
  if (metaA && metaA?.value && metaB && !metaB.value) {
    return -1;
  }
  if (metaA && !metaA?.value && metaB && metaB.value) {
    return 1;
  }
  return 0;
};
