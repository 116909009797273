import React, { Dispatch, SetStateAction } from "react";

import { ProfileDataTab } from "src/app/Task/tabs/ProfileDataTab/ProfileDataTab";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import Tabs from "../Tabs/Tabs";
import { ProfileTab } from "../GlobalTaskDetails/tabs/GlobalTaskSocialData/GlobalTaskSocialData";
import Tab from "../Tabs/Tab";

import "./SocialData.scss";

interface SocialDataProps {
  providers: ProfileTab[];
  socialProfiles: SocialProfile[];
  profileNotFound: boolean;
  selectedProvider: ProfileTab;
  data: {
    uuid: string;
    title: string;
  };
  profileData: any; // TODO ADD TYPE HERE!!
  setSelectedProvider: Dispatch<SetStateAction<ProfileTab>>;
}

export function SocialData({
  providers,
  selectedProvider,
  data,
  setSelectedProvider,
  socialProfiles,
  profileData,
  profileNotFound,
}: SocialDataProps) {
  return (
    <div className="social-data">
      <Tabs>
        {providers.map((tab) => (
          <Tab
            tabText={tab.label}
            active={selectedProvider.value === tab.value}
            onClick={() => setSelectedProvider(tab)}
          />
        ))}
      </Tabs>

      <ProfileDataTab
        socialProfiles={socialProfiles}
        profileData={profileData}
        selectedSocialProvider={selectedProvider}
        profileNotFound={profileNotFound}
        hasAnySocialProfile
        taskTitle={data.title}
        taskId={data.uuid}
      />
    </div>
  );
}
