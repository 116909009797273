import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { dropNotification } from "src/redux/main/mainActions";
import { RootState } from "src/redux/reducers";
import SingleNotification from "./components/SingleNotification";

export default function StatusBar() {
  const notifications = useSelector(
    (state: RootState) => state.mainReducer.notifications,
  );
  const dispatch = useDispatch();
  return (
    <div className="status-bar">
      {notifications.map((n) => (
        <SingleNotification
          key={n.id}
          onClose={() => {
            dispatch(dropNotification(n.id));
          }}
          type={n.type}
          message={n.message}
        />
      ))}
    </div>
  );
}
