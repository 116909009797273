import React from "react";
import {
  DropResult,
  DroppableProvided,
  DragDropContext,
  Droppable,
} from "react-beautiful-dnd";

interface FieldsListProps {
  onDragEnd: (result: DropResult) => void;
  listRenderer: (provided: DroppableProvided) => React.JSX.Element;
}

function FieldsList({ onDragEnd, listRenderer }: FieldsListProps) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(provided) => listRenderer(provided)}
      </Droppable>
    </DragDropContext>
  );
}

export default FieldsList;
