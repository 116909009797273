import React, { useState } from "react";
import "./TrialExceededModal.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { useSelector } from "react-redux";
import Modal, { ModalTitle, ModalText } from "../../components/Modal/Modal";
import { TrialLimitationsModal } from "../TrialLimitationsModal/TrialLimitationsModal";
import StiggUsageModal, {
  StiggUsageModalMode,
} from "../StiggUsageModal/StiggUsageModal";
import { getBdWsMemberEmail } from "../StiggUsageModal/utils";

function TrialExceededModal(props) {
  const { onClose } = props;
  const [showTrialLimitations, setShowTrialLimitations] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const {
    mainReducer: { identity },
  } = useSelector((state) => state);

  if (identity.subscriptionData) {
    return (
      <StiggUsageModal
        mode={StiggUsageModalMode.Warning}
        onClose={handleClose}
      />
    );
  }

  if (showTrialLimitations) {
    return (
      <TrialLimitationsModal onClose={() => setShowTrialLimitations(false)} />
    );
  }

  return (
    <Modal
      className="trial-exceeded-modal"
      onClose={handleClose}
      displayCancelButton
      closeButtonText="Close"
      variant="medium"
    >
      <ModalTitle>
        <IDHFormattedMessage id="ws_sorry" defaultMessage="Sorry" />
        ...
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_you_have_used_your_trial"
          defaultMessage="You've already used up your trial period."
        />
        &nbsp;
        <span
          className="trial-exceeded-modal__check"
          onClick={() => setShowTrialLimitations(true)}
        >
          <IDHFormattedMessage
            id="ws_check_what_the_trial_period_includes"
            defaultMessage="Check what the trial period includes."
          />
        </span>
        &nbsp;
        <IDHFormattedMessage
          id="ws_if_yout_are_interested_in_purchasing_full_sub_contact_sales_team"
          defaultMessage="If you are interested in purchasing a full subscription, please
        contact our sales team"
        />
        :
        <span className="contact-support-modal__email">
          &nbsp;
          <a href={`mailto:${getBdWsMemberEmail(identity)}`}>
            {getBdWsMemberEmail(identity)}
          </a>
        </span>
      </ModalText>
    </Modal>
  );
}

export { TrialExceededModal };
