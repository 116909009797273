import React from "react";

import Pictogram, {
  PictogramVariants,
} from "../components/Pictogram/Pictogram";
import { MetaFieldType } from "./getMetaFieldTypeOptions";

export const getDictionaryTypes = (identity) => {
  return (
    identity.sidebarItems
      .filter((item) => item.type === "wsDictionary")
      .map((item) => {
        const fileNameWithoutExtension = item.wsDictionaryIcon.replace(
          /\.[^.]+$/,
          "",
        );
        return {
          type: MetaFieldType.DictionaryElement,
          value: `${item.wsDictionaryElementName.toLowerCase()}_${
            item.wsDictionaryUuid
          }`,
          label: (
            <span className="image-select-option">
              <Pictogram
                identifier={fileNameWithoutExtension}
                variant={PictogramVariants.Original}
              />
              {item.wsDictionaryElementName}
            </span>
          ),
          isWsDictionaryMetaField: true,
        };
      }) || []
  );
};

export const getDictionaryIcon = (identity, wsDictionaryUuid) => {
  const item = identity.sidebarItems.find(
    (item) => item.wsDictionaryUuid === wsDictionaryUuid,
  );

  if (!item || item.type !== "wsDictionary") return null;

  const fileNameWithoutExtension = item.wsDictionaryIcon.replace(
    /\.[^.]+$/,
    "",
  );

  return (
    <Pictogram
      identifier={fileNameWithoutExtension}
      variant={PictogramVariants.Original}
    />
  );
};
