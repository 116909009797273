import { CacheService } from './cacheService';
import CachedEntitlement from './cachedEntitlement';

const DEFAULT_TTL_MS = 30 * 1000;

export class InMemoryCacheService implements CacheService {
  private entitlements: Map<string, CachedEntitlement>;
  private lastUpdate: Date | null;
  private lastChecked: Date | null;
  private features: Set<string>;

  constructor(private readonly ttlMs: number = DEFAULT_TTL_MS) {
    this.ttlMs = ttlMs;

    this.entitlements = new Map<string, CachedEntitlement>();
    this.features = new Set<string>();
    this.lastUpdate = null;
    this.lastChecked = null;
  }

  getEntitlement(featureId: string): CachedEntitlement | null {
    return this.entitlements.get(featureId) || null;
  }

  validateFeature(featureId: string): boolean {
    return this.features.has(featureId);
  }

  setFeatures = (features: string[]) => (this.features = new Set<string>(features));

  updateLastChecked() {
    this.lastChecked = new Date();
  }

  setEntitlements(entitlements: Map<string, CachedEntitlement>, lastUpdate: Date) {
    this.entitlements = entitlements;
    this.lastUpdate = lastUpdate;
  }

  getEntitlements(): Map<string, CachedEntitlement> {
    return this.entitlements;
  }

  getLastUpdate(): Date | null {
    return this.lastUpdate;
  }

  isLoaded(): boolean {
    return !!this.lastUpdate;
  }

  isOutdated(): boolean {
    if (this.lastChecked === null) {
      return true;
    } else {
      return new Date().getTime() - this.lastChecked.getTime() > this.ttlMs;
    }
  }
}
