import map from 'lodash/map';
import omit from 'lodash/omit';
import some from 'lodash/some';

import {
  AddonFragment,
  ApplySubscriptionMutation,
  CouponFragment,
  CustomerFragment,
  CustomerPortalFragment,
  CustomerResourceFragment,
  EntitlementCheckResult,
  EntitlementFragment,
  FeatureType,
  CheckoutStateFragment,
  GetCouponsQuery,
  GetMockPaywallQuery,
  GetPaywallQuery,
  MeterType,
  MockPaywallAddonFragment,
  MockPaywallPackageEntitlementFragment,
  MockPaywallPlanFragment,
  MockPaywallPriceFragment,
  MonthlyAccordingTo,
  MonthlyResetPeriodConfigInput,
  PackageEntitlementFragment,
  PlanFragment,
  PriceFragment,
  PricingType,
  PromotionalEntitlementFragment,
  ResetPeriodConfigurationFragment,
  SubscriptionFragment,
  SubscriptionPreviewFragment,
  TotalPriceFragment,
  UsageHistoryFragment,
  WeeklyAccordingTo,
  WeeklyResetPeriodConfigInput,
  SubscriptionInvoiceFragment,
  SubscriptionPreviewV2Fragment,
  SubscriptionPreviewInvoiceFragment,
  ImmediateSubscriptionPreviewInvoiceFragment,
  AddonDependencyFragment,
  MockPaywallAddonDependencyFragment,
  SlimCustomerFragment,
} from '@stigg/api-client-js/src/generated/sdk';

import {
  Addon,
  BooleanEntitlement,
  BooleanEntitlementFallback,
  ApplySubscriptionResults,
  Coupon,
  Currency,
  Customer,
  CustomerModel,
  CustomerPortal,
  CustomerResource,
  EntitlementFeature,
  EntitlementResetPeriod,
  GetCheckoutStateResults,
  MeteredEntitlement,
  MeteredEntitlementFallback,
  Money,
  NumericEntitlement,
  NumericEntitlementFallback,
  PackageEntitlement,
  PaymentMethodDetails,
  Paywall,
  Plan,
  Price,
  PromotionalEntitlement,
  ResetSettings,
  Subscription,
  SubscriptionPreview,
  SubscriptionPreviewDiscount,
  SubscriptionPrice,
  UsageHistoryPoint,
  SubscriptionPreviewV2,
  SubscriptionPreviewInvoice,
  CompatiblePackageGroup,
  DependencyAddon,
  SlimCustomer,
} from '../models';
import CachedEntitlement from '../services/cachedEntitlement';
import { Decision } from '../services/entitlementDecisionService';
import { mapDate } from './dateUtils';

export class ModelMapper {
  mapBooleanEntitlement(entitlement: CachedEntitlement, decision: Decision): BooleanEntitlement {
    return {
      isFallback: false,
      hasAccess: decision.hasAccess,
      accessDeniedReason: decision.accessDeniedReason,
      feature: entitlement.calculatedEntitlement.feature
        ? {
            ...entitlement.calculatedEntitlement.feature,
            isMetered: false,
          }
        : undefined,
    };
  }

  mapNumericEntitlement(entitlement: CachedEntitlement, decision: Decision): NumericEntitlement {
    const { hasUnlimitedUsage, usageLimit } = entitlement.calculatedEntitlement;
    return {
      isFallback: false,
      hasAccess: decision.hasAccess,
      accessDeniedReason: decision.accessDeniedReason,
      isUnlimited: hasUnlimitedUsage,
      value: usageLimit ?? undefined,
      feature: entitlement.calculatedEntitlement.feature
        ? {
            ...entitlement.calculatedEntitlement.feature,
            isMetered: false,
          }
        : undefined,
    };
  }

  mapMeteredEntitlement(
    entitlement: CachedEntitlement,
    decision: Decision,
    requestedUsage?: number,
  ): MeteredEntitlement {
    const { hasUnlimitedUsage, usageLimit, feature } = entitlement.calculatedEntitlement;
    const { currentUsage, resetPeriod, usagePeriodAnchor, usagePeriodStart, usagePeriodEnd } = entitlement.featureUsage;
    let resetSettings: ResetSettings | null = null;
    if (
      feature &&
      feature.featureType === FeatureType.Number &&
      feature.meterType === MeterType.Incremental &&
      resetPeriod &&
      usagePeriodEnd
    ) {
      resetSettings = {
        nextResetDate: usagePeriodEnd,
        resetPeriod,
      };
    }

    return {
      isFallback: false,
      hasAccess: decision.hasAccess,
      accessDeniedReason: decision.accessDeniedReason,
      isUnlimited: hasUnlimitedUsage,
      usageLimit: usageLimit ?? undefined,
      currentUsage: currentUsage,
      requestedUsage: requestedUsage || 0,
      resetPeriod: resetPeriod ?? undefined,
      usagePeriodAnchor,
      usagePeriodStart,
      usagePeriodEnd,
      resetSettings: resetSettings || undefined,
      feature: entitlement.calculatedEntitlement.feature
        ? {
            ...entitlement.calculatedEntitlement.feature,
            meterType: entitlement.calculatedEntitlement.feature.meterType || undefined,
            isMetered: true,
          }
        : undefined,
    };
  }

  mapEntitlement(entitlement: CachedEntitlement, decision: Decision, requestedUsage?: number) {
    const { calculatedEntitlement } = entitlement;
    if (!calculatedEntitlement.feature) {
      return this.mapBooleanEntitlement(entitlement, decision);
    }
    const { featureType, meterType } = calculatedEntitlement.feature;
    const isMetered =
      featureType === FeatureType.Number &&
      (meterType === MeterType.Incremental || meterType === MeterType.Fluctuating);

    switch (featureType) {
      case FeatureType.Boolean:
        return this.mapBooleanEntitlement(entitlement, decision);
      case FeatureType.Number: {
        return isMetered
          ? this.mapMeteredEntitlement(entitlement, decision, requestedUsage)
          : this.mapNumericEntitlement(entitlement, decision);
      }
    }
  }

  mapCachedEntitlement(entitlement: EntitlementFragment): CachedEntitlement {
    let feature: EntitlementFeature | undefined;
    if (entitlement.feature) {
      feature = {
        id: entitlement.feature.refId,
        featureType: entitlement.feature.featureType,
        meterType: entitlement.feature.meterType || undefined,
        units: entitlement.feature.featureUnits || undefined,
        unitsPlural: entitlement.feature.featureUnitsPlural || undefined,
        displayName: entitlement.feature.displayName,
        description: entitlement.feature.description || '',
      };
    }
    return {
      calculatedEntitlement: {
        usageLimit: entitlement.usageLimit,
        hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
        hasSoftLimit: !!entitlement.hasSoftLimit,
        accessDeniedReason: entitlement.accessDeniedReason || undefined,
        feature,
      },
      featureUsage: {
        resetPeriod: entitlement.resetPeriod,
        resetPeriodConfiguration: entitlement.resetPeriodConfiguration,
        currentUsage: entitlement.currentUsage || 0,
        usagePeriodAnchor: mapDate(entitlement.usagePeriodAnchor),
        usagePeriodStart: mapDate(entitlement.usagePeriodStart),
        usagePeriodEnd: mapDate(entitlement.usagePeriodEnd),
      },
    };
  }

  mapCachedEntitlements(updatedEntitlements: EntitlementFragment[]): Map<string, CachedEntitlement> {
    return new Map(
      updatedEntitlements.map((entitlement) => [
        entitlement.feature?.refId || '',
        this.mapCachedEntitlement(entitlement),
      ]),
    );
  }

  mapPlans(resultData: GetPaywallQuery): Plan[] {
    const plans = resultData?.paywall.plans || [];
    return plans.map((plan, index) => this.mapPlan(plan, index));
  }

  mapPaywall(resultData: GetPaywallQuery): Paywall {
    const mappedPlans = this.mapPlans(resultData);
    const configuration = resultData?.paywall.configuration;

    // this customer subscription list is deprecated, so we
    // only return it if no resource (backward compatability)
    const customerSubscriptions = (!resultData?.paywall.resource && resultData?.paywall.activeSubscriptions) || [];
    const customer = resultData?.paywall.customer
      ? this.mapCustomer(resultData?.paywall.customer, customerSubscriptions)
      : null;

    const resource = resultData?.paywall.resource ? this.mapResource(resultData?.paywall.resource) : null;
    const activeSubscriptions = resultData?.paywall.activeSubscriptions
      ? this.mapSubscriptions(resultData?.paywall.activeSubscriptions)
      : null;

    return {
      plans: mappedPlans,
      configuration,
      customer,
      resource,
      activeSubscriptions,
      currency: resultData.paywall.currency,
      paywallCalculatedPricePoints: resultData.paywall.paywallCalculatedPricePoints,
    };
  }

  mapMockPlans(resultData: GetMockPaywallQuery): Paywall {
    const plans = resultData?.mockPaywall.plans || [];
    return {
      plans: plans.map((plan, index) => this.mapPlan(plan, index)),
      configuration: resultData.mockPaywall.configuration,
      customer: null,
      resource: null,
      activeSubscriptions: null,
      currency: { code: Currency.Usd, symbol: '$' },
    };
  }

  mapCoupons(resultData: GetCouponsQuery): Coupon[] {
    return resultData.coupons.edges.map((x) => x.node).map((coupon) => this.mapCoupon(coupon));
  }

  mapResource(resource: CustomerResourceFragment): CustomerResource {
    return {
      id: resource.resourceId,
    };
  }

  mapCustomerPortal(customerPortal: CustomerPortalFragment): CustomerPortal {
    const resource = customerPortal.resource ? this.mapResource(customerPortal.resource) : null;

    return {
      ...customerPortal,
      resource,
    };
  }

  mapCheckoutState(checkoutState: CheckoutStateFragment): GetCheckoutStateResults {
    const { configuration, customer, plan, billingIntegration, resource, activeSubscription, setupSecret } =
      checkoutState;

    return {
      setupSecret,
      configuration,
      customer: this.mapCustomer(customer, activeSubscription ? [activeSubscription] : []),
      plan: this.mapPlan(plan),
      resource: resource ? this.mapResource(resource) : null,
      billingIntegration,
      activeSubscription: activeSubscription ? this.mapSubscription(activeSubscription) : null,
    };
  }

  mapApplySubscriptionResults = (
    graphApplySubscriptionResults: ApplySubscriptionMutation,
  ): ApplySubscriptionResults => {
    const {
      applySubscription: { subscription },
    } = graphApplySubscriptionResults;

    return { subscription: subscription ? this.mapSubscription(subscription) : undefined };
  };

  mapCustomer(customer: CustomerFragment, subscriptions: SubscriptionFragment[]): Customer {
    return new CustomerModel({
      id: customer.refId,
      name: customer.name || undefined,
      email: customer.email || undefined,
      createdAt: new Date(customer.createdAt),
      updatedAt: new Date(customer.updatedAt),
      hasPaymentMethod: customer.hasPaymentMethod,
      subscriptions: map(subscriptions, (x) => this.mapSubscription(x)),
      promotionalEntitlements: map(customer.promotionalEntitlements, (x) => this.mapPromotionalEntitlement(x)),
      metadata: customer.additionalMetaData,
      paymentMethodDetails: this.mapPaymentDetails(customer),
      trialedPlans:
        customer.trialedPlans?.map((trialedPlan) => ({
          productId: trialedPlan.productRefId,
          planId: trialedPlan.planRefId,
        })) || undefined,
      eligibleForTrial: customer.eligibleForTrial?.map((eligibleForTrial) => ({
        productId: eligibleForTrial.productRefId,
        eligible: eligibleForTrial.eligible,
      })),
      experimentInfo: omit(customer.experimentInfo, '__typename'),
    });
  }

  private mapPaymentDetails(customer: CustomerFragment): PaymentMethodDetails | undefined {
    const { defaultPaymentExpirationYear, defaultPaymentExpirationMonth, defaultPaymentMethodLast4Digits } = customer;
    if (
      some([defaultPaymentMethodLast4Digits, defaultPaymentExpirationMonth, defaultPaymentExpirationYear], (x) => x)
    ) {
      return {
        expirationMonth: defaultPaymentExpirationMonth,
        expirationYear: defaultPaymentExpirationYear,
        last4Digits: defaultPaymentMethodLast4Digits,
      };
    }

    return undefined;
  }

  private mapPriceWithTotalPrice(
    pricing: PriceFragment,
    totalPrice: TotalPriceFragment,
    usageLimit?: number | null,
  ): SubscriptionPrice {
    const { feature, tiersMode, tiers, billingModel, billingPeriod, blockSize } = pricing;

    const mappedPrice: SubscriptionPrice = {
      pricingModel: billingModel,
      billingPeriod,
      amount: totalPrice.total.amount,
      grossAmount: totalPrice.subTotal.amount,
      currency: this.getPriceCurrency(pricing),
      tiersMode,
      tiers,
      isTieredPrice: !!tiersMode,
      blockSize,
    };
    if (feature) {
      mappedPrice.feature = {
        featureId: feature.refId,
        displayName: feature.displayName,
        units: feature.featureUnits,
        unitsPlural: feature.featureUnitsPlural,
        unitQuantity: usageLimit,
      };
    }

    return mappedPrice;
  }

  private mapPrice(pricing: PriceFragment | MockPaywallPriceFragment, usageLimit?: number | null): Price {
    const {
      feature,
      price,
      tiers,
      tiersMode,
      billingId,
      billingModel,
      billingPeriod,
      minUnitQuantity,
      maxUnitQuantity,
      billingCountryCode,
      blockSize,
    } = pricing;

    const mappedPrice: Price = {
      pricingModel: billingModel,
      billingPeriod,
      billingId,
      amount: price ? price.amount : null,
      currency: this.getPriceCurrency(pricing),
      tiersMode,
      tiers,
      isTieredPrice: !!tiersMode,
      minUnitQuantity,
      maxUnitQuantity,
      billingCountryCode,
      blockSize,
    };
    if (feature) {
      mappedPrice.feature = {
        featureId: feature.refId,
        displayName: feature.displayName,
        units: feature.featureUnits,
        unitsPlural: feature.featureUnitsPlural,
        unitQuantity: usageLimit,
      };
    }

    return mappedPrice;
  }

  private mapPlan(
    plan: (PlanFragment | MockPaywallPlanFragment) & {
      compatibleAddons?: AddonFragment[] | MockPaywallAddonFragment[] | null;
    },
    order = 0,
  ): Plan {
    return {
      id: plan.refId,
      order,
      displayName: plan.displayName,
      description: plan.description,
      billingId: plan.billingId,
      basePlan: plan.basePlan ? { id: plan.basePlan.refId, displayName: plan.basePlan.displayName } : undefined,
      entitlements: map(plan.entitlements, (entitlement) =>
        this.mapPackageEntitlement(entitlement as PackageEntitlementFragment | EntitlementFragment),
      ),
      inheritedEntitlements: map(plan.inheritedEntitlements, (entitlement) =>
        this.mapPackageEntitlement(entitlement as PackageEntitlementFragment | EntitlementFragment),
      ),
      pricePoints: map(plan.prices, (price) => this.mapPrice(price as PriceFragment | MockPaywallPriceFragment)),
      pricingType: plan.pricingType,
      defaultTrialConfig: this.mapDefaultTrialConfig(plan.defaultTrialConfig),
      compatibleAddons: this.mapCompatibleAddons(plan),
      compatiblePackageGroups: this.mapCompatiblePackageGroups(plan),
      product: {
        id: plan.product.refId,
        displayName: plan.product.displayName,
        description: plan.product.description,
        metadata: plan.product.additionalMetaData,
      },
      metadata: plan.additionalMetaData,
    };
  }

  private mapCompatibleAddons(
    plan: (PlanFragment | MockPaywallPlanFragment) & {
      compatibleAddons?: (AddonFragment | MockPaywallAddonFragment)[] | null;
    },
  ) {
    if (plan.pricingType === PricingType.Free || !plan.compatibleAddons) {
      return [];
    }

    const { compatibleAddons } = plan;

    const filteredCompatibleAddons =
      plan.pricingType === PricingType.Paid
        ? compatibleAddons.filter((addon) => addon.pricingType === PricingType.Paid)
        : compatibleAddons;
    return filteredCompatibleAddons.map((addon: AddonFragment | MockPaywallAddonFragment) => this.mapAddon(addon));
  }

  private mapDefaultTrialConfig(defaultTrialConfig: PlanFragment['defaultTrialConfig']): Plan['defaultTrialConfig'] {
    if (!defaultTrialConfig) {
      return undefined;
    }

    return {
      duration: defaultTrialConfig?.duration,
      units: defaultTrialConfig?.units,
      budget: defaultTrialConfig.budget ? { limit: defaultTrialConfig.budget.limit } : undefined,
    };
  }

  private mapPackageEntitlement(
    entitlement: PackageEntitlementFragment | (MockPaywallPackageEntitlementFragment & { isCustom?: boolean }),
  ): PackageEntitlement {
    const { feature } = entitlement;
    if (!feature) {
      return {
        usageLimit: entitlement.usageLimit || 0,
        hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
        resetPeriod: entitlement.resetPeriod,
      };
    }
    return {
      usageLimit: entitlement.usageLimit || 0,
      feature: {
        id: feature.refId,
        featureType: feature.featureType,
        description: feature.description || '',
        meterType: feature.meterType || undefined,
        units: feature.featureUnits || undefined,
        unitsPlural: feature.featureUnitsPlural || undefined,
        displayName: feature.displayName,
        isMetered: feature.meterType === MeterType.Fluctuating || feature.meterType === MeterType.Incremental,
        metadata: feature.additionalMetaData,
      },
      isCustom: entitlement.isCustom || undefined,
      hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
      resetPeriod: entitlement.resetPeriod,
      hiddenFromWidgets: entitlement.hiddenFromWidgets,
      displayNameOverride: entitlement.displayNameOverride,
    };
  }

  private mapPromotionalEntitlement(entitlement: PromotionalEntitlementFragment): PromotionalEntitlement {
    const { feature } = entitlement;
    return {
      status: entitlement.status,
      usageLimit: entitlement.usageLimit || 0,
      feature: {
        id: feature.refId,
        displayName: feature.displayName,
        description: feature.description || '',
        featureType: feature.featureType,
        meterType: feature.meterType || undefined,
        units: feature.featureUnits || undefined,
        unitsPlural: feature.featureUnitsPlural || undefined,
        isMetered: feature.meterType === MeterType.Fluctuating || feature.meterType === MeterType.Incremental,
      },
      hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
      expiresAt: entitlement.endDate,
      hasExpirationDate: !!entitlement.endDate,
      isVisible: entitlement.isVisible,
    };
  }

  mapSlimCustomer = (customer: SlimCustomerFragment): SlimCustomer => {
    return {
      id: customer.refId,
      name: customer.name || undefined,
      email: customer.email || undefined,
      billingId: customer.billingId || undefined,
      createdAt: new Date(customer.createdAt),
      updatedAt: new Date(customer.updatedAt),
      metadata: customer.additionalMetaData,
      awsMarketplaceCustomerId: customer.awsMarketplaceCustomerId,
    };
  };

  mapSubscription(subscription: SubscriptionFragment): Subscription {
    const { totalPrice } = subscription;
    const [planPrice] = subscription.prices || [];
    return {
      id: subscription.subscriptionId,
      billingId: subscription.billingId || undefined,
      resourceId: subscription.resource?.resourceId,
      payingCustomer: subscription.payingCustomer ? this.mapSlimCustomer(subscription.payingCustomer) : null,
      status: subscription.status,
      currentBillingPeriodEnd: this.mapDate(subscription.currentBillingPeriodEnd),
      plan: this.mapPlan(subscription.plan),
      prices: subscription.prices
        ? subscription.prices
            .filter((price) => price.price !== null)
            .map((price) => this.mapPrice(price.price!, price.usageLimit))
        : [],
      totalPrice,
      price:
        planPrice && totalPrice && planPrice.price
          ? this.mapPriceWithTotalPrice(planPrice.price, totalPrice, planPrice.usageLimit)
          : null,
      pricingType: subscription.pricingType,
      addons:
        subscription.addons?.map(({ quantity, addon }) => ({
          quantity: quantity,
          addon: this.mapAddon(addon),
        })) || [],
      startDate: subscription.startDate,
      endDate: this.mapDate(subscription.endDate),
      trialEndDate: this.mapDate(subscription.trialEndDate),
      cancellationDate: this.mapDate(subscription.cancellationDate),
      effectiveEndDate: this.mapDate(subscription.effectiveEndDate),
      metadata: subscription.additionalMetaData,
      experimentInfo: omit(subscription.experimentInfo, '__typename'),
      scheduledUpdates: subscription.scheduledUpdates || [],
      futureUpdates: subscription.futureUpdates || [],
      latestInvoice: this.mapLatestInvoice(subscription.latestInvoice),
      paymentCollection: subscription.paymentCollection,
      paymentCollectionMethod: subscription.paymentCollectionMethod || undefined,
    };
  }

  private mapLatestInvoice(latestInvoice: SubscriptionInvoiceFragment | undefined | null) {
    return latestInvoice
      ? ({
          ...latestInvoice,
          createdAt: this.mapDate(latestInvoice.createdAt),
          updatedAt: this.mapDate(latestInvoice.updatedAt),
        } as SubscriptionInvoiceFragment)
      : undefined;
  }

  private mapDate(date?: Date) {
    return date ? new Date(date) : undefined;
  }

  private mapAddon(addon: AddonFragment | MockPaywallAddonFragment): Addon {
    return {
      id: addon.refId,
      displayName: addon.displayName,
      description: addon.description || '',
      billingId: addon.billingId,
      entitlements: map(addon.entitlements, (x) =>
        this.mapPackageEntitlement(x as PackageEntitlementFragment | EntitlementFragment),
      ),
      pricePoints: map(addon.prices, (price) => this.mapPrice(price as PriceFragment | MockPaywallPriceFragment)),
      pricingType: addon.pricingType,
      dependencies: addon.dependencies?.map((dependency) => this.mapDependencyAddon(dependency)) || [],
      metadata: addon.additionalMetaData,
    };
  }

  private getResetPeriodData = (
    resetPeriod?: EntitlementResetPeriod | null,
    resetPeriodConfiguration?: ResetPeriodConfigurationFragment | null,
  ): {
    weeklyResetPeriodConfiguration?: WeeklyResetPeriodConfigInput;
    monthlyResetPeriodConfiguration?: MonthlyResetPeriodConfigInput;
  } => {
    switch (resetPeriod) {
      case EntitlementResetPeriod.Month:
        const monthlyConfig = resetPeriodConfiguration as
          | { monthlyAccordingTo?: MonthlyAccordingTo | null }
          | undefined;
        return {
          monthlyResetPeriodConfiguration: {
            accordingTo: monthlyConfig?.monthlyAccordingTo || MonthlyAccordingTo.SubscriptionStart,
          },
        };
      case EntitlementResetPeriod.Week:
        const weeklyConfig = resetPeriodConfiguration as { weeklyAccordingTo?: WeeklyAccordingTo | null } | undefined;
        return {
          weeklyResetPeriodConfiguration: {
            accordingTo: weeklyConfig?.weeklyAccordingTo || WeeklyAccordingTo.EveryFriday,
          },
        };
      default:
        return {};
    }
  };

  mapFallbackBooleanEntitlementResult(
    entitlement: BooleanEntitlementFallback,
    decision: Decision,
  ): EntitlementCheckResult {
    return {
      hasAccess: entitlement.hasAccess,
      accessDeniedReason: decision.accessDeniedReason,
    };
  }

  mapFallbackNumericEntitlementResult(
    entitlement: NumericEntitlementFallback,
    decision: Decision,
  ): EntitlementCheckResult {
    return {
      hasAccess: entitlement.hasAccess,
      accessDeniedReason: decision.accessDeniedReason,
      usageLimit: entitlement.value,
      hasUnlimitedUsage: entitlement.isUnlimited,
    };
  }

  mapEntitlementResult(
    decision: Decision,
    entitlement?: CachedEntitlement,
    requestedUsage?: number,
  ): EntitlementCheckResult {
    return {
      hasAccess: decision.hasAccess,
      accessDeniedReason: decision.accessDeniedReason,
      hasUnlimitedUsage: entitlement?.calculatedEntitlement.hasUnlimitedUsage,
      usageLimit: entitlement?.calculatedEntitlement.usageLimit,
      currentUsage: entitlement?.featureUsage.currentUsage,
      requestedUsage,
      nextResetDate: entitlement?.featureUsage.usagePeriodEnd,
      resetPeriod: entitlement?.featureUsage.resetPeriod,
      ...this.getResetPeriodData(
        entitlement?.featureUsage.resetPeriod,
        entitlement?.featureUsage.resetPeriodConfiguration,
      ),
    };
  }

  mapFallbackMeteredEntitlementResult(
    entitlement: MeteredEntitlementFallback,
    decision: Decision,
    requestedUsage?: number,
  ): EntitlementCheckResult {
    return {
      hasAccess: entitlement.hasAccess,
      accessDeniedReason: decision.accessDeniedReason,
      hasUnlimitedUsage: entitlement.isUnlimited,
      usageLimit: entitlement.usageLimit,
      requestedUsage,
    };
  }

  mapTaxFields(
    fragment:
      | SubscriptionPreviewFragment
      | SubscriptionPreviewFragment['subscription']
      | SubscriptionPreviewInvoiceFragment
      | ImmediateSubscriptionPreviewInvoiceFragment,
  ): Pick<SubscriptionPreview, 'tax' | 'taxDetails'> {
    if (!fragment?.tax || !fragment?.taxDetails) {
      return {};
    }

    return {
      tax: this.mapMoney(fragment.tax),
      taxDetails: {
        displayName: fragment.taxDetails.displayName,
        percentage: fragment.taxDetails.percentage,
        inclusive: fragment.taxDetails.inclusive,
      },
    };
  }

  mapSubscriptionPreview(fragment: SubscriptionPreviewFragment): SubscriptionPreview {
    const { subscription, proration, billingPeriodRange, credits } = fragment;
    return {
      total: this.mapMoney(fragment.total),
      totalExcludingTax: this.mapMoney(fragment.totalExcludingTax),
      subTotal: this.mapMoney(fragment.subTotal),
      discountAmount: fragment.discountAmount ? this.mapMoney(fragment.discountAmount) : undefined,
      ...this.mapTaxFields(fragment),
      ...(fragment.discount ? { discount: this.mapDiscount(fragment.discount) } : {}),
      billingPeriodRange: {
        start: billingPeriodRange.start && new Date(billingPeriodRange.start),
        end: billingPeriodRange.end && new Date(billingPeriodRange.end),
      },
      proration: proration
        ? {
            credit: this.mapMoney(proration.credit),
            debit: this.mapMoney(proration.debit),
            netAmount: this.mapMoney(proration.netAmount),
            prorationDate: new Date(proration.prorationDate),
          }
        : undefined,
      subscription: subscription
        ? {
            total: this.mapMoney(subscription.total),
            totalExcludingTax: this.mapMoney(subscription.totalExcludingTax),
            subTotal: this.mapMoney(subscription.subTotal),
            discountAmount: subscription.discountAmount ? this.mapMoney(subscription.discountAmount) : undefined,
            ...(subscription.discount ? { discount: this.mapDiscount(subscription.discount) } : {}),
            ...this.mapTaxFields(subscription),
          }
        : undefined,
      isPlanDowngrade: !!fragment.isPlanDowngrade,
      hasScheduledUpdates: !!fragment.hasScheduledUpdates,
      credits: credits
        ? {
            initial: this.mapMoney(credits.initial),
            used: this.mapMoney(credits.used),
            remaining: this.mapMoney(credits.remaining),
          }
        : undefined,
    };
  }

  mapSubscriptionPreviewV2(fragment: SubscriptionPreviewV2Fragment): SubscriptionPreviewV2 {
    const { immediateInvoice, recurringInvoice, billingPeriodRange, hasScheduledUpdates, isPlanDowngrade } = fragment;

    return {
      immediateInvoice: {
        ...this.mapSubscriptionPreviewInvoice(immediateInvoice),
        proration: immediateInvoice.proration
          ? {
              credit: this.mapMoney(immediateInvoice.proration.credit),
              debit: this.mapMoney(immediateInvoice.proration.debit),
              netAmount: this.mapMoney(immediateInvoice.proration.netAmount),
              prorationDate: new Date(immediateInvoice.proration.prorationDate),
              hasProrations: immediateInvoice.proration.hasProrations || undefined,
            }
          : undefined,
        credits: immediateInvoice.credits
          ? {
              initial: this.mapMoney(immediateInvoice.credits.initial),
              used: this.mapMoney(immediateInvoice.credits.used),
              remaining: this.mapMoney(immediateInvoice.credits.remaining),
            }
          : undefined,
      },

      recurringInvoice: recurringInvoice
        ? {
            ...this.mapSubscriptionPreviewInvoice(recurringInvoice),
          }
        : undefined,
      billingPeriodRange: {
        start: billingPeriodRange.start && new Date(billingPeriodRange.start),
        end: billingPeriodRange.end && new Date(billingPeriodRange.end),
      },
      isPlanDowngrade: !!isPlanDowngrade,
      hasScheduledUpdates: !!hasScheduledUpdates,
    };
  }

  mapSubscriptionPreviewInvoice(
    fragment: SubscriptionPreviewInvoiceFragment | ImmediateSubscriptionPreviewInvoiceFragment,
  ): SubscriptionPreviewInvoice {
    return {
      total: this.mapMoney(fragment.total),
      subTotal: this.mapMoney(fragment.subTotal),
      totalExcludingTax: this.mapMoney(fragment.totalExcludingTax),
      discount: fragment.discount ? this.mapMoney(fragment.discount) : undefined,
      ...(fragment.discountDetails ? { discountDetails: this.mapDiscount(fragment.discountDetails) } : {}),
      ...this.mapTaxFields(fragment),
    };
  }

  mapDiscount(discount: NonNullable<SubscriptionPreviewFragment['discount']>): SubscriptionPreviewDiscount {
    const { name, type, value, durationType, durationInMonths } = discount;
    return { name, type, value, durationType, ...(durationInMonths ? { durationInMonths } : {}) };
  }

  mapMoney(fragment: { amount: number; currency: string }): Money {
    return {
      amount: fragment.amount,
      currency: fragment.currency,
    };
  }

  private mapCoupon(coupon: CouponFragment): Coupon {
    return {
      id: coupon.refId,
      name: coupon.name,
      description: coupon.description,
      metadata: coupon.additionalMetaData,
      discountValue: coupon.discountValue,
      percentOff: coupon.percentOff,
      amountsOff: coupon.amountsOff,
    };
  }

  mapSubscriptions(
    activeSubscriptions: Array<{ __typename?: 'CustomerSubscription' } & SubscriptionFragment>,
  ): Subscription[] {
    return activeSubscriptions.map((subscription) => this.mapSubscription(subscription));
  }

  mapUsageHistory(usageHistory: UsageHistoryFragment): UsageHistoryPoint[] {
    return usageHistory.usageMeasurements.map(({ date, value, isResetPoint }) => {
      return {
        date: new Date(date),
        value: value,
        isResetPoint: !!isResetPoint,
      };
    });
  }

  private getPriceCurrency({ price, tiers }: Pick<PriceFragment, 'price' | 'tiers'>): Currency {
    return price?.currency || tiers?.[0].unitPrice?.currency || tiers?.[0].flatPrice?.currency || Currency.Usd;
  }

  private mapCompatiblePackageGroups(plan: PlanFragment | MockPaywallPlanFragment): CompatiblePackageGroup[] {
    if (plan.pricingType === PricingType.Free || !plan.compatiblePackageGroups) {
      return [];
    }

    const { compatiblePackageGroups } = plan;
    return compatiblePackageGroups.map((group) => {
      const { packageGroupId, displayName, options, addons } = group;
      return {
        packageGroupId,
        displayName,
        addons: addons?.map((addon) => this.mapAddon(addon)) || [],
        options: {
          minItems: options?.minItems || undefined,
          freeItems: options?.freeItems || undefined,
        },
      };
    });
  }

  private mapDependencyAddon(addon: AddonDependencyFragment | MockPaywallAddonDependencyFragment): DependencyAddon {
    return {
      refId: addon.refId,
      displayName: addon.displayName,
      description: addon.description || undefined,
    };
  }
}
