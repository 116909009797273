import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import { DictionaryField } from "src/app/SettingsModal/tabs/DictionaryManager/types";
import { SelectOption } from "src/app/components/CustomSelect/types";
import { createOption } from "src/app/components/CustomSelect/functions/createOption";
import { FieldManagerFormData, FieldManagerFormDataKeys } from "../../types";
import { DICTIONARY_SUB_FIELDS_TYPE_WHITELIST } from "./utils";

interface DictionarySupportingFieldProps {
  editedField: DictionaryField | undefined;
  handleUpdateFormData: (
    key: FieldManagerFormDataKeys,
    value: FieldManagerFormData[FieldManagerFormDataKeys],
  ) => void;
  wsDictionarySubField: SelectOption | null;
  selectedDictionaryUuid: string | null;
}

export default function DictionarySupportingField({
  editedField,
  handleUpdateFormData,
  wsDictionarySubField,
  selectedDictionaryUuid,
}: DictionarySupportingFieldProps) {
  const { dictionaryAutoCompletesColumns } = useSelector(
    (state: RootState) => state.dictionaryReducer,
  );

  const subFieldOptions = useMemo(() => {
    const columns =
      dictionaryAutoCompletesColumns[
        `dictionaryType_${selectedDictionaryUuid}`
      ] ?? [];

    const generatedSubFieldOptions = columns
      .filter((column) =>
        DICTIONARY_SUB_FIELDS_TYPE_WHITELIST.includes(column.type),
      )
      .map(createOption);

    return generatedSubFieldOptions;
  }, [selectedDictionaryUuid, dictionaryAutoCompletesColumns]);

  useEffect(() => {
    if (editedField && editedField.data.wsDictionarySubFieldUuid) {
      const foundSelectedSubFieldOption = subFieldOptions.find(
        (option) => option.value === editedField.data.wsDictionarySubFieldUuid,
      );

      if (foundSelectedSubFieldOption) {
        handleUpdateFormData(
          "wsDictionarySubField",
          foundSelectedSubFieldOption,
        );
      }
    }
  }, [editedField, subFieldOptions]);

  return (
    <div className="dictionary-sub-field-wrapper__row">
      <div className="dictionary-sub-field-wrapper__sub-fields">
        <CustomInputLabel htmlFor="wsDictionarySubFieldUuid">
          <IDHFormattedMessage
            id="ws_select_field"
            defaultMessage="Select field"
          />
        </CustomInputLabel>
        <CustomSelect
          id="wsDictionarySubFieldUuid"
          options={subFieldOptions}
          value={wsDictionarySubField}
          onChange={(newOption: SelectOption) => {
            handleUpdateFormData("wsDictionarySubField", newOption);
          }}
          isSearchable
        />
      </div>
    </div>
  );
}
