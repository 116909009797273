import DOMPurify from "dompurify";
import React from "react";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RouterChildContext } from "react-router";
import { Dispatch } from "redux";
import {
  getDictionaryAutoComplete,
  setDictionaryAutoCompleteLoading,
} from "src/redux";
import linkifyHtml from "linkify-html";
import { ReduxError, TaskType } from "src/types";
import { WsTableView } from "src/redux/dictionary/types";
import Pictogram, {
  PictogramVariants,
} from "../app/components/Pictogram/Pictogram";
import { showToast } from "../app/methods/showToast";
import { IMember } from "../app/project/ProjectTypes";
import { getEmoji } from "../app/components/EmojiPicker/getEmoji";
import {
  getSelectDataSetOptions,
  setSelectDataSetOptionsLoading,
} from "../redux/select-data-set/selectDataSetActions";

export const addZero = (item: number) => (item >= 10 ? item : `0${item}`);

export const getFullDate = (date: Date) => {
  const day = addZero(Number(date.getDate()));
  const month = addZero(Number(date.getMonth() + 1));
  const year = addZero(Number(date.getFullYear()));

  return `${day}.${month}.${year}`;
};

export const getFullTime = (date: Date) => {
  const hours = addZero(Number(date.getHours()));
  const minutes = addZero(Number(date.getMinutes()));

  return `${hours}:${minutes}`;
};

export const splitNumbers = (number: number | string) => {
  if (number === null || number === undefined) {
    return 0;
  }

  const [integer, fraction] = String(number).split(".");
  const splitInteger = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return fraction ? `${splitInteger}.${fraction}` : splitInteger;
};

export const mergeNumbers = (number: string) => number.replace(/ /g, "");

export const splitPercents = (number: number | string) => {
  const result =
    Math.round(
      (typeof number === "string" ? parseFloat(number) : number) * 10000,
    ) / 100;
  return splitNumbers(result);
};

export const isEmailValid = (email: string) =>
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    email,
  );

export const isStringUuid = (value: string | null | undefined) => {
  if (typeof value !== "string") return false;
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[4|7][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    value,
  );
};

export const encodeMentions = (
  content: string,
  usedMentions: { label: string; code: string }[],
) => {
  let encodedContent = content;

  usedMentions.forEach((mention: any) => {
    if (encodedContent.includes(mention.label)) {
      encodedContent = encodedContent.replace(mention.label, mention.code);
    }
  });

  return encodedContent;
};

const HtmlParser = (html: string, allowedTags: any) => {
  const htmlWithNewLines = html.split(/\r\n|\r|\n/g).join("<br/>");

  return DOMPurify.sanitize(htmlWithNewLines, {
    ALLOWED_TAGS: allowedTags,
    ADD_ATTR: ["target", "class"],
  });
};

export const decodeMention = (
  content: string,
  membersList: IMember[],
  skipParsing?: boolean,
) => {
  const matches = content.match(/\[([a-zA-Z0-9:-]*?)\]/g);
  let decodedContent = content;

  if (matches !== null && matches?.length > 0 && membersList?.length > 0) {
    matches.forEach((key) => {
      const uuid = key.match(
        /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
      );

      if (uuid) {
        const wsMemberUuid = uuid[0];

        const member = membersList.find(
          (memberItem: IMember) => memberItem.id === wsMemberUuid,
        );

        if (member) {
          decodedContent = decodedContent.replace(
            key,
            `<span class='mention mention--highlight'>@${member.name}</span> `,
          );
        } else {
          decodedContent = decodedContent.replace(
            key,
            "<span class='mention mention--highlight'>@unknown member</span> ",
          );
        }
      }
    });
  }

  if (skipParsing) {
    return decodedContent;
  }

  return HtmlParser(decodedContent, ["span", "br", "b", "i"]);
};

export const decodeHyperlinks = (text: string) => {
  if (typeof text !== "string") return "";

  const urlRegex =
    /(?:(?:http|https):\/\/)?([-a-zA-Z0-9.]{2,256}\.[a-z]{2,4})\b(?:\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

  return DOMPurify.sanitize(
    text.replace(urlRegex, (url) => {
      let href = url;
      const prefix = "https://";

      // Add protocol to href if missing
      if (href.substring(0, prefix.length) !== prefix) {
        href = prefix + href;
      }

      return `<a class='ws-hyperlink' target="_blank" rel="nofollow noreferrer noopener" href="${href}">${url}</a>`;
    }),
    {
      ALLOWED_TAGS: ["a", "b", "i"],
      ADD_ATTR: ["target", "class", "rel"],
    },
  );
};

const findDiff = (str1: string, str2: string) => {
  let diff = "";
  str2.split("").forEach((val, i) => {
    if (val != str1.charAt(i)) diff += val;
  });
  return diff;
};

export const convertEmojis = (newValue: string, prevValue: string) => {
  // if (findDiff(prevValue, newValue) !== " ") return newValue;

  const dividedValue = newValue.split(" ");
  let newValueCopy = newValue;

  dividedValue.forEach((item) => {
    let valueCopy = item;

    if (valueCopy === "&lt;3") {
      valueCopy = ":heart:";
    }

    if (
      newValue.length > prevValue.length &&
      valueCopy?.length > 1 &&
      valueCopy[0] === ":"
    ) {
      if (getEmoji(valueCopy)) {
        newValueCopy = newValueCopy.replace(item, getEmoji(valueCopy) || "");
      }
    }
  });

  return newValueCopy;
};

export const capitalizeFirstLetter = (string: string | null | undefined) =>
  string && string.charAt(0).toUpperCase() + string.slice(1);

export const canDisplayChart = (arg: object | null | any[]): boolean => {
  if (arg === null) {
    return false;
  }
  if (arg instanceof Array) {
    if (arg.length > 1) {
      return true;
    }

    return false;
  }
  if (arg instanceof Object) {
    return Object.keys(arg).length > 1;
  }
  return false;
};

export const isEmpty = (arg: object | null | any[]): boolean => {
  // This is used because sometimes backend response with empty array for empty object instead of null

  if (arg === null) {
    return true;
  }
  if (arg instanceof Array) {
    if (arg.length > 0) {
      return false;
    }

    return true;
  }
  if (arg instanceof Object) {
    return Object.keys(arg).length === 0;
  }
  return true;
};

export const correctAgeLabel = (label: string) => {
  if (label === "below18") {
    return "< 18";
  }
  if (label === "above45") {
    return "> 45";
  }
  return label;
};

export const formatAudienceReachabilityLabel = (label: string) => {
  let formattedLabel = label;

  if (label?.trim().startsWith("-")) {
    formattedLabel = formattedLabel.replace("-", "< ");
  }

  if (label?.trim().endsWith("-")) {
    formattedLabel = `> ${formattedLabel.replace("-", "")}`;
  }

  formattedLabel = formattedLabel.replace("-", " - ");

  const regex = /\b(\d+)\b/g;
  const numbers = formattedLabel.match(regex);

  if (numbers) {
    const formattedNumbers = numbers.map((num) => {
      if (parseFloat(num) < 1000) {
        return num;
      }
      if (parseFloat(num) < 1000000) {
        return `${parseFloat((parseFloat(num) / 1000).toFixed(1))}K`;
      }
      return `${parseFloat((parseFloat(num) / 1000000).toFixed(1))}M`;
    });

    for (let i = 0; i < numbers.length; i++) {
      formattedLabel = formattedLabel.replace(numbers[i], formattedNumbers[i]);
    }
  }

  return formattedLabel;
};

export const mapObjectToKeyPairArray = (obj: any, key2: string) =>
  Object.keys(obj).map((key) => ({
    value: obj[key],
    [key2]: key,
  }));

export const copyLink = (link: string) => {
  navigator.clipboard.writeText(link).then(
    () => {
      showToast(
        "success",
        <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
        <IDHFormattedMessage
          id="ws_link_copied"
          defaultMessage="Link copied!"
        />,
      );
    },
    (err) => {
      showToast(
        "error",
        <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
        <IDHFormattedMessage
          id="ws_error_while_copying"
          defaultMessage="Error while copying!"
        />,
      );
      console.error("Async: Could not copy text: ", err);
    },
  );
};

export const setCaretPosition = (el: any, pos: number) => {
  for (const node of el.childNodes) {
    if (node.nodeType == 3) {
      if (node.length >= pos) {
        const range = document.createRange();
        const sel = window.getSelection() as any;
        range.setStart(node, pos);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        return -1;
      }
      pos -= node.length;
    } else {
      pos = setCaretPosition(node, pos);
      if (pos == -1) {
        return -1;
      }
    }
  }
  return pos;
};

export const extractEmails = (text: string) =>
  text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);

export const scrollToElementBottom = (id: string) => {
  const el = document.querySelector(id);
  el?.scrollTo(0, el.clientHeight + window.innerHeight);
};

export const openUrlInNewTab = (url: string) => {
  if (url) window.open(url, "_blank")?.focus();
};

export const getExtensionFromName = (name: string) =>
  String(/[^.]+$/.exec(name));

export const toFixedIfNecessary = (value: any, dp: number) => {
  if (!value) return Number(0).toFixed(dp);
  return +parseFloat(value).toFixed(dp);
};

export const scrollbarVisible = (element: HTMLElement) =>
  element?.scrollHeight > element?.clientHeight;

export const loadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const loadImg = new Image();
    loadImg.src = src;
    loadImg.onload = () => resolve(src);
    loadImg.onerror = (err) => reject(err);
  }).catch((err) => {});

export const layoutsAreEqual = (x: any, y: any) => {
  if (x.length !== y.length) return false;

  for (let i = 0; i < x.length; i++) {
    if (
      x[i].i !== y[i].i ||
      x[i].x !== y[i].x ||
      x[i].y !== y[i].y ||
      x[i].h !== y[i].h ||
      x[i].w !== y[i].w
    ) {
      return false;
    }
  }
  return true;
};

export const separatorsAreEqual = (x: any, y: any) => {
  if (x.length !== y.length) return false;

  for (let i = 0; i < x.length; i++) {
    if (x[i].key !== y[i].key || x[i].provider !== y[i].provider) {
      return false;
    }
  }
  return true;
};

export const pagesAreEqual = (x: any, y: any) => {
  if (x.length !== y.length) return false;

  for (let i = 0; i < x.length; i++) {
    const elX = x[i].elements.filter(
      (item: any) => !item.i.includes("separator"),
    );
    const elY = y[i].elements.filter(
      (item: any) => !item.i.includes("separator"),
    );
    if (elX.length !== elY.length) {
      return false;
    }
  }
  return true;
};

export const selectOptionsAreEqual = (x: any, y: any) => {
  if (x?.length !== y?.length) return false;

  for (let i = 0; i < x.length; i++) {
    if (x[i] !== y[i]) {
      return false;
    }
  }
  return true;
};

export const formatNumber = (num: number) => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, "")}G`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, "")}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, "")}K`;
  }
  return num;
};

export const stripEmojis = (str: string) =>
  str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      "",
    )
    .replace(/\s+/g, " ")
    .trim();

export const getImageSource = (
  cover: any,
  type: "big" | "original" | "small" | "tiny",
  placeholder?: string,
) => {
  if (!cover?.thumbnails) return placeholder || null;

  // Return chosen thumbnail
  if (cover.thumbnails[type]) {
    return cover.thumbnails[type];
  }
  // Return default thumbnail
  if (cover.publicUrl) {
    return cover.publicUrl;
  }

  return placeholder || null;
};

export const isThumbnailsExist = (
  cover: any,
  type: "big" | "original" | "small" | "tiny",
) => {
  if (!cover?.thumbnails) return false;

  // Return chosen thumbnail
  if (cover.thumbnails[type]) {
    return true;
  }

  return false;
};

export const getDictionaryUuidFromCurrentUrl = () => {
  const urlParams = window.location.pathname.split("/dict/");
  const dictUuid = urlParams[1]?.slice(-36);

  if (dictUuid == null) {
    return null;
  }

  const dictUuidFromUrl = dictUuid.match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$",
  );

  if (dictUuidFromUrl == null) {
    return null;
  }

  return dictUuidFromUrl[0];
};

export const getWorkspaceUuidFromCurrentUrl = () => {
  const url = window.location.pathname;
  let wsWorkspaceUuid = url.replace("/workspace/", "");

  wsWorkspaceUuid = wsWorkspaceUuid.substr(0, 36);

  const wsWorkspaceUuidFromUrl = wsWorkspaceUuid.match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$",
  );

  if (wsWorkspaceUuidFromUrl === null) {
    return null;
  }

  return wsWorkspaceUuidFromUrl[0];
};

export const isCurrentUrlRequiresAuthorization = () => {
  const url = window.location.pathname;
  const URLS_WITHOUT_AUTHORIZATION = [
    "workspace/access",
    "workspace/recruitment-form",
    "workspace/submission-form",
    "workspace/credibility-tool",
  ];

  for (let i = 0; i < URLS_WITHOUT_AUTHORIZATION.length; i++) {
    if (url.indexOf(URLS_WITHOUT_AUTHORIZATION[i]) > -1) {
      return false;
    }
  }

  return true;
};

export const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

export const getRandomString = (length: number) => {
  let text = "";
  const charset =
    "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (let i = 0; i < length; i++) {
    text += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return text;
};

export function convertFileName(text: string) {
  return text.replace(/[^a-z0-9]/gi, "-").toLowerCase();
}

export const isStringNumeric = (str: string) => {
  if (typeof str !== "string") return false; // we only process strings!
  return (
    !Number.isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export const wrapEmojis = (txt: string) => {
  const regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return txt.replace(
    regex,
    (emoji: any) => `<span class="emoji">${emoji}</span>`,
  );
};

export const urlIncludes = (string: string) =>
  window.location.href.indexOf(string) > -1;

export const getDictionaryAutoCompletesForColumns = (
  dispatch: Dispatch,
  columns: { type: string; options: { wsDictionaryUuid?: string } }[],
) => {
  if (!columns.length) return;

  const uuids: string[] = [];

  columns.map((column) => {
    if (column.type !== "dictionaryElement") return;
    const { wsDictionaryUuid } = column?.options || {};
    if (wsDictionaryUuid && !uuids.includes(wsDictionaryUuid)) {
      uuids.push(wsDictionaryUuid);
    }
  });

  if (!uuids.length) return;
  dispatch(setDictionaryAutoCompleteLoading(true));
  uuids.forEach((id) => {
    dispatch(getDictionaryAutoComplete(id));
  });
  dispatch(setDictionaryAutoCompleteLoading(false));
};

export const getSelectDataSetForColumns = (
  dispatch: Dispatch,
  columns: { type: string; options: { wsSelectDataSetUuid?: string } }[],
) => {
  if (!columns.length) return;

  const uuids: string[] = [];

  columns.map((column) => {
    if (!column?.options?.wsSelectDataSetUuid) return;
    const { wsSelectDataSetUuid } = column?.options || {};
    if (wsSelectDataSetUuid && !uuids.includes(wsSelectDataSetUuid)) {
      uuids.push(wsSelectDataSetUuid);
    }
  });

  if (!uuids.length) return;
  dispatch(setSelectDataSetOptionsLoading(true));
  uuids.forEach((id) => {
    dispatch(getSelectDataSetOptions(id));
  });
  dispatch(setSelectDataSetOptionsLoading(false));
};

export const linkifyString = (input: string | null): string | null => {
  if (!input) {
    return null;
  }

  return linkifyHtml(input, { target: "_blank", rel: "noreferrer, noopener" });
};

export const getIconByPath = (
  path: string,
  variant: PictogramVariants = PictogramVariants.Original,
): React.ReactNode => {
  const fileNameWithoutExtension = path.replace(/\.[^.]+$/, "");

  return <Pictogram identifier={fileNameWithoutExtension} variant={variant} />;
};

export const setQueryParam = (
  history: RouterChildContext["router"]["history"],
  key: string,
  value: string,
) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  history.push(url.pathname + url.search);
};

export const showErrorToasts = (errorMessages: ReduxError[]) => {
  for (let i = 0; i < errorMessages.length; i++) {
    showToast(
      "error",
      <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
      errorMessages[i].message,
    );
  }
};

interface ShowErrorToast {
  id?: string;
  defaultMessage?: string;
}

export const showErrorToast = ({ id, defaultMessage }: ShowErrorToast = {}) => {
  const errorDisplayed =
    id && defaultMessage ? (
      <IDHFormattedMessage id={id} defaultMessage={defaultMessage} />
    ) : (
      <IDHFormattedMessage
        id="ws_something_went_wrong"
        defaultMessage="Something went wrong"
      />
    );

  showToast(
    "error",
    <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
    errorDisplayed,
  );
};

export const customEqual = (oldValue: any, newValue: any) =>
  JSON.stringify(oldValue) === JSON.stringify(newValue);

export const moveInArray = (arr: any[], newIndex: number, oldIndex: number) => {
  const newArray = [...arr];
  newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);
  return newArray;
};

export interface ErrorMessage {
  message: string;
}
type InputErrors = { message: string }[] | string[];

export const parseErrorMessages = (input: InputErrors): ErrorMessage[] => {
  if (!input || input.length === 0) return [];

  const hasMessageKey = typeof input[0] === "object" && "message" in input[0];

  return input.map((item: any) => ({
    message: hasMessageKey ? item.message : item,
  }));
};

export const isOnCurrentlyDisplayedTaskTypeTab = (taskType: string) => {
  const params = new URLSearchParams(window.location.search);
  const currentTaskType = params.get("taskType");

  return currentTaskType === taskType;
};

export const arrayBufferToBase64 = (buffer: Uint8Array) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.length;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
};

export const camelCaseToSeparatedWords = (text: string) => {
  if (typeof text !== "string") return text;
  return capitalizeFirstLetter(text.replace(/([a-z])([A-Z])/g, "$1 $2"));
};

export enum WsMessengerMessageStatusEnum {
  Pending = "PENDING",
  Sent = "SENT",
  Error = "ERROR",
  Skipped = "SKIPPED",
}

export const updateSelectedTableViewInLS = (
  LSKey: string,
  value: WsTableView,
) => {
  const workspaceId = getWorkspaceUuidFromCurrentUrl() || "";
  const settingsFromLS = localStorage.getItem(LSKey);
  const settingsData = settingsFromLS ? JSON.parse(settingsFromLS) : null;
  const settings = settingsData ? settingsData[workspaceId] : {};
  localStorage.setItem(
    LSKey,
    JSON.stringify({
      ...settingsData,
      [workspaceId]: {
        ...settings,
        selectedTableView: value,
      },
    }),
  );
};

export const isValueTaskType = (value: unknown): value is TaskType => {
  if (typeof value === "string") {
    return (Object.values(TaskType) as string[]).includes(value);
  }
  return false;
};
