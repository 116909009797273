export const saveLocalProjectSettings = (
  projectId,
  activeSortingColumn,
  activeTab,
  sortingDirection,
  selectedFilters,
) => {
  const prev = localStorage.getItem("ws-project-settings");
  let prevObject = JSON.parse(prev || "[]");
  let result = prevObject.find((project) => project.projectId === projectId);

  if (!result)
    result = {
      projectId,
      creator: { activeSortingColumn: "", selectedFilters: [] },
      content: { activeSortingColumn: "", selectedFilters: [] },
      action: { activeSortingColumn: "", selectedFilters: [] },
      publication: { activeSortingColumn: "", selectedFilters: [] },
      payment: { activeSortingColumn: "", selectedFilters: [] },
    };

  if (result[activeTab]) {
    result[activeTab].activeSortingColumn = activeSortingColumn;
    result[activeTab].selectedFilters = selectedFilters.selections;
    result[activeTab].sortingDirection = sortingDirection;
  }

  prevObject = prevObject.filter((project) => project.projectId !== projectId);

  localStorage.setItem(
    "ws-project-settings",
    JSON.stringify([...prevObject, result]),
  );
};
