import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/redux/reducers";
import { TasksColumn } from "src/redux/task/taskReducer";
import TasksTable from "./TasksTable";
import { sortTableData } from "./tasksTableSorting/tableSortingFunctions/sortTableData";
import { sortTitleData } from "./tasksTableSorting/tableSortingFunctions/sortTitleData";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";

interface TasksTableWrapperInterface {
  columns: TasksColumn[];
}

export default function TasksTableWrapper({
  columns,
}: TasksTableWrapperInterface) {
  const dictionaryAutoCompletes = useSelector(
    (state: RootState) => state.dictionaryReducer.dictionaryAutoCompletes,
  );

  const membersList = useSelector(
    (state: RootState) => state.projectReducer.activeMembersList,
  );

  const projectTasksAutocomplete = useSelector(
    (state: RootState) => state.taskReducer.projectTasksAutocomplete,
  );

  const taskType = useSelector(
    (state: RootState) => state.projectReducer.taskType,
  );

  const sortingDirection = useSelector(
    (state: RootState) => state.taskFiltersReducer.sortingDirection,
  );

  const getVisibleColumns = () => {
    const columnsCopy = [...columns]; // I do it because sort() returns the reference to the same, but now sorted array.
    return columnsCopy
      .sort((a, b) => {
        return a.metaFieldRank?.localeCompare(b.metaFieldRank);
      })
      .filter((column) => column.metaFieldVisible)
      .map((column) => {
        return {
          id: column.metaFieldId,
          header: column.metaFieldName,
          footer: column.metaFieldName,
          accessorKey: column.metaFieldId,
          size: 180,
          columnFromApi: column,
          // @ts-ignore
          sortingFn: (...sortingProps) =>
            sortTableData({
              sortingProps,
              sortingDirection,
              membersList,
              tasksAutocomplete: projectTasksAutocomplete,
              dictionaryAutoCompletes,
            }),
        };
      });
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: "drag-handle",
        accessorKey: "drag-handle",
        header: "",
        size: 48,
      },
      {
        id: "select",
        header: ({ table }: any) => (
          <IndeterminateCheckbox
            {...{
              table,
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row, table }: any) => (
          <IndeterminateCheckbox
            {...{
              row,
              table,
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
        size: 48,
      },
      {
        id: taskType,
        header: taskType,
        footer: taskType,
        accessorKey: taskType,
        size: 240,
        sortingFn: sortTitleData,
      },
      ...getVisibleColumns(),
      {
        id: " action",
        header: " action",
        footer: " action",
        size: 120,
      },
    ],
    [
      columns,
      projectTasksAutocomplete,
      dictionaryAutoCompletes,
      sortingDirection,
    ],
  );

  return <TasksTable columns={COLUMNS} />;
}
