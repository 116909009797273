import React, { Dispatch, SetStateAction, useEffect } from "react";
import { DroppableProvided, Draggable, DropResult } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import FieldsList from "src/app/modals/components/FieldsList/FieldsList";
import { ReactComponent as GridIcon } from "src/images/grid.svg";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import {
  MetaFieldIcon,
  renderTooltipContent,
  renderTooltipIcon,
} from "src/app/modals/ManageFieldModal/components/GlobalFields/utils";
import {
  sortByRank,
  createNewRankStringForArrayItem,
} from "src/app/modals/components/FieldsList/utils";
import { RootState } from "src/redux/reducers";
import { SelectOption } from "src/types";
import { setShouldUpdateDictionaryData } from "src/redux";
import { Dictionary } from "../types";
import DictionaryFieldActionDropdown from "../components/DictionaryFieldActionDropdown";
import AddDictionaryFieldModal from "../components/AddDictionaryFieldModal";
import {
  handleUpdatetDictionaryMetaFieldRank,
  handleUpdatetDictionaryMetaFieldOptions,
} from "../utils";

interface DictionaryManagerFieldsTabProps {
  editedDictionary: Dictionary;
  setEditedDictionary: Dispatch<SetStateAction<Dictionary | null>>;
  syncLocalDictionaryWithServer: (uuid: string) => Promise<void>;
  isAddFieldManagerModalOpen: boolean;
  setIsAddFieldManagerModalOpen: Dispatch<SetStateAction<boolean>>;
}

export default function DictionaryManagerFieldsTab({
  editedDictionary,
  setEditedDictionary,
  syncLocalDictionaryWithServer,
  isAddFieldManagerModalOpen,
  setIsAddFieldManagerModalOpen,
}: DictionaryManagerFieldsTabProps) {
  const { dictionaryMetaFieldSelectOptions, shouldUpdateDictionaryData } =
    useSelector((state: RootState) => state.dictionaryReducer);

  const dispatch = useDispatch();

  const dictionaryMetaFieldsSortedByRankAndFilteredOutRequiredFields = [
    ...editedDictionary.metaFields,
  ]
    .sort(sortByRank)
    .filter((field) => !field.required);

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const { destination, source } = result;

    const sourceField =
      dictionaryMetaFieldsSortedByRankAndFilteredOutRequiredFields[
        source.index
      ];

    const newRank = createNewRankStringForArrayItem(
      destination,
      source,
      dictionaryMetaFieldsSortedByRankAndFilteredOutRequiredFields,
    );

    setEditedDictionary((prevDictionary) => {
      if (!prevDictionary) return null;

      const updatedMetaFields = prevDictionary.metaFields.map((field) => {
        if (field.uuid === sourceField.uuid) {
          return { ...field, rank: newRank };
        }
        return field;
      });

      return { ...prevDictionary, metaFields: updatedMetaFields };
    });

    handleUpdatetDictionaryMetaFieldRank({
      uuid: sourceField.uuid,
      rank: newRank,
    });
  };

  const listRenderer = (provided: DroppableProvided) => {
    return (
      <ul
        ref={provided.innerRef}
        className="dictionary-manager__fields"
        {...provided.droppableProps}
      >
        {dictionaryMetaFieldsSortedByRankAndFilteredOutRequiredFields.map(
          (metaField, index) => {
            return (
              <Draggable
                key={metaField.uuid}
                draggableId={metaField.uuid}
                index={index}
              >
                {(provided) => (
                  <li
                    ref={provided.innerRef}
                    className="dictionary-manager__list-item grid--fields"
                    key={metaField.uuid}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                      left: "auto !important",
                      top: "auto !important",
                    }}
                    data-qa-field-name={metaField.name}
                  >
                    <div className="dictionary-manager__text">
                      <div {...provided.dragHandleProps}>
                        <GridIcon />
                      </div>

                      <div className="dictionary-manager__list-col">
                        <div className="dictionary-manager__name">
                          <span className="dictionary-manager__name-text">
                            {metaField.name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="dictionary-manager__icon">
                      <TooltipPortal
                        content={renderTooltipContent(metaField.type, null)}
                      >
                        <MetaFieldIcon
                          icon={renderTooltipIcon(metaField.type, null)}
                        />
                      </TooltipPortal>
                    </div>
                    <DictionaryFieldActionDropdown
                      editedDictionaryUuid={editedDictionary.uuid}
                      field={metaField}
                      syncLocalDictionaryWithServer={
                        syncLocalDictionaryWithServer
                      }
                    />
                  </li>
                )}
              </Draggable>
            );
          },
        )}
        {provided.placeholder}
      </ul>
    );
  };

  useEffect(() => {
    if (dictionaryMetaFieldSelectOptions) {
      if (Array.isArray(dictionaryMetaFieldSelectOptions.value)) {
        handleUpdatetDictionaryMetaFieldOptions({
          uuid: dictionaryMetaFieldSelectOptions.uuid,
          options: dictionaryMetaFieldSelectOptions.value,
        });

        setEditedDictionary((prevDictionary) => {
          if (!prevDictionary) return null;

          const updatedMetaFields = prevDictionary.metaFields.map((field) => {
            if (field.uuid === dictionaryMetaFieldSelectOptions.uuid) {
              return {
                ...field,
                data: {
                  singleSelectOptions:
                    dictionaryMetaFieldSelectOptions.value as SelectOption[],
                },
              };
            }
            return field;
          });

          return { ...prevDictionary, metaFields: updatedMetaFields };
        });
      }
    }
  }, [dictionaryMetaFieldSelectOptions]);

  useEffect(() => {
    if (shouldUpdateDictionaryData) {
      const updateDictionaryData = async () => {
        await syncLocalDictionaryWithServer(editedDictionary.uuid);
        dispatch(setShouldUpdateDictionaryData(false));
      };
      updateDictionaryData();
    }
  }, [shouldUpdateDictionaryData, editedDictionary.uuid]);

  return (
    <>
      <div className="dictionary-manager__fields-tab">
        {editedDictionary.metaFields.length === 0 ? (
          <div className="dictionary-manager__empty-fields-screen">
            <div className="dictionary-manager__empty-fields-text">
              <div>
                <IDHFormattedMessage
                  id="ws_no_field_has_been_added"
                  defaultMessage="No field has been added"
                />
              </div>
              <div>
                <IDHFormattedMessage
                  id="ws_add_fields_from_the_library"
                  defaultMessage="Add fields from the library or create custom fields for this dictionary."
                />
              </div>
            </div>

            <Button
              size="large"
              variant="blue"
              onClick={() => setIsAddFieldManagerModalOpen(true)}
              data-qa-button="addWsDictionaryMetaField"
            >
              <PlusIcon />
              <IDHFormattedMessage
                id="ws_add_field"
                defaultMessage="Add field"
              />
            </Button>
          </div>
        ) : (
          <>
            <div className="dictionary-manager__headers grid--fields">
              <div>
                <IDHFormattedMessage
                  id="ws_dictionary"
                  defaultMessage="Dictionary"
                />
              </div>
              <div>
                <IDHFormattedMessage id="ws_active" defaultMessage="Active" />{" "}
              </div>
              <div>
                <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
              </div>
            </div>
            <FieldsList onDragEnd={onDragEnd} listRenderer={listRenderer} />
          </>
        )}
      </div>
      {isAddFieldManagerModalOpen && (
        <AddDictionaryFieldModal
          editedDictionaryUuid={editedDictionary.uuid}
          syncLocalDictionaryWithServer={syncLocalDictionaryWithServer}
          setIsAddFieldManagerModalOpen={setIsAddFieldManagerModalOpen}
        />
      )}
    </>
  );
}
