import { API_URLS } from "src/utils/API_URLS";
import { SelectOption } from "src/types";
import { MetaDataValue } from "./types";
import {
  CLEAR_DICTIONARY_DETAILS,
  CLEAR_DICTIONARY_LIST,
  CLEAR_DICTIONARY_META_FIELD_SELECT_OPTIONS,
  CREATE_DICTIONARY_LIST_ELEMENT,
  GET_DICTIONARY_AUTOCOMPLETE,
  GET_DICTIONARY_DETAILS,
  GET_DICTIONARY_LIST,
  GET_DICTIONARY_LIST_SINGLE_ELEMENT,
  REMOVE_DICTIONARY_ELEMENT,
  SET_DICTIONARY_AUTOCOMPLETE_LOADING,
  SET_SHOULD_UPDATE_DICTIONARY_DATA,
  SET_DICTIONARY_ELEMENT_META_VALUE,
  SET_SHOULD_CLOSE_ADD_NEW_DICTIONARY_ELEMENT_MODAL,
  UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD,
  UPDATE_DICTIONARY_META_FIELD_SELECT_OPTIONS,
} from "./dictionaryTypes";

const getDictionaryList = (dictionaryUuid: string, params: any) => {
  const url = API_URLS.getDictionaryList.replace(
    ":dictionaryUuid:",
    dictionaryUuid,
  );
  return {
    type: GET_DICTIONARY_LIST,
    payload: {
      request: { url, params },
    },
  };
};

const clearDictionaryList = () => {
  return {
    type: CLEAR_DICTIONARY_LIST,
  };
};

const updateDictionaryElementSelectMetaField = (
  metaFieldId: string,
  singleSelectOptions: SelectOption[],
) => {
  const url = API_URLS.updateDictionaryElementSelectMetaField;
  return {
    type: UPDATE_DICTIONARY_ELEMENT_SELECT_META_FIELD,
    payload: {
      request: {
        method: "put",
        url,
        data: {
          metaFieldId,
          singleSelectOptions,
        },
      },
      metaFieldId,
      singleSelectOptions,
    },
  };
};

const setDictionaryElementMetaValue = (
  ids: string | string[],
  metaFieldUuid: string,
  metaValue: MetaDataValue | null,
  appendValues: boolean = false,
) => {
  const uuids = typeof ids === "string" ? [ids] : ids;
  const url = API_URLS.setMetaValueDictionaryElement;
  return {
    type: SET_DICTIONARY_ELEMENT_META_VALUE,
    payload: {
      request: {
        method: "put",
        url,
        data: {
          uuids,
          metaFieldUuid,
          metaValue,
          appendValues,
        },
      },
      uuids,
      metaFieldUuid,
      metaValue,
    },
  };
};

export function getDictionaryDetails(
  dictionaryUuid: string,
  dictionaryElementUuid: string,
) {
  const url = API_URLS.getDictionaryDetails
    .replace(":dictionaryUuid:", dictionaryUuid)
    .replace(":dictionaryElementUuid:", dictionaryElementUuid);

  return {
    type: GET_DICTIONARY_DETAILS,
    payload: {
      request: { url },
    },
  };
}

function clearDictionaryDetails() {
  return { type: CLEAR_DICTIONARY_DETAILS };
}

function deleteDictionaryElement(dictionaryUuid: string, uuids: string[]) {
  const url = API_URLS.deleteDictionaryElement.replace(
    ":dictionaryUuid:",
    dictionaryUuid,
  );

  return {
    type: REMOVE_DICTIONARY_ELEMENT,
    payload: {
      request: {
        method: "delete",
        url,
        data: {
          uuids,
        },
      },
      uuids,
    },
  };
}

function getDictionaryListElement(
  dictionaryUuid: string,
  dictionaryElementUuid: string,
) {
  const url = API_URLS.getSingleDictionaryElement
    .replace(":dictionaryUuid:", dictionaryUuid)
    .replace(":dictionaryElementUuid:", dictionaryElementUuid);
  return {
    type: GET_DICTIONARY_LIST_SINGLE_ELEMENT,
    payload: {
      request: { url },
    },
  };
}

function createDictionaryElements(
  workspaceUuid: string,
  dictionaryUuid: string,
  wsDictionaryElementsData: { uuid: string; name: string }[],
) {
  const url = API_URLS.createDictionaryElements
    .replace(":workspaceUuid:", workspaceUuid)
    .replace(":dictionaryUuid:", dictionaryUuid);

  return {
    type: CREATE_DICTIONARY_LIST_ELEMENT,
    payload: {
      request: {
        method: "post",
        url,
        data: {
          wsDictionaryElementsData,
        },
      },
      wsDictionaryElementsData,
    },
  };
}

function setShouldCloseAddDictionaryElementModal(
  shouldCloseAddDictionaryElementModal: boolean,
) {
  return {
    type: SET_SHOULD_CLOSE_ADD_NEW_DICTIONARY_ELEMENT_MODAL,
    payload: { shouldCloseAddDictionaryElementModal },
  };
}

const getDictionaryAutoComplete = (dictionaryUuid: string) => {
  const url = API_URLS.getDictionaryList.replace(
    ":dictionaryUuid:",
    dictionaryUuid,
  );
  return {
    type: GET_DICTIONARY_AUTOCOMPLETE,
    payload: {
      request: { url },
    },
  };
};

const setDictionaryAutoCompleteLoading = (
  dictionaryAutocompleteLoading: boolean,
) => {
  return {
    type: SET_DICTIONARY_AUTOCOMPLETE_LOADING,
    payload: { dictionaryAutocompleteLoading },
  };
};

function updateDictionaryMetaFieldSelectOptions({
  uuid,
  value,
}: {
  uuid: string;
  value: string | SelectOption[];
}) {
  return {
    type: UPDATE_DICTIONARY_META_FIELD_SELECT_OPTIONS,
    payload: { uuid, value },
  };
}

function clearDictionaryMetaFieldSelectOptions() {
  return {
    type: CLEAR_DICTIONARY_META_FIELD_SELECT_OPTIONS,
  };
}

function setShouldUpdateDictionaryData(update: boolean) {
  return {
    type: SET_SHOULD_UPDATE_DICTIONARY_DATA,
    payload: { update },
  };
}

export {
  clearDictionaryList,
  getDictionaryList,
  updateDictionaryElementSelectMetaField,
  setDictionaryElementMetaValue,
  clearDictionaryDetails,
  deleteDictionaryElement,
  getDictionaryListElement,
  createDictionaryElements,
  setShouldCloseAddDictionaryElementModal,
  getDictionaryAutoComplete,
  setDictionaryAutoCompleteLoading,
  updateDictionaryMetaFieldSelectOptions,
  clearDictionaryMetaFieldSelectOptions,
  setShouldUpdateDictionaryData,
};
