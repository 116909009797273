import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { SourceType } from '@stigg/api-client-js/src/generated/sdk';
import { EntitlementsFallback } from './models';
import { LogConfiguration, LoggerService, LogLevel } from './services/loggerService';

export const SDK_SOURCE_TYPE = SourceType.JsClientSdk;
export const SDK_NAME = 'js-client-sdk';

const LOCALHOST_IPS = ['localhost', '127.0.0.1', '::1'];

export type ClientConfiguration = {
  apiKey: string;
  baseUri?: string;
  baseEdgeUri?: string;
  customerId?: string | null;
  resourceId?: string | null;
  customerToken?: string | null;
  /** @deprecated not longer in use */
  entitlementPollingInterval?: number;
  /** @deprecated not longer in use */
  useEntitlementPolling?: boolean;
  cacheTtlMs?: number;
  logConfiguration?: LogConfiguration;
  lazyLoad?: boolean;
  enableEdge?: boolean;
  entitlementsFallback?: EntitlementsFallback;
  clientName?: string;
  clientVersion?: string;
  offline?: boolean;
};

const defaults = (): Partial<ClientConfiguration> => {
  const baseUri = 'https://api.stigg.io';
  return {
    baseUri,
    baseEdgeUri: getBaseEdgeApiUri(baseUri),
    logConfiguration: {
      logger: console,
      logLevel: LogLevel.ERROR,
    },
    enableEdge: true,
    cacheTtlMs: 30000,
  };
};

export function validateConfiguration(configuration: ClientConfiguration, loggerService: LoggerService) {
  if (isEmpty(configuration.apiKey) && !configuration.offline) {
    const error = 'You must configure the client with an SDK key';
    loggerService.error(error);
    throw new Error(error);
  }
}

export function getConfiguration(configuration: ClientConfiguration) {
  const defaultConfig = defaults();
  const baseEdgeUri = configuration.baseEdgeUri
    ? configuration.baseEdgeUri
    : configuration.baseUri
    ? getBaseEdgeApiUri(configuration.baseUri)
    : defaultConfig.baseEdgeUri;

  // This is for backward compatibility, since we don't need entitlementPollingInterval anymore
  // but someone may still use it
  const cacheTtlMs = configuration.cacheTtlMs || configuration.entitlementPollingInterval;

  return merge(defaultConfig, configuration, { baseEdgeUri, cacheTtlMs }) as Required<ClientConfiguration>;
}

function getBaseEdgeApiUri(apiUrl: string) {
  const url = new URL(apiUrl);

  if (LOCALHOST_IPS.includes(url.hostname)) {
    url.port = '3060';
  } else {
    url.hostname = `edge.${url.hostname}`;
  }

  // Remove last char since URL.toString() adds a redundant backslash
  return url.toString().slice(0, -1);
}

export function ensureCustomerRefIdExists({ customerId }: ClientConfiguration) {
  if (!customerId || isEmpty(customerId)) {
    throw new Error('You must set customer ID first, use setCustomerId function or pass it via the constructor');
  }
  return customerId;
}
