import React, { useEffect, useState } from "react";
import "./App.scss";

import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/pl";

import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect, RouteComponentProps } from "react-router";
import { IntercomProvider } from "react-use-intercom";

import axios from "axios";
import { IntlProvider } from "react-intl";
import {
  getTranslations,
  getTranslationsForMember,
  HidePdfReadyModal,
  identify,
  isLoggedIn,
  setActiveWorkspaceUuid,
  setIntercomUnreadMessages,
  setWebSocket,
} from "src/redux/main/mainActions";
import Sidebar from "./app/Sidebar/Sidebar";
import Navbar from "./app/Navbar/Navbar";
import Project from "./app/project/Project";
import Dashboard from "./app/dashboard/Dashboard";
import SingleProject from "./app/project/SingleProject/SingleProject";
import MyTasks from "./app/MyTasks/MyTasks";
import { LoginPage } from "./app/LoginPage/LoginPage";
import WorkspaceCatcher from "./app/logic-components/WorkspaceCatcher";
import OnboardingPage from "./app/OnboardingPage/OnboardingPage";
import CreatorDiscoveryTool from "./app/pages/CreatorDiscoveryTool/CreatorDiscoveryTool";
import CreatorShowcase from "./app/CreatorShowcase/CreatorShowcase";
import { ReportGenerator } from "./app/ReportGenerator/ReportGenerator";
import MessageListener from "./MessageListener";
import { Activity } from "./app/components/Activity/Activity";
import StatusBar from "./app/components/StatusBar/StatusBar";
import { API_URLS } from "./utils/API_URLS";
import {
  customEqual,
  getWorkspaceUuidFromCurrentUrl,
  isCurrentUrlRequiresAuthorization,
  showErrorToast,
} from "./utils/methods";
import { AccessLinkPage } from "./app/pages/AccessLinkPage/AccessLinkPage";
import { TrialPage } from "./app/TrialPage/TrialPage";
import { showToast } from "./app/methods/showToast";
import IDHFormattedMessage from "./app/components/IDHFormattedMessage/IDHFormattedMessage";
import { SearchParamWatcher } from "./SearchParamWatcher";
import { RecruitmentFormAccessLinkPage } from "./app/pages/AccessLinkPage/RecruitmentFormAccessLinkPage";
import { CouponGroups } from "./app/CouponGroups/CouponGroups";
import { FreeTrialSnackBarModal } from "./app/FreeTrialSnackbarModal/FreeTrialSnackBarModal";
import Dictionary from "./app/Dictionary/Dictionary";
import CreatorDatabase from "./app/CreatorDatabase/CreatorDatabase";
import { WorkspacePermissionsEnum } from "./utils/PermissionsEnums";
import { CredibilityToolPage } from "./app/CredibilityTool/CredibilityToolPage";
import CredibilityToolListPage from "./app/CredibilityTool/CredibilityToolListPage";
import { HashtagLiveSearch } from "./app/HashtagLiveSearch/HashtagLiveSearch";
import CreateNewProjectModal from "./app/modals/CreateNewProjectModal/CreateNewProjectModal";
import { RootState } from "./redux/reducers";
import useNetworkStatus from "./hooks/useNetworkStatus";
import WsProjectReportShowcasePdfReadyModal from "./app/modals/WsProjectReportShowcasePdfReadyModal/WsProjectReportShowcasePdfReadyModal";
import WorkspaceTasksSummary from "./app/pages/WorkspaceTasksSummary/WorkspaceTasksSummary";
import { getProjectAutocompleteList } from "./redux";
import DemoModeInfoBar, {
  DEMO_MODE_INFO_BAR_PADDING,
} from "./app/components/DemoModeInfoBar/DemoModeInfoBar";

interface PropsSubmissionForm extends RouteComponentProps {
  match: {
    isExact: boolean;
    params: {
      extensionUuid: string;
      token: string;
    };
    path: string;
    url: string;
  };
}

export const overlaysRef = React.createRef<any>();

function App() {
  const [translationsDownloaded, setTranslationsDownloaded] = useState(false);

  useNetworkStatus();

  const translations = useSelector(
    (state: RootState) => state.mainReducer.translations,
    customEqual,
  );
  const locale = useSelector((state: RootState) => state.mainReducer.locale);
  const wsProjectReportShowcasePdfReadyModalData = useSelector(
    (state: RootState) =>
      state.mainReducer.wsProjectReportShowcasePdfReadyModalData,
  );

  const identity = useSelector(
    (state: RootState) => state.mainReducer.identity,
  );
  const notifications = useSelector(
    (state: RootState) => state.mainReducer.notifications,
    customEqual,
  );
  const websocket = useSelector(
    (state: RootState) => state.mainReducer.websocket,
  );
  const workspaceUuid = getWorkspaceUuidFromCurrentUrl();

  const excludedPathnames = ["", "creator-showcase"];

  const activeWorkspaceUuid =
    workspaceUuid && !excludedPathnames.includes(workspaceUuid)
      ? workspaceUuid
      : null;

  const dispatch = useDispatch();

  const showOnboarding = identity?.errors?.includes("onboarding");

  useEffect(() => {
    dispatch(getTranslations());
  }, []);

  useEffect(() => {
    if (activeWorkspaceUuid) {
      dispatch(setActiveWorkspaceUuid(activeWorkspaceUuid));
      dispatch(getProjectAutocompleteList(activeWorkspaceUuid));
    }
  }, [activeWorkspaceUuid]);

  useEffect(() => {
    if (identity?.id && !translationsDownloaded) {
      dispatch(getTranslationsForMember());
      setTranslationsDownloaded(true);
    }
  }, [identity]);

  // websocket handling has been moved to separate component - WebsocketListener
  useEffect(() => {
    if (!isCurrentUrlRequiresAuthorization()) {
      return;
    }

    if (activeWorkspaceUuid) {
      dispatch(identify(activeWorkspaceUuid));
    } else {
      axios
        .get(API_URLS.getLastActiveWorkspaceUuid)
        .then((result) => {
          if (result?.data?.content?.lastActiveWorkspaceUuid) {
            dispatch(identify(result.data.content.lastActiveWorkspaceUuid));
          } else {
            dispatch(isLoggedIn());
          }
        })
        .catch((error) => {
          console.error(error);
          showErrorToast();
        });
    }
  }, [activeWorkspaceUuid]);

  useEffect(() => {
    if (!isCurrentUrlRequiresAuthorization()) {
      return;
    }

    if (
      !identity?.id &&
      window.location.pathname === "/workspace/magic-link-expired"
    ) {
      showToast(
        "info",
        <IDHFormattedMessage
          id="ws_magic_link_expired_title"
          defaultMessage="Info"
        />,
        <IDHFormattedMessage
          id="ws_magic_link_expired_message"
          defaultMessage="Login link expired"
        />,
      );
    }

    if (showOnboarding && window.location.pathname !== "/workspace/") {
      window.location.pathname = "/workspace/";
    }
  }, [identity, window.location.pathname]);

  useEffect(() => {
    if (
      activeWorkspaceUuid &&
      identity.id &&
      identity.websocketChallenge &&
      !websocket
    ) {
      dispatch(
        setWebSocket(
          activeWorkspaceUuid,
          identity.id,
          identity.websocketChallenge,
        ),
      );
    }
  }, [identity, activeWorkspaceUuid]);

  const hasAccessToCreatorDatabase = identity?.permissions?.workspace?.includes(
    WorkspacePermissionsEnum.CREATOR_DATABASE,
  );
  const hasAccessToDictionaries = identity?.permissions?.workspace?.includes(
    WorkspacePermissionsEnum.DICTIONARIES,
  );
  const hasAccessToCreatorDiscovery =
    identity?.permissions?.workspace?.includes(
      WorkspacePermissionsEnum.CREATOR_DISCOVERY,
    );

  const calculatePaddingToOffsetNotifications = () => {
    return (notifications ? notifications.length : 0) * 44;
  };

  const getPaddingToOffsetDemoModeInfoBar = () => {
    return identity?.isDemoWorkspace ? DEMO_MODE_INFO_BAR_PADDING : 0;
  };

  const appContent = (
    <div className="App">
      <IntlProvider
        locale={locale.slice(0, 2)}
        key={locale}
        messages={translations}
      >
        <div className="sticky-bars">
          {identity?.isDemoWorkspace && <DemoModeInfoBar />}
          <StatusBar />
        </div>
        <main
          className="App__main-container"
          style={{
            marginLeft: activeWorkspaceUuid ? 72 : 0,
            paddingTop:
              calculatePaddingToOffsetNotifications() +
              getPaddingToOffsetDemoModeInfoBar(),
          }}
        >
          <Router>
            <Switch>
              <Route
                path="/workspace/access/:taskUuid/:type/:metafieldUuid/:token"
                component={() => <AccessLinkPage />}
              />
              <Route
                path="/workspace/recruitment-form/:extensionUuid/:token"
                component={(props: PropsSubmissionForm) => (
                  <Redirect
                    to={`/workspace/submission-form/${props.match.params.extensionUuid}/${props.match.params.token}`}
                  />
                )}
              />
              <Route
                path="/workspace/submission-form/:extensionUuid/:token"
                component={() => <RecruitmentFormAccessLinkPage />}
              />
              <Route
                exact
                path="/workspace/"
                component={showOnboarding ? OnboardingPage : LoginPage}
              />

              <Route
                exact
                path="/workspace/magic-link-expired"
                component={LoginPage}
              />

              <Route path="/workspace/trial" component={TrialPage} />

              <Route
                path="/workspace/credibility-tool/:userUuid"
                component={CredibilityToolListPage}
              />

              <Route
                exact
                path="/workspace/credibility-tool"
                component={CredibilityToolPage}
              />

              <Route
                path="/workspace/"
                component={() =>
                  websocket ? (
                    <>
                      <Navbar />
                      <Sidebar />
                      {identity?.trialLimits && <FreeTrialSnackBarModal />}
                    </>
                  ) : null
                }
              />
            </Switch>

            {Object.keys(identity).length > 0 && (
              <>
                <Route path="/workspace" component={SearchParamWatcher} />

                <Switch>
                  {activeWorkspaceUuid?.length && !showOnboarding && (
                    <Redirect
                      exact
                      from="/workspace/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
                      to={`/workspace/${activeWorkspaceUuid}/activity`}
                    />
                  )}
                  <Route
                    path="/workspace/:workspaceUuid/create-new-project"
                    component={CreateNewProjectModal}
                  />
                  <Route
                    path="/workspace/:workspaceUuid/activity"
                    component={Activity}
                  />
                  <Route
                    path="/workspace/:workspaceUuid/dashboard"
                    component={Dashboard}
                  />
                  <Route
                    path="/workspace/:workspaceUuid/coupon-groups"
                    component={CouponGroups}
                  />
                  <Route
                    path="/workspace/:workspaceUuid/my-actions/:projectId?/:taskId?/:commentId?"
                    component={MyTasks}
                  />
                  <Route
                    path="/workspace/:workspaceUuid/projects"
                    exact
                    component={Project}
                  />

                  {websocket && (
                    <Route
                      path="/workspace/:workspaceUuid/projects/:projectId/:taskId?/:commentId?"
                      component={SingleProject}
                    />
                  )}

                  <Route
                    path="/workspace/:workspaceUuid/finance"
                    component={OnboardingPage}
                  />
                  {hasAccessToCreatorDiscovery && (
                    <Route
                      path="/workspace/:workspaceUuid/creator-discovery-tool"
                      component={CreatorDiscoveryTool}
                    />
                  )}

                  <Route
                    path="/workspace/:workspaceUuid/hashtag-live-search"
                    component={HashtagLiveSearch}
                  />

                  {hasAccessToCreatorDatabase && (
                    <Route
                      path="/workspace/:workspaceUuid/creator-database"
                      component={CreatorDatabase}
                    />
                  )}
                  <Route
                    path="/workspace/creator-showcase"
                    exact
                    component={CreatorShowcase}
                  />
                  <Route
                    path="/workspace/:workspaceUuid/report-generator/:projectId"
                    exact
                    component={ReportGenerator}
                  />
                  <Route
                    path="/workspace/:workspaceUuid/summary/:projectId?/:taskId?"
                    component={WorkspaceTasksSummary}
                  />
                  {hasAccessToDictionaries && (
                    <Route
                      path="/workspace/:workspaceUuid/dict/:wsDictionaryUrl/:dictionaryUuid"
                      exact
                      render={({ match }) => (
                        <Dictionary
                          key={match.params.dictionaryUuid}
                          {...match.params}
                        />
                      )}
                    />
                  )}
                  {/* <Route path="*" component={NoMatch} /> */}
                </Switch>
              </>
            )}

            <Route path="/workspace" component={MessageListener} />

            <Route
              path="/workspace/:workspaceUuid"
              component={WorkspaceCatcher}
            />
          </Router>
        </main>

        <div className="Overlays" ref={overlaysRef} />

        <ToastContainer
          position="top-right"
          draggable={false}
          className="ws-toast"
        />

        {wsProjectReportShowcasePdfReadyModalData && (
          <WsProjectReportShowcasePdfReadyModal
            reportUrl={wsProjectReportShowcasePdfReadyModalData?.reportUrl}
            closePdfReadyModal={() => dispatch(HidePdfReadyModal())}
          />
        )}
      </IntlProvider>
    </div>
  );

  /**
   * Fix for problem with white screen after reload app: https://indahash.slack.com/archives/C02BGLBHLBE/p1727164555848889
   */
  try {
    return (
      <IntercomProvider
        appId="neilngrl"
        onUnreadCountChange={(amount: number) =>
          dispatch(setIntercomUnreadMessages(amount))
        }
      >
        {appContent}
      </IntercomProvider>
    );
  } catch (e) {
    console.log("[WS] Fail to render intercom component");
    return <>{appContent}</>;
  }
}

export default App;
