import React from "react";
import "./DataHubNoColumns.scss";

interface Props {
  image?: React.ReactNode;
  upperText?: string | React.ReactNode;
  lowerText?: string | React.ReactNode;
  bottomContent?: any;
}

export function DataHubNoColumns(props: Props) {
  const { image, upperText, lowerText, bottomContent } = props;

  return (
    <div className="data-hub-no-columns">
      <span className="data-hub-no-columns__image">{image}</span>

      <div className="data-hub-no-columns__text">
        <span className="data-hub-no-columns__text-upper">{upperText}</span>
        <span className="data-hub-no-columns__text-lower">{lowerText}</span>
      </div>
      {bottomContent && (
        <span className="data-hub-no-columns__bottom-content">
          {bottomContent}
        </span>
      )}
    </div>
  );
}
