import { Stigg, StiggClient } from './clients';
import {
  ApplySubscriptionResults,
  BillingVendorIdentifier,
  BOOLEAN_DEFAULT_FALLBACK_ENTITLEMENT,
  BooleanEntitlement,
  Coupon,
  Currency,
  Customer,
  CustomerPortal,
  Entitlement,
  GetBooleanEntitlement,
  GetCheckoutStateResults,
  GetMeteredEntitlement,
  GetNumericEntitlement,
  METERED_DEFAULT_FALLBACK_ENTITLEMENT,
  MeteredEntitlement,
  NUMERIC_DEFAULT_FALLBACK_ENTITLEMENT,
  NumericEntitlement,
  PaymentCollection,
  Paywall,
  PricingType,
  SlimSubscriptionFragmentV2Fragment,
  Subscription,
  SubscriptionPreview,
  SubscriptionPreviewV2,
  SubscriptionStatus,
  UsageHistoryPoint,
  UsageHistoryV2Fragment,
} from './models';
import { mapGetEntitlementsFallback } from './utils/mapGetEntitlementsFallback';

export class OfflineStigg extends Stigg {
  getBooleanEntitlement({ featureId, options }: GetBooleanEntitlement): BooleanEntitlement {
    const fallbackValue = options?.fallback || this.configuration.entitlementsFallback?.[featureId] || {};
    return {
      ...BOOLEAN_DEFAULT_FALLBACK_ENTITLEMENT,
      ...fallbackValue,
    };
  }

  getMeteredEntitlement({ featureId, options }: GetMeteredEntitlement): MeteredEntitlement {
    const fallbackValue = options?.fallback || this.configuration.entitlementsFallback?.[featureId] || {};
    return {
      ...METERED_DEFAULT_FALLBACK_ENTITLEMENT,
      ...fallbackValue,
    };
  }

  getNumericEntitlement({ featureId, options }: GetNumericEntitlement): NumericEntitlement {
    const fallbackValue = options?.fallback || this.configuration.entitlementsFallback?.[featureId] || {};
    return {
      ...NUMERIC_DEFAULT_FALLBACK_ENTITLEMENT,
      ...fallbackValue,
    };
  }

  async getEntitlements(): Promise<Entitlement[]> {
    return mapGetEntitlementsFallback(this.configuration.entitlementsFallback) as Entitlement[];
  }

  addListener(): void {
    return;
  }

  clearCustomer(): void {
    return;
  }

  clearResource(): void {
    return;
  }

  async getActiveSubscriptionsList(): Promise<SlimSubscriptionFragmentV2Fragment[]> {
    return [];
  }

  async getCoupons(): Promise<Coupon[]> {
    return [];
  }

  async getSubscription(): Promise<Subscription> {
    return {
      id: 'mock-subscription-id',
      addons: [],
      futureUpdates: [],
      paymentCollection: PaymentCollection.NotRequired,
      plan: {
        compatibleAddons: [],
        compatiblePackageGroups: [],
        displayName: 'mock-plan',
        entitlements: [],
        id: 'mock-plan-id',
        inheritedEntitlements: [],
        order: 0,
        pricePoints: [],
        product: {
          id: 'mock-product-id',
        },
      },
      price: null,
      prices: [],
      pricingType: PricingType.Paid,
      startDate: new Date(),
      status: SubscriptionStatus.Active,
      totalPrice: {
        total: {
          amount: 0,
          currency: Currency.Usd,
        },
        subTotal: {
          amount: 0,
          currency: Currency.Usd,
        },
      },
    };
  }

  get isCustomerLoaded(): boolean {
    return false;
  }

  get isResourceLoaded(): boolean {
    return false;
  }

  get isWidgetWatermarkEnabled(): boolean {
    return true;
  }

  removeListener(): void {
    return;
  }

  async waitForInitialization(): Promise<StiggClient> {
    return this;
  }

  async setResource(): Promise<void> {
    return;
  }

  async getCheckoutState(): Promise<GetCheckoutStateResults> {
    return {
      setupSecret: 'mock-setup-secret',
      resource: null,
      plan: {
        compatibleAddons: [],
        compatiblePackageGroups: [],
        displayName: 'mock-plan',
        entitlements: [],
        id: 'mock-plan-id',
        inheritedEntitlements: [],
        order: 0,
        pricePoints: [],
        product: {
          id: 'mock-product-id',
        },
      },
      customer: {
        createdAt: new Date(),
        updatedAt: new Date(),
        hasPaymentMethod: false,
        id: 'mock-customer-id',
        subscriptions: [],
        promotionalEntitlements: [],
        getActivePromotionalEntitlements: () => [],
        getActiveSubscriptions: () => [],
        getActiveTrials: () => [],
      },
      billingIntegration: {
        billingIdentifier: BillingVendorIdentifier.Stripe,
        credentials: {
          accountId: 'mock-account-id',
          publicKey: 'mock-public-key',
        },
      },
    };
  }

  async applySubscription(): Promise<ApplySubscriptionResults> {
    return {
      subscription: undefined,
    };
  }

  async estimateSubscription(): Promise<SubscriptionPreview> {
    return {
      billingPeriodRange: {},
      total: {
        amount: 0,
        currency: Currency.Usd,
      },
      subTotal: {
        amount: 0,
        currency: Currency.Usd,
      },
      totalExcludingTax: {
        amount: 0,
        currency: Currency.Usd,
      },
    };
  }

  async previewSubscription(): Promise<SubscriptionPreviewV2> {
    return {
      billingPeriodRange: {},
      immediateInvoice: {
        total: {
          amount: 0,
          currency: Currency.Usd,
        },
        subTotal: {
          amount: 0,
          currency: Currency.Usd,
        },
        totalExcludingTax: {
          amount: 0,
          currency: Currency.Usd,
        },
      },
    };
  }

  async estimateSubscriptionUpdate(): Promise<SubscriptionPreview> {
    return {
      billingPeriodRange: {},
      total: {
        amount: 0,
        currency: Currency.Usd,
      },
      subTotal: {
        amount: 0,
        currency: Currency.Usd,
      },
      totalExcludingTax: {
        amount: 0,
        currency: Currency.Usd,
      },
    };
  }

  async waitForCheckoutCompleted(): Promise<Subscription | null> {
    return null;
  }

  async refresh(): Promise<void> {
    return;
  }

  async fetchUsageHistory(): Promise<UsageHistoryPoint[] | undefined> {
    return [];
  }

  async getUsageHistoryV2(): Promise<UsageHistoryV2Fragment> {
    return {
      markers: [],
      series: [],
    };
  }

  async setCustomerId(): Promise<void> {
    return;
  }

  async getPaywall(): Promise<Paywall> {
    return {
      activeSubscriptions: [],
      currency: {
        code: Currency.Usd,
        symbol: '$',
      },
      plans: [],
      customer: null,
      resource: null,
    };
  }

  async getCustomerPortal(): Promise<CustomerPortal> {
    return {
      subscriptions: [],
      entitlements: [],
      promotionalEntitlements: [],
      billingInformation: {},
      canUpgradeSubscription: false,
      resource: null,
      showWatermark: true,
    };
  }

  async getActiveSubscriptions(): Promise<Subscription[]> {
    return [];
  }

  async getCustomer(): Promise<Customer> {
    return {
      id: 'offline-customer-id',
      createdAt: new Date(),
      updatedAt: new Date(),
      subscriptions: [],
      promotionalEntitlements: [],
      metadata: {},
      hasPaymentMethod: false,
      email: 'offline-customer@example.com',
      getActivePromotionalEntitlements: () => [],
      getActiveSubscriptions: () => [],
      getActiveTrials: () => [],
    };
  }
}
