import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { RootState } from "src/redux/reducers";
import { flexRender } from "@tanstack/react-table";
import { SortableHandle } from "react-sortable-hoc";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import { TasksColumn } from "src/redux/task/taskReducer";
import { Column } from "src/redux/dictionary/types";
import { ProjectPermissionsEnum } from "src/utils/PermissionsEnums";
import { ReactComponent as ClientOrangeIcon } from "../../../../images/client-orange.svg";
import { ReactComponent as GridIcon } from "../../../../images/grid-grey.svg";
import { SkeletonText } from "../../../components/Skeleton/Skeleton";
import { TaskTableHeaderOptionsDropdown } from "../../../dropdowns/TaskTableHeaderOptionsDropdown/TaskTableHeaderOptionsDropdown";
import ShareWithClientModal from "../../../modals/ShareWithClientModal/ShareWithClientModal";
import StopSharingWithClientModal from "../../../modals/StopSharingWithClientModal/StopSharingWithClientModal";
import { ColumnHeaderName } from "./ColumnHeaderName";
import SortingTasksArrow from "./tasksTableSorting/SortingTasksArrow/SortingTasksArrow";
import getCommonPinningStyles from "./functions";
import { TableContext } from "../../TableField/types";

export const SKELETON_BLACKLIST = ["select", "drag-handle"];

const isTasksColumn = (column: unknown): column is TasksColumn => {
  if (typeof column === "object" && column !== null) {
    return "metaFieldId" in column;
  }
  return false;
};

const isColumn = (column: unknown): column is Column => {
  if (typeof column === "object" && column !== null) {
    return "uuid" in column;
  }
  return false;
};

const DragHandle = SortableHandle(
  ({ draggable }: { draggable: boolean | undefined }) => (
    <div className="tasks-table__th-drag-and-drop-wrapper">
      {draggable ? (
        <GridIcon className="tasks-table__th-drag-and-drop" />
      ) : null}
    </div>
  ),
);

const COLUMN_ID_BLACKLIST = [
  "fullWidthTable",
  " action",
  "rightPadding",
  "select",
  "drag-handle",
];

interface HeaderCellBaseProps {
  header: any;
  draggable: boolean | undefined;
  tasksLoading: boolean;
  columnIndex: number;
  isDropdownOpened: boolean;
  setIsDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>;
  activeSortingColumn: string | null;
  tableContext: TableContext;
}

interface HeaderCellContentForColumnProps extends HeaderCellBaseProps {
  columnFromApi: Column;
}

function HeaderCellContentForColumn({
  header,
  columnFromApi,
  draggable,
  tasksLoading,
  columnIndex,
  isDropdownOpened,
  setIsDropdownOpened,
  activeSortingColumn,
  tableContext,
}: HeaderCellContentForColumnProps) {
  const { column } = header;

  const key = columnFromApi?.name.split(" ").join("_").toLowerCase();
  return (
    <th
      className="tasks-table__th"
      style={{
        ...getCommonPinningStyles(header.column, draggable),
      }}
    >
      {tasksLoading ? (
        <>
          <span style={{ visibility: "hidden" }} />
          <SkeletonText width={60} height={15} />
        </>
      ) : (
        <div
          className={classNames("tasks-table__header-wrapper", {
            "tasks-table__header-wrapper--active": isDropdownOpened,
          })}
          data-column-name={columnFromApi?.name}
          data-column-key={key}
        >
          <DragHandle draggable={draggable} />
          {activeSortingColumn === header.id && (
            <SortingTasksArrow tableContext={tableContext} />
          )}

          <ColumnHeaderName column={column} showNameTooltip={columnIndex > 1}>
            {column.columnDef.header}
          </ColumnHeaderName>

          <TaskTableHeaderOptionsDropdown
            uuid={header.id}
            setIsDropdownOpened={setIsDropdownOpened}
            activeSortingColumn={activeSortingColumn}
            tableContext={tableContext}
          />
        </div>
      )}
    </th>
  );
}

interface HeaderCellContentForTaskColumnProps extends HeaderCellBaseProps {
  columnFromApi: TasksColumn;
}

function HeaderCellContentForTaskColumn({
  header,
  columnFromApi,
  columnIndex,
  tasksLoading,
  draggable,
  isDropdownOpened,
  setIsDropdownOpened,
  activeSortingColumn,
  tableContext,
}: HeaderCellContentForTaskColumnProps) {
  const [showShareWithClientModal, setShowShareWihClientModal] =
    useState(false);
  const [showStopSharingWithClientModal, setShowStopSharingWithClientModal] =
    useState(false);

  const permissions = useSelector(
    (state: RootState) => state.projectReducer.projectBasicData?.permissions,
  );
  const clientMode = useSelector(
    (state: RootState) => state.projectReducer.projectBasicData?.clientMode,
  );

  const { column } = header;

  const showShareResourcesTooltip =
    permissions?.project.includes(
      ProjectPermissionsEnum.SHARE_PROJECT_RESOURCES_PREVIEW,
    ) &&
    clientMode &&
    columnFromApi &&
    columnFromApi?.metaFieldType !== MetaFieldType.DictionaryElement;

  const openShareWithClientModal = () => {
    permissions?.project.includes(
      ProjectPermissionsEnum.SHARE_PROJECT_RESOURCES,
    ) && setShowShareWihClientModal(true);
  };

  const openStopSharingWithClientModal = () => {
    permissions?.project.includes(
      ProjectPermissionsEnum.SHARE_PROJECT_RESOURCES,
    ) && setShowStopSharingWithClientModal(true);
  };

  return (
    <th
      className="tasks-table__th"
      style={{
        ...getCommonPinningStyles(header.column, draggable),
      }}
    >
      {tasksLoading ? (
        <>
          <span style={{ visibility: "hidden" }} />
          <SkeletonText width={60} height={15} />
        </>
      ) : (
        <div
          className={classNames("tasks-table__header-wrapper", {
            "tasks-table__header-wrapper--active": isDropdownOpened,
          })}
          data-column-name={columnFromApi?.metaFieldName}
          data-column-key={columnFromApi?.metaFieldKey}
        >
          <DragHandle draggable={draggable} />
          {activeSortingColumn === header.id && (
            <SortingTasksArrow tableContext={tableContext} />
          )}

          <ColumnHeaderName
            column={column}
            showShareResourcesTooltip={showShareResourcesTooltip}
            showNameTooltip={columnIndex > 1}
          >
            {column.columnDef.header}
          </ColumnHeaderName>
          {showShareResourcesTooltip && (
            <TooltipPortal
              className="tasks-table__header-wrapper-share-icon"
              center
              content={
                columnFromApi.metaFieldShared ||
                columnFromApi.metaFieldIsSystem ? (
                  <IDHFormattedMessage
                    id="ws_tooltip_click_to_stop_sharing"
                    defaultMessage="Click to stop sharing with Client"
                  />
                ) : (
                  <IDHFormattedMessage
                    id="ws_tooltip_click_to_share"
                    defaultMessage="Click to share with Client"
                  />
                )
              }
              contentHidden={
                !permissions?.project.includes(
                  ProjectPermissionsEnum.SHARE_PROJECT_RESOURCES,
                )
              }
            >
              {columnFromApi.metaFieldShared ? (
                <ClientOrangeIcon
                  className={classNames("tasks-table__column-shared", {
                    "tasks-table__column-shared--disabled":
                      !permissions?.project.includes(
                        ProjectPermissionsEnum.SHARE_PROJECT_RESOURCES,
                      ),
                  })}
                  onClick={openStopSharingWithClientModal}
                />
              ) : (
                <div
                  className={classNames("tasks-table__column-not-shared", {
                    "tasks-table__column-not-shared--disabled":
                      !permissions?.project.includes(
                        ProjectPermissionsEnum.SHARE_PROJECT_RESOURCES,
                      ),
                  })}
                  onClick={openShareWithClientModal}
                />
              )}
            </TooltipPortal>
          )}
          <TaskTableHeaderOptionsDropdown
            uuid={header.id}
            columnFromApi={columnFromApi}
            setIsDropdownOpened={setIsDropdownOpened}
            openShareWithClientModal={openShareWithClientModal}
            openStopSharingWithClientModal={openStopSharingWithClientModal}
            tableContext={tableContext}
            activeSortingColumn={activeSortingColumn}
          />
        </div>
      )}

      {showShareWithClientModal && (
        <ShareWithClientModal
          onClose={() => setShowShareWihClientModal(false)}
          metaFieldId={header.id}
          taskType={columnFromApi.metaFieldTaskType}
        />
      )}

      {showStopSharingWithClientModal && (
        <StopSharingWithClientModal
          onClose={() => setShowStopSharingWithClientModal(false)}
          metaFieldId={header.id}
          taskType={columnFromApi.metaFieldTaskType}
        />
      )}
    </th>
  );
}

interface HeaderCellProps {
  header: any;
  columnIndex: number;
  tasksLoading: boolean;
  draggable?: boolean;
  totalRowCount?: number;
  currentRowCount?: number;
  tableContext: TableContext;
}

function HeaderCell({
  header,
  columnIndex,
  tasksLoading,
  draggable,
  totalRowCount,
  currentRowCount,
  tableContext,
}: HeaderCellProps) {
  const { column } = header;
  const { columnFromApi }: { columnFromApi: TasksColumn | Column | undefined } =
    column?.columnDef;
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const { activeSortingColumn: taskActiveSortingColumn } = useSelector(
    (state: RootState) => state.taskFiltersReducer,
  );

  const { activeSortingColumn: workspaceActiveSortingColumn } = useSelector(
    (state: RootState) => state.workspaceTaskFiltersReducer,
  );

  const activeSortingColumn =
    tableContext === "workspaceTasksList"
      ? workspaceActiveSortingColumn
      : taskActiveSortingColumn;

  if (columnFromApi === undefined && column.id) {
    const renderContent = () => {
      if (column.id === "select") {
        return flexRender(header.column.columnDef.header, header.getContext());
      }

      if (columnIndex === 2) {
        return (
          <ColumnHeaderName column={column}>
            DISPLAYING: {currentRowCount} OF {totalRowCount}
          </ColumnHeaderName>
        );
      }

      return (
        <ColumnHeaderName column={column} showNameTooltip={columnIndex > 1}>
          {column.columnDef.header}
        </ColumnHeaderName>
      );
    };

    return (
      <th
        className={classNames("tasks-table__th", {
          "tasks-table__th--action": column.id === " action",
        })}
        style={{
          ...getCommonPinningStyles(header.column),
        }}
      >
        {tasksLoading ? (
          !SKELETON_BLACKLIST.includes(column.id) && (
            <>
              <span style={{ visibility: "hidden" }} />
              <SkeletonText width={60} height={15} />
            </>
          )
        ) : (
          <div
            className={classNames("tasks-table__header-wrapper", {
              "tasks-table__header-wrapper--active": isDropdownOpened,
            })}
          >
            {activeSortingColumn === header.id && (
              <SortingTasksArrow tableContext={tableContext} />
            )}
            {renderContent()}
            {!COLUMN_ID_BLACKLIST.includes(column.id) && columnIndex !== 2 && (
              <TaskTableHeaderOptionsDropdown
                uuid={header.id}
                setIsDropdownOpened={setIsDropdownOpened}
                tableContext={tableContext}
                activeSortingColumn={activeSortingColumn}
              />
            )}
          </div>
        )}
      </th>
    );
  }

  const headerCellContentCommonProps = {
    header,
    columnIndex,
    tasksLoading,
    draggable,
    isDropdownOpened,
    setIsDropdownOpened,
    activeSortingColumn,
    tableContext,
  };

  if (isTasksColumn(columnFromApi)) {
    return (
      <HeaderCellContentForTaskColumn
        {...headerCellContentCommonProps}
        draggable={draggable}
        columnFromApi={columnFromApi}
        tableContext={tableContext}
      />
    );
  }
  if (isColumn(columnFromApi)) {
    return (
      <HeaderCellContentForColumn
        {...headerCellContentCommonProps}
        draggable={draggable}
        columnFromApi={columnFromApi}
        tableContext={tableContext}
      />
    );
  }
  return (
    <th
      className="tasks-table__th"
      style={{
        ...getCommonPinningStyles(header.column),
      }}
    >
      &nbsp;
    </th>
  );
}

export { HeaderCell };
