import React, {
  useRef,
  useState,
  useEffect,
  FunctionComponent,
  SVGProps,
  useMemo,
} from "react";
import "./CustomSelect.scss";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import classNames from "classnames";
import { overlaysRef } from "src/App";
import { ReactComponent as CloseIcon } from "src/images/mutableCloseIcon.svg";
import { ReactComponent as CircleCrossIcon } from "src/images/circle-cross.svg";

const createSelectComponents = (props: any) => {
  return {
    ClearIndicator: (clearProps: any) => (
      <div
        className="ws-react-select__clear-indicator"
        {...clearProps.innerProps}
      >
        <CircleCrossIcon />
      </div>
    ),
    Menu: (menuProps: any) => (
      <components.Menu {...menuProps} className="menu" />
    ),
    MenuList: (menuListProps: any) => (
      <div
        className="ws-react-select__menu-list-wrap"
        style={{ maxHeight: menuListProps.maxHeight }}
      >
        <div className="ws-react-select__menu-list">
          {menuListProps.children}
        </div>
        {props.afterMenuList}
      </div>
    ),
    MultiValueRemove: (removeProps: any) => {
      return (
        <div {...removeProps.innerProps}>
          <CloseIcon
            {...removeProps}
            width={16}
            height={16}
            fill={removeProps.data.color || "#8E93AC"}
          />
        </div>
      );
    },
    Option: ({ children, data, ...optionProps }: any) => {
      const ref = useRef<HTMLDivElement>(null);

      const [textValue, setTextValue] = useState("custom-option");

      const getTextValue = () => {
        if (!ref?.current) return "custom-option";
        const { textContent, outerText, innerText } = ref?.current;
        if (textContent) return textContent;
        if (outerText) return outerText;
        if (innerText) return innerText;
        return "custom-option";
      };

      useEffect(() => {
        setTextValue(getTextValue());
      }, [ref?.current]);

      return (
        <components.Option
          className={classNames(
            data.hasOwnProperty("className") && data.className,
            optionProps.innerProps?.className,
          )}
          {...optionProps}
        >
          <div ref={ref} className="idh-select-option" data-qa={textValue}>
            {children}
            {data.hasOwnProperty("customElement") && data.customElement}
          </div>
        </components.Option>
      );
    },
    ...props.components,
  };
};

function CustomSelect(props: any) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [height, setHeight] = useState<number | null>(null);
  const [openOnFocus, setOpenOnFocus] = useState(
    props?.openOnFocus !== undefined ? props.openOnFocus : true,
  );
  const selectWrapperRef = useRef<any>(null);
  const selectRef = useRef<any>(null);

  const selectComponents = useMemo(() => createSelectComponents(props), []);

  useEffect(() => {
    if (props.forceOpen) {
      selectRef.current.select.focus();
    }
  }, [props.forceOpen]);

  useEffect(() => {
    if (props.forceFocus) {
      setInterval(() => {
        selectRef.current?.select && selectRef.current.select.inputRef.focus();
      }, 0);
    }
  }, [props.forceFocus]);

  const onMenuOpen = () => {
    setHeight(selectWrapperRef.current.offsetHeight);
    setMenuOpen(true);
    if (props.afterMenuOpen) {
      props.afterMenuOpen();
    }
  };

  const onMenuClose = () => {
    const menuEl = document.querySelector(`.menu`);

    const containerEl = menuEl?.parentElement;
    const clonedMenuEl = menuEl?.cloneNode(true) as HTMLElement;

    setMenuOpen(false);
    if (!clonedMenuEl) return;

    clonedMenuEl.classList.add("menu--close");
    clonedMenuEl.addEventListener("animationend", () => {
      containerEl?.removeChild(clonedMenuEl);
    });

    containerEl?.appendChild(clonedMenuEl!);
  };

  const onPlaceholderClick = () => {
    if (!selectRef.current) return;
    selectRef.current.select.focus();
  };

  const onChangeHandler = (item: any) => {
    // apply onChange from parent
    props.onChange(item);

    // logic to not focus select when clearing
    // but select openMenuOnFocus props must be true
    // to clicking on placeholder works correctly
    if (props.isClearable) {
      if (item === null || (props.isMulti && item.length === 0)) {
        setOpenOnFocus(false);
        setTimeout(() => {
          selectRef.current.blur();
          setOpenOnFocus(true);
        }, 10);
      }
    }
  };

  const styles = props.customStyles || {
    control: (provided: any, state: any) => ({
      ...provided,
      boxShadow: state.isFocused && "0 0 0 1px #5d78ff",
    }),
    option: (
      styles: any,
      {
        isDisabled,
        isFocused,
        isSelected,
      }: { isDisabled: boolean; isFocused: boolean; isSelected: boolean },
    ) => {
      return {
        ...styles,
      };
    },
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: "transparent",
      };
    },
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "#74788d",
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "#D3D5E1",
      ":hover": {
        backgroundColor: "#F2F2F6",
        color: "#D3D5E1",
      },
    }),
  };

  const options = {
    ref: selectRef,
    menuPortalTarget: props?.portalized && overlaysRef.current,
    classNamePrefix: "ws-react-select",
    className: classNames("ws-react-select", {
      "ws-react-select--with-tiles": props.withTiles,
    }),
    hideSelectedOptions: false,
    onMenuOpen,
    onMenuClose,
    // openMenuOnFocus must be true to work correctly clicking on placeholder
    openMenuOnFocus: openOnFocus,
    styles,
    ...props,
    noOptionsMessage: () => props.noOptionsMessage,
    onSelectResetsInput: false,
    onBlurResetsInput: false,
    placeholder: "", // empty placeholder must be after overwrite
    onChange: onChangeHandler, // onChange overwrite must be after {...props}
    onCreateOption: props.onCreateOption,
  };

  // Below function is to handle required for both multi select and single
  const getValueForHTMLInput = () => {
    if (props.isMulti && Array.isArray(props.value)) {
      if (props.value.length > 0) {
        return props.value[0].value;
      }
      return undefined;
    }
    if (!props.isMulti && props.value) {
      return props.value.value;
    }
    return undefined;
  };

  return (
    <>
      {props.label && (
        <div className="ws-react-select-label">{props.label}</div>
      )}

      <div className="ws-react-select-container">
        <div
          ref={selectWrapperRef}
          className={classNames("ws-react-select-wrapper", {
            "ws-react-select-wrapper--hide-placeholder":
              ((props.value?.value || props.value?.length > 0) &&
                !isMenuOpen) ||
              (isMenuOpen && props.creatable),
            "ws-react-select-wrapper--disabled": props.disabled,
          })}
        >
          {props.creatable ? (
            <CreatableSelect {...options} components={selectComponents} />
          ) : (
            <>
              <Select
                isDisabled={props.isDisabled}
                isSearchable={false}
                components={selectComponents}
                {...options}
              />
              {/* This is hack because this version of react-select doesn't have required props */}
              {props.required && (
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  style={{
                    opacity: 0,
                    height: 0,
                    position: "absolute",
                    left: "-10px",
                    top: "40px",
                  }}
                  value={getValueForHTMLInput()}
                  required={props.required}
                />
              )}
            </>
          )}
          {(!props.isSearchable ||
            (props.isSearchable && !isMenuOpen === true)) && (
            <span
              className="ws-react-select__placeholder"
              onClick={onPlaceholderClick}
            >
              {props.placeholder}
            </span>
          )}
        </div>
        <span
          className="ws-react-select__height"
          style={{ height: `${height}px` }}
        />
      </div>
    </>
  );
}

export default CustomSelect;

interface ImageOptionProps {
  children?: React.ReactNode;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  className?: string;
}

export const ImageOption: React.FC<ImageOptionProps> = (props) => {
  const { children, icon: Icon, className } = props;
  return (
    <span className={classNames("image-select-option", className)}>
      <Icon />
      <span className="image-select-option__text">{children}</span>
    </span>
  );
};
