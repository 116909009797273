import React from "react";

import { SelectOption } from "../types";

export const createOption = ({
  uuid,
  name,
}: {
  uuid: string;
  name: string;
}): SelectOption => {
  return {
    value: uuid,
    label: (
      <span className="image-select-option dictionary-element-option">
        {name}
      </span>
    ),
    name,
  };
};
