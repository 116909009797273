import React, { ReactNode } from "react";
import axios, { AxiosResponse } from "axios";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import responseCodes from "src/utils/responseCodes";
import { showToast } from "../app/methods/showToast";
import { parseErrorMessages } from "../utils/methods";

const wsAxiosPatch = async (
  url: string,
  payload: any,
  errorMessage: string,
  initCallback?: () => void,
  thenCallback?: () => void,
  finallyCallback?: () => void,
): Promise<void> => {
  if (initCallback) {
    initCallback();
  }
  return axios
    .patch(url, payload, {})
    .then((response) => {
      // dev/beta 200=200, 400=400, 500=200 with "fail" status
      if (response?.data?.status === "fail") {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage id={errorMessage} />,
        );
      }
      if (thenCallback) {
        thenCallback();
      }
    })
    .catch((err) => {
      // local/live 200=200, 400=400, 500=500
      if (err.response?.data?.errors.length > 0) {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id={parseErrorMessages(err.response.data.errors)[0].message}
          />,
        );
      } else {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage id={errorMessage} />,
        );
      }
    })
    .finally(() => {
      if (finallyCallback) {
        finallyCallback();
      }
    });
};

const wsAxiosPost = async <T,>(
  url: string,
  payload: T,
  errorMessage?: string,
  initCallback?: () => void,
  thenCallback?: () => void,
  finallyCallback?: () => void,
): Promise<void> => {
  if (initCallback) {
    initCallback();
  }
  return axios
    .post(url, payload)
    .then((response) => {
      // dev/beta 200=200, 400=400, 500=200 with "fail" status
      if (response?.data?.status === "fail") {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          errorMessage ? (
            <IDHFormattedMessage id={errorMessage} />
          ) : (
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong"
            />
          ),
        );
      }
      if (thenCallback) {
        thenCallback();
      }
    })
    .catch((err) => {
      // local/live 200=200, 400=400, 500=500
      if (err.response?.data?.errors?.length > 0) {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id={parseErrorMessages(err.response.data.errors)[0].message}
          />,
        );
      } else {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          errorMessage ? (
            <IDHFormattedMessage id={errorMessage} />
          ) : (
            <IDHFormattedMessage
              id="ws_something_went_wrong"
              defaultMessage="Something went wrong"
            />
          ),
        );
      }
    })
    .finally(() => {
      if (finallyCallback) {
        finallyCallback();
      }
    });
};

const wsAxiosPut = async (
  url: string,
  payload: any,
  errorMessage: string,
  initCallback?: () => void,
  thenCallback?: () => void,
  finallyCallback?: () => void,
): Promise<void> => {
  if (initCallback) {
    initCallback();
  }
  return axios
    .put(url, payload, {})
    .then((response) => {
      // dev/beta 200=200, 400=400, 500=200 with "fail" status
      if (response?.data?.status === "fail") {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage id={errorMessage} />,
        );
      }
      if (thenCallback) {
        thenCallback();
      }
    })
    .catch((err) => {
      // local/live 200=200, 400=400, 500=500
      if (err.response?.data?.errors?.length > 0) {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id={parseErrorMessages(err.response.data.errors)[0].message}
          />,
        );
      } else {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage id={errorMessage} />,
        );
      }
    })
    .finally(() => {
      if (finallyCallback) {
        finallyCallback();
      }
    });
};

export interface AxiosGetProps {
  url: string;
  errorMessage?: ReactNode;
  initialCallback?: () => void;
  finallyCallback?: () => void;
}

const wsAxiosGet = async <T,>({
  url,
  initialCallback,
  finallyCallback,
  errorMessage,
}: AxiosGetProps) => {
  try {
    if (initialCallback) {
      initialCallback();
    }

    const response: AxiosResponse<T> = await axios.get(url);

    if (response.status >= responseCodes["400_BAD_REQUEST"]) {
      throw new Error(`Request failed with status code ${response.status}`);
    }
    return response.data;
  } catch (error) {
    console.error("wsAxiosGet:", error);
    showToast(
      "error",
      <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
      errorMessage ?? (
        <IDHFormattedMessage
          id="ws_something_went_wrong"
          defaultMessage="Something went wrong"
        />
      ),
    );
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
};

async function wsAxiosDelete<T>({
  url,
  data,
  initialCallback,
  thenCallback,
  finallyCallback,
  errorCallback,
}: {
  url: string;
  data?: T;
  initialCallback?: () => void;
  thenCallback?: () => void;
  errorCallback?: () => void;
  finallyCallback?: () => void;
}) {
  try {
    if (initialCallback) {
      initialCallback();
    }

    await axios.delete(url, {
      data,
    });

    if (thenCallback) {
      thenCallback();
    }
  } catch (error) {
    if (errorCallback) {
      errorCallback();
    }
    console.error(error);
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
}

export { wsAxiosPatch, wsAxiosPost, wsAxiosPut, wsAxiosGet, wsAxiosDelete };
