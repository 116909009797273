import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { tableDataType } from "src/app/components/Table/Table";
import { DictionaryField } from "src/app/SettingsModal/tabs/DictionaryManager/types";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RootState } from "src/redux/reducers";
import { createOption } from "src/app/components/CustomSelect/functions/createOption";
import { SelectOption } from "src/app/components/CustomSelect/types";
import { FILTER_DICTIONARY_META_FIELDS_WHITELIST } from "./utils";
import { FieldManagerFormData, FieldManagerFormDataKeys } from "../../types";

interface DictionaryFilterElementsProps {
  editedField: DictionaryField | undefined;
  dataType: tableDataType;
  selectedDictionaryUuid: string | null;
  formData: FieldManagerFormData;
  handleUpdateFormData: (
    key: FieldManagerFormDataKeys,
    value: FieldManagerFormData[FieldManagerFormDataKeys],
  ) => void;
}

export default function DictionaryFilterElements({
  editedField,
  dataType,
  selectedDictionaryUuid,
  formData,
  handleUpdateFormData,
}: DictionaryFilterElementsProps) {
  const { dictionaryAutoCompletesColumns } = useSelector(
    (state: RootState) => state.dictionaryReducer,
  );

  const { dictionary } = useSelector(
    (state: RootState) => state.settingsReducer,
  );

  const { selectedFilterMetaFieldTarget, selectedFilterByMetaField } = formData;

  const filterTargetOptions = useMemo(() => {
    switch (dataType) {
      case tableDataType.DictionaryManager:
        return dictionary?.metaFields
          ? dictionary.metaFields.map(createOption)
          : [];
      default:
        return [];
    }
  }, [dictionary, dataType]);

  const dictionaryMetaFieldOptions = useMemo(() => {
    switch (dataType) {
      case tableDataType.DictionaryManager: {
        const columns =
          dictionaryAutoCompletesColumns[
            `dictionaryType_${selectedDictionaryUuid}`
          ];
        return columns
          ? columns
              .filter((column) =>
                FILTER_DICTIONARY_META_FIELDS_WHITELIST.includes(column.type),
              )
              .map(createOption)
          : [];
      }
      default:
        return [];
    }
  }, [dataType, dictionaryAutoCompletesColumns, selectedDictionaryUuid]);

  useEffect(() => {
    if (editedField?.data.wsDictionaryElementsFilter) {
      const foundFilterTargetOption = filterTargetOptions.find(
        (option) =>
          option.value ===
          editedField.data.wsDictionaryElementsFilter
            ?.wsDictionaryMetaFieldTargetUuid,
      );

      if (foundFilterTargetOption) {
        handleUpdateFormData(
          "selectedFilterMetaFieldTarget",
          foundFilterTargetOption,
        );
      }

      const foundDictionaryMetaFieldOptions = dictionaryMetaFieldOptions.find(
        (option) =>
          option.value ===
          editedField.data.wsDictionaryElementsFilter
            ?.wsDictionaryMetaFieldFilterByUuid,
      );

      if (foundDictionaryMetaFieldOptions) {
        handleUpdateFormData(
          "selectedFilterByMetaField",
          foundDictionaryMetaFieldOptions,
        );
      }
    }
  }, [editedField, filterTargetOptions, dictionaryMetaFieldOptions]);

  return (
    <>
      <div className="dictionary-sub-field-wrapper__row">
        <div className="dictionary-sub-field-wrapper__sub-fields">
          <CustomInputLabel>
            <IDHFormattedMessage
              id="ws_select_field"
              defaultMessage="Select field"
            />
          </CustomInputLabel>
          <CustomSelect
            options={filterTargetOptions}
            value={selectedFilterMetaFieldTarget}
            onChange={(newOption: SelectOption) => {
              handleUpdateFormData("selectedFilterMetaFieldTarget", newOption);
            }}
            disabled={!!editedField}
            isSearchable
          />
        </div>
      </div>

      <div className="dictionary-sub-field-wrapper__row">
        <div className="dictionary-sub-field-wrapper__sub-fields">
          <CustomInputLabel>
            <IDHFormattedMessage
              id="ws_dictionary_meta_field"
              defaultMessage="Dictionary meta field"
            />
          </CustomInputLabel>
          <CustomSelect
            options={dictionaryMetaFieldOptions}
            value={selectedFilterByMetaField}
            onChange={(newOption: SelectOption) => {
              handleUpdateFormData("selectedFilterByMetaField", newOption);
            }}
            disabled={!!editedField}
            isSearchable
          />
        </div>
      </div>
    </>
  );
}
