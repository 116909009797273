import React, { useEffect, useState } from "react";
import "./EditSelectOptionsModal.scss";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle } from "src/app/components/Modal/Modal";
import Select from "src/app/components/Select/Select";
import { tableDataType } from "src/app/components/Table/Table";
import { SelectOption } from "src/types";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { WarningModal } from "src/app/modals/WarningModal/WarningModal";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import {
  getWorkspaceUuidFromCurrentUrl,
  showErrorToast,
} from "src/utils/methods";
import {
  getSettingsGlobalFields,
  setShouldUpdateDictionaryData,
} from "src/redux";
import { useDispatch } from "react-redux";

interface Props {
  onClose: () => void;
  data: any;
  dataType: tableDataType;
}

enum WarningTypes {
  EnablePreExisting = "enable-pre-existing",
  DisablePreExisting = "disable-pre-existing",
  ChangePreExisting = "change-pre-existing",
}
interface Warning {
  type: WarningTypes;
  data?: SelectOption;
}

const EditSelectOptionsModal: React.FC<Props> = (props) => {
  const { onClose, data, dataType } = props;

  const [options, setOptions] = useState<SelectOption[]>([]);
  const [usePreExistingDataSet, setUsePreExistingDataSet] = useState(false);
  const [dataSetOptions, setDataSetOptions] = useState([]);
  const [selectedDataSet, setSelectedDataSet] = useState<any>({});
  const [warning, setWarning] = useState<Warning | null>(null);

  const dispatch = useDispatch();

  const fieldData = data?.settings || data?.data;

  const wsWorkspaceUuid = getWorkspaceUuidFromCurrentUrl();

  const getDataSetList = async () => {
    if (!wsWorkspaceUuid) return;

    const url = API_URLS.getDataSetList.replace(
      ":wsWorkspaceUuid:",
      wsWorkspaceUuid,
    );

    try {
      const {
        data: { content },
      } = await axios.get(url);

      const newOptions = content.map((item: any) => ({
        label: item.name,
        value: item.uuid,
        data: item,
      }));

      setDataSetOptions(newOptions);
    } catch (error) {
      console.error(error);
      showErrorToast();
    }
  };

  const setProjectDataSet = async (newValue: { value: string } | null) => {
    if (!wsWorkspaceUuid) return;

    const wsSelectDataSetUuid = newValue?.value || null;

    try {
      await axios.post(API_URLS.setProjectMetaFieldDataSetUuid, {
        wsGlobalProjectMetaFieldUuid: data.uuid,
        wsSelectDataSetUuid,
        wsWorkspaceUuid,
      });
      setSelectedDataSet(newValue);
      if (!wsSelectDataSetUuid) setOptions([]);
      dispatch(getSettingsGlobalFields(wsWorkspaceUuid));
    } catch (err) {
      showErrorToast();
    }
  };

  const setTaskDataSet = async (newValue: { value: string } | null) => {
    if (!wsWorkspaceUuid) return;

    const wsSelectDataSetUuid = newValue?.value || null;

    try {
      await axios.post(API_URLS.setTaskMetaFieldDataSetUuid, {
        wsGlobalTaskMetaFieldUuid: data.uuid,
        wsSelectDataSetUuid,
        wsWorkspaceUuid,
      });
      if (!wsSelectDataSetUuid) setOptions([]);
      dispatch(getSettingsGlobalFields(wsWorkspaceUuid));
    } catch (err) {
      showErrorToast();
    }
  };

  const setDictionaryDataSet = async (newValue: SelectOption | null) => {
    if (!wsWorkspaceUuid) return;

    const wsSelectDataSetUuid = newValue?.value || null;

    try {
      await axios.post(API_URLS.setDictionaryMetaFieldDataSet, {
        metaFieldUuid: data.uuid,
        wsSelectDataSetUuid,
      });
      setSelectedDataSet(newValue);
      if (!wsSelectDataSetUuid) setOptions([]);
      dispatch(setShouldUpdateDictionaryData(true));
    } catch (error) {
      showErrorToast();
      console.error(error);
    }
  };

  const handleChange = (newValue: SelectOption | null) => {
    if (!wsWorkspaceUuid) return;

    switch (dataType) {
      case tableDataType.GlobalTask:
        setTaskDataSet(newValue);
        break;

      case tableDataType.GlobalProject: {
        setProjectDataSet(newValue);
        break;
      }
      case tableDataType.DictionaryManager:
        setDictionaryDataSet(newValue);
        break;
      default:
        break;
    }
  };

  const handleUsePreExistingDataSet = () => {
    setUsePreExistingDataSet(true);
    handleChange(dataSetOptions[0]);
    setWarning(null);
  };

  const handleSwitchChange = (checked: boolean) => {
    if (checked) {
      if (options.length > 0) {
        setWarning({ type: WarningTypes.EnablePreExisting });
      } else {
        handleUsePreExistingDataSet();
      }
    } else {
      setWarning({ type: WarningTypes.DisablePreExisting });
    }
  };

  const confirmFunction = () => {
    if (!warning) return;

    switch (warning.type) {
      case WarningTypes.EnablePreExisting:
        handleUsePreExistingDataSet();
        break;
      case WarningTypes.DisablePreExisting:
        setUsePreExistingDataSet(false);
        handleChange(null);
        break;
      case WarningTypes.ChangePreExisting:
        if (warning.data) handleChange(warning.data);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getDataSetList();
  }, []);

  useEffect(() => {
    const dataSetUuid = fieldData.wsSelectDataSetUuid;

    if (dataSetUuid && dataSetOptions.length) {
      const dataSet: any = dataSetOptions.find(
        (item: { value: string }) => item.value === dataSetUuid,
      );

      if (dataSet) {
        setSelectedDataSet(dataSet);
        setUsePreExistingDataSet(true);
        return;
      }
    }

    setOptions(fieldData.singleSelectOptions || []);
  }, [fieldData, dataSetOptions]);

  useEffect(() => {
    if (selectedDataSet?.data?.settings) {
      setOptions(selectedDataSet.data.settings);
    }
  }, [selectedDataSet]);

  return (
    <>
      <Modal
        className="edit-select-options-modal"
        onClose={onClose}
        onCancelClick={onClose}
        displayCancelButton
      >
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_manage_select_options"
            defaultMessage="Manage select options"
          />
        </ModalTitle>

        {dataSetOptions?.length ? (
          <div className="edit-select-options-modal__content">
            <div className="edit-select-options-modal__switch-wrapper">
              <IDHFormattedMessage
                id="ws_use_pre_existing_data_set"
                defaultMessage="Use pre-existing data set"
              />

              <CustomSwitch
                checked={usePreExistingDataSet}
                onChange={(e) => handleSwitchChange(e.target.checked)}
              />
            </div>

            {usePreExistingDataSet && (
              <>
                <CustomInputLabel htmlFor="data-set">
                  <IDHFormattedMessage
                    id="ws_select_data_set"
                    defaultMessage="Select data set"
                  />
                </CustomInputLabel>

                <CustomSelect
                  id="data-set"
                  options={dataSetOptions}
                  value={selectedDataSet}
                  onChange={(newValue: SelectOption) => {
                    if (newValue.value !== selectedDataSet.value) {
                      setWarning({
                        type: WarningTypes.ChangePreExisting,
                        data: newValue,
                      });
                    }
                  }}
                />
              </>
            )}
          </div>
        ) : null}

        <Select
          variant={selectedDataSet?.value ? "disable_all" : "default"}
          mode="edit-global"
          uuid={data.uuid}
          optionsData={options}
          fieldName={data.name}
          fieldValue={null}
          dataType={dataType}
          readOnly={Boolean(selectedDataSet?.value)}
        />
      </Modal>

      {Boolean(warning) && (
        <WarningModal
          onClose={() => setWarning(null)}
          objectNames={["Pre-existing data set"]}
          confirmAction={confirmFunction}
          warningMessage={
            <IDHFormattedMessage
              id="ws_previously_created_options_will_be_deleted_this_action_cannot_be_undone"
              defaultMessage="Previously created options will be deleted. This action cannot be undone."
            />
          }
        />
      )}
    </>
  );
};

export default EditSelectOptionsModal;
