import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/redux/reducers";
import { SettingsButtons } from "../../components/SettingsTabContent/SettingsTabContent";
import EditedDictionary from "./EditedDictionary";
import { SettingsTab } from "../../enums";
import DictionaryList from "./DictionaryList";
import { Dictionary } from "./types";

import "./DictionaryManager.scss";

interface DictionaryManagerProps {
  onClose: () => void;
}

function DictionaryManager({ onClose }: DictionaryManagerProps) {
  const [editedDictionary, setEditedDictionary] = useState<Dictionary | null>(
    null,
  );

  const { settingsModalData } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const { tab } = settingsModalData;

  return (
    <div className="dictionary-manager">
      {editedDictionary && (
        <EditedDictionary
          editedDictionary={editedDictionary}
          setEditedDictionary={setEditedDictionary}
        />
      )}
      {tab === SettingsTab.DictionaryManager && !editedDictionary && (
        <DictionaryList setEditedDictionary={setEditedDictionary} />
      )}
      <SettingsButtons onClose={onClose} />
    </div>
  );
}

export default DictionaryManager;
