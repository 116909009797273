import { valueExists } from "src/app/methods/valueExists";
import { SelectOption } from "src/app/modals/ManageFieldModal/SocialMetrics";
import {
  getWorkspaceUuidFromCurrentUrl,
  updateSelectedTableViewInLS,
} from "src/utils/methods";
import { FieldRank } from "src/app/components/TableOptionsDropdown/MetaDataOptionsDropdown";
import { SetWorkspaceTaskFilterParams } from "./workspaceTaskFiltersActions";
import * as actionTypes from "./workspaceTaskFiltersTypes";
import { TaskSortingDirection } from "../taskFilters/types";
import {
  removeHiddenSettings,
  sanitizeFilters,
} from "../projectFilters/projectFiltersReducer";

const defaultSelectedTableView = {
  label: "Custom",
  value: null,
};

const wsWorkspaceUuid = getWorkspaceUuidFromCurrentUrl();

export interface WorkspaceTaskFiltersReducerState {
  activeSortingColumn: string | null;
  sortingDirection: TaskSortingDirection;
  filters: Record<string, { value: any }>;
  selectedTableView: SelectOption;
  visibleColumns: string[];
  columnRanks: FieldRank[];
}

const getTaskTypeFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("taskType");
};

const defaultState = {
  activeSortingColumn: null,
  sortingDirection: TaskSortingDirection.Descending,
  filters: {},
  selectedTableView: defaultSelectedTableView,
  visibleColumns: [],
  columnRanks: [],
};

export const getInitialState = () => {
  const taskType = getTaskTypeFromURL() || "creator";

  const settingsDataFromLS = localStorage.getItem(
    `ws-data-hub-settings-${taskType}`,
  );

  const settingsData = settingsDataFromLS
    ? JSON.parse(settingsDataFromLS)
    : null;

  const settings =
    settingsData && wsWorkspaceUuid ? settingsData[wsWorkspaceUuid] : null;

  const selectedTableView = settings?.selectedTableView;

  if (selectedTableView?.data) {
    return {
      ...defaultState,
      ...settings,
      ...selectedTableView.data,
      selectedTableView,
    };
  }

  if (settings) return settings;

  return defaultState;
};

const save = (obj: any, skipLSUpdate?: boolean) => {
  const taskType = getTaskTypeFromURL();

  if (!skipLSUpdate && wsWorkspaceUuid) {
    localStorage.setItem(
      `ws-data-hub-settings-${taskType}`,
      JSON.stringify({
        ...JSON.parse(
          localStorage.getItem(`ws-data-hub-settings-${taskType}`) || "{}",
        ),
        [wsWorkspaceUuid]: obj,
      }),
    );
  }
  return sanitizeFilters(obj);
};

function workspaceTaskFiltersReducer(state = getInitialState(), action: any) {
  const taskType = getTaskTypeFromURL();

  switch (action.type) {
    case actionTypes.REFRESH_DATA_HUB_STATE:
      return {
        ...state,
        ...getInitialState(),
      };

    case actionTypes.SET_WORKSPACE_TASK_SORTING_COLUMN:
      return save(
        {
          ...state,
          activeSortingColumn: action.payload.activeSortingColumn,
          selectedTableView: defaultSelectedTableView,
        },
        action.payload.skipLSUpdate,
      );

    case actionTypes.SET_WORKSPACE_TASK_SORTING_DIRECTION:
      return save(
        {
          ...state,
          sortingDirection: action.payload.sortingDirection,
          selectedTableView: defaultSelectedTableView,
        },
        action.payload.skipLSUpdate,
      );

    case actionTypes.SET_WORKSPACE_TASK_FILTER: {
      const { uuid, value }: SetWorkspaceTaskFilterParams = action.payload;
      const doesValueExist = valueExists(value);

      let newFilters = { ...state.filters };

      if (doesValueExist) {
        newFilters = { ...state.filters, [uuid]: { value } };
      } else {
        delete newFilters[uuid];
      }

      return save({
        ...state,
        filters: newFilters,
      });
    }

    case actionTypes.SET_WORKSPACE_TASK_FILTERS:
      return save(
        {
          ...state,
          filters: action.payload.filters,
          selectedTableView: defaultSelectedTableView,
        },
        action.payload.skipLSUpdate,
      );

    case actionTypes.CLEAR_WORKSPACE_TASK_FILTERS:
      return save({
        ...state,
        filters: {},
        selectedTableView: defaultSelectedTableView,
      });

    case actionTypes.SET_WORKSPACE_TASK_VISIBLE_COLUMNS:
      return save(
        {
          ...state,
          selectedTableView: defaultSelectedTableView,
          visibleColumns: action.payload.visibleColumns,
        },
        action.payload.skipLSUpdate,
      );

    case actionTypes.TOGGLE_WORKSPACE_TASK_COLUMN_VISIBILITY: {
      const { uuid } = action.payload;
      return save({
        ...removeHiddenSettings(state, uuid),
        selectedTableView: defaultSelectedTableView,
        visibleColumns: state.visibleColumns?.includes(uuid)
          ? state.visibleColumns.filter((hc: string) => hc !== uuid)
          : [...(state.visibleColumns || []), uuid],
      });
    }

    case actionTypes.SET_WORKSPACE_TASK_COLUMN_RANKS:
      if (action.payload.saveConfig) {
        save({
          ...state,
          selectedTableView: defaultSelectedTableView,
          columnRanks: action.payload.ranks,
        });
      }

      return {
        ...state,
        columnRanks: action.payload.ranks,
      };

    case actionTypes.SET_WORKSPACE_TASK_COLUMN_RANK: {
      const { uuid, rank } = action.payload;

      let foundRank = false;

      const newRanks = state.columnRanks.map((col: any) => {
        if (col.uuid === uuid) {
          foundRank = true;
          return {
            ...col,
            rank,
          };
        }

        return col;
      });

      if (!foundRank) newRanks.push({ uuid, rank });

      return save({
        ...state,
        selectedTableView: defaultSelectedTableView,
        columnRanks: newRanks,
      });
    }

    case actionTypes.SET_WS_TASKS_SELECTED_TABLE_VIEW:
      updateSelectedTableViewInLS(
        `ws-data-hub-settings-${taskType}`,
        action.payload.selectedTableView,
      );
      return {
        ...state,
        selectedTableView: action.payload.selectedTableView,
      };

    default:
      return state;
  }
}

export default workspaceTaskFiltersReducer;
