import {
  UsageHistoryV2Input,
  GetUsageHistoryV2Query,
  GetUsageHistoryV2QueryVariables,
  GetUsageHistoryV2Document,
} from '@stigg/api-client-js/src/generated/sdk';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';

export class UsageHistoryApi {
  constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

  async getUsageHistoryV2(input: UsageHistoryV2Input) {
    return this.client.query<GetUsageHistoryV2Query, GetUsageHistoryV2QueryVariables>({
      query: GetUsageHistoryV2Document,
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
