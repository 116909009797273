import React, {
  ChangeEvent,
  useEffect,
  useState,
  FormEvent,
  useMemo,
} from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { uuidv7 } from "uuidv7";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { Button } from "src/app/components/Button/Button";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle, ModalColumn } from "src/app/components/Modal/Modal";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { translateMessage } from "src/app/methods/translateMessage";
import { getDictionaryTypes } from "src/app/methods/getDictionaryTypes";
import { RootState } from "src/redux/reducers";
import { DictionaryField } from "src/app/SettingsModal/tabs/DictionaryManager/types";
import { tableDataType } from "src/app/components/Table/Table";
import CurrencyField from "./components/CurrencyField";
import {
  FormDataSubmitPayload,
  FieldManagerFormData,
  FieldManagerFormDataKeys,
  FieldManagerAdditionalOptions,
  FieldManagerAdditionalOptionsKeys,
  MetaFieldTypeOption,
} from "./types";
import {
  formDataInitialState,
  allMetaFieldTypeOptions,
  additionalOptionsInitialState,
} from "./utils";
import MembersSelection from "./components/MembersSelection";
import DictionarySubFields from "./components/DictionarySubFields/DictionarySubFields";

import "./FieldManagerModal.scss";

interface AddFieldModalProps {
  onClose: () => void;
  handleFormDataSubmit: ({
    formData,
  }: {
    formData: FormDataSubmitPayload;
  }) => Promise<boolean>;
  editedField?: DictionaryField;
  metaFieldTypeOptionsWhiteList?: MetaFieldType[];
  dataType: tableDataType;
}

export default function FieldManagerModal({
  onClose,
  handleFormDataSubmit,
  editedField,
  metaFieldTypeOptionsWhiteList,
  dataType,
}: AddFieldModalProps) {
  const [fieldName, setFieldName] = useState("");
  const [selectedMetaFieldTypeOption, setSelectedMetaFieldTypeOption] =
    useState<MetaFieldTypeOption>();
  const [formData, setFormData] =
    useState<FieldManagerFormData>(formDataInitialState);

  const [additionalOptions, setAdditionalOptions] =
    useState<FieldManagerAdditionalOptions>(additionalOptionsInitialState);
  const [isLoading, setIsLoading] = useState(false);

  const { identity, activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const intl = useIntl();

  const resetToInitialData = () => {
    setFormData(formDataInitialState);
    setAdditionalOptions(additionalOptionsInitialState);
  };

  const metaFieldOptions = useMemo(() => {
    const metaFieldTypeOptionsFiltered = allMetaFieldTypeOptions.filter(
      (option) => {
        if (metaFieldTypeOptionsWhiteList) {
          return metaFieldTypeOptionsWhiteList.includes(option.type);
        }
        return true;
      },
    );

    const dictionaryOptions = getDictionaryTypes(identity);

    return [...metaFieldTypeOptionsFiltered, ...dictionaryOptions];
  }, [metaFieldTypeOptionsWhiteList, identity]);

  const shouldDisableConfirmButton = () => {
    if (fieldName.length === 0 || !selectedMetaFieldTypeOption?.value) {
      return true;
    }

    switch (selectedMetaFieldTypeOption.type) {
      case MetaFieldType.Currency:
        return formData.selectedCurrency === null;
      case MetaFieldType.Member:
        return (
          additionalOptions.enableFilterMemberByTeam &&
          formData.wsTeamUuids.length === 0
        );
      case MetaFieldType.DictionaryElement:
        return (
          (additionalOptions.enableDictionarySupportingField &&
            formData.wsDictionarySubField === null) ||
          (additionalOptions.enableFilterElementsFields &&
            (formData.selectedFilterByMetaField === null ||
              formData.selectedFilterMetaFieldTarget === null))
        );
      default:
        return false;
    }
  };

  const handleUpdateFormData = (
    key: FieldManagerFormDataKeys,
    value: FieldManagerFormData[FieldManagerFormDataKeys],
  ) => {
    setFormData((prevFormData) => ({ ...prevFormData, [key]: value }));
  };

  const handleUpdateAdditionalOptions = (
    key: FieldManagerAdditionalOptionsKeys,
    value: FieldManagerAdditionalOptions[FieldManagerAdditionalOptionsKeys],
  ) => {
    setAdditionalOptions((prevAdditionalOptions) => ({
      ...prevAdditionalOptions,
      [key]: value,
    }));
  };

  const renderMetaFieldTypeComponent = () => {
    if (!selectedMetaFieldTypeOption) return null;

    switch (selectedMetaFieldTypeOption.type) {
      case MetaFieldType.Member:
        return (
          <MembersSelection
            editedField={editedField}
            formData={formData}
            handleUpdateFormData={handleUpdateFormData}
            additionalOptions={additionalOptions}
            handleUpdateAdditionalOptions={handleUpdateAdditionalOptions}
          />
        );
      case MetaFieldType.Currency:
        return (
          <CurrencyField
            editedField={editedField}
            formData={formData}
            handleUpdateFormData={handleUpdateFormData}
          />
        );
      case MetaFieldType.DictionaryElement:
        return (
          <DictionarySubFields
            editedField={editedField}
            handleUpdateAdditionalOptions={handleUpdateAdditionalOptions}
            handleUpdateFormData={handleUpdateFormData}
            additionalOptions={additionalOptions}
            formData={formData}
            selectedDictionaryUuid={
              selectedMetaFieldTypeOption.value.split("_")[1] ?? null
            }
            dataType={dataType}
          />
        );
      default:
        return null;
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!selectedMetaFieldTypeOption?.value) {
      console.error("No metafield type selected");
      return;
    }

    setIsLoading(true);

    const uuid = editedField ? editedField.uuid : uuidv7();

    const isFormDataSubmitSuccessful = await handleFormDataSubmit({
      formData: {
        wsWorkspaceUuid: activeWorkspaceUuid,
        uuid,
        fieldName,
        selectedMetaFieldTypeOption,
        wsDictionaryUuid:
          selectedMetaFieldTypeOption?.type === MetaFieldType.DictionaryElement
            ? selectedMetaFieldTypeOption.value.split("_")[1]
            : null,
        ...formData,
      },
    });

    setIsLoading(false);

    if (isFormDataSubmitSuccessful) {
      onClose();
    }
  };

  useEffect(() => {
    if (editedField) {
      setFieldName(editedField.name);

      if (editedField.type === MetaFieldType.DictionaryElement) {
        const foundSelectedDictionaryOption = metaFieldOptions.find(
          (option) => {
            const selectedDictionaryUuid = option.value.split("_")[1];

            return selectedDictionaryUuid === editedField.data.wsDictionaryUuid;
          },
        );

        if (foundSelectedDictionaryOption) {
          setSelectedMetaFieldTypeOption(foundSelectedDictionaryOption);
        }
      } else {
        const foundOption = metaFieldOptions.find(
          (option) => option.type === editedField.type,
        );

        if (foundOption) {
          setSelectedMetaFieldTypeOption(foundOption);
        }
      }
    }
  }, [editedField, metaFieldOptions]);

  return (
    <Modal
      onClose={onClose}
      className="field-manager-modal"
      isLoading={isLoading}
    >
      <ModalTitle>
        {editedField ? (
          <IDHFormattedMessage
            id="ws_field_setup"
            defaultMessage="Field setup"
          />
        ) : (
          <IDHFormattedMessage
            id="ws_add_table_column"
            defaultMessage="Add table column"
          />
        )}
      </ModalTitle>
      <form onSubmit={handleSubmit} className="field-manager-modal__form">
        <AnimatedDiv>
          <div className="row-wrapper">
            <ModalColumn>
              <CustomInputLabel htmlFor="column-name">
                <IDHFormattedMessage
                  id="ws_column_name"
                  defaultMessage="Column name"
                />
              </CustomInputLabel>
              <CustomInput
                id="column-name"
                type="text"
                value={fieldName}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldName(e.target.value);
                }}
                placeholder={translateMessage({
                  intl,
                  id: "ws_manage_field_modal_title_placeholder",
                  defaultMessage: "e.g., Participation, Status, Assignee",
                })}
              />
            </ModalColumn>

            <ModalColumn>
              <CustomInputLabel htmlFor="field-type">
                <IDHFormattedMessage id="ws_type" defaultMessage="Type" />
              </CustomInputLabel>
              <CustomSelect
                id="field-type"
                value={selectedMetaFieldTypeOption}
                options={metaFieldOptions}
                placeholder={`${translateMessage({
                  intl,
                  id: "ws_select",
                  defaultMessage: "Select",
                })}...`}
                onChange={(newValue: MetaFieldTypeOption) => {
                  resetToInitialData();
                  setSelectedMetaFieldTypeOption(newValue);
                }}
                disabled={!!editedField}
              />
            </ModalColumn>
          </div>

          <div>{renderMetaFieldTypeComponent()}</div>
        </AnimatedDiv>

        <div className="field-manager-modal__footer-buttons">
          <Button
            variant="white-with-border"
            size="large"
            onClick={onClose}
            data-qa="cancel-button"
          >
            <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
          </Button>

          <Button
            type="submit"
            data-qa="confirm-button"
            variant="blue"
            disabled={shouldDisableConfirmButton()}
          >
            <IDHFormattedMessage id="ws_confirm" defaultMessage="Confirm" />
          </Button>
        </div>
      </form>
    </Modal>
  );
}
