import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { DictionaryElementsFilter } from "src/app/Task/Subtask/Subtask";
import { GlobalField } from "./components/GlobalFields/GlobalFields";
import { SelectOption } from "./SocialMetrics";

export interface MetricsData {
  [key: string]: {
    [key: string]: {
      key: string;
      type: string;
    };
  };
}

export interface SummaryMetricsData {
  sourceName: string;
  taskMetaFieldUuid: string;
}

export interface EmvValues {
  [key: string]: number | undefined;
}
export interface CurrencyOption {
  key: string;
  value: string;
  label: JSX.Element;
}

export type MFStateEntries = keyof MFState;

export interface MFState {
  fieldName: string;
  description: string;
  nameEdited: boolean;
  selectedOption: Partial<SelectOption>;
  selectedTaskType: Partial<SelectOption>;
  metricsData: MetricsData | null;
  formulaMetricsData: Array<SummaryMetricsData>;
  salesMetrics: any;
  salesMetricOptions: any;
  isAudienceReach: boolean;
  selectedMetric: Partial<MetricOption>;
  selectedAudienceCountry: Partial<MetricOption>;
  currenciesData: any;
  selectedCurrency: Partial<CurrencyOption>;
  formulaTab: FormulaTab;
  selectedSummarySources: any;
  targetValue: string;
  formulaValue: Partial<SelectOption>;
  matchValue: Partial<SelectOption>;
  conditionColumns: any;
  conditionValues: any;
  availableTargets: any;
  availableTargetsValue: any;
  targetValueOption: string;
  valueSourceOptions: any;
  selectedPlatform: Partial<SelectOption>;
  dataApplied: boolean;
  selectedColor: string;
  selectedIcon: Partial<SelectOption>;
  addIcon: boolean;
  selectedFormulaElements: SelectedFormulaElements;
  selectedFormulaOperator: Partial<SelectOption>;
  selectedResultFormat: Partial<SelectOption>;
  globalMetaFieldData: GlobalField[];
  teamsData: any;
  filterMemberByTeam: boolean;
  wsTeamUuids: string[];
  showInCreatorDatabase: boolean;
  enableDictionarySubField: boolean;
  wsDictionarySubFieldUuid: string | null;
  relatedMetaField: Partial<SelectOption>;
  relatedMetaFieldAggregationType: Partial<SelectOption>;
  relatedDictionaryMetaFieldUuid: Partial<SelectOption>;
  formulaElementOptions: SelectedFormulaElement[];
  emvValues: EmvValues;
  filterElementsFields: Partial<DictionaryElementsFilter> | null;
  areFilterElementsEnabled: boolean;
}

interface MetricOption {
  label: string;
  value: string;
  type: string;
  icon: any; // TODO: front
}

export interface IMetaFields {
  wsMetaFieldAccessMode: string;
  wsMetaFieldIsRequired: boolean;
  wsMetaFieldType: string;
  wsMetaFieldUuid: string;
  wsMetaFieldDescription: string;
}

export interface IAccessLinkData {
  caption?: string;
  description?: string;
  metaFields: IMetaFields[];
}

export type FormulaTab = "table" | "overview";

export enum ActiveTabs {
  Library = "Library",
  Custom = "Custom",
}

export interface SelectedFormulaElement {
  label?: string;
  value?: {
    id: string;
    currencyCode?: string | null;
  };
}
export interface SelectedFormulaElements {
  a: SelectedFormulaElement;
  b: SelectedFormulaElement;
}
export interface MetaFieldTypeOption {
  value: MetaFieldType;
  label: JSX.Element;
}
