import React from "react";
import "./UpgradeAccountModal.scss";

import { useSelector } from "react-redux";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalTitle, ModalText } from "../../components/Modal/Modal";

import { ReactComponent as UnionIcon } from "../../../images/union.svg";
import { getBdWsMemberEmail } from "../StiggUsageModal/utils";

function UpgradeAccountModal(props) {
  const { onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const {
    mainReducer: { identity },
  } = useSelector((state) => state);

  return (
    <Modal
      className="upgrade-account-modal"
      onClose={handleClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      variant="medium"
    >
      <ModalTitle icon={<UnionIcon />}>
        <IDHFormattedMessage
          id="ws_upgrade_account"
          defaultMessage="Upgrade account"
        />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_upgrade_account_modal_text"
          defaultMessage="To upgrade your account, you need to contact our sales team to discuss the details. Would you like to schedule a call? Reach out to us at"
        />
        :
        <span className="contact-support-modal__email">
          &nbsp;
          <a href={`mailto:${getBdWsMemberEmail(identity)}`}>
            {getBdWsMemberEmail(identity)}
          </a>
        </span>
      </ModalText>
    </Modal>
  );
}

export { UpgradeAccountModal };
