import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { DictionaryField } from "src/app/SettingsModal/tabs/DictionaryManager/types";
import { RootState } from "src/redux/reducers";
import { API_URLS } from "src/utils/API_URLS";
import { showErrorToast } from "src/utils/methods";
import { Team } from "src/app/SettingsModal/tabs/Teams/utils";
import MemberTeamItem from "../../ManageFieldModal/components/MemberTeams/MemberTeamItem";
import {
  FieldManagerFormDataKeys,
  FieldManagerFormData,
  FieldManagerAdditionalOptions,
  FieldManagerAdditionalOptionsKeys,
} from "../types";

interface MembersSelectionProps {
  editedField?: DictionaryField;
  handleUpdateFormData: (
    key: FieldManagerFormDataKeys,
    value: FieldManagerFormData[FieldManagerFormDataKeys],
  ) => void;
  handleUpdateAdditionalOptions: (
    key: FieldManagerAdditionalOptionsKeys,
    value: FieldManagerAdditionalOptions[FieldManagerAdditionalOptionsKeys],
  ) => void;
  additionalOptions: FieldManagerAdditionalOptions;
  formData: FieldManagerFormData;
}

export default function MembersSelection({
  editedField,
  handleUpdateFormData,
  handleUpdateAdditionalOptions,
  additionalOptions: { enableFilterMemberByTeam },
  formData: { wsTeamUuids },
}: MembersSelectionProps) {
  const [teamsData, setTeamsData] = useState<Array<Team>>([]);

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const addElementIfNotExists = (newElement: string) => {
    if (!wsTeamUuids.includes(newElement)) {
      const updatedArray = [...wsTeamUuids, newElement];
      handleUpdateFormData("wsTeamUuids", updatedArray);
    }
  };

  const removeElementIfExists = (elementToRemove: string) => {
    if (wsTeamUuids.includes(elementToRemove)) {
      const updatedArray = wsTeamUuids.filter(
        (element) => element !== elementToRemove,
      );
      handleUpdateFormData("wsTeamUuids", updatedArray);
    }
  };

  const handleCheckboxChange = (checked: boolean, teamUuid: string) => {
    if (checked) {
      addElementIfNotExists(teamUuid);
    } else {
      removeElementIfExists(teamUuid);
    }
  };

  useEffect(() => {
    const getTeams = async () => {
      try {
        const { data } = await axios.get<{ content: Array<Team> }>(
          API_URLS.teamsForWorkspace.replace(
            ":wsWorkspaceUuid:",
            activeWorkspaceUuid,
          ),
        );
        setTeamsData(data.content);
      } catch (error) {
        console.error(error);
        showErrorToast();
      }
    };
    getTeams();
  }, [activeWorkspaceUuid]);

  useEffect(() => {
    if (editedField) {
      const selectedTeamUuids: Array<string> = [];
      editedField.data?.wsTeamUuids?.forEach((teamUuid) => {
        const foundSelectedTeam = teamsData.find(
          (team) => team.uuid === teamUuid,
        );
        if (foundSelectedTeam) {
          selectedTeamUuids.push(foundSelectedTeam.uuid);
        }
      });

      if (selectedTeamUuids.length > 0) {
        handleUpdateAdditionalOptions("enableFilterMemberByTeam", true);
        handleUpdateFormData("wsTeamUuids", selectedTeamUuids);
      }
    }
  }, [editedField, teamsData]);

  return (
    <div className="member-team-wrapper">
      <div className="member-team-wrapper__column">
        <div className="member-team-wrapper__row">
          <div className="member-team-wrapper__switch">
            <CustomSwitch
              checked={enableFilterMemberByTeam}
              onChange={(e) => {
                const { checked } = e.target;
                if (!checked) {
                  handleUpdateFormData("wsTeamUuids", []);
                }
                handleUpdateAdditionalOptions(
                  "enableFilterMemberByTeam",
                  checked,
                );
              }}
            />
          </div>
          <div className="member-team-wrapper__switch-info">
            <div className="member-team-wrapper__switch-info-header">
              <IDHFormattedMessage
                id="ws_team_members_selection"
                defaultMessage="Team Members Selection"
              />
            </div>
            <div className="member-team-wrapper__switch-info-description">
              <IDHFormattedMessage
                id="ws_team_members_selection_description"
                defaultMessage="Allows you to limit the member selection list to selected teams."
              />
            </div>
          </div>
        </div>
        {enableFilterMemberByTeam && (
          <div className="member-team-list__table">
            <div className="member-team-list__table-head-row">
              <span className="member-team-list__table-head-row-cell-name">
                <IDHFormattedMessage id="ws_team" defaultMessage="Team" />
              </span>
              <span className="member-team-list__table-head-row-cell-members">
                <IDHFormattedMessage id="ws_members" defaultMessage="Members" />
              </span>
            </div>
            <div className="member-team-list__table-body">
              {teamsData.map((team) => (
                <MemberTeamItem
                  key={team.uuid}
                  team={team}
                  wsTeamUuids={wsTeamUuids}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
