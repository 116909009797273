import React, { useState, ChangeEvent } from "react";
import { useScrollLock } from "src/app/methods/useScrollLock";
import DropdownPortal, {
  DropdownPortalMenu,
} from "src/app/components/DropdownPortal";
import classNames from "classnames";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { ReactComponent as PlusIcon } from "src/images/plus-alt.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { getExtensionFromName } from "../../../utils/methods";

import { MetaFileData } from "../MultipleFileField/MultipleFileField";

import { ReactComponent as TrashCanIcon } from "../../../images/trash-can.svg";
import { ReactComponent as FileGreyIcon } from "../../../images/files/file-grey.svg";

import { DropdownMenuItem } from "../../components/Dropdown/Dropdown";
import { getFileFieldIcon } from "./utils";
import Attachment from "../../Task/Attachments/Attachment";
import { AttachmentPlacement, TaskType } from "../../../types";

interface FilesDropdownProps {
  filesData: MetaFileData[];
  deleteFile: (assetId: string) => Promise<void>;
  uploadFile: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  readOnly?: boolean;
}

export const getPreviewFileType = (extension: string, data: any) => {
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "mp4":
    case "webm":
    case "mov":
    case "avi":
      return (
        <Attachment
          data={data}
          place={AttachmentPlacement.Dropdown}
          taskType={TaskType.Creator}
        />
      );
    case "txt":
    case "pdf":
      return (
        <a
          href={data.publicPreviewUrl || data.publicDownloadUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="attachment-wrapper"
        >
          {getFileFieldIcon(extension)}
        </a>
      );
    case "url":
      return (
        <Attachment
          data={data}
          place={AttachmentPlacement.Dropdown}
          taskType={TaskType.Creator}
        />
      );
    default:
      return (
        <a
          href={data.publicPreviewUrl || data.publicDownloadUrl}
          download
          className="attachment-wrapper"
          onClick={(e) =>
            !(data.publicPreviewUrl || data.publicDownloadUrl) &&
            e.preventDefault()
          }
        >
          {getFileFieldIcon(extension)}
        </a>
      );
  }
};

export function FilesDropdownPortal({
  filesData,
  deleteFile,
  uploadFile,
  readOnly,
}: FilesDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { lockScroll, unlockScroll } = useScrollLock();

  return (
    <DropdownPortal
      className="file-field__dropdown"
      overlay={
        <FilesDropdownMenu
          filesData={filesData}
          readOnly={readOnly}
          deleteFile={deleteFile}
          uploadFile={uploadFile}
          onClose={() => {
            unlockScroll();
            setIsOpen(false);
          }}
        />
      }
      onVisibleChange={(visible: boolean) => {
        visible ? lockScroll() : unlockScroll();
        setIsOpen(visible);
      }}
      visible={isOpen}
    >
      <span
        className="file-field__value-counter"
        onClick={() => setIsOpen(true)}
      >
        <FileGreyIcon />
        {filesData?.length} files
      </span>
    </DropdownPortal>
  );
}

interface FilesDropdownMenuProps {
  filesData: MetaFileData[];
  deleteFile: (assetId: string) => Promise<void> | void;
  uploadFile: (e: ChangeEvent<HTMLInputElement>) => Promise<void> | void;
  readOnly?: boolean;
  onClose?: () => void;
}

export function FilesDropdownMenu({
  filesData,
  readOnly,
  deleteFile,
  uploadFile,
  onClose,
}: FilesDropdownMenuProps) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<{
    assetId: string;
    assetName: string;
  }>({ assetId: "", assetName: "" });
  return (
    <>
      <DropdownPortalMenu className="file-field__dropdown-menu">
        {filesData.map((item) => {
          const extension = getExtensionFromName(item.name);
          const downloadUrl = item.publicDownloadUrl || item.publicPreviewUrl;

          return (
            <DropdownMenuItem
              className="file-field__value-element"
              key={item.assetId}
            >
              {getPreviewFileType(extension, item)}
              <a
                className="file-field__value-element-wrap"
                href={downloadUrl}
                download
              >
                <div className="file-field__value-element-name">
                  <span
                    className={classNames("file-field__value-element-name", {
                      "file-field__value-element-name--full-width": readOnly,
                    })}
                  >
                    {item.name}
                  </span>
                </div>
              </a>
              {!readOnly && (
                <TrashCanIcon
                  className="file-field__value-element-delete"
                  onClick={() => {
                    setShowConfirmationModal(true);
                    setFileToDelete({
                      assetId: String(item.assetId),
                      assetName: item.name,
                    });
                  }}
                />
              )}
            </DropdownMenuItem>
          );
        })}
        {!readOnly && (
          <div role="button" className="file-field__add-file-btn">
            <PlusIcon width={20} height={20} />
            <IDHFormattedMessage id="ws_add_file" defaultMessage="Add file" />
            <input
              type="file"
              multiple
              onChange={(e) => {
                uploadFile(e);
                onClose?.();
              }}
            />
          </div>
        )}
      </DropdownPortalMenu>
      {showConfirmationModal && (
        <RemoveModal
          onClose={() => setShowConfirmationModal(false)}
          objectNames={[fileToDelete?.assetName ?? ""]}
          removeFunction={() => {
            setShowConfirmationModal(false);
            deleteFile(fileToDelete.assetId);
          }}
        />
      )}
    </>
  );
}
