import React, {
  ReactNode,
  useState,
  DispatchWithoutAction,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";

import { ReactComponent as MagnifyingGlass } from "src/images/magnifier-grey.svg";
import { Column, Row } from "src/redux/dictionary/types";
import { splitNumbers } from "src/utils/methods";
import GlideDataGrid from "./GlideDataGrid";
import { Button } from "../Button/Button";
import PageHeader from "../PageHeader/PageHeader";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import {
  GlideDataGridContext,
  GlideDataGridFiltersAndSortingOptions,
} from "./types";
import AnimatedDiv from "../AnimatedDiv/AnimatedDiv";
import { useCanOverrideSearch } from "./utils";
import Tabs from "../Tabs/Tabs";
import Tab from "../Tabs/Tab";

interface GlideDataGridWrapperProps {
  columnsData: Column[];
  context: GlideDataGridContext;
  elementToScrollUuid?: string | null;
  filtersAndSortingOptions?: GlideDataGridFiltersAndSortingOptions;
  filtersAndSortingOptionsDispatch?: DispatchWithoutAction;
  handleRowNameClick?: (objectUuid: string) => void;
  header: string | ReactNode;
  description: string | ReactNode;
  isLoading: boolean;
  noFilteredResultsScreen: ReactNode;
  noResultsScreen: ReactNode;
  renderHeaderToolbar?: () => ReactNode;
  rowsData: Row[];
  setElementToScrollUuid?: Dispatch<SetStateAction<string | null>>;
  titleColumnLabel?: string;
  openGlideElement: (uuid: string) => string;
  totalRowsCount: number;
}

function GlideDataGridWrapper(props: GlideDataGridWrapperProps) {
  const [showSearch, setShowSearch] = useState(false);
  const canOverrideSearch = useCanOverrideSearch();

  const {
    columnsData,
    context,
    elementToScrollUuid,
    filtersAndSortingOptions,
    filtersAndSortingOptionsDispatch,
    handleRowNameClick,
    header,
    description,
    isLoading,
    noFilteredResultsScreen,
    noResultsScreen,
    renderHeaderToolbar,
    rowsData,
    setElementToScrollUuid,
    titleColumnLabel,
    openGlideElement,
    totalRowsCount,
  } = props;

  const renderHeader = () => (
    <div className="glide-data-grid__header">
      <PageHeader title={header} description={description} />
      <div className="glide-data-grid__toolbar">
        {renderHeaderToolbar != null && renderHeaderToolbar()}
        {rowsData.length > 0 && (
          <Button
            variant="white"
            size="small"
            textWithIcon
            onClick={() => setShowSearch(true)}
          >
            <MagnifyingGlass />
            <IDHFormattedMessage id="ws_search" defaultMessage="Search" />
          </Button>
        )}
      </div>
    </div>
  );

  const renderTabs = () => {
    return (
      <div className="glide-data-grid__tabs">
        <Tabs>
          <Tab
            tabText={titleColumnLabel}
            active
            onClick={() => {}}
            big
            count={splitNumbers(totalRowsCount)}
          />
        </Tabs>
      </div>
    );
  };

  useEffect(() => {
    if (!elementToScrollUuid || !setElementToScrollUuid) return;
    const newTaskIndex = rowsData.findIndex(
      (row) => row.uuid === elementToScrollUuid,
    );
    if (newTaskIndex > 0) {
      const table = document.querySelector(".dvn-scroller");
      table?.scrollTo({
        top: newTaskIndex * 86,
        left: 0,
        behavior: "smooth",
      });
      setElementToScrollUuid(null);
    }
  }, [elementToScrollUuid, rowsData]);

  useEffect(() => {
    if (!canOverrideSearch) return undefined;
    const handleSearch = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.code === "KeyF") {
        setShowSearch((prev) => !prev);
        e.stopPropagation();
        e.preventDefault();
      }
    };
    window.addEventListener("keydown", handleSearch);
    return () => window.removeEventListener("keydown", handleSearch);
  }, [canOverrideSearch]);

  return (
    <AnimatedDiv className="glide-data-grid">
      <div className="glide-data-grid__wrapper">
        {renderHeader()}
        {rowsData.length > 0 && renderTabs()}
        {!rowsData.length &&
          !isLoading &&
          Object.keys(filtersAndSortingOptions?.filters ?? {}).length === 0 &&
          noResultsScreen != null &&
          noResultsScreen}
        {!rowsData.length &&
          !isLoading &&
          Object.keys(filtersAndSortingOptions?.filters ?? {}).length !== 0 &&
          noFilteredResultsScreen != null &&
          noFilteredResultsScreen}
        {!!rowsData.length && (
          <GlideDataGrid
            columnsData={columnsData}
            context={context}
            filtersAndSortingOptions={filtersAndSortingOptions}
            filtersAndSortingOptionsDispatch={filtersAndSortingOptionsDispatch}
            handleRowNameClick={handleRowNameClick}
            isLoading={isLoading}
            rowsData={rowsData}
            setShowSearch={setShowSearch}
            showSearch={showSearch}
            openGlideElement={openGlideElement}
            totalRowsCount={totalRowsCount}
          />
        )}
      </div>
    </AnimatedDiv>
  );
}

export default GlideDataGridWrapper;
