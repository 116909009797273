import {
  BillingModel,
  BillingPeriod,
  Currency,
  PricingType,
  PromotionalEntitlementStatus,
  SubscriptionStatus,
  TrialPeriodUnits,
  ExperimentGroupType,
  CustomerPortalEntitlementFragment,
  CustomerPortalPromotionalEntitlementFragment,
  CustomerPortalSubscriptionFragment,
  CustomerPortalSubscriptionAddonFragment,
  CustomerPortalBillingInformationFragment,
  PaywallConfigurationFragment,
  CustomerPortalConfigurationFragment,
  WidgetType,
  CustomerPortalSubscriptionScheduledUpdateDataFragment,
  DiscountType,
  DiscountDurationType,
  PackageEntitlementFragment,
  PaywallCurrencyFragment,
  FeatureType,
  MeterType,
  EntitlementResetPeriod,
  AccessDeniedReason,
  PaywallCalculatedPricePointsFragment,
  SubscriptionInvoiceFragment,
  PaymentCollection,
  PlanFragment,
  BillingVendorIdentifier,
  TiersMode,
  SubscriptionFutureUpdateDataFragment,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - it's used for jsdoc
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SubscriptionScheduleStatus,
  PriceTierFragment,
  TotalPriceFragment,
  CheckoutConfigurationFragment,
  TypographyConfigurationFragment,
  BillingAddress,
  ScheduleStrategy,
  PaymentCollectionMethod,
  BudgetConfigurationInput,
} from '@stigg/api-client-js/src/generated/sdk';

export * from '@stigg/api-client-js/src/generated/sdk';

interface Metadata {
  [key: string]: string;
}

export type EntitlementFeature = {
  id: string;
  featureType: FeatureType;
  displayName: string;
  description?: string;
  units?: string;
  unitsPlural?: string;
  meterType?: MeterType.Fluctuating | MeterType.Incremental | MeterType.None;
  isMetered?: boolean;

  metadata?: Record<string, string>;
};

export type BaseEntitlement = {
  hasAccess: boolean;
  isFallback: boolean;
  accessDeniedReason?: AccessDeniedReason;
  feature?: EntitlementFeature;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BooleanEntitlement extends BaseEntitlement {}

export interface NumericEntitlement extends BaseEntitlement {
  value?: number;
  isUnlimited: boolean;
}

/** @deprecated - fields were moved into {@link MeteredEntitlement} */
export type ResetSettings = {
  /** @deprecated - renamed to {@link MeteredEntitlement.usagePeriodEnd} */
  nextResetDate: Date;
  /** @deprecated - renamed to {@link MeteredEntitlement.resetPeriod} */
  resetPeriod: EntitlementResetPeriod;
};

export interface MeteredEntitlement extends BaseEntitlement {
  usageLimit?: number;
  isUnlimited: boolean;
  currentUsage: number;
  requestedUsage: number;
  resetPeriod?: EntitlementResetPeriod;
  /** The anchor for calculating the usage period for metered entitlements with a reset period configured */
  usagePeriodAnchor?: Date;
  /** The start date of the usage period for metered entitlements with a reset period configured */
  usagePeriodStart?: Date;
  /** The end date of the usage period for metered entitlements with a reset period configured */
  usagePeriodEnd?: Date;
  /** @deprecated fields were moved into {@link MeteredEntitlement} */
  resetSettings?: ResetSettings;
}

export type Entitlement = BooleanEntitlement | NumericEntitlement | MeteredEntitlement;

/** @internal */
export const BOOLEAN_DEFAULT_FALLBACK_ENTITLEMENT: BooleanEntitlement = {
  isFallback: true,
  hasAccess: false,
  accessDeniedReason: AccessDeniedReason.Unknown,
};

/** @internal */
export const NUMERIC_DEFAULT_FALLBACK_ENTITLEMENT: NumericEntitlement = {
  isFallback: true,
  hasAccess: false,
  isUnlimited: false,
  accessDeniedReason: AccessDeniedReason.Unknown,
};

/** @internal */
export const METERED_DEFAULT_FALLBACK_ENTITLEMENT: MeteredEntitlement = {
  isFallback: true,
  hasAccess: false,
  isUnlimited: false,
  currentUsage: 0,
  requestedUsage: 1,
  accessDeniedReason: AccessDeniedReason.Unknown,
};

export type PackageEntitlement = {
  usageLimit: number;
  feature?: EntitlementFeature;
  isCustom?: boolean;
  hasUnlimitedUsage?: boolean | null;
  resetPeriod?: EntitlementResetPeriod | null;
  hiddenFromWidgets?: PackageEntitlementFragment['hiddenFromWidgets'];
  displayNameOverride?: PackageEntitlementFragment['displayNameOverride'];
};

export type PromotionalEntitlement = {
  usageLimit: number;
  feature: EntitlementFeature;
  hasUnlimitedUsage?: boolean | null;
  status: PromotionalEntitlementStatus;
  expiresAt?: Date;
  hasExpirationDate: boolean;
  isVisible: boolean;
};

export type Price = {
  pricingModel: BillingModel;
  billingPeriod: BillingPeriod;
  billingId?: string | null;
  amount?: number | null;
  currency: Currency;
  tiers?: PriceTierFragment[] | null | undefined;
  tiersMode: TiersMode | null | undefined;
  isTieredPrice: boolean;
  minUnitQuantity?: number | null | undefined;
  maxUnitQuantity?: number | null | undefined;
  billingCountryCode?: string | null | undefined;
  feature?: {
    featureId: string;
    units?: string | null | undefined;
    unitsPlural?: string | null | undefined;
    unitQuantity?: number | null;
    displayName: string;
  } | null;
  blockSize?: number | null;
};

export type SubscriptionPrice = Price & { grossAmount: number };

export type BasePlan = {
  displayName: string;
  id: string;
};

export type Plan = {
  id: string;
  displayName: string;
  description?: string | null;
  billingId?: string | null;
  order: number;
  basePlan?: BasePlan;
  entitlements: PackageEntitlement[];
  inheritedEntitlements: PackageEntitlement[];
  pricePoints: Price[];
  pricingType?: PricingType | null;
  defaultTrialConfig?: DefaultTrialConfig | null;
  compatibleAddons: Addon[];
  compatiblePackageGroups: CompatiblePackageGroup[];
  product: Product;
  metadata?: Metadata | null;
};

export type Coupon = {
  id: string;
  name: string;
  description?: string | null;
  discountValue: number;
  percentOff?: number | null;
  amountsOff?: Money[] | null;
  metadata?: Metadata | null;
};

export type Product = {
  id: string;
  displayName?: string | null;
  description?: string | null;
  metadata?: Metadata | null;
};

export type DefaultTrialConfig = {
  duration: number;
  units: TrialPeriodUnits;
  budget?: {
    limit: BudgetConfigurationInput['limit'];
  };
};

export type GetBooleanEntitlement = {
  featureId: string;
  options?: BooleanEntitlementOptions;
  resourceId?: string;
};

export type GetNumericEntitlement = {
  featureId: string;
  options?: NumericEntitlementOptions;
  resourceId?: string;
};

export type GetMeteredEntitlement = {
  featureId: string;
  options?: MeteredEntitlementOptions;
  resourceId?: string;
};

export type GetPaywall = {
  productId?: string;
  resourceId?: string;
  context?: WidgetType;
  billingCountryCode?: string;
  includeHiddenPlans?: boolean;
};

export type GetSubscription = {
  subscriptionId: string;
};

export type GetActiveSubscriptions = {
  resourceId?: string | string[];
};

export type GetCustomerPortal = {
  resourceId?: string;
  productId?: string;
};

export type GetCheckoutState = {
  planId: string;
  billingCountryCode?: string;
  resourceId?: string | null;
};

export type CheckoutAddon = {
  addonId: string;
  quantity?: number;
};

export type ApplySubscription = {
  planId: string;
  paymentMethodId?: string;
  payingCustomerId?: string;
  resourceId?: string;
  addons?: CheckoutAddon[];
  startDate?: Date;
  billingId?: string;
  billingPeriod?: BillingPeriod;
  skipTrial?: boolean;
  unitQuantity?: number;
  billableFeatures?: BillableFeature[];
  metadata?: Metadata | null;
  promotionCode?: string;
  billingInformation?: Omit<SubscriptionBillingInfo, 'taxPercentage'>;
  billingCountryCode?: string;
  scheduleStrategy?: ScheduleStrategy;
};

export type SubscriptionBillingInfo = {
  taxRateIds?: string[];
  taxPercentage?: number;
  billingAddress?: BillingAddress;
};

export type ApplySubscriptionResults = {
  subscription?: Subscription;
};

export type EntitlementsFallback = {
  [featureId: string]: BooleanEntitlementFallback | NumericEntitlementFallback | MeteredEntitlementFallback;
};

export type BooleanEntitlementFallback = {
  hasAccess: boolean;
};

export type BooleanEntitlementOptions = {
  shouldTrack?: boolean;
  fallback?: BooleanEntitlementFallback;
};

export type NumericEntitlementFallback = {
  hasAccess: boolean;
  value?: number;
  isUnlimited?: boolean;
};

export type NumericEntitlementOptions = {
  shouldTrack?: boolean;
  fallback?: NumericEntitlementFallback;
};

export type MeteredEntitlementFallback = {
  hasAccess: boolean;
  usageLimit?: number;
  isUnlimited?: boolean;
};

export type MeteredEntitlementOptions = {
  requestedUsage?: number;
  shouldTrack?: boolean;
  fallback?: MeteredEntitlementFallback;
};

export type CompatiblePackageGroupOptions = { minItems?: number; freeItems?: number };

export type CompatiblePackageGroup = {
  packageGroupId: string;
  displayName: string;
  addons: Addon[];
  options?: CompatiblePackageGroupOptions;
};

export type DependencyAddon = {
  refId: string;
  displayName: string;
  description?: string;
};

export type Addon = {
  id: string;
  displayName: string;
  description: string;
  billingId?: string | null;
  entitlements: PackageEntitlement[];
  pricePoints: Price[];
  pricingType?: PricingType | null;
  dependencies?: DependencyAddon[];
  metadata?: Metadata | null;
};

export type SubscriptionAddon = {
  quantity: number;
  addon: Addon;
};
export type ExperimentInfo = {
  id: string;
  name: string;
  groupName: string;
  groupType?: ExperimentGroupType | null;
};

export type Subscription = {
  id: string;
  status: SubscriptionStatus;
  resourceId?: string;
  payingCustomer?: SlimCustomer | null;
  plan: Plan;
  /** @deprecated use {@link prices} instead with {@link totalPrice} */
  price: SubscriptionPrice | null;
  prices: Price[];
  totalPrice: TotalPriceFragment | null | undefined;
  pricingType: PricingType;
  addons: SubscriptionAddon[];
  billingId?: string;
  crmId?: string;
  startDate: Date;
  currentBillingPeriodEnd?: Date;
  /**
   * The calculated end date after which the subscription will be canceled, which takes trial expiration and scheduled cancellations into consideration
   */
  effectiveEndDate?: Date;
  endDate?: Date;
  trialEndDate?: Date;
  cancellationDate?: Date;
  metadata?: Metadata | null;
  experimentInfo?: ExperimentInfo | null;
  /** @deprecated use {@link futureUpdates} instead with filter for {@link SubscriptionScheduleStatus.Scheduled} */
  scheduledUpdates?: CustomerPortalSubscriptionScheduledUpdateDataFragment[];
  /** Future updates can be of 2 types {@link SubscriptionScheduleStatus.Scheduled} or {@link SubscriptionScheduleStatus.PendingPayment}  */
  futureUpdates: SubscriptionFutureUpdateDataFragment[];
  latestInvoice?: SubscriptionInvoiceFragment;
  paymentCollection: PaymentCollection;
  paymentCollectionMethod?: PaymentCollectionMethod;
};

export type CustomerPortalBillingInformation = Omit<CustomerPortalBillingInformationFragment, '__typename'>;
export type CustomerPortalSubscriptionAddon = Omit<CustomerPortalSubscriptionAddonFragment, '__typename'>;
export type CustomerPortalSubscription = Omit<CustomerPortalSubscriptionFragment, '__typename'>;
export type CustomerPortalEntitlement = Omit<CustomerPortalEntitlementFragment, '__typename'>;
export type CustomerPortalPromotionalEntitlement = Omit<CustomerPortalPromotionalEntitlementFragment, '__typename'>;
export type CustomerPortalConfiguration = Omit<CustomerPortalConfigurationFragment, '__typename'>;
export type CustomerPortal = {
  subscriptions: CustomerPortalSubscription[];
  entitlements: CustomerPortalEntitlement[];
  promotionalEntitlements: CustomerPortalPromotionalEntitlement[];
  billingInformation: CustomerPortalBillingInformation;
  showWatermark: boolean;
  canUpgradeSubscription: boolean;
  billingPortalUrl?: string | null;
  configuration?: CustomerPortalConfiguration | null;
  resource: CustomerResource | null;
};

export type CheckoutStatePlan = Plan & Pick<PlanFragment, 'prices'>;

export type CheckoutBillingIntegration = {
  billingIdentifier: BillingVendorIdentifier;
  credentials: {
    accountId: string;
    publicKey: string;
  };
};

export type TypographyConfiguration = Omit<TypographyConfigurationFragment, '__typename'>;

export type CheckoutConfiguration = Omit<CheckoutConfigurationFragment, '__typename'>;

export type GetCheckoutStateResults = {
  configuration?: CheckoutConfiguration | null;
  setupSecret: string;
  plan: CheckoutStatePlan;
  customer: Customer;
  resource: CustomerResource | null;
  activeSubscription?: Subscription | null;
  billingIntegration: CheckoutBillingIntegration;
};

export type PaywallConfiguration = Omit<PaywallConfigurationFragment, '__typename'>;

export type PaywallCurrency = Omit<PaywallCurrencyFragment, '__typename'>;

export type PaywallCalculatedPricePoint = Omit<PaywallCalculatedPricePointsFragment, '__typename'>;

export type Paywall = {
  plans: Plan[];
  configuration?: PaywallConfiguration | null;
  customer: Customer | null;
  resource: CustomerResource | null;
  activeSubscriptions: Subscription[] | null;
  currency: PaywallCurrency;
  paywallCalculatedPricePoints?: PaywallCalculatedPricePoint[] | null;
};

export type CustomerResource = {
  id: string;
};

export type Customer = {
  id: string;
  name?: string;
  email?: string;
  createdAt: Date;
  updatedAt: Date;
  subscriptions: Subscription[];
  promotionalEntitlements: PromotionalEntitlement[];
  hasPaymentMethod: boolean;
  paymentMethodDetails?: PaymentMethodDetails;
  metadata?: Metadata | null;
  trialedPlans?: TrialedPlan[] | null;
  eligibleForTrial?: EligibleForTrial[] | null;
  experimentInfo?: ExperimentInfo | null;

  getActivePromotionalEntitlements(): PromotionalEntitlement[];

  /** @deprecated use {@link StiggClient.getActiveSubscriptions} instead with filter for {@link SubscriptionStatus.Active} */
  getActiveSubscriptions(): Subscription[];

  /** @deprecated use {@link StiggClient.getActiveSubscriptions} instead with filter for {@link SubscriptionStatus.InTrial} */
  getActiveTrials(): Subscription[];
};

export type SlimCustomer = {
  id: string;
  name?: string;
  email?: string;
  createdAt: Date;
  updatedAt: Date;
  billingId?: string;
  metadata?: Metadata | null;
  awsMarketplaceCustomerId?: string | null;
};

export type CustomerState = Omit<
  Customer,
  'getActiveSubscriptions' | 'getActivePromotionalEntitlements' | 'getActiveTrials'
>;
export type TrialedPlan = {
  productId?: string | null;
  planId?: string | null;
};

export type EligibleForTrial = {
  productId?: string | null;
  eligible: boolean;
};

export type WaitForCheckoutCompleted = {
  timeoutMs?: number;
  refreshOnComplete?: boolean;
};

export type PaymentMethodDetails = {
  expirationMonth?: number | null;
  expirationYear?: number | null;
  last4Digits?: string | null;
};

export type SubscriptionEstimationAddon = {
  addonId: string;
  quantity?: number;
};

export type DateRange = {
  start?: Date;

  end?: Date;
};

export type Money = {
  amount: number;

  currency: string;
};

export type SubscriptionPreviewProration = {
  prorationDate: Date;

  credit: Money;

  debit: Money;

  netAmount: Money;

  hasProrations?: boolean;
};

export type SubscriptionPricePreview = {
  subTotal: Money;
  totalExcludingTax: Money;
  total: Money;
  tax?: Money;
  discountAmount?: Money;
  taxDetails?: SubscriptionPreviewTaxDetails;
  discount?: SubscriptionPreviewDiscount;
};

export type SubscriptionPreviewInvoice = {
  total: Money;
  subTotal: Money;
  totalExcludingTax: Money;
  tax?: Money;
  discount?: Money;
  taxDetails?: SubscriptionPreviewTaxDetails;
  discountDetails?: SubscriptionPreviewDiscount;
};

export type ImmediateSubscriptionPreviewInvoice = SubscriptionPreviewInvoice & {
  proration?: SubscriptionPreviewProration;
  credits?: SubscriptionPreviewCredits;
};

export type SubscriptionPreviewDiscount = {
  name?: string | null;
  type: DiscountType;
  value: number;
  durationType: DiscountDurationType;
  durationInMonths?: number;
};

export type SubscriptionPreviewTaxDetails = {
  displayName: string;
  percentage: number;
  inclusive: boolean;
};

export type SubscriptionPreviewCredits = {
  initial: Money;
  used: Money;
  remaining: Money;
};

export interface SubscriptionPreview {
  subTotal: Money;
  totalExcludingTax: Money;
  total: Money;
  tax?: Money;
  discountAmount?: Money;
  taxDetails?: SubscriptionPreviewTaxDetails;
  discount?: SubscriptionPreviewDiscount;
  billingPeriodRange: DateRange;
  proration?: SubscriptionPreviewProration;
  subscription?: SubscriptionPricePreview;
  isPlanDowngrade?: boolean;
  hasScheduledUpdates?: boolean;
  credits?: SubscriptionPreviewCredits;
}

export interface SubscriptionPreviewV2 {
  /**
   * The immediate invoice prices that will be generated for the subscription
   * for the previewed subscription \ changes
   */
  immediateInvoice: ImmediateSubscriptionPreviewInvoice;

  /**
   * The recurring invoice prices that will be generated for the subscription
   * for the previewed subscription \ changes, starting from the next billing period
   */
  recurringInvoice?: SubscriptionPreviewInvoice;
  billingPeriodRange: DateRange;
  isPlanDowngrade?: boolean;
  hasScheduledUpdates?: boolean;
}

export type EstimateSubscription = {
  customerId: string;
  payingCustomerId?: string;
  resourceId?: string;
  planId: string;
  addons?: SubscriptionEstimationAddon[];
  startDate?: Date;
  billingPeriod?: BillingPeriod;
  billingCountryCode?: string;
  unitQuantity?: number;
  billableFeatures?: BillableFeature[];
  billingInformation?: SubscriptionBillingInfo;
  promotionCode?: string;
  skipTrial?: boolean;
};

export type EstimateSubscriptionUpdate = {
  subscriptionId: string;
  unitQuantity?: number;
  billableFeatures?: BillableFeature[];
  addons?: SubscriptionEstimationAddon[];
  promotionCode?: string;
};

export type PreviewSubscription = {
  customerId: string;
  payingCustomerId?: string;
  planId: string;
  resourceId?: string;
  addons?: SubscriptionEstimationAddon[];
  startDate?: Date;
  billingPeriod?: BillingPeriod;
  billingCountryCode?: string;
  unitQuantity?: number;
  billableFeatures?: BillableFeature[];
  billingInformation?: SubscriptionBillingInfo;
  promotionCode?: string;
  scheduleStrategy?: ScheduleStrategy;
};

export type FetchUsageHistory = {
  customerId: string;
  resourceId?: string;
  featureId: string;
  startDate: Date;
  endDate?: Date;
};

export type UsageHistoryPoint = {
  date: Date;
  value: number;
  isResetPoint: boolean;
};

export type BillableFeature = {
  featureId: string;
  quantity: number;
};

/** @hidden */
export class CustomerModel implements Customer {
  id!: string;
  createdAt!: Date;
  updatedAt!: Date;
  subscriptions!: Subscription[];
  promotionalEntitlements!: PromotionalEntitlement[];
  hasPaymentMethod!: boolean;
  metadata?: Metadata | null;
  paymentMethodDetails?: PaymentMethodDetails;

  constructor(state: CustomerState) {
    Object.assign(this, state);
  }

  getActiveSubscriptions() {
    return this.subscriptions.filter((x) => x.status === SubscriptionStatus.Active);
  }

  getActiveTrials() {
    return this.subscriptions.filter((x) => x.status === SubscriptionStatus.InTrial);
  }

  getActivePromotionalEntitlements(): PromotionalEntitlement[] {
    return this.promotionalEntitlements.filter((x) => x.status === PromotionalEntitlementStatus.Active);
  }
}
