import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Draggable, DroppableProvided, DropResult } from "react-beautiful-dnd";
import { useIntl } from "react-intl";

import { useSelector } from "react-redux";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "src/app/methods/translateMessage";
import FieldsList from "src/app/modals/components/FieldsList/FieldsList";
import {
  MetaFieldIcon,
  renderTooltipIcon,
} from "src/app/modals/ManageFieldModal/components/GlobalFields/utils";
import { ReactComponent as GridIcon } from "src/images/grid.svg";
import { ReactComponent as TrashCanIcon } from "src/images/trash-can-gray.svg";
import { RootState } from "src/redux/reducers";
import {
  GlobalProjectMetaField,
  GlobalTaskMetaField,
} from "src/redux/settings/settingsReducer";
import { TaskType } from "src/types";
import {
  FieldOption,
  HandleAddFieldsProps,
  HandleRemoveFieldsProps,
  HandleUpdateTemplateValueParams,
  Template,
} from "../types";
import {
  handleUpdateProjectTemplate,
  mapFieldTypeToFieldName,
  rearrangeElementsInArray,
} from "../utils";
import TaskTypeTabs from "./TaskTypeTabs";

interface FieldsTabProps {
  editedTemplate: Template;
  handleUpdateTemplateValue: (params: HandleUpdateTemplateValueParams) => void;
  handleAddFields: (params: HandleAddFieldsProps) => void;
  handleRemoveFields: (params: HandleRemoveFieldsProps) => void;
  fieldType: "project" | "task";
  fields: Array<GlobalProjectMetaField | GlobalTaskMetaField>;
  setEditedTemplate: Dispatch<SetStateAction<Template | null>>;
  syncLocalTemplateWithServer: (uuid: string) => Promise<void>;
}

export default function FieldsTab({
  editedTemplate,
  handleUpdateTemplateValue,
  handleAddFields,
  handleRemoveFields,
  fieldType,
  fields,
  setEditedTemplate,
  syncLocalTemplateWithServer,
}: FieldsTabProps) {
  const [selectedTaskType, setSelectedTaskType] = useState<TaskType>(
    TaskType.Creator,
  );

  const [selectedFields, setSelectedFields] = useState<Array<FieldOption>>([]);

  const intl = useIntl();

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const fieldsFromTemplate = useMemo(
    () =>
      editedTemplate[mapFieldTypeToFieldName[fieldType]][selectedTaskType] ??
      [],
    [editedTemplate, fieldType, selectedTaskType],
  );

  const handleDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const sourceField = selectedFields[source.index];
    const targetField = selectedFields[destination.index];

    setEditedTemplate((prevTemplate) => {
      if (!prevTemplate) return null;

      const updatedTemplate = { ...prevTemplate };

      const fields =
        updatedTemplate[mapFieldTypeToFieldName[fieldType]][selectedTaskType];

      if (fields) {
        const sourceIndex = fields.findIndex(
          (field) => field.uuid === sourceField.value,
        );
        const targetIndex = fields.findIndex(
          (field) => field.uuid === targetField.value,
        );

        if (sourceIndex !== -1 && targetIndex !== -1) {
          const rearrangedFields = rearrangeElementsInArray(
            fields,
            sourceIndex,
            targetIndex,
          );

          updatedTemplate[mapFieldTypeToFieldName[fieldType]][
            selectedTaskType
          ] = rearrangedFields;
        }
      }

      handleUpdateProjectTemplate({
        data: {
          wsWorkspaceUuid: activeWorkspaceUuid,
          ...updatedTemplate,
        },
        catchCallback: () => syncLocalTemplateWithServer(editedTemplate.uuid),
      });

      return updatedTemplate;
    });
  };

  const handleChange = (newFields: FieldOption[]) => {
    setSelectedFields(newFields);

    const newAttributes = newFields.filter(
      (attr) =>
        fieldsFromTemplate.findIndex((field) => field.uuid === attr.value) ===
        -1,
    );

    if (newAttributes.length === 0) {
      const removedFieldsIds = fieldsFromTemplate
        .filter(
          (field) =>
            newFields.findIndex((attr) => attr.value === field.uuid) === -1,
        )
        .map((field) => field.uuid);

      handleRemoveFields({
        fieldsToRemove: removedFieldsIds,
        fieldType,
        taskType: selectedTaskType,
      });
    } else {
      const mapNewAttributesToFieldData = newAttributes.map((attr) => ({
        uuid: attr.value,
        hide: false,
      }));

      handleAddFields({
        newFieldsData: mapNewAttributesToFieldData,
        fieldType,
        taskType: selectedTaskType,
      });
    }
  };

  const listRenderer = (provided: DroppableProvided) => {
    return (
      <ul
        ref={provided.innerRef}
        className="template-manager__list template-manager__list--fields"
        {...provided.droppableProps}
      >
        {selectedFields.map((option: FieldOption, index: number) => {
          const foundGlobalField = fields.find(
            (field) => field.uuid === option.value,
          );
          const foundFieldFromTemplate = fieldsFromTemplate.find(
            (field) => field.uuid === option.value,
          );

          if (!foundGlobalField || !foundFieldFromTemplate) return null;
          return (
            <Draggable
              key={option.value}
              draggableId={option.value}
              index={index}
            >
              {(provided) => (
                <li
                  ref={provided.innerRef}
                  className="template-manager__list-item grid--fields"
                  key={option.value}
                  {...provided.draggableProps}
                  style={{
                    ...provided.draggableProps.style,
                    left: "auto !important",
                    top: "auto !important",
                  }}
                  data-qa-field-name={option.label}
                >
                  <div className="template-manager__text">
                    <div {...provided.dragHandleProps}>
                      <GridIcon />
                    </div>

                    <div className="template-manager__name">
                      <MetaFieldIcon
                        icon={renderTooltipIcon(
                          foundGlobalField.type,
                          foundGlobalField.valueSource,
                        )}
                      />
                      <span className="template-manager__name-text">
                        {foundGlobalField.name}
                      </span>
                    </div>
                  </div>
                  <div className="template-manager__list-actions">
                    <CustomSwitch
                      checked={foundFieldFromTemplate.hide}
                      onChange={(e) => {
                        handleUpdateTemplateValue({
                          uuid: foundFieldFromTemplate.uuid,
                          valueFieldName: "hide",
                          updatedValue: e.target.checked,
                          fieldType,
                          taskType: selectedTaskType,
                        });
                      }}
                    />
                    <button
                      aria-label="Remove field"
                      type="button"
                      className="button-style-reset"
                      onClick={() =>
                        handleRemoveFields({
                          fieldsToRemove: [foundFieldFromTemplate.uuid],
                          fieldType,
                          taskType: selectedTaskType,
                        })
                      }
                    >
                      <TrashCanIcon />
                    </button>
                  </div>
                </li>
              )}
            </Draggable>
          );
        })}
        {provided.placeholder}
      </ul>
    );
  };

  const fieldOptions = useMemo(
    () =>
      fields
        .filter((field) => field.taskType === selectedTaskType)
        .map((filteredField) => {
          return {
            label: filteredField.name,
            value: filteredField.uuid,
          };
        }),
    [fields, selectedTaskType],
  );

  useEffect(() => {
    const alreadySelectedFields: FieldOption[] = [];

    fieldsFromTemplate.forEach((fieldFromTemplate) => {
      const foundFieldOption = fieldOptions.find(
        (fieldOption) => fieldOption.value === fieldFromTemplate.uuid,
      );

      if (foundFieldOption) {
        alreadySelectedFields.push(foundFieldOption);
      }
    });

    setSelectedFields(alreadySelectedFields);
  }, [fieldOptions, fieldsFromTemplate]);

  return (
    <>
      <TaskTypeTabs
        selectedTaskType={selectedTaskType}
        setSelectedTaskType={setSelectedTaskType}
      />
      <div className="template-manager__headers grid--fields">
        <div>
          <IDHFormattedMessage
            id="ws_table_column"
            defaultMessage="Table Column"
          />
        </div>
        <div>
          <IDHFormattedMessage id="ws_hide" defaultMessage="Hide" />
        </div>
        <div />
      </div>
      <div className="template-manager__selector">
        <CustomSelect
          options={fieldOptions}
          value={selectedFields}
          closeMenuOnSelect={false}
          isClearable={false}
          isMulti
          placeholder={translateMessage({
            intl,
            id:
              fieldType === "project"
                ? "ws_select_overview"
                : "ws_select_table_column",
            defaultMessage:
              fieldType === "project"
                ? "Select overview"
                : "Select table column",
          })}
          onChange={handleChange}
        />
      </div>
      <FieldsList onDragEnd={handleDragEnd} listRenderer={listRenderer} />
    </>
  );
}
