import React, { useMemo, useRef, useState } from "react";
import "./TableViewSelector.scss";
import { ReactComponent as EyeIcon } from "src/images/eye.svg";
import { ReactComponent as SelectedIcon } from "src/images/selected.svg";
import { ReactComponent as TrashcanIcon } from "src/images/trash-can.svg";
import { ReactComponent as SaveIcon } from "src/images/save.svg";
import { getWorkspaceUuidFromCurrentUrl } from "src/utils/methods";
import { useSelector } from "react-redux";
import { WorkspacePermissionsEnum } from "src/utils/PermissionsEnums";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../components/Dropdown/Dropdown";
import { Button } from "../components/Button/Button";
import { deleteTableView, getTableViewOptions } from "./tableViewFunctions";
import { SaveTableViewModal } from "./components/SaveTableViewModal/SaveTableViewModal";
import { RemoveModal } from "../modals/RemoveModal/RemoveModal";
import DropdownPortal from "../components/DropdownPortal";

export default function TableViewSelector({
  wsTableViews,
  selectedWsTableView,
  setSelectedWsTableView,
  setFilters,
  setHiddenOrVisibleColumnIds,
  useVisibleColumns = false,
  setSortBy,
  setSort,
  columnIds,
  localStorageKey,
  saveTableViewFunction = (name) => {},
  reloadDataFunction = () => {},
  buttonDisabled = false,
}) {
  const [showSaveTableViewModal, setShowSaveTableViewModal] = useState(false);
  const [showDeleteViewModal, setShowDeleteViewModal] = useState(false);
  const [viewToBeDeleted, setViewToBeDeleted] = useState(null);
  const [newTableViewName, setNewTableViewName] = useState("");
  const [visible, setVisible] = useState(false);

  const dropdownRef = useRef(null);

  const identity = useSelector((state) => state.mainReducer.identity);

  const workspaceId = getWorkspaceUuidFromCurrentUrl();

  const columnsKey = useVisibleColumns ? "visibleColumns" : "hiddenColumns";

  const options = useMemo(
    () => getTableViewOptions(wsTableViews),
    [wsTableViews],
  );

  const localSelectedOption = useMemo(() => {
    const option = options.find(
      (item) => item?.value === selectedWsTableView?.value,
    );

    if (option) return option;

    return {
      label: "Custom",
      value: null,
    };
  }, [options, selectedWsTableView?.value]);

  const onChangeWsTableView = (newView) => {
    if (newView.value === null) {
      setSelectedWsTableView({
        label: "Custom",
        value: null,
      });
      const settingsData = JSON.parse(localStorage.getItem(localStorageKey));
      if (!settingsData) return;
      const settings = settingsData ? settingsData[workspaceId] : {};
      const { filters, sortBy, sort } = settings;
      const newColumnIds = settings[columnsKey];
      setHiddenOrVisibleColumnIds(newColumnIds || [], true);
      setFilters(filters || [], true);
      setSortBy(sortBy || null, true);
      setSort(sort || "DESC");
      return;
    }
    const sourceOfSelectedWsTableView = wsTableViews.find(
      (wsTableView) => wsTableView.uuid === newView.value,
    );
    if (!sourceOfSelectedWsTableView) {
      return;
    }
    if (
      sourceOfSelectedWsTableView &&
      sourceOfSelectedWsTableView.data &&
      sourceOfSelectedWsTableView.data.filters
    ) {
      const { filters } = sourceOfSelectedWsTableView.data;
      setFilters(filters || [], true);
    }
    const visibleColumnIds = sourceOfSelectedWsTableView.data.fields.map(
      (field) => field.uuid,
    );
    const hiddenColumnIds = columnIds.filter(
      (id) => !visibleColumnIds.includes(id),
    );
    const newColumnIds = useVisibleColumns ? visibleColumnIds : hiddenColumnIds;
    setHiddenOrVisibleColumnIds(newColumnIds || [], true);
    setSortBy(sourceOfSelectedWsTableView.data.sortBy || null, true);
    setSort(sourceOfSelectedWsTableView.data.sort || "DESC", true);
    setSelectedWsTableView({
      ...newView,
      data: {
        sort: sourceOfSelectedWsTableView.data.sort,
        sortBy: sourceOfSelectedWsTableView.data.sortBy,
        filters: sourceOfSelectedWsTableView.data.filters,
        [columnsKey]: newColumnIds,
      },
    });
  };

  const handleRemoveView = () => {
    deleteTableView(viewToBeDeleted.value);
    reloadDataFunction();
  };

  const canManageTableViews = identity?.permissions?.workspace?.includes(
    WorkspacePermissionsEnum.MANAGE_TABLE_VIEWS,
  );

  return (
    <>
      <DropdownPortal
        overlay={
          <DropdownMenu className="table-view-selector__menu" isOpen>
            {options.length ? (
              options.map((option) => (
                <DropdownMenuItem
                  key={option.value}
                  className="view-option"
                  dark={option.value === localSelectedOption?.value}
                  onClick={() => {
                    onChangeWsTableView(option);
                    setVisible(false);
                  }}
                >
                  <span className="view-option__content">
                    {option.label}

                    {option.value === localSelectedOption?.value && (
                      <SelectedIcon style={{ marginLeft: 8, marginRight: 0 }} />
                    )}
                  </span>

                  {canManageTableViews &&
                    option.value !== localSelectedOption?.value &&
                    option.value !== null && (
                      <TrashcanIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisible(false);
                          setShowDeleteViewModal(true);
                          setViewToBeDeleted(option);
                        }}
                        className="trashcan"
                        style={{ marginLeft: 8, marginRight: 0 }}
                      />
                    )}
                </DropdownMenuItem>
              ))
            ) : (
              <DropdownMenuItem>
                <IDHFormattedMessage
                  id="ws_nothing_to_sort"
                  defaultMessage="Nothing to sort"
                />
              </DropdownMenuItem>
            )}

            {canManageTableViews && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="table-view-selector__save-view-button"
                  onClick={() => {
                    setShowSaveTableViewModal(true);
                    setVisible(false);
                  }}
                >
                  <SaveIcon />
                  <IDHFormattedMessage
                    id="ws_save_view"
                    defaultMessage="Save view"
                  />
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenu>
        }
        ref={dropdownRef}
        placement="topRight"
        onVisibleChange={(v) => setVisible(v)}
        visible={visible}
      >
        <Button
          size="small"
          textWithIcon
          variant="white"
          disabled={buttonDisabled}
        >
          <EyeIcon />
          <IDHFormattedMessage id="ws_view" defaultMessage="View" />
          :&nbsp;
          {localSelectedOption?.label}
        </Button>
      </DropdownPortal>

      {showSaveTableViewModal && (
        <SaveTableViewModal
          onClose={() => setShowSaveTableViewModal(false)}
          name={newTableViewName}
          setName={setNewTableViewName}
          saveFunction={saveTableViewFunction}
        />
      )}

      {showDeleteViewModal && (
        <RemoveModal
          onClose={() => setShowDeleteViewModal(false)}
          objectNames={[viewToBeDeleted?.label || ""]}
          removeFunction={handleRemoveView}
        />
      )}
    </>
  );
}
