import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import CustomInput, {
  CustomInputLabel,
} from "src/app/components/CustomInput/CustomInput";
import { translateMessage } from "src/app/methods/translateMessage";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { AvatarAndName } from "src/app/components/GlobalTaskDetails/tabs/GlobalActivityTab/GlobalActivityTabCampaigns";
import placeholderIcon from "src/images/placeholder-image.svg";
import { ReactComponent as RemoveIcon } from "src/images/close.svg";
import { ReactComponent as ResizeIcon } from "src/images/chevron-up-grey.svg";
import TooltipPortal from "src/app/components/TooltipPortal/TooltipPortal";
import { RootState } from "src/redux/reducers";
import { TextBox } from "src/app/components/TextBox/TextBox";
import { EmailContent, MessageDetails, MessageRecipient, Steps } from "./types";

interface CreatorMessagePaneCreateEmailProps {
  messageDetails: MessageDetails;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
  emailContent: EmailContent;
  setEmailContent: React.Dispatch<React.SetStateAction<EmailContent>>;
  recipientsWithNoEmailCRM: MessageRecipient[] | undefined;
}

export default function CreatorMessagePaneCreateEmail({
  messageDetails,
  setStep,
  emailContent,
  setEmailContent,
  recipientsWithNoEmailCRM,
}: CreatorMessagePaneCreateEmailProps) {
  const intl = useIntl();
  const [isTextareaExpanded, setIsTextareaExpanded] = useState(false);
  const [messageText, setMessageText] = useState("");

  const { identity } = useSelector((state: RootState) => state.mainReducer);

  const {
    replyToEmail,
    includeInCcCampaignMembers,
    includeInBccCampaignMembers,
    subject,
    message,
    recipients,
  } = emailContent;

  const recipientsWithEmails = recipients.filter(
    (recipient) => recipient.emailFromCrm,
  );

  const handleSetEmailContent = (
    fieldName: keyof EmailContent,
    value: string | boolean,
  ) => {
    setEmailContent((prevState) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleRemoveRecipient = (wsTaskUuid: string) => {
    setEmailContent((prev) => ({
      ...prev,
      recipients: prev.recipients.filter(
        (recipient) => recipient.wsTaskUuid !== wsTaskUuid,
      ),
    }));
  };

  const handleResizeEmailTextarea = () => {
    setIsTextareaExpanded((prev) => !prev);
  };

  const handleBlur = () => {
    handleSetEmailContent("message", messageText);
  };

  useEffect(() => {
    handleSetEmailContent("replyToEmail", identity.email);
  }, [identity?.email]);

  return (
    <div className="creator-message-pane__content">
      {isTextareaExpanded && (
        <button
          type="button"
          aria-label="Resize"
          className="button-style-reset creator-message-pane__email-body-resize-btn"
          onClick={handleResizeEmailTextarea}
          style={{ top: "53px" }}
        >
          <ResizeIcon style={{ transform: "rotate(180deg)" }} />
        </button>
      )}
      <div
        className="creator-message-pane__email-header"
        style={{ display: isTextareaExpanded ? "none" : "flex" }}
      >
        <div className="creator-message-pane__email-row">
          <CustomInputLabel>
            <IDHFormattedMessage id="ws_from" defaultMessage="From" />:
          </CustomInputLabel>

          <div>{messageDetails.fromEmail}</div>
        </div>
        <div className="creator-message-pane__email-row">
          <CustomInputLabel>
            <IDHFormattedMessage id="ws_to" defaultMessage="To" />:
          </CustomInputLabel>

          <div className="creator-message-pane__email-from-box">
            {recipientsWithEmails.map((recipient) => (
              <TooltipPortal content={recipient.emailFromCrm}>
                <div className="creator-message-pane__email-gray-label">
                  <AvatarAndName
                    name={recipient.title}
                    avatar={recipient.avatar}
                    placeholder={placeholderIcon}
                    avatarHeight={20}
                    avatarWidth={20}
                  />
                  <button
                    type="button"
                    aria-label="Remove"
                    className="button-style-reset"
                    onClick={() => handleRemoveRecipient(recipient.wsTaskUuid)}
                  >
                    <RemoveIcon />
                  </button>
                </div>
              </TooltipPortal>
            ))}
          </div>
        </div>
        <div className="creator-message-pane__email-row">
          <CustomInputLabel>&nbsp;</CustomInputLabel>
          <div className="creator-message-pane__email-checkbox-wrapper">
            <span>
              <IDHFormattedMessage
                id="ws_include_campaign_members_in"
                defaultMessage="Include campaign members in"
              />
              :
            </span>
            <div className="creator-message-pane__email-checkbox">
              <CustomCheckbox
                id="includeInCcCampaignMembers"
                name="includeInCcCampaignMembers"
                label="CC"
                checked={includeInCcCampaignMembers}
                onChange={(e) => {
                  if (includeInBccCampaignMembers && e.target.checked) {
                    handleSetEmailContent("includeInBccCampaignMembers", false);
                  }
                  handleSetEmailContent(
                    "includeInCcCampaignMembers",
                    e.target.checked,
                  );
                }}
              />
              <CustomCheckbox
                id="includeInBccCampaignMembers"
                name="includeInBccCampaignMembers"
                label="BCC"
                checked={includeInBccCampaignMembers}
                onChange={(e) => {
                  if (includeInCcCampaignMembers && e.target.checked) {
                    handleSetEmailContent("includeInCcCampaignMembers", false);
                  }
                  handleSetEmailContent(
                    "includeInBccCampaignMembers",
                    e.target.checked,
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="creator-message-pane__email-row">
          <CustomInputLabel htmlFor="replyToEmail">
            <IDHFormattedMessage id="ws_reply_to" defaultMessage="Reply to" />:
          </CustomInputLabel>

          <CustomInput
            id="replyToEmail"
            type="email"
            value={replyToEmail}
            onChange={(e) =>
              handleSetEmailContent("replyToEmail", e.target.value)
            }
          />
        </div>
        <div className="creator-message-pane__email-row">
          <CustomInputLabel htmlFor="subject">
            <IDHFormattedMessage id="ws_subject" defaultMessage="Subject" />:
          </CustomInputLabel>

          <CustomInput
            id="subject"
            type="text"
            value={subject}
            onChange={(e) => handleSetEmailContent("subject", e.target.value)}
          />
        </div>
      </div>

      <div
        className="creator-message-pane__email-body"
        style={{ borderTop: isTextareaExpanded ? "none" : "1px solid #ededef" }}
      >
        {!isTextareaExpanded && (
          <button
            type="button"
            aria-label="Resize"
            className="button-style-reset creator-message-pane__email-body-resize-btn"
            onClick={handleResizeEmailTextarea}
            style={{ top: "-12px" }}
          >
            <ResizeIcon />
          </button>
        )}
        <TextBox
          className="ws-input creator-message-pane__email-textarea"
          value={message}
          setEncodedValue={setMessageText}
          onBlur={() => {
            handleBlur();
          }}
          placeholder={translateMessage({
            intl,
            id: "ws_enter_your_message",
            defaultMessage: "Enter your message",
          })}
        />

        <div className="creator-message-pane__footer">
          {recipientsWithNoEmailCRM?.length !== 0 && (
            <Button
              size="large"
              variant="white-with-light-grey-border"
              onClick={() => setStep(Steps.MANAGE_EMAILS)}
            >
              <IDHFormattedMessage id="ws_back" defaultMessage="Back" />
            </Button>
          )}
          <Button
            variant="blue"
            size="large"
            onClick={() => setStep(Steps.CONFIRM_EMAIL)}
            disabled={
              recipientsWithEmails.length === 0 ||
              messageText.length === 0 ||
              subject.length === 0
            }
          >
            <IDHFormattedMessage id="ws_continue" defaultMessage="Continue" />
          </Button>
        </div>
      </div>
    </div>
  );
}
