import axios from "axios";

import { API_URLS } from "src/utils/API_URLS";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import responseCodes from "src/utils/responseCodes";
import { SelectOption } from "src/types";
import { Dictionary } from "./types";

interface HandleUpdateDictionaryParams {
  data: Dictionary;
  catchCallback?: () => void;
}

export const handleUpdateDictionary = async ({
  data,
  catchCallback,
}: HandleUpdateDictionaryParams) => {
  try {
    const response = await axios.put(API_URLS.updateDictionary, data);
    if (response.status >= responseCodes["400_BAD_REQUEST"]) {
      throw new Error(`Request failed with status code ${response.status}`);
    }
  } catch (error) {
    if (catchCallback) {
      catchCallback();
    }
    if (axios.isAxiosError(error) && error.response?.data?.errors) {
      const errorMessage = parseErrorMessages(error.response.data.errors)[0]
        .message;
      showErrorToast({
        id: errorMessage,
        defaultMessage: errorMessage,
      });
    } else {
      showErrorToast({
        id: "ws_could_not_update_dictionary",
        defaultMessage: "An error occurred while updating the dictionary",
      });
    }
    console.error(error);
  }
};

export const handleUpdatetDictionaryRank = async (data: {
  uuid: string;
  rank: string;
}) => {
  try {
    const response = await axios.post(API_URLS.updateDictionaryRank, {
      wsDictionaryUuid: data.uuid,
      rank: data.rank,
    });
    if (response.status >= responseCodes["400_BAD_REQUEST"]) {
      throw new Error(`Request failed with status code ${response.status}`);
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data?.errors) {
      const errorMessage = parseErrorMessages(error.response.data.errors)[0]
        .message;
      showErrorToast({
        id: errorMessage,
        defaultMessage: errorMessage,
      });
    } else {
      showErrorToast({
        id: "ws_could_not_update_dictionary",
        defaultMessage: "An error occurred while updating the dictionary",
      });
    }
    console.error(error);
  }
};

export const handleUpdatetDictionaryMetaFieldRank = async (data: {
  uuid: string;
  rank: string;
}) => {
  try {
    const response = await axios.post(API_URLS.updateDictionaryMetaFieldRank, {
      metaFieldUuid: data.uuid,
      rank: data.rank,
    });
    if (response.status >= responseCodes["400_BAD_REQUEST"]) {
      throw new Error(`Request failed with status code ${response.status}`);
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data?.errors) {
      const errorMessage = parseErrorMessages(error.response.data.errors)[0]
        .message;
      showErrorToast({
        id: errorMessage,
        defaultMessage: errorMessage,
      });
    } else {
      showErrorToast({
        id: "ws_could_not_update_dictionary",
        defaultMessage: "An error occurred while updating the dictionary",
      });
    }
    console.error(error);
  }
};

export const handleUpdatetDictionaryMetaFieldOptions = async (data: {
  uuid: string;
  options: SelectOption[];
}) => {
  try {
    await axios.post(API_URLS.updateDictionaryMetaFieldSelectOptions, {
      metaFieldUuid: data.uuid,
      singleSelectOptions: data.options,
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data?.errors) {
      const errorMessage = parseErrorMessages(error.response.data.errors)[0]
        .message;
      showErrorToast({
        id: errorMessage,
        defaultMessage: errorMessage,
      });
    } else {
      showErrorToast({
        id: "ws_could_not_update_dictionary",
        defaultMessage: "An error occurred while updating the dictionary",
      });
    }
    console.error(error);
  }
};
