import CONSTS from "../helpers/consts.jsx";
import METHODS from "../helpers/methods.jsx";

export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_INTERESTS = "GET_INTERESTS";
export const GET_INTERESTS_SUCCESS = "GET_INTERESTS_SUCCESS";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_AUDIENCE_BRAND_AFFINITY = "GET_AUDIENCE_BRAND_AFFINITY";
export const GET_AUDIENCE_BRAND_AFFINITY_SUCCESS =
  "GET_AUDIENCE_BRAND_AFFINITY_SUCCESS";
export const GET_KEYWORDS = "GET_KEYWORDS";
export const GET_KEYWORDS_SUCCESS = "GET_KEYWORDS_SUCCESS";
export const POST_SEARCH_AUDIENCE = "POST_SEARCH_AUDIENCE";
export const POST_SEARCH_AUDIENCE_SUCCESS = "POST_SEARCH_AUDIENCE_SUCCESS";
export const POST_SEARCH_AUDIENCE_FAIL = "POST_SEARCH_AUDIENCE_FAIL";
export const POST_LOAD_MORE_SEARCH_RESULTS = "POST_LOAD_MORE_SEARCH_RESULTS";
export const POST_LOAD_MORE_SEARCH_RESULTS_SUCCESS =
  "POST_LOAD_MORE_SEARCH_RESULTS_SUCCESS";
export const POST_LOAD_MORE_SELECTED_INFLUENCERS =
  "POST_LOAD_MORE_SELECTED_INFLUENCERS";
export const POST_LOAD_MORE_SELECTED_INFLUENCERS_SUCCESS =
  "POST_LOAD_MORE_SELECTED_INFLUENCERS_SUCCESS";
export const CHANGE_SEARCH_AUDIENCE_FILTERS = "CHANGE_SEARCH_AUDIENCE_FILTERS";
export const SELECT_INFLUENCER = "SELECT_INFLUENCER";
export const SELECT_MULTIPLE_INFLUENCER = "SELECT_MULTIPLE_INFLUENCER";
export const CREATE_INFLUENCERS_LIST = "CREATE_INFLUENCERS_LIST";
export const CREATE_INFLUENCERS_LIST_SUCCESS =
  "CREATE_INFLUENCERS_LIST_SUCCESS";
export const CREATE_INFLUENCERS_LIST_FAIL = "CREATE_INFLUENCERS_LIST_FAIL";
export const GET_INFLUENCERS_LIST = "GET_INFLUENCERS_LIST";
export const GET_INFLUENCERS_LIST_SUCCESS = "GET_INFLUENCERS_LIST_SUCCESS";
export const GET_INFLUENCERS_LIST_DATA = "GET_INFLUENCERS_LIST_DATA";
export const GET_INFLUENCERS_LIST_DATA_SUCCESS =
  "GET_INFLUENCERS_LIST_DATA_SUCCESS";
export const GET_INFLUENCERS_LIST_DATA_FAIL = "GET_INFLUENCERS_LIST_DATA_FAIL";
export const GET_INFLUENCER_INTERESTS = "GET_INFLUENCER_INTERESTS";
export const GET_INFLUENCER_INTERESTS_SUCCESS =
  "GET_INFLUENCER_INTERESTS_SUCCESS";
export const DELETE_INFLUENCERS_LIST = "DELETE_INFLUENCERS_LIST";
export const DUPLICATE_INFLUENCERS_LIST = "DUPLICATE_INFLUENCERS_LIST";
export const CLEAR_RESULTS = "CLEAR_RESULTS";
export const EDIT_NAME = "EDIT_NAME";
export const EDIT_NAME_SUCCESS = "EDIT_NAME_SUCCESS";
export const CLEAR_SELECTED_USERS = "CLEAR_SELECTED_USERS";
export const CLEAR_ALL_SELECTED_USERS = "CLEAR_ALL_SELECTED_USERS";
export const CLEAR_ALL_SELECTED_USERS_BOTH_TYPES =
  "CLEAR_ALL_SELECTED_USERS_BOTH_TYPES";
export const DELETE_INFLUENCERS_FROM_LIST = "DELETE_INFLUENCERS_FROM_LIST";
export const DELETE_INFLUENCERS_FROM_LIST_SUCCESS =
  "DELETE_INFLUENCERS_FROM_LIST_SUCCESS";
export const ADD_TO_AUDIENCES = "ADD_TO_AUDIENCES";
export const ADD_TO_AUDIENCES_SUCCESS = "ADD_TO_AUDIENCES_SUCCESS";
export const UPDATE_SEARCH_FILTERS = "UPDATE_SEARCH_FILTERS";
export const GET_VELOCITY_SUMMARY_DATA = "GET_VELOCITY_SUMMARY_DATA";
export const GET_VELOCITY_SUMMARY_DATA_SUCCESS =
  "GET_VELOCITY_SUMMARY_DATA_SUCCESS";
export const CLEAR_VELOCITY_SUMMARY_DATA = "CLEAR_VELOCITY_SUMMARY_DATA";
export const EXPORT_LIST = "EXPORT_LIST";
export const EXPORT_FULL_LIST = "EXPORT_FULL_LIST";
export const SET_COUNTRY_DISABLED = "SET_COUNTRY_DISABLED";
export const CLEAR_DISABLED_COUNTRIES = "CLEAR_DISABLED_COUNTRIES";
export const ADD_CREATORS_TO_LIST_FROM_SOCIALS =
  "ADD_CREATORS_TO_LIST_FROM_SOCIALS";
export const ADD_CREATORS_TO_LIST_FROM_SOCIALS_SUCCESS =
  "ADD_CREATORS_TO_LIST_FROM_SOCIALS_SUCCESS";
export const SET_LIST_SOCIAL_PROVIDER = "SET_LIST_SOCIAL_PROVIDER";
export const POST_REFRESH_AUDIENCE = "POST_REFRESH_AUDIENCE";
export const POST_REFRESH_AUDIENCE_SUCCESS = "POST_REFRESH_AUDIENCE_SUCCESS";
export const CLEAR_INFLUENCERS_LIST_INFO = "CLEAR_INFLUENCERS_LIST_INFO";
export const CLOSE_TRIAL_EXCEEDED_CDT_MODAL = "CLOSE_TRIAL_EXCEEDED_CDT_MODAL";
export const DELETE_AWAITING_USER = "DELETE_AWAITING_USER";
export const SET_AUDIENCE_DATA = "SET_AUDIENCE_DATA";
export const SET_AUDIENCE_LOADING = "SET_AUDIENCE_LOADING";
export const SET_IS_INCORRECT_PROVIDER_ERROR =
  "SET_IS_INCORRECT_PROVIDER_ERROR";
export const CLEAR_AUDIENCE_DATA = "CLEAR_AUDIENCE_DATA";
export const SET_IS_AUDIENCE_DATA_ERROR = "SET_IS_AUDIENCE_DATA_ERROR";

export function getSettings() {
  return {
    type: GET_SETTINGS,
    payload: {
      request: {
        url: CONSTS.getSettings,
        headers: {
          "Workspace-Uuid": METHODS.getWorkspaceUuidFromCurrentUrl(),
        },
      },
    },
  };
}

export function getInterests() {
  return {
    type: GET_INTERESTS,
    payload: {
      request: {
        url: CONSTS.getInterests,
      },
    },
  };
}

export function getCountries() {
  return {
    type: GET_COUNTRIES,
    payload: {
      request: {
        url: CONSTS.getCountries,
      },
    },
  };
}

export function getAudienceBrandAffinity() {
  return {
    type: GET_AUDIENCE_BRAND_AFFINITY,
    payload: {
      request: {
        url: CONSTS.getAudienceBrandAffinity,
      },
    },
  };
}

export function getKeywords() {
  return {
    type: GET_KEYWORDS,
    payload: {
      request: {
        url: CONSTS.getKeywords,
      },
    },
  };
}

export function searchAudience(data) {
  return {
    type: POST_SEARCH_AUDIENCE,
    payload: {
      recentSearchAudienceQuery: JSON.stringify(data),
      request: {
        method: "post",
        url: CONSTS.searchAudience,
        headers: {
          "Workspace-Uuid": METHODS.getWorkspaceUuidFromCurrentUrl(),
        },
        data,
      },
    },
  };
}

export function loadMoreSearchResults(data) {
  return {
    type: POST_LOAD_MORE_SEARCH_RESULTS,
    payload: {
      request: {
        method: "post",
        url: CONSTS.searchAudience,
        headers: {
          "Workspace-Uuid": METHODS.getWorkspaceUuidFromCurrentUrl(),
        },
        data,
      },
    },
  };
}

export function refreshAudience(searchData, page = 1, sortBy) {
  return {
    type: POST_REFRESH_AUDIENCE,
    payload: {
      audienceLoading: false,
      request: {
        method: "post",
        url: CONSTS.searchAudience,
        data: {
          refresh: true,
          searchFilters: METHODS.parseAudienceSearchToRequest(searchData),
          pagination: {
            page: parseInt(page),
            onPage: 20,
            orderBy: sortBy || "velocityIndex",
            order: "desc",
          },
        },
      },
    },
  };
}

export function changeSearchAudienceFilters(data) {
  return {
    type: CHANGE_SEARCH_AUDIENCE_FILTERS,
    payload: { searchData: data },
  };
}

export function selectInfluencer(userId, profileUrl, type) {
  return {
    type: SELECT_INFLUENCER,
    payload: { userId, profileUrl, type },
  };
}

export function selectMultipleInfluencer(userIds, profileUrls, type) {
  return {
    type: SELECT_MULTIPLE_INFLUENCER,
    payload: { userIds, profileUrls, type },
  };
}

export function createInfluencersList(searchData, influencersList, name) {
  return {
    type: CREATE_INFLUENCERS_LIST,
    payload: {
      request: {
        method: "post",
        url: CONSTS.createInfluencersList,
        data: {
          name,
          searchFilters: JSON.stringify(
            METHODS.parseAudienceSearchToRequest(searchData),
          ),
          userIds: influencersList,
        },
      },
      messages: ["front_create_audience_success", "front_create_audience_fail"],
    },
  };
}

export function getInfluencersList() {
  return {
    type: GET_INFLUENCERS_LIST,
    payload: {
      request: {
        method: "post",
        url: CONSTS.getInfluencersList,
        headers: {
          "Workspace-Uuid": METHODS.getWorkspaceUuidFromCurrentUrl(),
        },
      },
    },
  };
}

export function getInfluencersListData(listId, page = 1, sortBy = null) {
  return {
    type: GET_INFLUENCERS_LIST_DATA,
    payload: {
      request: {
        method: "post",
        url: CONSTS.getInfluencersListData,
        data: { listId },
        headers: {
          "Workspace-Uuid": METHODS.getWorkspaceUuidFromCurrentUrl(),
        },
      },
    },
  };
}

export function loadMoreSelectedInfluencers(listId, page, sortBy) {
  return {
    type: POST_LOAD_MORE_SELECTED_INFLUENCERS,
    payload: {
      request: {
        method: "post",
        url: CONSTS.getInfluencersListData,
        data: {
          listId,
          pagination: {
            page: page ? parseInt(page) : 1,
            onPage: CONSTS.audienceListPageSize,
            orderBy: sortBy || "created",
            order: "desc",
          },
        },
      },
    },
  };
}

export function deleteInfluencersList(listId) {
  return {
    type: DELETE_INFLUENCERS_LIST,
    payload: {
      request: {
        method: "post",
        url: CONSTS.deleteInfluencersListData,
        data: {
          listId,
        },
      },
      messages: ["front_delete_audience_success", "front_delete_audience_fail"],
    },
  };
}

export function duplicateList(name, listId) {
  return {
    type: DUPLICATE_INFLUENCERS_LIST,
    payload: {
      request: {
        method: "post",
        url: CONSTS.duplicateInfluencersListData,
        data: {
          name,
          listId,
        },
      },
      messages: [
        "front_duplicate_audience_success",
        "front_duplicate_audience_fail",
      ],
    },
  };
}

export function clearResults() {
  return {
    type: CLEAR_RESULTS,
  };
}

export function editName(newName, listId) {
  return {
    type: EDIT_NAME,
    payload: {
      request: {
        method: "post",
        url: CONSTS.editName,
        data: {
          listId,
          name: newName,
        },
      },
      messages: [
        "front_edit_audience_name_success",
        "front_edit_audience_name_fail",
      ],
    },
    newName,
  };
}

export function clearSelectedUsers(userIds, profileUrls, type) {
  return {
    type: CLEAR_SELECTED_USERS,
    payload: { userIds, profileUrls, type },
  };
}

export function clearAllSelectedUsers(type) {
  return {
    type: CLEAR_ALL_SELECTED_USERS,
    payload: { type },
  };
}

export function clearAllSelectedUsersBothTypes() {
  return {
    type: CLEAR_ALL_SELECTED_USERS_BOTH_TYPES,
  };
}

export function deleteInfluencersFromList(userList, listId) {
  return {
    type: DELETE_INFLUENCERS_FROM_LIST,
    payload: {
      request: {
        method: "post",
        url: CONSTS.deleteInfluencersFromList,
        data: {
          userIds: userList,
          listId,
        },
      },
      messages: [
        "front_delete_influencer_from_list_success",
        "front_delete_influencer_from_list_fail",
      ],
    },
  };
}

export function addToAudiences(userList, listId) {
  return {
    type: ADD_TO_AUDIENCES,
    payload: {
      request: {
        method: "post",
        url: CONSTS.addToAudiences,
        data: {
          userIds: userList,
          listId,
        },
      },
      messages: [
        "front_add_to_selected_influencers_success",
        "front_add_to_selected_influencers_fail",
      ],
    },
  };
}

export function updateSearchFilters(listId, searchData) {
  return {
    type: UPDATE_SEARCH_FILTERS,
    payload: {
      request: {
        method: "post",
        url: CONSTS.updateSearchFilters,
        data: {
          listId,
          searchFilters: JSON.stringify(
            METHODS.parseAudienceSearchToRequest(searchData),
          ),
        },
      },
    },
  };
}

export function getVelocitySummaryData(userId, searchData) {
  return {
    type: GET_VELOCITY_SUMMARY_DATA,
    payload: {
      request: {
        method: "post",
        url: CONSTS.getVelocitySummaryData,
        data: {
          userId,
          searchFilters: METHODS.parseAudienceSearchToRequest(searchData),
        },
      },
    },
  };
}

export function clearVelocitySummaryData() {
  return {
    type: CLEAR_VELOCITY_SUMMARY_DATA,
  };
}

export function exportList(type, userIds, searchData) {
  return {
    type: EXPORT_LIST,
    payload: {
      request: {
        method: "post",
        url: CONSTS.exportListPdfPptx,
        data: {
          userIds,
          exportType: type,
          searchFilters: searchData
            ? METHODS.parseAudienceSearchToRequest(searchData)
            : null,
        },
      },
      messages: ["front_export_success", "front_export_fail"],
    },
  };
}

export function exportFullList(type, listId) {
  return {
    type: EXPORT_FULL_LIST,
    payload: {
      request: {
        method: "post",
        url: CONSTS.exportFullListPdfPptx,
        data: {
          exportType: type,
          listId,
        },
      },
      messages: ["front_export_success", "front_export_fail"],
    },
  };
}

export function setCountryDisabled(type, country, disabled) {
  return {
    type: SET_COUNTRY_DISABLED,
    payload: { type, country, disabled },
  };
}

export function clearDisabledCountries() {
  return {
    type: CLEAR_DISABLED_COUNTRIES,
  };
}

export function addCreatorsToListFromSocials(listId, usersSocials) {
  return {
    type: ADD_CREATORS_TO_LIST_FROM_SOCIALS,
    payload: {
      request: {
        method: "post",
        url: CONSTS.addCreatorsToListFromSocials,
        data: {
          listId,
          usersSocials,
        },
      },
    },
  };
}

export function setListSocialProvider(listSocialProvider) {
  return {
    type: SET_LIST_SOCIAL_PROVIDER,
    payload: { listSocialProvider },
  };
}

export function clearInfluencersListInfo() {
  return {
    type: CLEAR_INFLUENCERS_LIST_INFO,
  };
}

export function closeTrialExceededCDTModal() {
  return {
    type: CLOSE_TRIAL_EXCEEDED_CDT_MODAL,
  };
}

export function deleteAwaitingUsers(awaitingUserId) {
  return {
    type: DELETE_AWAITING_USER,
    payload: { awaitingUserId },
  };
}

export function setAudienceLoading(isLoading) {
  return {
    type: SET_AUDIENCE_LOADING,
    payload: { isLoading },
  };
}

export function setAudienceData(data) {
  return {
    type: SET_AUDIENCE_DATA,
    payload: {
      data,
    },
  };
}

export function setIsIncorrectProviderError(isIncorrectProviderError) {
  return {
    type: SET_IS_INCORRECT_PROVIDER_ERROR,
    payload: isIncorrectProviderError,
  };
}

export function clearAudienceData() {
  return {
    type: CLEAR_AUDIENCE_DATA,
  };
}

export function setIsAudienceDataError(isAudienceDataError) {
  return {
    type: SET_IS_AUDIENCE_DATA_ERROR,
    payload: isAudienceDataError,
  };
}
