export const REFRESH_DATA_HUB_STATE = "REFRESH_DATA_HUB_STATE";
export const SET_WORKSPACE_TASK_SORTING_COLUMN =
  "SET_WORKSPACE_TASK_SORTING_COLUMN";
export const SET_WORKSPACE_TASK_SORTING_DIRECTION =
  "SET_WORKSPACE_TASK_SORTING_DIRECTION";
export const SET_WORKSPACE_TASK_FILTER = "SET_WORKSPACE_TASK_FILTER";
export const SET_WORKSPACE_TASK_FILTERS = "SET_WORKSPACE_TASK_FILTERS";
export const CLEAR_WORKSPACE_TASK_FILTERS = "CLEAR_WORKSPACE_TASK_FILTERS";
export const SET_WS_TASKS_SELECTED_TABLE_VIEW =
  "SET_WS_TASKS_SELECTED_TABLE_VIEW";
export const SET_WORKSPACE_TASK_VISIBLE_COLUMNS =
  "SET_WORKSPACE_TASK_VISIBLE_COLUMNS";
export const TOGGLE_WORKSPACE_TASK_COLUMN_VISIBILITY =
  "TOGGLE_WORKSPACE_TASK_COLUMN_VISIBILITY";
export const SET_WORKSPACE_TASK_COLUMN_RANKS =
  "SET_WORKSPACE_TASK_COLUMN_RANKS";
export const SET_WORKSPACE_TASK_COLUMN_RANK = "SET_WORKSPACE_TASK_COLUMN_RANK";
