import React, { useEffect, useMemo, useReducer, useState } from "react";

import moment from "moment";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as CloseIcon } from "src/images/cross-alt.svg";
import CreatorPlaceholder from "src/images/creator-placeholder.svg";
import { useQuery } from "src/app/methods/useQuery";
import {
  clearGlobalTaskDetails,
  deleteGlobalTasks,
  getGlobalTaskDetails,
} from "src/redux/creator-database/creatorDatabaseActions";
import { IMember } from "src/app/project/ProjectTypes";
import { useShowPane } from "src/hooks/useShowPane";
import { convertToLocalTimezone } from "src/app/methods/convertToLocalTimezone";
import { Pane } from "../Pane/Pane";
import Tabs from "../Tabs/Tabs";
import Tab from "../Tabs/Tab";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";
import { closeGlobalTaskDetails } from "./methods";
import { Breadcrumbs, BreadcrumbsItem } from "../Breadcrumbs/Breadcrumbs";
import PreviewInfo from "../Preview/components/PreviewInfo";
import { PreviewContext } from "../Preview/utils";
import { GlobalData } from "./tabs/GlobalData/GlobalData";
import {
  Performance,
  PerformanceContext,
  PerformanceElement,
} from "./tabs/Performance/Performance";
import {
  IPerformanceFilters,
  InitialPerformanceFilters,
  PerformanceFilters,
} from "./tabs/Performance/components/PerformanceFilters/PerformanceFilters";
import { GlobalTaskSocialData } from "./tabs/GlobalTaskSocialData/GlobalTaskSocialData";
import { GlobalActivityTab } from "./tabs/GlobalActivityTab/GlobalActivityTab";

import "./GlobalTaskDetails.scss";
import { PerformanceFiltersButton } from "./tabs/Performance/components/PerformanceFilters/components/PerformanceFiltersButton/PerformanceFiltersButton";
import { Dropdown, DropdownMenu, DropdownMenuItem } from "../Dropdown/Dropdown";
import { Button } from "../Button/Button";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import { ReactComponent as RocketIcon } from "src/images/rocket-grey.svg";
import AddCreatorsCDTWorkspaceProjectModal, {
  SelectedCreator,
} from "src/app/modals/AddCreatorsCDTWorkspaceProjectModal/AddCreatorsCDTWorkspaceProjectModal";
import { setCreateNewProjectData } from "src/redux/main/mainActions";
import { navigateToCreateNewProject } from "src/app/SettingsModal/methods";
import { generateRankString } from "src/utils/rankStrings";
import { uuidv7 as uuid } from "uuidv7";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import { RootState } from "src/redux/reducers";
import { PreviewSettingsDropdown } from "./PreviewSettingsDropdown/PreviewSettingsDropdown";
import { CreatorShowcaseContext } from "src/app/CreatorShowcase/utils";
import { AppConfig } from "../../../config/AppConfig";
import TableViewSelector from "src/app/TableView/TableViewSelector";
import {
  setDictionaryFilters,
  setDictionarySort,
  setDictionarySortBy,
  setHiddenDictionaryColumnIds,
} from "../DictionaryFilters/redux/dictionaryFiltersActions";
import creatorDatabaseFiltersReducer, {
  creatorDatabaseFiltersReducerInitialState,
} from "src/app/CreatorDatabase/redux/creatorDatabaseFiltersReducer";
import { setSelectedTableView } from "src/redux";
import { Metadata } from "src/app/Task/Subtask/Subtask";
import { DictionaryTab } from "./tabs/DictionaryTab/DictionaryTab";

enum GlobalTaskPreviewTab {
  GlobalData = "global-data",
  SocialData = "social-data",
  Activity = "activity",
  Collaborations = "collaborations",
  Performance = "performance",
}

export function GlobalTaskDetails(props: any) {
  const { tableViewsFunctions = {} } = props;
  const [activeTab, setActiveTab] = useState(GlobalTaskPreviewTab.GlobalData);
  const [dateCreated, setDateCreated] = useState<moment.Moment | null>(null);

  const [performanceData, setPerformanceData] = useState<PerformanceElement[]>(
    [],
  );
  const [performanceFilters, setPerformanceFilters] =
    useState<IPerformanceFilters>(InitialPerformanceFilters);
  const [showFilters, setShowFilters] = useState(false);
  const [profileData, setProfileData] = useState<SelectedCreator>({
    name: "",
    socialProfiles: [],
  });
  const [
    showModalAddCreatorsToWorkspaceProject,
    setShowModalAddCreatorsToWorkspaceProject,
  ] = useState(false);

  const globalTaskColumns = useSelector(
    (state: RootState) => state.creatorDatabaseReducer.globalTaskColumns,
  );
  const {
    mainReducer: { activeWorkspaceUuid },
    creatorDatabaseReducer: { globalTaskDetails },
    projectReducer: { membersList },
  } = useSelector((state: RootState) => state);

  const [creatorDatabaseFilters, dispatchCDF] = useReducer(
    creatorDatabaseFiltersReducer,
    creatorDatabaseFiltersReducerInitialState(globalTaskColumns),
  );

  const filters = tableViewsFunctions || creatorDatabaseFilters;

  const {
    hiddenColumns,
    creatorDatabaseColumnRanks,
    selectedTableView = {
      label: "Custom",
      value: null,
    },
  } = filters;

  const { wsTableViews } = globalTaskDetails;

  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const taskId = query.get("displayGlobalTask") || "";

  const [showPane, hidePane] = useShowPane(() => {
    closeGlobalTaskDetails(history);
  });

  const dictionaryTabs = globalTaskDetails?.wsDictionariesRelated;

  const isDictionary = useMemo(
    () =>
      Boolean(
        dictionaryTabs?.find(
          (item: { wsDictionaryUrl: string }) =>
            item.wsDictionaryUrl === activeTab,
        ),
      ),
    [activeTab, dictionaryTabs],
  );

  const taskOwner = useMemo(() => {
    return membersList?.find(
      (member: IMember) => member.id === globalTaskDetails?.ownerWsMemberUuid,
    );
  }, [globalTaskDetails?.ownerWsMemberUuid, membersList]);

  const showSocialData = globalTaskDetails?.socialProfiles?.length > 0;

  useEffect(() => {
    dispatch(getGlobalTaskDetails(taskId));
  }, [taskId]);

  useEffect(() => {
    if (globalTaskDetails?.socialProfiles) {
      setProfileData({
        name: globalTaskDetails.title,
        socialProfiles: globalTaskDetails?.socialProfiles.map(
          (profile: SocialProfile) => ({
            ...profile,
          }),
        ),
      });
    }
  }, [globalTaskDetails?.socialProfiles]);

  useEffect(() => {
    if (globalTaskDetails?.created) {
      const dateValue = globalTaskDetails.created.date;
      if (dateValue !== null) {
        setDateCreated(convertToLocalTimezone(dateValue));
      }
    }
  }, [globalTaskDetails?.created]);

  const changeTab = (tab: GlobalTaskPreviewTab) => {
    setActiveTab(tab);
    dispatch(getGlobalTaskDetails(taskId, true));
  };

  const selectedCreatorsPayload = () => {
    const previousRank = "a";

    const newRank = generateRankString(previousRank, "");

    return [
      {
        uuid: uuid(),
        name: profileData.name,
        socialProfiles: profileData.socialProfiles,
        rank: newRank,
      },
    ];
  };

  const dispatchDataAndNavigateToCreateNewProject = () => {
    dispatch(
      setCreateNewProjectData({
        selectedCreatorsPayload: selectedCreatorsPayload(),
      }),
    );
    navigateToCreateNewProject(history, activeWorkspaceUuid);
  };

  useEffect(() => {
    return () => {
      if (window.location.pathname.includes("/projects")) return;
      dispatch(clearGlobalTaskDetails());
    };
  }, []);

  const filteredMetadata = useMemo(
    () =>
      globalTaskDetails?.metadata
        ?.filter((col) => !hiddenColumns?.includes(col.uuid))
        .sort((a, b) => {
          const rankA = creatorDatabaseColumnRanks?.find(
            (col: { uuid: string }) => col.uuid === a.uuid,
          )?.rank;
          const rankB = creatorDatabaseColumnRanks?.find(
            (col: { uuid: string }) => col.uuid === b.uuid,
          )?.rank;

          if (rankA && rankB) return rankA.localeCompare(rankB);
          return 0;
        }) as Metadata[],
    [globalTaskDetails?.metadata, hiddenColumns, creatorDatabaseColumnRanks],
  );

  const renderTab = () => {
    switch (activeTab) {
      case GlobalTaskPreviewTab.GlobalData:
        return (
          <GlobalData
            taskId={taskId}
            metadata={filteredMetadata}
            taskOwner={taskOwner}
            dateCreated={dateCreated}
          />
        );
      case GlobalTaskPreviewTab.SocialData:
        return (
          <GlobalTaskSocialData
            data={{
              uuid: taskId,
              title: globalTaskDetails.title,
            }}
            socialProfiles={globalTaskDetails?.socialProfiles}
          />
        );

      case GlobalTaskPreviewTab.Performance:
        return (
          <Performance
            context={PerformanceContext.GlobalTaskDetails}
            taskId={taskId}
            data={performanceData}
            setData={setPerformanceData}
            filters={performanceFilters}
          />
        );

      case GlobalTaskPreviewTab.Activity:
        return <GlobalActivityTab taskId={taskId} />;
    }

    if (isDictionary) {
      return <DictionaryTab activeTab={activeTab} />;
    }

    return null;
  };

  return (
    <Pane
      className="global-task-details"
      backdropClassName="global-task-details__backdrop"
      showPane={showPane}
      hidePane={hidePane}
    >
      <div className="global-task-details__topbar">
        <Breadcrumbs>
          <BreadcrumbsItem
            text={
              <IDHFormattedMessage
                id="ws_creator_database"
                defaultMessage="Creator database"
              />
            }
            to={`/workspace/${activeWorkspaceUuid}/creator-database`}
          />
          <BreadcrumbsItem
            text={globalTaskDetails?.title}
            to={`/workspace/${activeWorkspaceUuid}/creator-database?displayGlobalTask=${taskId}`}
          />
        </Breadcrumbs>

        <div className="global-task-details__topbar-buttons">
          <Dropdown toRight>
            {(isOpen, setIsOpen) => (
              <>
                <Button
                  size="small"
                  variant="blue"
                  onClick={() => setIsOpen(true)}
                >
                  <PlusIcon className="icon-left" />
                  <IDHFormattedMessage
                    id="front_add_to"
                    defaultMessage="Add to..."
                  />
                </Button>
                <DropdownMenu isOpen={isOpen}>
                  <DropdownMenuItem
                    onClick={() => {
                      setShowModalAddCreatorsToWorkspaceProject(true);
                      setIsOpen(false);
                    }}
                  >
                    <RocketIcon />
                    <IDHFormattedMessage
                      id="front_campaign"
                      defaultMessage="Campaign"
                    />
                  </DropdownMenuItem>
                </DropdownMenu>
              </>
            )}
          </Dropdown>

          <PreviewSettingsDropdown
            showcaseData={{
              history,
              context: CreatorShowcaseContext.CreatorDatabase,
              creatorId: globalTaskDetails?.uuid,
              ids: [globalTaskDetails?.uuid],
              workspaceId: activeWorkspaceUuid,
            }}
            linkToCopy={`${AppConfig.getAppRoute()}/${activeWorkspaceUuid}/creator-database?displayGlobalTask=${globalTaskDetails?.uuid}`}
            removeData={{
              objectNames: [globalTaskDetails.title],
              removeFunction: () => {
                history.push(
                  `/workspace/${activeWorkspaceUuid}/creator-database`,
                );
                dispatch(deleteGlobalTasks([globalTaskDetails?.uuid]));
              },
            }}
          />

          <CloseIcon
            onClick={hidePane}
            className="global-task-details__topbar-close-icon"
          />
        </div>
      </div>

      <div className="global-task-details__content">
        <PreviewInfo
          context={PreviewContext.CreatorDatabase}
          previewObjectUuid={globalTaskDetails.uuid}
          title={globalTaskDetails.title}
          titleMetaFieldUuid={globalTaskDetails?.titleMetaFieldUuid}
          cover={{
            data: globalTaskDetails?.cover,
            placeholder: CreatorPlaceholder,
          }}
          socialProfiles={globalTaskDetails?.socialProfiles}
        />

        <Tabs>
          <Tab
            tabText={
              <IDHFormattedMessage id="ws_general" defaultMessage="General" />
            }
            active={activeTab === GlobalTaskPreviewTab.GlobalData}
            onClick={() => changeTab(GlobalTaskPreviewTab.GlobalData)}
          />

          {dictionaryTabs?.map((item: any) => (
            <Tab
              key={item.wsDictionaryUrl}
              tabText={item.wsDictionaryName}
              active={activeTab === item.wsDictionaryUrl}
              onClick={() => changeTab(item.wsDictionaryUrl)}
            />
          ))}

          {showSocialData && (
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_social_data"
                  defaultMessage="Social Data"
                />
              }
              active={activeTab === GlobalTaskPreviewTab.SocialData}
              onClick={() => changeTab(GlobalTaskPreviewTab.SocialData)}
            />
          )}

          <Tab
            tabText={
              <IDHFormattedMessage id="ws_activity" defaultMessage="Activity" />
            }
            active={activeTab === GlobalTaskPreviewTab.Activity}
            onClick={() => changeTab(GlobalTaskPreviewTab.Activity)}
          />

          <Tab
            tabText={
              <IDHFormattedMessage
                id="ws_performance"
                defaultMessage="Performance"
              />
            }
            active={activeTab === GlobalTaskPreviewTab.Performance}
            onClick={() => changeTab(GlobalTaskPreviewTab.Performance)}
          />

          {activeTab === GlobalTaskPreviewTab.Performance &&
          performanceData.length ? (
            <PerformanceFiltersButton
              openFilters={() => setShowFilters(true)}
              filters={performanceFilters}
            />
          ) : null}

          {activeTab === GlobalTaskPreviewTab.GlobalData && (
            <li className="table-view-selector-button">
              <TableViewSelector
                wsTableViews={wsTableViews}
                selectedWsTableView={selectedTableView}
                setSelectedWsTableView={(newValue: any) =>
                  // @ts-ignore
                  dispatchCDF(setSelectedTableView(newValue))
                }
                setFilters={(newFilters: any, skipLS: boolean) =>
                  // @ts-ignore
                  dispatchCDF(setDictionaryFilters(newFilters, skipLS))
                }
                setHiddenOrVisibleColumnIds={(
                  columnIds: any,
                  skipLS: boolean,
                ) =>
                  // @ts-ignore
                  dispatchCDF(setHiddenDictionaryColumnIds(columnIds, skipLS))
                }
                setSortBy={(newValue: any, skipLS: boolean) =>
                  // @ts-ignore
                  dispatchCDF(setDictionarySortBy(newValue, skipLS))
                }
                setSort={(newValue: any, skipLS: boolean) =>
                  // @ts-ignore
                  dispatchCDF(setDictionarySort(newValue, skipLS))
                }
                columnIds={globalTaskColumns.map((item) => item.uuid)}
                localStorageKey="ws-creator-database-settings"
                {...tableViewsFunctions}
              />
            </li>
          )}
        </Tabs>

        {renderTab()}

        {showModalAddCreatorsToWorkspaceProject && (
          <AddCreatorsCDTWorkspaceProjectModal
            onClose={() => setShowModalAddCreatorsToWorkspaceProject(false)}
            selectedCreators={[profileData]}
            openCreateNewProjectModal={
              dispatchDataAndNavigateToCreateNewProject
            }
          />
        )}
      </div>

      <PerformanceFilters
        setFiltersVisible={setShowFilters}
        filtersVisible={showFilters}
        performanceData={performanceData}
        filters={performanceFilters}
        setFilters={setPerformanceFilters}
      />
    </Pane>
  );
}
