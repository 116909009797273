import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CustomRadio from "src/app/components/CustomRadio/CustomRadio";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Modal, { ModalRow, ModalTitle } from "src/app/components/Modal/Modal";
import { translateMessage } from "src/app/methods/translateMessage";
import { ReactComponent as PlusGreyIcon } from "src/images/plus-grey.svg";
import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import CustomDatePicker, {
  DatePickerWrapper,
} from "src/app/components/CustomDatePicker/CustomDatePicker";
import { API_URLS } from "src/utils/API_URLS";
import { RootState } from "src/redux/reducers";

import "./CreatePaymentModal.scss";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import responseCodes from "src/utils/responseCodes";

type PaymentType = "singlePayment" | "splitPayment";

const percentages = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];

interface CreatePaymentModalProps {
  onClose: () => void;
}

function CreatePaymentModal({ onClose }: CreatePaymentModalProps) {
  const [paymentType, setPaymentType] = useState<PaymentType>("singlePayment");
  const [selectedPercentage, setSelectedPercentage] = useState<number | null>(
    null,
  );
  const [firstPaymentDate, setFirstPaymentDate] = useState<string>("");
  const [secondPaymentDate, setSecondPaymentDate] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams<{
    projectId: string;
  }>();

  const { projectId } = params;

  const {
    mainReducer: { activeWorkspaceUuid },
    taskReducer: { selectedTasks },
    projectReducer: { taskType },
  } = useSelector((state: RootState) => state);

  const intl = useIntl();

  const handleConfirm = async () => {
    setIsLoading(true);

    if (!selectedPercentage) {
      console.error("Missing payment percentage");
      return;
    }

    const firstPayment = selectedPercentage.toString();
    const secondPayment = Number(
      (1 - selectedPercentage).toFixed(1),
    ).toString();

    try {
      const response = await axios.post(API_URLS.createSingleSplitPayments, {
        wsTasksUuids: selectedTasks.map((task) => task.taskId),
        wsWorkspaceUuid: activeWorkspaceUuid,
        wsProjectUuid: projectId,
        wsTaskType: taskType,
        paymentData: {
          type: paymentType,
          amount:
            paymentType === "singlePayment"
              ? [firstPayment]
              : [firstPayment, secondPayment],
          dueDate:
            paymentType === "singlePayment"
              ? [firstPaymentDate]
              : [firstPaymentDate, secondPaymentDate],
        },
      });

      if (response.status >= responseCodes["400_BAD_REQUEST"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      setIsLoading(false);
      onClose();
      toast.success(
        <IDHFormattedMessage
          id="ws_payment_success"
          defaultMessage="Payment has been created successfully."
        />,
      );
    } catch (error) {
      setIsLoading(false);
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_payment_error",
          defaultMessage:
            "Something went wrong. Please try sending the payment again.",
        });
      }
      console.error(error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentType(event.target.value as PaymentType);
  };

  useEffect(() => {
    if (selectedPercentage === 1) {
      setPaymentType("singlePayment");
    }
  }, [selectedPercentage]);

  return (
    <Modal
      isLoading={isLoading}
      className="create-payment-modal"
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      confirmButtonDisabled={
        selectedPercentage === null ||
        (paymentType === "singlePayment" && firstPaymentDate.length === 0) ||
        (paymentType === "splitPayment" &&
          (firstPaymentDate.length === 0 || secondPaymentDate.length === 0))
      }
      onClose={onClose}
      displayCancelButton
      onConfirmClick={handleConfirm}
      confirmButtonText={
        <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
      }
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_create_payment"
          defaultMessage="Create payment"
        />
      </ModalTitle>
      <div className="create-payment-modal__wrapper">
        <ModalRow>
          <CustomRadio
            id="single-payment"
            name="payment"
            value="singlePayment"
            checked={paymentType === "singlePayment"}
            onChange={handleChange}
            label={translateMessage({
              intl,
              id: "ws_single_payment",
              defaultMessage: "Single payment",
            })}
          />

          <CustomRadio
            id="split-payment"
            name="payment"
            value="splitPayment"
            checked={paymentType === "splitPayment"}
            onChange={handleChange}
            disabled={selectedPercentage === 1}
            label={translateMessage({
              intl,
              id: "ws_split_payment",
              defaultMessage: "Split payment",
            })}
          />
        </ModalRow>

        <ModalRow>
          <div className="create-payment-modal__payment-config">
            <div className="create-payment-modal__input">
              {paymentType === "splitPayment" && (
                <div className="create-payment-modal__payment">
                  <IDHFormattedMessage
                    id="ws_first_payment"
                    defaultMessage="First payment"
                  />
                </div>
              )}

              <div>
                <CustomInputLabel>
                  <IDHFormattedMessage id="ws_amount" defaultMessage="Amount" />
                </CustomInputLabel>
              </div>
              <div className="create-payment-modal__tags">
                {percentages.map((percentage) => {
                  if (selectedPercentage) {
                    if (selectedPercentage === percentage) {
                      return (
                        <button
                          key={percentage}
                          className="button-style-reset create-payment-modal__selectable-tag create-payment-modal__selectable-tag--active"
                          onClick={() => setSelectedPercentage(null)}
                          aria-label="Percentage"
                          type="button"
                        >
                          {percentage * 100}%
                          <PlusGreyIcon
                            className="select-tags__selectable-labels__single__add"
                            height={14}
                            width={14}
                          />
                        </button>
                      );
                    }
                    return null;
                  }
                  return (
                    <button
                      key={percentage}
                      className="button-style-reset create-payment-modal__selectable-tag"
                      onClick={() => setSelectedPercentage(percentage)}
                      aria-label="Percentage"
                      type="button"
                    >
                      {percentage * 100}%
                      <PlusGreyIcon
                        className="select-tags__selectable-labels__single__add"
                        height={14}
                        width={14}
                      />
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="create-payment-modal__input">
              <div>
                <CustomInputLabel>
                  <IDHFormattedMessage
                    id="ws_due_date"
                    defaultMessage="Due Date"
                  />
                </CustomInputLabel>
              </div>
              <div className="task-meta-fields__value">
                <span className="task-meta-fields__value-content">
                  <div className="value-field">
                    <DatePickerWrapper value={firstPaymentDate}>
                      <CustomDatePicker
                        initialDate={firstPaymentDate}
                        dateChangeHandler={(newDate: string) =>
                          setFirstPaymentDate(newDate)
                        }
                      />
                    </DatePickerWrapper>
                  </div>
                </span>
              </div>
            </div>
          </div>

          {paymentType === "splitPayment" && selectedPercentage && (
            <div className="create-payment-modal__payment-config">
              <div className="create-payment-modal__input">
                <div className="create-payment-modal__payment">
                  <IDHFormattedMessage
                    id="ws_second_payment"
                    defaultMessage="Second payment"
                  />
                </div>

                <div>
                  <CustomInputLabel>
                    <IDHFormattedMessage
                      id="ws_amount"
                      defaultMessage="Amount"
                    />
                  </CustomInputLabel>
                </div>
                <div className="create-payment-modal__tags">
                  {typeof selectedPercentage === "number" && (
                    <button
                      className="button-style-reset create-payment-modal__selectable-tag create-payment-modal__selectable-tag--active"
                      onClick={() => setSelectedPercentage(null)}
                      aria-label="Select percentage"
                      type="button"
                    >
                      {((1 - selectedPercentage) * 100).toFixed(0)}%
                      <PlusGreyIcon
                        className="select-tags__selectable-labels__single__add"
                        height={14}
                        width={14}
                      />
                    </button>
                  )}
                </div>
              </div>

              <div className="create-payment-modal__input">
                <div>
                  <CustomInputLabel>
                    <IDHFormattedMessage
                      id="ws_due_date"
                      defaultMessage="Due Date"
                    />
                  </CustomInputLabel>
                </div>
                <div className="task-meta-fields__value">
                  <span className="task-meta-fields__value-content">
                    <div className="value-field">
                      <DatePickerWrapper value={secondPaymentDate}>
                        <CustomDatePicker
                          initialDate={secondPaymentDate}
                          dateChangeHandler={(newDate: string) =>
                            setSecondPaymentDate(newDate)
                          }
                        />
                      </DatePickerWrapper>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          )}
        </ModalRow>
      </div>
    </Modal>
  );
}

export default CreatePaymentModal;
