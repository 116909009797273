import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { DictionaryField } from "src/app/SettingsModal/tabs/DictionaryManager/types";
import { getDictionaryAutoComplete } from "src/redux";
import { tableDataType } from "src/app/components/Table/Table";
import {
  FieldManagerAdditionalOptions,
  FieldManagerAdditionalOptionsKeys,
  FieldManagerFormData,
  FieldManagerFormDataKeys,
} from "../../types";
import DictionarySupportingField from "./DictionarySupportingField";
import DictionaryFilterElements from "./DictionaryFilterElements";

interface DictionarySubFieldsProps {
  editedField?: DictionaryField;
  handleUpdateAdditionalOptions: (
    key: FieldManagerAdditionalOptionsKeys,
    value: FieldManagerAdditionalOptions[FieldManagerAdditionalOptionsKeys],
  ) => void;
  handleUpdateFormData: (
    key: FieldManagerFormDataKeys,
    value: FieldManagerFormData[FieldManagerFormDataKeys],
  ) => void;
  formData: FieldManagerFormData;
  additionalOptions: FieldManagerAdditionalOptions;
  selectedDictionaryUuid: string | null;
  dataType: tableDataType;
}

export default function DictionarySubFields({
  editedField,
  handleUpdateAdditionalOptions,
  handleUpdateFormData,
  selectedDictionaryUuid,
  formData,
  additionalOptions,
  dataType,
}: DictionarySubFieldsProps) {
  const dispatch = useDispatch();

  const { wsDictionarySubField } = formData;
  const { enableDictionarySupportingField, enableFilterElementsFields } =
    additionalOptions;

  useEffect(() => {
    if (selectedDictionaryUuid) {
      dispatch(getDictionaryAutoComplete(selectedDictionaryUuid));
    }
  }, [selectedDictionaryUuid]);

  useEffect(() => {
    if (editedField?.data.wsDictionarySubFieldUuid) {
      handleUpdateAdditionalOptions("enableDictionarySupportingField", true);
    }
    if (editedField?.data?.wsDictionaryElementsFilter) {
      if (
        editedField.data.wsDictionaryElementsFilter
          .wsDictionaryMetaFieldTargetUuid ||
        editedField.data.wsDictionaryElementsFilter
          .wsDictionaryMetaFieldTargetUuid
      ) {
        handleUpdateAdditionalOptions("enableFilterElementsFields", true);
      }
    }
  }, [editedField]);

  return (
    <div className="dictionary-sub-field-wrapper">
      <div className="dictionary-sub-field-wrapper__column">
        <div className="dictionary-sub-field-wrapper__row">
          <div
            className="dictionary-sub-field-wrapper__switch"
            data-qa="dictionary-supporting-field-switch"
          >
            <CustomSwitch
              checked={enableDictionarySupportingField}
              onChange={(e) => {
                const { checked } = e.target;

                if (!checked) {
                  handleUpdateFormData("wsDictionarySubField", null);
                }

                handleUpdateAdditionalOptions(
                  "enableDictionarySupportingField",
                  checked,
                );
              }}
            />
          </div>
          <div className="dictionary-sub-field-wrapper__switch-info">
            <div className="dictionary-sub-field-wrapper__switch-info-header">
              <IDHFormattedMessage
                id="ws_supporting_field_selection"
                defaultMessage="Supporting field"
              />
            </div>
            <div className="dictionary-sub-field-wrapper__switch-info-description">
              <IDHFormattedMessage
                id="ws_supporting_field_selection_description"
                defaultMessage="Allows you to display additional information from other fields related to each other."
              />
            </div>
          </div>
        </div>
        {enableDictionarySupportingField && (
          <DictionarySupportingField
            editedField={editedField}
            handleUpdateFormData={handleUpdateFormData}
            wsDictionarySubField={wsDictionarySubField}
            selectedDictionaryUuid={selectedDictionaryUuid}
          />
        )}

        <div
          className="dictionary-sub-field-wrapper__row"
          data-qa="dictionary-sub-field-filter-elements"
        >
          <div
            className="dictionary-sub-field-wrapper__switch"
            data-qa="dictionary-filter-elements-switch"
          >
            <CustomSwitch
              checked={enableFilterElementsFields}
              onChange={(e) => {
                handleUpdateAdditionalOptions(
                  "enableFilterElementsFields",
                  e.target.checked,
                );
              }}
              disabled={!!editedField}
            />
          </div>

          <div className="dictionary-sub-field-wrapper__switch-info">
            <div className="dictionary-sub-field-wrapper__switch-info-header">
              <IDHFormattedMessage
                id="ws_filter_elements"
                defaultMessage="Filter elements"
              />
            </div>
          </div>
        </div>

        {enableFilterElementsFields && (
          <DictionaryFilterElements
            editedField={editedField}
            dataType={dataType}
            selectedDictionaryUuid={selectedDictionaryUuid}
            handleUpdateFormData={handleUpdateFormData}
            formData={formData}
          />
        )}
      </div>
    </div>
  );
}
