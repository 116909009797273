export const GET_SETTINGS_GLOBAL_FIELDS = "GET_SETTINGS_GLOBAL_FIELDS";
export const GET_SETTINGS_GLOBAL_FIELDS_SUCCESS =
  "GET_SETTINGS_GLOBAL_FIELDS_SUCCESS";

export const CREATE_NEW_TASK_GLOBAL_FIELD = "CREATE_NEW_TASK_GLOBAL_FIELD";
export const CREATE_NEW_TASK_GLOBAL_FIELD_SUCCESS =
  "CREATE_NEW_TASK_GLOBAL_FIELD_SUCCESS";
export const CREATE_NEW_PROJECT_GLOBAL_FIELD =
  "CREATE_NEW_PROJECT_GLOBAL_FIELD";
export const CREATE_NEW_PROJECT_GLOBAL_FIELD_SUCCESS =
  "CREATE_NEW_PROJECT_GLOBAL_FIELD_SUCCESS";

export const SET_RELOAD_TASK_FIELDS = "SET_RELOAD_TASK_FIELDS";
export const SET_RELOAD_PROJECT_FIELDS = "SET_RELOAD_PROJECT_FIELDS";

export const UPDATE_GLOBAL_TASK_FIELD = "UPDATE_GLOBAL_TASK_FIELD";
export const UPDATE_GLOBAL_TASK_FIELD_SUCCESS =
  "UPDATE_GLOBAL_TASK_FIELD_SUCCESS";

export const UPDATE_GLOBAL_PROJECT_FIELD = "UPDATE_PROJECT_TASK_FIELD";
export const UPDATE_GLOBAL_PROJECT_FIELD_SUCCESS =
  "UPDATE_PROJECT_TASK_FIELD_SUCCESS";

export const UPDATE_TASK_GLOBAL_SELECT_FIELD =
  "UPDATE_TASK_GLOBAL_SELECT_FIELD";
export const UPDATE_PROJECT_GLOBAL_SELECT_FIELD =
  "UPDATE_PROJECT_GLOBAL_SELECT_FIELD";

export const UPDATE_GLOBAL_TASK_FIELD_RANK = "UPDATE_GLOBAL_TASK_FIELD_RANK";
export const UPDATE_GLOBAL_PROJECT_FIELD_RANK =
  "UPDATE_GLOBAL_PROJECT_FIELD_RANK";

export const CLEAR_SETTINGS_GLOBAL_FIELDS = "CLEAR_SETTINGS_GLOBAL_FIELDS";
export const GET_DICTIONARY = "GET_DICTIONARY";
export const GET_DICTIONARY_SUCCESS = "GET_DICTIONARY_SUCCESS";
export const CLEAR_DICTIONARY = "CLEAR_DICTIONARY";
