import React from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalColumn, ModalTitle } from "src/app/components/Modal/Modal";
import { SettingsButtons } from "src/app/SettingsModal/components/SettingsTabContent/SettingsTabContent";
import { WsExtension, ExtensionTypeEnum } from "src/types";
import { ChangeSettingsSectionParam } from "src/app/SettingsModal/methods";
import { SettingsTab } from "src/app/SettingsModal/enums";
import ExtensionsListItem from "./ExtensionsListItem";
import ExtensionListLockWsProjectEditing from "./ExtensionsListLockWsProjectEditing";

interface ExtensionsListProps {
  title?: React.ReactNode;
  listHeader?: React.ReactNode;
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  onClose: () => void;
  extensionList: WsExtension[];
  currentSettingsTab: SettingsTab;
  permissions: { project: string[]; workspace: string[] };
  wsProjectUuid: string | null;
  isProjectExtensions: boolean;
}

const getExtensionDataByType = (
  extensionList: WsExtension[],
  extensionType: ExtensionTypeEnum,
) => {
  return extensionList.find((extensionDataItem) => {
    return extensionDataItem.type === extensionType;
  });
};

const displayAllowedExtensions = (
  extensionList: WsExtension[],
  currentSettingsTab: SettingsTab,
) => {
  let ALLOWED_TYPES: ExtensionTypeEnum[] = [];
  switch (currentSettingsTab) {
    case SettingsTab.Integrations:
      ALLOWED_TYPES = [
        ExtensionTypeEnum.XtrmPayments,
        ExtensionTypeEnum.CreatorMessenger,
      ];
      break;
    case SettingsTab.CreatorDatabaseConfiguration:
      ALLOWED_TYPES = [
        ExtensionTypeEnum.CreatorDatabaseSubmissionForm,
        ExtensionTypeEnum.CreatorDatabaseMetaFieldList,
        ExtensionTypeEnum.ApiConnection,
      ];
      break;
    case SettingsTab.Extensions:
      ALLOWED_TYPES = [ExtensionTypeEnum.RecruitmentForm];
      break;
    default:
      ALLOWED_TYPES = [];
      break;
  }
  return extensionList.filter((extension) =>
    ALLOWED_TYPES.includes(extension.type),
  );
};

export default function ExtensionsList({
  title,
  listHeader,
  setExtensionsSection,
  onClose,
  extensionList,
  currentSettingsTab,
  permissions,
  wsProjectUuid,
  isProjectExtensions,
}: ExtensionsListProps) {
  return (
    <>
      <ModalTitle>
        {title ?? (
          <IDHFormattedMessage id="ws_extensions" defaultMessage="Extensions" />
        )}
      </ModalTitle>
      <>
        <ModalColumn>
          {listHeader && (
            <div className="extensions__list-header">{listHeader}</div>
          )}
          <ul className="extensions__list">
            {displayAllowedExtensions(extensionList, currentSettingsTab).map(
              (extension) => (
                <ExtensionsListItem
                  key={extension.type}
                  data={extension}
                  setExtensionsSection={setExtensionsSection}
                  permissions={permissions}
                />
              ),
            )}
            {isProjectExtensions && (
              <ExtensionListLockWsProjectEditing
                extensionData={getExtensionDataByType(
                  extensionList,
                  ExtensionTypeEnum.WsProjectLock || null,
                )}
                permissions={permissions}
                wsProjectUuid={wsProjectUuid}
              />
            )}
          </ul>
        </ModalColumn>
        <SettingsButtons onClose={onClose} />
      </>
    </>
  );
}
