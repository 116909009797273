import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router";
import axios from "axios";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import PageHeader from "src/app/components/PageHeader/PageHeader";
import { SingleProjectSelectedTasksBar } from "src/app/project/SingleProject/components/SingleProjectSelectedTasksBar";
import { RootState } from "src/redux/reducers";
import {
  clearWorkspaceTasks,
  getProjectTasksAutocomplete,
  getWorkspaceTasks,
  setShowTask,
} from "src/redux";
import { SortDirection, TaskType } from "src/types";
import Task from "src/app/Task/Task";
import { Button } from "src/app/components/Button/Button";
import WorkspaceTasksTableWrapper from "src/app/pages/WorkspaceTasksSummary/WorkspaceTasksTableWrapper";
import WebsocketListener from "src/WebsocketListener";
import { ReactComponent as FilterIcon } from "src/images/filter.svg";
import { Pane } from "src/app/components/Pane/Pane";
import { TaskSortingDirection } from "src/redux/taskFilters/types";
import { ReactComponent as DownloadIcon } from "src/images/download.svg";
import { ReactComponent as SwitchesIcon } from "src/images/switches.svg";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import { API_URLS } from "src/utils/API_URLS";
import {
  getDictionaryAutoCompletesForColumns,
  getSelectDataSetForColumns,
  isStringUuid,
  isValueTaskType,
  setQueryParam,
  showErrorToast,
} from "src/utils/methods";
import Tab from "src/app/components/Tabs/Tab";
import Tabs from "src/app/components/Tabs/Tabs";
import {
  refreshDataHubInitialState,
  setWorkspaceTaskFilters,
  setWSTaskActiveSortingColumn,
  setWSTasksColumnRanks,
  setWSTaskSortingDirection,
  setWSTasksSelectedTableView,
  setWSTasksVisibleColumns,
} from "src/redux/workspaceTaskFilters/workspaceTaskFiltersActions";
import WorkspaceTasksSortDropdown from "./WorkspaceTasksSortDropdown";
import WorkspaceTasksFilters from "./WorkspaceTasksFilters";
import { showToast } from "../../methods/showToast";

import "./WorkspaceTasksTable.scss";
import TableViewSelector from "src/app/TableView/TableViewSelector";
import { uuidv7 } from "uuidv7";
import { createDataHubWsTableView } from "src/app/TableView/tableViewFunctions";
import { SelectOption } from "src/app/modals/ManageFieldModal/SocialMetrics";
import { useQuery } from "src/app/methods/useQuery";
import { GlobalTaskDetails } from "src/app/components/GlobalTaskDetails/GlobalTaskDetails";
import { Column } from "src/redux/dictionary/types";
import MetaDataOptionsDropdown, {
  MetaDataOptionsContext,
} from "src/app/components/TableOptionsDropdown/MetaDataOptionsDropdown";
import {
  generateLackingRanks,
  generateMetadataRanks,
} from "src/app/CreatorDatabase/redux/creatorDatabaseFiltersReducer";
import _ from "lodash";
import { mockWorkspaceTasks } from "src/redux/task/mockTasks";
import { DataHubNoColumns } from "src/app/pages/WorkspaceTasksSummary/components/DataHubNoColumns/DataHubNoColumns";
import classNames from "classnames";

const INITIAL_TAB = TaskType.Creator;

export const mapSortingEnums = {
  [TaskSortingDirection.Ascending]: SortDirection.Ascending,
  [TaskSortingDirection.Descending]: SortDirection.Descending,
};

export default function WorkspaceTasksSummary() {
  const [showFilters, setShowFilters] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [taskType, setTaskType] = useState<TaskType>();
  const [columnsWithoutSkeleton, setColumnsWithoutSkeleton] = useState<
    Column[]
  >([]);
  const [initialRankGenerationDone, setInitialRankGenerationDone] =
    useState(false);

  const { showTask } = useSelector((state: RootState) => state.projectReducer);
  const query = useQuery();
  const showGlobalTask = isStringUuid(query.get("displayGlobalTask") || "");

  const {
    filters,
    selectedTableView,
    activeSortingColumn,
    sortingDirection,
    visibleColumns,
    columnRanks,
  } = useSelector((state: RootState) => state.workspaceTaskFiltersReducer);

  const {
    workspaceTasks,
    workspaceTasksColumns,
    areWorkspaceTasksLoading,
    wsTableViews,
  } = useSelector((state: RootState) => state.taskReducer);

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const dispatch = useDispatch();
  const params = useParams<{
    taskId: string | undefined;
  }>();

  const { search } = useLocation();
  const history = useHistory();

  const changeTab = (newTab: TaskType) => {
    if (newTab === taskType) {
      return;
    }

    setTimeout(() => {
      dispatch(clearWorkspaceTasks());
      setQueryParam(history, "taskType", newTab);
      setInitialRankGenerationDone(false);
      dispatch(refreshDataHubInitialState());
    }, 50);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const paramTaskType = searchParams.get("taskType");

    if (isValueTaskType(paramTaskType)) {
      setQueryParam(history, "taskType", paramTaskType);
      setTaskType(paramTaskType);
    } else {
      setQueryParam(history, "taskType", INITIAL_TAB);
      setTaskType(INITIAL_TAB);
    }
  }, [search, history]);

  const getXlsxReport = async () => {
    setIsDownloadingReport(true);
    try {
      if (!taskType) {
        throw new Error("Task type is not defined");
      }

      const url = API_URLS.getXlsxReportFileForTaskType
        .replace(":wsWorkspaceUuid:", activeWorkspaceUuid)
        .replace(":taskType:", taskType);
      await axios.get(url, {
        params: {
          filters,
          sortBy: activeSortingColumn,
          sort: mapSortingEnums[sortingDirection].toLowerCase(),
          wsGlobalTaskMetaFieldsUuids: visibleColumns,
        },
      });

      showToast(
        "success",
        <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
        <IDHFormattedMessage
          id="ws_report_has_been_queued_sent_email"
          defaultMessage="The report has been queued for sending via email"
        />,
      );
    } catch (error) {
      console.error(error);
      showErrorToast();
    } finally {
      setIsDownloadingReport(false);
    }
  };

  const columnsSortedByRankAndFiltered = useMemo(() => {
    const columnsCopy = [...workspaceTasksColumns];

    const metaFieldUuidsToHide: string[] = [];

    workspaceTasks.forEach((task) => {
      const foundMetaField = task.metadata.find(
        (metaField) => metaField.isSystem,
      );
      if (foundMetaField && foundMetaField.wsGlobalTaskMetaFieldUuid) {
        if (
          !metaFieldUuidsToHide.includes(
            foundMetaField.wsGlobalTaskMetaFieldUuid,
          )
        ) {
          metaFieldUuidsToHide.push(foundMetaField.wsGlobalTaskMetaFieldUuid);
        }
      }
    });

    const columnsSortedAndFiltered =
      columnsCopy
        .filter(
          (column) => visibleColumns?.includes(column.uuid) && !column.isSystem,
        )
        ?.sort((a, b) => {
          const rankA = columnRanks?.find((col) => col?.uuid === a?.uuid)?.rank;
          const rankB = columnRanks?.find((col) => col?.uuid === b?.uuid)?.rank;
          if (rankA && rankB) return rankA?.localeCompare(rankB);
          return 0;
        }) || [];

    if (!areWorkspaceTasksLoading) {
      setColumnsWithoutSkeleton(columnsSortedAndFiltered);
    }

    return columnsSortedAndFiltered;
  }, [
    workspaceTasksColumns,
    workspaceTasks,
    areWorkspaceTasksLoading,
    visibleColumns,
    columnRanks,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramTaskType = searchParams.get("taskType");
    if (!isValueTaskType(paramTaskType)) return;
    dispatch(
      getWorkspaceTasks({
        wsWorkspaceUuid: activeWorkspaceUuid,
        filters,
        sortBy: activeSortingColumn,
        sort: mapSortingEnums[sortingDirection],
        taskType: paramTaskType,
        visibleColumns,
      }),
    );
  }, [filters, activeSortingColumn, sortingDirection, visibleColumns]);

  const colsString = JSON.stringify(
    workspaceTasksColumns.map((column) => ({
      type: column.type,
      options: column.data,
    })),
  );

  useEffect(() => {
    const cols = JSON.parse(colsString);
    getDictionaryAutoCompletesForColumns(dispatch, cols);
    getSelectDataSetForColumns(dispatch, cols);
  }, [colsString]);

  const projectUuids = useMemo(() => {
    const autocompleteTypes = ["creator", "publicationRelated", "content"];

    const uuids: string[] = [];
    [...workspaceTasks]
      .sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
      )
      .forEach((task) => {
        const notDuplicate = !uuids.includes(task.wsProjectUuid);
        const includesAutocompleteType = task?.metadata?.find((item) =>
          autocompleteTypes.includes(item.type),
        );

        if (notDuplicate && includesAutocompleteType) {
          uuids.push(task.wsProjectUuid);
        }
      });
    return uuids.filter(Boolean);
  }, [workspaceTasks]);

  const projectUuidsStringified = JSON.stringify(projectUuids);

  useEffect(() => {
    const getTasksAutocompleteForAllProjects = () => {
      const projectUuidsParsed: string[] = JSON.parse(projectUuidsStringified);

      if (projectUuidsParsed.length) {
        projectUuidsParsed.forEach((projectUuid) => {
          dispatch(getProjectTasksAutocomplete(projectUuid));
        });
      }
    };

    getTasksAutocompleteForAllProjects();
  }, [projectUuidsStringified]);

  useEffect(() => {
    if (params.taskId) {
      dispatch(setShowTask(true));
    } else {
      dispatch(setShowTask(false));
    }
  }, [params.taskId]);

  useEffect(() => {
    let dataHubSettingsObject;
    try {
      const projectsListSettingsJSON = localStorage.getItem(
        `ws-data-hub-settings-${taskType}`,
      );

      if (projectsListSettingsJSON) {
        dataHubSettingsObject = JSON.parse(projectsListSettingsJSON);
      }
    } catch {
      console.error(
        `Error parsing json from key: ws-data-hub-settings-${taskType}`,
      );
    }

    const settings = dataHubSettingsObject
      ? dataHubSettingsObject[activeWorkspaceUuid]
      : null;

    if (!settings?.visibleColumns && workspaceTasksColumns?.length) {
      const initialHiddenColumns = workspaceTasksColumns.map(
        (item) => item.uuid,
      );

      if (
        initialHiddenColumns.length &&
        isStringUuid(initialHiddenColumns[0])
      ) {
        dispatch(setWSTasksVisibleColumns([]));
      }
    }

    if (initialRankGenerationDone) return;

    if (
      isStringUuid(workspaceTasksColumns[0]?.uuid) &&
      !settings?.columnRanks?.length
    ) {
      dispatch(
        setWSTasksColumnRanks(generateMetadataRanks(workspaceTasksColumns)),
      );
      setInitialRankGenerationDone(true);
    } else if (
      isStringUuid(workspaceTasksColumns[0]?.uuid) &&
      workspaceTasksColumns.length !== columnRanks.length
    ) {
      dispatch(
        setWSTasksColumnRanks(
          generateLackingRanks(workspaceTasksColumns, settings?.columnRanks),
        ),
      );
      setInitialRankGenerationDone(true);
    }
  }, [
    activeWorkspaceUuid,
    initialRankGenerationDone,
    workspaceTasksColumns,
    columnRanks,
    taskType,
  ]);

  const handleRestoreDefault = () => {
    const lsItem = localStorage.getItem(`ws-data-hub-settings-${taskType}`);

    if (!lsItem) {
      return;
    }

    const settingsData = lsItem ? JSON.parse(lsItem) : null;
    delete settingsData[activeWorkspaceUuid];

    localStorage.setItem(
      `ws-data-hub-settings-${taskType}`,
      JSON.stringify(settingsData),
    );

    dispatch(setWSTasksVisibleColumns([]));
    dispatch(
      setWSTasksColumnRanks(generateMetadataRanks(workspaceTasksColumns), true),
    );
    setInitialRankGenerationDone(true);
  };

  const saveTableView = async (name: string) => {
    if (!taskType) return null;

    const visibleColumnsForView = visibleColumns?.map((uuid) => ({
      uuid,
      exampleKey: "aaaa",
    }));

    const uuid = uuidv7();

    await createDataHubWsTableView({
      filters,
      sortBy: activeSortingColumn,
      sort: sortingDirection,
      wsWorkspaceUuid: activeWorkspaceUuid,
      visibleColumns: visibleColumnsForView,
      taskType,
      name,
      uuid,
    });
    dispatch(
      getWorkspaceTasks({
        wsWorkspaceUuid: activeWorkspaceUuid,
        filters,
        sortBy: activeSortingColumn,
        sort: mapSortingEnums[sortingDirection],
        taskType,
        visibleColumns,
      }),
    );
    dispatch(
      setWSTasksSelectedTableView({
        label: name,
        value: uuid,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearWorkspaceTasks());
    };
  }, []);

  const openSettingsDropdown = () => {
    const button = document.querySelector(
      ".data-hub-settings-button",
    ) as HTMLButtonElement;
    button?.click();
  };

  const noColumns = columnsSortedByRankAndFiltered?.length === 0;

  return (
    <AnimatedDiv
      className={classNames("workspace-tasks-table", "scroll-fixed", {
        "workspace-tasks-table--no-columns":
          noColumns && !areWorkspaceTasksLoading,
      })}
    >
      <div className="workspace-tasks-table__topbar">
        <PageHeader
          className="workspace-tasks-table__header"
          title={
            <IDHFormattedMessage id="ws_data_hub" defaultMessage="Data Hub" />
          }
          description={
            <IDHFormattedMessage
              id="ws_data_hub_description"
              defaultMessage="Here you can access and manage all campaign data, organized into different types."
            />
          }
        />
        <div className="workspace-tasks-table__tabs">
          <Tabs>
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_creators"
                  defaultMessage="Creators"
                />
              }
              active={taskType === TaskType.Creator}
              onClick={() => changeTab(TaskType.Creator)}
            />
            <Tab
              tabText={
                <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
              }
              active={taskType === TaskType.Content}
              onClick={() => changeTab(TaskType.Content)}
            />
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_publications"
                  defaultMessage="Publications"
                />
              }
              active={taskType === TaskType.Publication}
              onClick={() => changeTab(TaskType.Publication)}
            />
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_payments"
                  defaultMessage="Payments"
                />
              }
              active={taskType === TaskType.Payment}
              onClick={() => changeTab(TaskType.Payment)}
            />
            <Tab
              tabText={
                <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
              }
              active={taskType === TaskType.Action}
              onClick={() => changeTab(TaskType.Action)}
            />
            <div className="workspace-tasks-table__toolbar">
              <Button
                variant="white"
                size="small"
                textWithIcon
                onClick={() => setShowFilters((prev) => !prev)}
                data-qa="filter-button"
                disabled={areWorkspaceTasksLoading}
              >
                <FilterIcon />
                <span>
                  <IDHFormattedMessage id="ws_filter" defaultMessage="Filter" />
                  {Object.keys(filters).length > 0 &&
                    `: ${Object.keys(filters).length}`}
                </span>
              </Button>

              <WorkspaceTasksSortDropdown
                columns={columnsWithoutSkeleton}
                sortBy={activeSortingColumn}
                buttonDisabled={areWorkspaceTasksLoading}
              />

              <TableViewSelector
                wsTableViews={wsTableViews}
                selectedWsTableView={selectedTableView}
                setSelectedWsTableView={(newValue: SelectOption) =>
                  dispatch(setWSTasksSelectedTableView(newValue))
                }
                setFilters={(
                  newFilters: {
                    filters: Record<string, { value: any }>;
                  },
                  skipLS: boolean,
                ) => dispatch(setWorkspaceTaskFilters(newFilters, skipLS))}
                setHiddenOrVisibleColumnIds={(
                  newVisibleColumns: string[],
                  skipLS: boolean,
                ) =>
                  dispatch(setWSTasksVisibleColumns(newVisibleColumns, skipLS))
                }
                setSortBy={(newValue: string | null, skipLS: boolean) =>
                  dispatch(setWSTaskActiveSortingColumn(newValue, skipLS))
                }
                setSort={(newValue: string, skipLS: boolean) =>
                  dispatch(setWSTaskSortingDirection(newValue, skipLS))
                }
                columnIds={[]}
                localStorageKey={`ws-data-hub-settings-${taskType}`}
                saveTableViewFunction={saveTableView}
                reloadDataFunction={() => {
                  if (taskType) {
                    dispatch(
                      getWorkspaceTasks({
                        wsWorkspaceUuid: activeWorkspaceUuid,
                        filters,
                        sortBy: activeSortingColumn,
                        sort: mapSortingEnums[sortingDirection],
                        taskType,
                        visibleColumns,
                      }),
                    );
                  }
                }}
                buttonDisabled={areWorkspaceTasksLoading}
                useVisibleColumns
              />

              <Button
                variant="white"
                size="small"
                textWithIcon
                onClick={getXlsxReport}
                disabled={isDownloadingReport || areWorkspaceTasksLoading}
              >
                <DownloadIcon />
                <IDHFormattedMessage
                  id="ws_download_xlsx"
                  defaultMessage="Download XLSX"
                />
              </Button>

              <MetaDataOptionsDropdown
                context={MetaDataOptionsContext.DataHub}
                fields={workspaceTasksColumns.filter(
                  (column) => !column.isSystem,
                )}
                fieldRanks={columnRanks}
                hiddenColumns={workspaceTasksColumns
                  .filter((item) => !visibleColumns?.includes(item.uuid))
                  .map((item) => item.uuid)}
                dispatchFunction={dispatch}
                resetToDefaultSettings={handleRestoreDefault}
                disabled={areWorkspaceTasksLoading}
                buttonClassName="data-hub-settings-button"
              />
            </div>
          </Tabs>
        </div>
      </div>

      <WorkspaceTasksTableWrapper
        taskType={taskType}
        columns={
          areWorkspaceTasksLoading || noColumns
            ? mockWorkspaceTasks.columns
            : columnsSortedByRankAndFiltered
        }
        noColumns={noColumns}
      />

      {!columnsSortedByRankAndFiltered.length && !areWorkspaceTasksLoading && (
        <DataHubNoColumns
          image={<SwitchesIcon />}
          upperText={
            <IDHFormattedMessage
              id="ws_no_columns_selected_for_display"
              defaultMessage="No columns selected for display."
            />
          }
          lowerText={
            <IDHFormattedMessage
              id="ws_please_select_table_columns_that_you_want_to_see_on_this_tab"
              defaultMessage="Please select table columns that you want to see on this tab."
            />
          }
          bottomContent={
            <Button size="large" variant="blue" onClick={openSettingsDropdown}>
              <PlusIcon />
              <IDHFormattedMessage
                id="ws_select_table_columns"
                defaultMessage="Select table columns"
              />
            </Button>
          }
        />
      )}

      <Pane
        showPane={showFilters}
        hidePane={() => setShowFilters(false)}
        width={309}
      >
        <WorkspaceTasksFilters
          setFiltersVisible={setShowFilters}
          columns={columnsWithoutSkeleton}
          taskType={taskType}
        />
      </Pane>
      {showTask && <Task onCloseHandler={() => dispatch(setShowTask(false))} />}
      {showGlobalTask && <GlobalTaskDetails />}

      <SingleProjectSelectedTasksBar tableContext="workspaceTasksList" />

      <WebsocketListener />
    </AnimatedDiv>
  );
}
