import React, { useEffect, useState } from "react";
import "./Sidebar.scss";

import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { ReactComponent as LogoIcon } from "src/images/menu-icons/idh-logo.svg";
import { RootState } from "src/redux/reducers";
import { isValueTaskType } from "src/utils/methods";
import { getWorkspacesList } from "src/redux/main/mainActions";
import { AppConfig } from "../../config/AppConfig";

import { ReactComponent as ArabyAdsIcon } from "./images/araby-ads.svg";
import { ReactComponent as LogotypeIcon } from "./images/indahash-full.svg";
import {
  SidebarElement,
  SidebarItemTypes,
  getSidebarElement,
} from "./SidebarItems";

import SidebarItem from "./SidebarItem";

const Sidebar: React.FC = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);
  const dispatch = useDispatch();
  const envName = AppConfig.getEnvironmentName();
  const { boot, isOpen } = useIntercom();

  const {
    mainReducer: { identity, activeWorkspaceUuid, workspacesList },
  } = useSelector((state: RootState) => state);

  const baseUrl = `/workspace/${activeWorkspaceUuid}`;

  const workspaceData = workspacesList.find(
    (workspace) => workspace.uuid === activeWorkspaceUuid,
  );

  const sidebarElements: Array<SidebarElement> = identity
    ? identity.sidebarItems
        .map((item: SidebarItemTypes) => getSidebarElement(item))
        .filter(Boolean)
    : [];

  useEffect(() => {
    if (identity?.id && identity?.email && identity?.intercomId) {
      try {
        boot({
          name: identity?.name,
          email: identity.email,
          alignment: "left",
          horizontalPadding: 20,
          verticalPadding: 20,
          backgroundColor: "#5d78ff",
          customAttributes: {
            user_id: identity?.intercomId ?? "",
            articles_template: "workspace",
          },
        });
      } catch (error: any) {
        console.log("[WS] Fail to init intercom");
      }
    }
  }, [identity]);

  useEffect(() => {
    dispatch(getWorkspacesList());
  }, []);

  return (
    <aside
      className={classNames("sidebar", {
        "sidebar--opened": isOpen,
      })}
      onMouseEnter={() => setIsOpenSidebar(true)}
      onMouseLeave={() => setIsOpenSidebar(false)}
    >
      <nav role="navigation" aria-label="primary">
        <Link to="/workspace/">
          {workspaceData?.logo ? (
            <div
              className={classNames("sidebar__brand-logo", {
                "sidebar__brand-logo--hover": isOpenSidebar,
              })}
            >
              {isOpenSidebar ? (
                <img
                  src={workspaceData.logo}
                  className="sidebar__brand-logo-full"
                />
              ) : (
                <img
                  src={workspaceData.smallLogo ?? workspaceData.logo}
                  className="sidebar__brand-logo-small"
                />
              )}
            </div>
          ) : (
            <>
              {workspaceData ? (
                <div
                  className={classNames("sidebar__logo", {
                    "sidebar__logo--local":
                      envName === "local" || envName === "localdev",
                    "sidebar__logo--dev": envName === "dev",
                    "sidebar__logo--beta": envName === "beta",
                    "sidebar__logo--stage1": envName === "stage1",
                  })}
                >
                  <LogoIcon className="sidebar__logo-shape" />
                  <ArabyAdsIcon className="sidebar__logo-araby-ads" />
                  <LogotypeIcon className="sidebar__logo-word" />
                </div>
              ) : (
                <div className="sidebar__brand-logo-empty" />
              )}
            </>
          )}
        </Link>
        <ul className="sidebar__list">
          {sidebarElements.map((wsSidebarElement) => {
            let path;

            if (isValueTaskType(wsSidebarElement.path)) {
              path = `${baseUrl}/summary`;
            } else {
              path = `${baseUrl}/${wsSidebarElement.path}`;
            }

            return (
              <SidebarItem
                key={wsSidebarElement.key}
                to={path}
                messageId={wsSidebarElement.translateKey}
                defaultMessage={wsSidebarElement.defaultMessage}
                overrideHtml={wsSidebarElement.overrideHtml}
              >
                {wsSidebarElement.icon}
              </SidebarItem>
            );
          })}
        </ul>

        <ul className="sidebar__list sidebar__list--bottom">
          {/* }<SidebarItem
            onClick={() => {
              if (isOpen === true) {
                hide();
              } else {
                show();
              }
            }}
            messageId="ws_support"
            defaultMessage="Support"
          >
            <div className="sidebar__intercom-icon">
              <IntercomIcon />
              <span className="sidebar__intercom-icon-counter">{intercomUnreadMessages}</span>
            </div>
          </SidebarItem> */}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
