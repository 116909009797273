import { Column } from "../dictionary/types";
import { ITask } from "./taskReducer";

export const generateLackingMetaFieldData = (
  tasks: ITask[],
  columns: Column[],
) =>
  tasks.map((item) => {
    return {
      ...item,
      metadata: item.metadata.map((field) => {
        const column = columns.find(
          (c) => c.uuid === field.wsGlobalTaskMetaFieldUuid,
        );

        if (column)
          return {
            ...field,
            data: {
              ...field.data,
              singleSelectOptions: column.data.singleSelectOptions,
            },
          };

        return field;
      }),
    };
  });
