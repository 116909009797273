import { EntitlementFeature, EntitlementResetPeriod } from '../models';
import { AccessDeniedReason, ResetPeriodConfigurationFragment } from '@stigg/api-client-js/src/generated/sdk';

export interface CalculatedEntitlement {
  usageLimit?: number | null;
  hasUnlimitedUsage: boolean;
  hasSoftLimit: boolean;
  feature?: EntitlementFeature;
  accessDeniedReason?: AccessDeniedReason;
}

export interface EntitlementUsage {
  currentUsage: number;
  usagePeriodAnchor?: Date;
  usagePeriodStart?: Date;
  usagePeriodEnd?: Date;
  resetPeriod?: EntitlementResetPeriod | null;
  resetPeriodConfiguration?: ResetPeriodConfigurationFragment | null;
}

export default class CachedEntitlement {
  readonly calculatedEntitlement: CalculatedEntitlement;
  readonly featureUsage: EntitlementUsage;

  constructor(calculatedEntitlement: CalculatedEntitlement, featureUsage: EntitlementUsage) {
    this.calculatedEntitlement = calculatedEntitlement;
    this.featureUsage = featureUsage;
  }
}
