import { DraggableLocation } from "react-beautiful-dnd";
import { generateRankString } from "src/utils/rankStrings";

export const createNewRankStringForArrayItem = (
  destination: DraggableLocation,
  source: DraggableLocation,
  list: { uuid: string; rank: string }[],
) => {
  const targetField = list[destination.index];
  const fieldIndex = list.findIndex((field) => field.uuid === targetField.uuid);

  let newRank = "";
  if (destination.index < source.index) {
    const prevFieldRank = list[fieldIndex - 1]?.rank || "";
    const nextFieldRank = list[fieldIndex].rank;

    newRank = generateRankString(prevFieldRank, nextFieldRank);
    // move bottom
  } else {
    const prevFieldRank = list[fieldIndex].rank;
    const nextFieldRank = list[fieldIndex + 1]?.rank || "";

    newRank = generateRankString(prevFieldRank, nextFieldRank);
  }

  return newRank;
};

export const sortByRank = (a: { rank: string }, b: { rank: string }) =>
  a.rank.localeCompare(b.rank);
