import CachedEntitlement from './cachedEntitlement';
import { AccessDeniedReason, FeatureType, MeterType } from '../models';

export class EntitlementDecisionService {
  public static decideEntitlementPolicy(entitlement?: CachedEntitlement | null, requestUsage = 0): Decision {
    if (!entitlement || !entitlement.calculatedEntitlement.feature) {
      return { hasAccess: false, accessDeniedReason: AccessDeniedReason.NoFeatureEntitlementInSubscription };
    }

    if (entitlement.calculatedEntitlement.accessDeniedReason === AccessDeniedReason.BudgetExceeded) {
      return { hasAccess: false, accessDeniedReason: AccessDeniedReason.BudgetExceeded };
    }

    const { featureType } = entitlement.calculatedEntitlement.feature;

    switch (featureType) {
      case FeatureType.Boolean:
        return { hasAccess: true };
      case FeatureType.Number: {
        if (entitlement.calculatedEntitlement.feature?.meterType === MeterType.None) {
          return { hasAccess: true };
        }
        if (entitlement.calculatedEntitlement.hasUnlimitedUsage || entitlement.calculatedEntitlement.hasSoftLimit) {
          return { hasAccess: true };
        }

        const usageLimit = entitlement.calculatedEntitlement?.usageLimit || 0;
        const currentUsage = entitlement.featureUsage.currentUsage;

        if (currentUsage + requestUsage <= usageLimit) {
          return { hasAccess: true };
        } else {
          return { hasAccess: false, accessDeniedReason: AccessDeniedReason.RequestedUsageExceedingLimit };
        }
      }
    }
  }
}

export interface Decision {
  readonly hasAccess: boolean;
  readonly accessDeniedReason?: AccessDeniedReason;
}
