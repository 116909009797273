import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uuidv7 as uuid } from "uuidv7";
import axios from "axios";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { showToast } from "src/app/methods/showToast";
import { getProjectsList, openTrialExceededModal } from "src/redux";
import { translateMessage } from "src/app/methods/translateMessage";
import { SocialProfile } from "src/app/modals/AddSocialProfilesModal/AddSocialProfilesModal";
import responseCodes from "src/utils/responseCodes";
import { RootState } from "src/redux/reducers";
import { SkeletonText } from "src/app/components/Skeleton/Skeleton";
import Modal, {
  ModalRow,
  ModalText,
  ModalTitle,
} from "../../components/Modal/Modal";
import CustomSelect, {
  ImageOption,
} from "../../components/CustomSelect/CustomSelect";
import { API_URLS } from "../../../utils/API_URLS";
import { ReactComponent as PlusIcon } from "../../../images/plus-alt.svg";
import { ReactComponent as RocketIcon } from "../../../images/rocket.svg";

import "./AddCreatorsCDTWorkspaceProjectModal.scss";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { MetaFieldType } from "../../methods/getMetaFieldTypeOptions";
import { isFacebookNumericUsername, SocialProvider } from "../../../types";
import { setStiggPaymentErrors } from "src/redux/main/mainActions";

export const skeletonOptions = [
  {
    value: "skeleton-1",
    label: (
      <span className="image-select-option">
        <SkeletonText height={20} width={180} />
      </span>
    ),
  },
  {
    value: "skeleton-2",
    label: (
      <span className="image-select-option">
        <SkeletonText height={20} width={180} />
      </span>
    ),
  },
  {
    value: "skeleton-3",
    label: (
      <span className="image-select-option">
        <SkeletonText height={20} width={180} />
      </span>
    ),
  },
];

const selectedWsProjectDefaultValue = {
  label: "",
  value: null,
};

interface CampaignOption {
  label: string;
  value: string | null;
}

export interface SelectedCreator {
  name: string;
  uuid?: string;
  socialProfiles?: SocialProfile[];
  rank?: string | null;
  displayName?: string | null;
}

interface Props extends WrappedComponentProps<"intl"> {
  onClose: () => void;
  openCreateNewProjectModal: () => void;
  selectedCreators: SelectedCreator[];
  activeProjectUuid?: string | null;
  intl: IntlShape;
}

const AddCreatorsCDTWorkspaceProjectModal: React.FC<Props> = (props) => {
  const {
    onClose,
    activeProjectUuid,
    selectedCreators,
    openCreateNewProjectModal,
    intl,
  } = props;

  const [selectedWsProject, setSelectedWsProject] = useState<CampaignOption>(
    selectedWsProjectDefaultValue,
  );
  const [loading, setLoading] = useState(false);
  const [copyFieldValues, setCopyFieldValues] = useState(false);

  const dispatch = useDispatch();

  const {
    projectReducer: { projectsList, projectLoading },
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const onChangeProjectUuid = (newValue: CampaignOption) => {
    setSelectedWsProject(newValue);
  };

  const mapProjectsToOptions = () => {
    return projectsList
      .filter((project) => {
        if (typeof activeProjectUuid === "string") {
          return project.projectId !== activeProjectUuid;
        }
        return true;
      })
      .map((project) => ({
        value: project.projectId,
        label: project.projectName,
      }));
  };

  const addCreatorsToWorkspaceProject = () => {
    if (activeProjectUuid) {
      copyTaskToWsProjectWithValues();
    } else {
      copyTaskToWsProject();
    }
  };

  const copyTaskToWsProject = () => {
    if (selectedWsProject && selectedWsProject.value) {
      const tasksData = selectedCreators.map((creator) => {
        const isFacebookNumericName =
          creator.socialProfiles &&
          creator.socialProfiles[0].socialProvider ===
            SocialProvider.Facebook &&
          isFacebookNumericUsername(creator.name);

        if (creator.socialProfiles) {
          return {
            uuid: uuid(),
            name: creator.name,
            socialProfiles: creator.socialProfiles,
            rank: null,
            displayName: isFacebookNumericName ? creator?.displayName : null,
          };
        }

        return {
          uuid: uuid(),
          name: creator,
          rank: null,
          displayName: isFacebookNumericName ? creator?.displayName : null,
        };
      });

      const payload = {
        wsTaskWorkspaceUuid: activeWorkspaceUuid,
        projectId: selectedWsProject.value,
        taskType: "creator",
        tasksData,
      };

      setLoading(true);

      axios
        .post(API_URLS.createMultipleTasks, payload)
        .then(() => {
          onClose();
          showToast(
            "success",
            "Success",
            translateMessage({
              intl,
              id: "ws_creators_has_been_added_to_campaign",
              defaultMessage:
                "Creators has been added to the selected campaign.",
            }),
          );
        })
        .catch((err) => {
          if (
            err?.response.data.statusCode ==
            responseCodes["402_PAYMENT_REQUIRED"]
          ) {
            onClose();
            dispatch(openTrialExceededModal());
            dispatch(setStiggPaymentErrors(err.response.data.errors));
          } else {
            showToast(
              "error",
              <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
              translateMessage({
                intl,
                id: "ws_creators_has_been_added_to_campaign-",
                defaultMessage:
                  "Error on adding Creators to the selected campaign.",
              }),
            );
          }

          setLoading(false);
        });
    }
  };

  const copyTaskToWsProjectWithValues = () => {
    if (selectedWsProject && selectedWsProject.value) {
      const tasksData = selectedCreators.map((creator: SelectedCreator) => {
        const isFacebookNumericName =
          creator.socialProfiles &&
          creator.socialProfiles[0].socialProvider ===
            SocialProvider.Facebook &&
          isFacebookNumericUsername(creator.name);

        if (creator.socialProfiles) {
          return {
            uuid: creator.uuid,
            name: creator.name,
            socialProfiles: creator.socialProfiles,
            rank: null,
            displayName: isFacebookNumericName ? creator?.displayName : null,
          };
        }

        return {
          uuid: creator.uuid,
          name: creator,
          rank: null,
          displayName: isFacebookNumericName ? creator?.displayName : null,
        };
      });

      const payload = {
        wsWorkspaceUuid: activeWorkspaceUuid,
        sourceWsProjectUuid: activeProjectUuid,
        targetWsProjectUuid: selectedWsProject.value,
        taskType: MetaFieldType.Creator,
        copyFieldValues,
        tasksData,
      };

      setLoading(true);

      axios
        .post(API_URLS.copySelectedTasksToProject, payload)
        .then(() => {
          onClose();
          showToast(
            "success",
            "Success",
            translateMessage({
              intl,
              id: "ws_creators_has_been_added_to_campaign",
              defaultMessage:
                "Creators has been added to the selected campaign.",
            }),
          );
        })
        .catch((err) => {
          if (
            err?.response.data.statusCode ==
            responseCodes["402_PAYMENT_REQUIRED"]
          ) {
            onClose();
            dispatch(openTrialExceededModal());
            dispatch(setStiggPaymentErrors(err.response.data.errors));
          } else {
            showToast(
              "error",
              <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
              translateMessage({
                intl,
                id: "ws_error_adding_creating_to_the_selected_campaign",
                defaultMessage:
                  "Error on adding Creators to the selected campaign.",
              }),
            );
          }

          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!projectsList.length) {
      dispatch(getProjectsList(activeWorkspaceUuid));
    }
  }, []);

  return (
    <Modal
      className="share-project-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={translateMessage({
        intl,
        id: "ws_cancel",
        defaultMessage: "Cancel",
      })}
      onConfirmClick={addCreatorsToWorkspaceProject}
      confirmButtonLoading={loading}
      confirmButtonDisabled={selectedWsProject.value === null}
    >
      <ModalTitle>
        <IDHFormattedMessage
          id="ws_select_campaign"
          defaultMessage="Select campaign"
        />
        &nbsp;
        <RocketIcon />
      </ModalTitle>
      <ModalText>
        <IDHFormattedMessage
          id="ws_select_campaign_to_which_you_want_to_add_creators"
          defaultMessage="Select the campaign to which you want to add selected Creators."
        />
      </ModalText>
      <ModalRow>
        <CustomSelect
          id="projectUuid"
          value={selectedWsProject}
          onChange={(newValue: CampaignOption) => {
            if (!projectLoading) {
              onChangeProjectUuid(newValue);
            }
          }}
          options={projectLoading ? skeletonOptions : mapProjectsToOptions()}
          isSearchable
          afterMenuList={
            <div
              className="create-project-wrap"
              onClick={openCreateNewProjectModal}
            >
              <ImageOption icon={PlusIcon} className="create-project">
                <IDHFormattedMessage
                  id="ws_create_new_campaign"
                  defaultMessage="Create new campaign"
                />
              </ImageOption>
            </div>
          }
          placeholder={translateMessage({
            intl,
            id: "ws_select_campaign",
            defaultMessage: "Select Campaign",
          })}
          filterOption={(candidate: any, input: string) => {
            if (candidate.label.props) return true;
            return (
              candidate.label.toLowerCase().includes(input.toLowerCase()) ||
              candidate.value === "createProject"
            );
          }}
        />
      </ModalRow>
      {activeProjectUuid && selectedWsProject?.value && (
        <ModalRow>
          <CustomCheckbox
            id="copy_task_values"
            name="copy_task_values"
            className="share-project-modal__copy-values-checkbox"
            checked={copyFieldValues}
            label={translateMessage({
              intl,
              id: "ws_copy_task_values",
              defaultMessage: "Copy field values",
            })}
            onChange={() => setCopyFieldValues(!copyFieldValues)}
          />
        </ModalRow>
      )}
    </Modal>
  );
};

export default injectIntl(AddCreatorsCDTWorkspaceProjectModal);
