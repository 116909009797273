import axios from "axios";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Draggable, DropResult, DroppableProvided } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { uuidv7 } from "uuidv7";
import { useIntl } from "react-intl";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { Button } from "src/app/components/Button/Button";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Loader from "src/app/components/Loader/Loader";
import FieldsList from "src/app/modals/components/FieldsList/FieldsList";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { wsAxiosGet } from "src/helpers/wsAxios";
import { ReactComponent as CopyIcon } from "src/images/duplicate-gray.svg";
import { ReactComponent as EditIcon } from "src/images/edit-gray.svg";
import { ReactComponent as GridIcon } from "src/images/grid.svg";
import { ReactComponent as PlusIcon } from "src/images/plus-transparent.svg";
import { ReactComponent as TrashCanIcon } from "src/images/trash-can-gray.svg";
import { RootState } from "src/redux/reducers";
import { API_URLS } from "src/utils/API_URLS";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import { generateRankString } from "src/utils/rankStrings";
import responseCodes from "src/utils/responseCodes";
import {
  sortByRank,
  createNewRankStringForArrayItem,
} from "src/app/modals/components/FieldsList/utils";
import { DEFAULT_RANK } from "src/constants/ranks";
import { translateMessage } from "src/app/methods/translateMessage";
import ExtensionView from "../Extensions/ExtensionView";
import { Dictionary } from "./types";
import { handleUpdateDictionary, handleUpdatetDictionaryRank } from "./utils";

interface DictionaryListProps {
  setEditedDictionary: Dispatch<SetStateAction<Dictionary | null>>;
}

function DictionaryList({ setEditedDictionary }: DictionaryListProps) {
  const [dictionariesList, setDictionariesList] = useState<Array<Dictionary>>(
    [],
  );
  const [isDictionariesListLoading, setIsDictionariesListLoading] =
    useState(false);
  const [dictionaryToRemove, setDictionaryToRemove] =
    useState<Dictionary | null>(null);

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const intl = useIntl();

  const sortedDictionariesList = [...dictionariesList].sort(sortByRank);

  const getDictionaries = useCallback(async () => {
    const response = await wsAxiosGet<{
      content: { wsDictionaries: Array<Dictionary> };
    }>({
      url: API_URLS.getDictionaries.replace(
        ":workspaceUuid:",
        activeWorkspaceUuid,
      ),
      initialCallback: () => setIsDictionariesListLoading(true),
      finallyCallback: () => setIsDictionariesListLoading(false),
    });
    if (response) {
      const sortedDictionaries =
        response.content.wsDictionaries.sort(sortByRank);
      setDictionariesList(sortedDictionaries);
    }
  }, [activeWorkspaceUuid]);

  const handleDuplicateDictionary = async (
    duplicatedDictionary: Dictionary,
  ) => {
    const dictionaryUuid = uuidv7();

    const duplicatedDictionaryIndex = dictionariesList.findIndex(
      (dictionary) => dictionary.uuid === duplicatedDictionary.uuid,
    );

    let duplicatedDictionaryRank =
      dictionariesList[dictionariesList.length - 1]?.rank || DEFAULT_RANK;

    if (duplicatedDictionaryIndex !== -1) {
      duplicatedDictionaryRank = generateRankString(
        dictionariesList[duplicatedDictionaryIndex - 1]?.rank || "",
        dictionariesList[duplicatedDictionaryIndex + 1]?.rank || "",
      );
    }

    setDictionariesList((prev) => [
      ...prev,
      {
        ...duplicatedDictionary,
        rank: duplicatedDictionaryRank,
        name: `Copy of ${duplicatedDictionary.name}`,
        uuid: dictionaryUuid,
      },
    ]);
    try {
      const response = await axios.post(API_URLS.duplicateDictionary, {
        name: `Copy of ${duplicatedDictionary.name}`,
        uuid: dictionaryUuid,
        rank: duplicatedDictionaryRank,
        wsDictionaryUuid: duplicatedDictionary.uuid,
      });
      if (response.status >= responseCodes["400_BAD_REQUEST"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_could_not_duplicate_dictionary",
          defaultMessage: "An error occurred while duplicating the dictionary",
        });
      }
      console.error(error);
      getDictionaries();
    }
  };

  const handleDeleteDictionary = async (removedDictionaryUuid: string) => {
    try {
      const response = await axios.delete(API_URLS.deleteDictionary, {
        data: {
          uuid: removedDictionaryUuid,
        },
      });

      if (response.status >= responseCodes["400_BAD_REQUEST"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      setDictionariesList((prev) =>
        prev.filter(
          (prevDictionary) => prevDictionary.uuid !== removedDictionaryUuid,
        ),
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_could_not_remove_dictionary",
          defaultMessage: "An error ocurred while removing the dictionary",
        });
      }
      console.error(error);
    }
  };

  const handleToggleDictionary = (
    e: ChangeEvent<HTMLInputElement>,
    dictionary: Dictionary,
  ) => {
    const { checked } = e.target;

    const updatedDictionary = {
      ...dictionary,
      enabled: checked,
    };

    handleUpdateDictionary({
      data: updatedDictionary,
      catchCallback: getDictionaries,
    });

    setDictionariesList((prev) => {
      return prev.map((prevDictionary) => {
        if (prevDictionary.uuid === dictionary.uuid) {
          return {
            ...prevDictionary,
            enabled: checked,
          };
        }
        return prevDictionary;
      });
    });
  };

  const handleAddNewDictionary = async () => {
    const dictionaryUuid = uuidv7();

    const lastElementRank =
      dictionariesList[dictionariesList.length - 1]?.rank || DEFAULT_RANK;
    const newRank = generateRankString(lastElementRank, "");

    const newDictionaryData = {
      description: translateMessage({
        intl,
        id: "ws_new_dictionary_description",
        defaultMessage: "This is a new dictionary",
      }),
      elementName: translateMessage({
        intl,
        id: "ws_new_dictionary_element_name",
        defaultMessage: "Item",
      }),
      enabled: false,
      icon: "Food/Sushi",
      name: translateMessage({
        intl,
        id: "ws_new_dictionary_name",
        defaultMessage: "New dictionary",
      }),
      rank: newRank,
      showInGlobalCreator: false,
      showInSidebar: false,
      url: "",
      uuid: dictionaryUuid,
      wsWorkspaceUuid: activeWorkspaceUuid,
      metaFields: [],
    };

    setEditedDictionary(newDictionaryData);

    try {
      const response = await axios.post(API_URLS.createDictionary, {
        ...newDictionaryData,
        rank: newRank,
      });
      if (response.status >= responseCodes["400_BAD_REQUEST"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.errors) {
        const errorMessage = parseErrorMessages(error.response.data.errors)[0]
          .message;
        showErrorToast({
          id: errorMessage,
          defaultMessage: errorMessage,
        });
      } else {
        showErrorToast({
          id: "ws_could_not_create_dictionary",
          defaultMessage: "An error occurred while creating the dictionary",
        });
      }
      setEditedDictionary(null);
      console.error(error);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const { destination, source } = result;

    const sourceField = sortedDictionariesList[source.index];
    const newRank = createNewRankStringForArrayItem(
      destination,
      source,
      sortedDictionariesList,
    );

    setDictionariesList((prevDictionaries) => {
      return prevDictionaries.map((dictionary) => {
        if (dictionary.uuid === sourceField.uuid) {
          return { ...dictionary, rank: newRank };
        }

        return dictionary;
      });
    });

    handleUpdatetDictionaryRank({
      uuid: sourceField.uuid,
      rank: newRank,
    });
  };

  const listRenderer = (provided: DroppableProvided) => {
    return (
      <ul
        {...provided.droppableProps}
        ref={provided.innerRef}
        className="dictionary-manager__list"
      >
        {sortedDictionariesList.map((dictionary, index) => (
          <Draggable
            key={dictionary.uuid}
            draggableId={dictionary.uuid}
            index={index}
          >
            {(provided) => (
              <li
                ref={provided.innerRef}
                className="dictionary-manager__list-item grid--dictionaries-list"
                key={dictionary.uuid}
                {...provided.draggableProps}
                style={{
                  ...provided.draggableProps.style,
                  left: "auto !important",
                  top: "auto !important",
                }}
                data-qa-field-name={dictionary.name}
              >
                <div className="dictionary-manager__text">
                  <div {...provided.dragHandleProps}>
                    <GridIcon />
                  </div>

                  <div className="dictionary-manager__list-col">
                    <div className="dictionary-manager__name">
                      <span className="dictionary-manager__name-text">
                        {dictionary.name}
                      </span>
                    </div>
                    {dictionary.description && (
                      <div className="dictionary-manager__description">
                        {dictionary.description}
                      </div>
                    )}
                  </div>
                </div>
                <CustomSwitch
                  checked={dictionary.enabled}
                  dataAttributes={{
                    "data-qa-input": "toggleWsDictionaryActive",
                  }}
                  onChange={(e) => {
                    handleToggleDictionary(e, dictionary);
                  }}
                />
                <div className="dictionary-manager__list-actions">
                  <button
                    className="button-style-reset"
                    type="button"
                    onClick={() => setEditedDictionary(dictionary)}
                    data-qa-button="editWsDictionary"
                  >
                    <EditIcon />
                  </button>
                  <button
                    type="button"
                    className="button-style-reset"
                    onClick={() => handleDuplicateDictionary(dictionary)}
                    data-qa-button="duplicateWsDictionary"
                  >
                    <CopyIcon />
                  </button>
                  <button
                    type="button"
                    className="button-style-reset"
                    onClick={() => setDictionaryToRemove(dictionary)}
                    data-qa-button="removeWsDictionary"
                  >
                    <TrashCanIcon />
                  </button>
                </div>
              </li>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </ul>
    );
  };

  useEffect(() => {
    getDictionaries();
  }, [getDictionaries]);

  return (
    <>
      <ExtensionView
        title={
          <div className="settings-modal__view-header">
            <IDHFormattedMessage
              id="ws_dictionary_manager"
              defaultMessage="Dictionary Manager"
            />

            <Button
              size="large"
              variant="blue"
              onClick={handleAddNewDictionary}
              data-qa-button="createWsDictionary"
            >
              <PlusIcon />
              <IDHFormattedMessage
                id="ws_create_dictionary"
                defaultMessage="Create dictionary"
              />
            </Button>
          </div>
        }
      >
        <AnimatedDiv>
          <div className="dictionary-manager__headers grid--dictionaries-list">
            <div>
              <IDHFormattedMessage
                id="ws_dictionary"
                defaultMessage="Dictionary"
              />
            </div>
            <div>
              <IDHFormattedMessage id="ws_active" defaultMessage="Active" />{" "}
            </div>
            <div>
              <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
            </div>
          </div>
          {isDictionariesListLoading ? (
            <Loader />
          ) : (
            <FieldsList listRenderer={listRenderer} onDragEnd={onDragEnd} />
          )}
        </AnimatedDiv>
      </ExtensionView>
      {dictionaryToRemove && (
        <RemoveModal
          objectNames={[dictionaryToRemove.name]}
          onClose={() => setDictionaryToRemove(null)}
          removeFunction={() => handleDeleteDictionary(dictionaryToRemove.uuid)}
        />
      )}
    </>
  );
}

export default DictionaryList;
