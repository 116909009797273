import React, { useMemo } from "react";
import "./DictionaryTabList.scss";
import { CustomTable } from "src/app/components/CustomTable/CustomTable";
import goToImage from "src/images/share-alt.svg";
import { Img } from "src/app/components/Img/Img";
import { openDictionaryDetails } from "src/app/components/DictionaryDetails/methods";
import { useHistory } from "react-router";
import { Button } from "src/app/components/Button/Button";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import {
  actionColumnWidth,
  nameColumnWidth,
} from "../../../GlobalActivityTab/utils";

export function DictionaryTabList(props) {
  const { list, dictionaryData, showDictionaryModal, loading } = props;

  const history = useHistory();

  const rows = useMemo(
    () =>
      list.map((item) => {
        return {
          name: item.title,
          action: <Img src={goToImage} fallbackImageSrc={goToImage} />,
          onRowClick: () =>
            openDictionaryDetails(
              history,
              item.uuid,
              dictionaryData.wsDictionaryUuid,
            ),
        };
      }),
    [list],
  );

  const getColumns = (name) => [
    {
      Header: `${name} NAME`,
      accessor: `name`,
      width: nameColumnWidth,
    },
    {
      Header: "ACTION",
      accessor: "action",
      width: actionColumnWidth,
    },
  ];

  return (
    <div className="dictionary-tab-list">
      <CustomTable
        headerClassName="activity-tab__table-header"
        columns={getColumns(dictionaryData?.wsDictionaryElementName)}
        data={rows}
        isLoading={loading}
      />
      <Button variant="blue" size="small" onClick={showDictionaryModal}>
        <PlusIcon />
        <IDHFormattedMessage id="ws_add" defaultMessage="Add" />{" "}
        {dictionaryData?.wsDictionaryElementName.toLowerCase()}
      </Button>
    </div>
  );
}
