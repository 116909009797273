import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { uuidv7 as uuid } from "uuidv7";
import { IntlShape, WrappedComponentProps, injectIntl } from "react-intl";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { TaskType } from "src/types";
import { RootState } from "src/redux/reducers";
import { setStiggPaymentErrors } from "src/redux/main/mainActions";
import CustomInput from "../components/CustomInput/CustomInput";
import Modal, {
  hideModal,
  ModalColumn,
  ModalRow,
  ModalTitle,
} from "../components/Modal/Modal";
import { generateRankString } from "../../utils/rankStrings";
import {
  getTaskDetails,
  addNewTask,
  getTasksList,
  getTasksCounters,
  openTrialExceededModal,
} from "../../redux";
import IDHFormattedMessage from "../components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "../methods/translateMessage";
import { showToast } from "../methods/showToast";

interface Props extends WrappedComponentProps<"intl"> {
  onClose?: any;
  projectId: string;
  lastTaskRank: string;
  addNewTask(
    title: string,
    projectId: string,
    uuid: string,
    rank: string,
    taskType: string | null,
    wsWorkspaceUuid: string,
    defaultMetaFieldsValues: {
      key: string;
      taskType: string;
      value: string;
    }[],
  ): any;
  getTasksList: any;
  taskType: string;
  defaultMetaFieldsValues?: {
    key: string;
    taskType: string;
    value: string;
  };
  tasksLength: number;
  intl: IntlShape;
}

const AddTaskModal: React.FC<Props> = (props) => {
  const {
    onClose,
    projectId,
    lastTaskRank,
    taskType,
    defaultMetaFieldsValues,
    tasksLength,
    intl,
  } = props;

  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { activeWorkspaceUuid } = useSelector(
    (state: RootState) => state.mainReducer,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 150);
    }
  }, [inputRef.current]);

  useEffect(() => {
    const taskNumber = tasksLength || 0;

    if (taskType === TaskType.Content) {
      setValue(
        `${translateMessage({
          intl,
          id: "ws_content",
          defaultMessage: "Content",
        })} #${taskNumber + 1}`,
      );
    } else if (taskType === TaskType.Payment) {
      setValue(
        `${translateMessage({
          intl,
          id: "ws_payment",
          defaultMessage: "Payment",
        })} #${taskNumber + 1}`,
      );
    }
  }, [tasksLength]);

  const createTask = (e: React.FormEvent) => {
    e.preventDefault();

    const defaultPublicationData = defaultMetaFieldsValues
      ? [
          {
            key: defaultMetaFieldsValues?.key,
            taskType: defaultMetaFieldsValues?.taskType,
            value: defaultMetaFieldsValues?.value.trim(),
          },
        ]
      : [];

    setLoading(true);

    axios
      .post(API_URLS.createTask, {
        projectId,
        taskName: value,
        taskRank: generateRankString(lastTaskRank, ""),
        taskId: uuid(),
        taskType,
        wsWorkspaceUuid: activeWorkspaceUuid,
        defaultMetaFieldsValues: defaultPublicationData,
      })
      .then(() => {
        if (defaultMetaFieldsValues) {
          // Refresh content task publications tab
          dispatch(getTaskDetails(defaultMetaFieldsValues.value));
        } else if (projectId) {
          dispatch(getTasksCounters(projectId));
          props.getTasksList(projectId, taskType);
        }
        hideModal(onClose);
      })
      .catch((err: any) => {
        if (err.response?.data?.errors?.length > 0) {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage id={err.response.data.errors[0].message} />,
          );
        } else if (err?.response?.data?.statusCode == 402) {
          hideModal(onClose);
          dispatch(openTrialExceededModal());
          dispatch(setStiggPaymentErrors(err.response.data.errors));
        }
        setLoading(false);
      });
  };

  const renderModalTitle = () => {
    switch (taskType) {
      case "content":
        return <IDHFormattedMessage id="ws_content" defaultMessage="Content" />;

      case "publication":
        return (
          <IDHFormattedMessage
            id="ws_publication"
            defaultMessage="Publication"
          />
        );
      case "payment":
        return <IDHFormattedMessage id="ws_payment" defaultMessage="Payment" />;
      default:
        return <IDHFormattedMessage id="ws_action" defaultMessage="Action" />;
    }
  };

  return (
    <Modal
      className="add-task-modal"
      onClose={onClose}
      displayCancelButton
      closeButtonText={
        <IDHFormattedMessage id="ws_cancel" defaultMessage="Cancel" />
      }
      onConfirmClick={createTask}
      confirmButtonDisabled={!value.trim()}
      confirmButtonText={
        <IDHFormattedMessage id="ws_create" defaultMessage="Create" />
      }
      confirmButtonLoading={loading}
    >
      <ModalTitle>
        <span>
          <IDHFormattedMessage id="ws_add" defaultMessage="Add" />
          &nbsp;
          {renderModalTitle()}
        </span>
      </ModalTitle>
      <form onSubmit={createTask}>
        <ModalRow>
          <ModalColumn>
            <CustomInput
              ref={inputRef}
              id="fieldTitle"
              type="text"
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(e.target.value.trimStart())
              }
              placeholder={translateMessage({
                intl,
                id: "ws_enter_title",
                defaultMessage: "Enter title",
              })}
            />
          </ModalColumn>
        </ModalRow>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  taskType: state.projectReducer.taskType,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    addNewTask: (
      title: string,
      projectId: string,
      uuid: string,
      rank: string,
      taskType: string | null,
      wsWorkspaceUuid: string,
      defaultMetaFieldsValues: {
        key: string;
        taskType: string;
        value: string;
      }[],
    ) =>
      dispatch(
        addNewTask(
          title,
          projectId,
          uuid,
          rank,
          taskType,
          wsWorkspaceUuid,
          defaultMetaFieldsValues,
        ),
      ),
    getTasksList: (projectId: string, taskType: string) =>
      dispatch(getTasksList(projectId, taskType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddTaskModal));
