import React, { Dispatch, SetStateAction } from "react";
import axios from "axios";

import { showErrorToast } from "src/utils/methods";
import { API_URLS } from "src/utils/API_URLS";
import responseCodes from "src/utils/responseCodes";
import FieldManagerModal from "src/app/modals/FieldManagerModal/FieldManagerModal";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { FormDataSubmitPayload } from "src/app/modals/FieldManagerModal/types";
import { tableDataType } from "src/app/components/Table/Table";

const dictionaryFieldTypes = [
  MetaFieldType.Text,
  MetaFieldType.Member,
  MetaFieldType.Date,
  MetaFieldType.Number,
  MetaFieldType.BoolVal,
  MetaFieldType.Percent,
  MetaFieldType.Currency,
  MetaFieldType.File,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.Rating,
];

interface AddDictionaryFieldModalProps {
  editedDictionaryUuid: string;
  setIsAddFieldManagerModalOpen: Dispatch<SetStateAction<boolean>>;
  syncLocalDictionaryWithServer: (uuid: string) => Promise<void>;
}

export default function AddDictionaryFieldModal({
  editedDictionaryUuid,
  setIsAddFieldManagerModalOpen,
  syncLocalDictionaryWithServer,
}: AddDictionaryFieldModalProps) {
  const handleFormDataSubmit = async ({
    formData,
  }: {
    formData: FormDataSubmitPayload;
  }) => {
    try {
      const response = await axios.post(API_URLS.createDictionaryMetaField, {
        wsWorkspaceUuid: formData.wsWorkspaceUuid,
        metaFieldUuid: formData.uuid,
        metaFieldName: formData.fieldName,
        metaFieldType: formData.selectedMetaFieldTypeOption.type,
        metaFieldCurrency: formData.selectedCurrency?.value ?? null,
        wsTeamUuids:
          formData.wsTeamUuids.length === 0 ? null : formData.wsTeamUuids,
        metaFieldWsDictionaryUuid: editedDictionaryUuid,
        wsDictionarySubFieldUuid: formData.wsDictionarySubField?.value ?? null,
        wsDictionaryUuid: formData.wsDictionaryUuid,
        wsDictionaryElementsFilter: {
          wsDictionaryMetaFieldTargetUuid:
            formData.selectedFilterMetaFieldTarget?.value ?? null,
          wsDictionaryMetaFieldFilterByUuid:
            formData.selectedFilterByMetaField?.value ?? null,
        },
      });
      if (response.status >= responseCodes["400_BAD_REQUEST"]) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      syncLocalDictionaryWithServer(editedDictionaryUuid);
      return true;
    } catch (error) {
      showErrorToast();
      console.error(error);
      return false;
    }
  };

  return (
    <FieldManagerModal
      metaFieldTypeOptionsWhiteList={dictionaryFieldTypes}
      handleFormDataSubmit={handleFormDataSubmit}
      onClose={() => setIsAddFieldManagerModalOpen(false)}
      dataType={tableDataType.DictionaryManager}
    />
  );
}
