interface PlainObject {
  [key: string]: any;
}

export const getRoute = (
  path: string,
  pathParams?: PlainObject,
  queryParameters?: PlainObject,
) => {
  let pathToGo = path;

  if (pathParams) {
    Object.keys(pathParams).forEach((param) => {
      pathToGo = pathToGo.replace(`:${param}`, pathParams[param]);
    });
  }

  if (queryParameters) {
    const searchParams = new URLSearchParams(queryParameters);
    return `${pathToGo}?${searchParams}`;
  }

  return pathToGo;
};

export const getUrlWithQueryParams = (
  path: string,
  pathParams?: PlainObject,
) => {
  const pathToGo = path;
  const url = new URL(path);

  if (pathParams) {
    Object.keys(pathParams).forEach((param) => {
      url.searchParams.set(param, pathParams[param]);
    });
  }

  return path + url.search;
};

const workspaceBase = "/workspace";
export const ROUTES = {
  BASE: workspaceBase,
  MY_ACTIONS: `${workspaceBase}/:wsWorkspaceUuid/my-actions`,
  PROJECT: `${workspaceBase}/:wsWorkspaceUuid/projects/:wsProjectUuid`,
  PROJECTS: `${workspaceBase}/:wsWorkspaceUuid/projects`,
  GLOBAL_TASK_DETAILS: `${workspaceBase}/:wsWorkspaceUuid/creator-database`,
  DICTIONARY_ELEMENT_DETAILS: `${workspaceBase}/:wsWorkspaceUuid/dict/:wsDictionaryUrl/:wsDictionaryUuid`,
  CREATOR_DISCOVERY_TOOL_LIST: `${workspaceBase}/:wsWorkspaceUuid/creator-discovery-tool/list/:wsListUuid`,
  TASK_DETAILS: `${workspaceBase}/:wsWorkspaceUuid/projects/:wsProjectUuid/:wsTaskUuid`,
  CREATOR_DISCOVERY_PROFILE: `${workspaceBase}/:wsWorkspaceUuid/creator-discovery-tool/list/:wsListUuid/profile/:socialProvider/:socialUsername`,
  CREATE_NEW_PROJECT: `${workspaceBase}/:wsWorkspaceUuid/create-new-project`,
  COMMENT_DETAILS: `${workspaceBase}/:wsWorkspaceUuid/projects/:wsProjectUuid/:wsTaskUuid/:wsCommentUuid`,
  WORKSPACE_TASKS_SUMMARY_DETAILS: `${workspaceBase}/:wsWorkspaceUuid/summary/:wsProjectUuid/:wsTaskUuid`,
} as const;
