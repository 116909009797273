import { SelectOption } from "src/app/modals/ManageFieldModal/SocialMetrics";
import * as actionTypes from "./workspaceTaskFiltersTypes";

export function refreshDataHubInitialState() {
  return {
    type: actionTypes.REFRESH_DATA_HUB_STATE,
  };
}

export interface SetWorkspaceTaskFilterParams {
  uuid: string;
  value: any;
}

export function setWorkspaceTaskFilter({
  uuid,
  value,
}: SetWorkspaceTaskFilterParams) {
  return {
    type: actionTypes.SET_WORKSPACE_TASK_FILTER,
    payload: { uuid, value },
  };
}

export function setWorkspaceTaskFilters(
  filters: {
    filters: Record<string, { value: any }>;
  },
  skipLSUpdate?: boolean,
) {
  return {
    type: actionTypes.SET_WORKSPACE_TASK_FILTERS,
    payload: { filters, skipLSUpdate },
  };
}

export function setWSTaskActiveSortingColumn(
  sortingColumn: string | null,
  skipLSUpdate?: boolean,
) {
  return {
    type: actionTypes.SET_WORKSPACE_TASK_SORTING_COLUMN,
    payload: {
      activeSortingColumn: sortingColumn,
      skipLSUpdate,
    },
  };
}

export function setWSTaskSortingDirection(
  sortingDirection: string,
  skipLSUpdate?: boolean,
) {
  return {
    type: actionTypes.SET_WORKSPACE_TASK_SORTING_DIRECTION,
    payload: {
      sortingDirection,
      skipLSUpdate,
    },
  };
}

export function clearWorkspaceTaskFilters() {
  return {
    type: actionTypes.CLEAR_WORKSPACE_TASK_FILTERS,
  };
}

export function setWSTasksSelectedTableView(selectedTableView: SelectOption) {
  return {
    type: actionTypes.SET_WS_TASKS_SELECTED_TABLE_VIEW,
    payload: { selectedTableView },
  };
}

export function setWSTasksVisibleColumns(
  visibleColumns: string[],
  skipLSUpdate?: boolean,
) {
  return {
    type: actionTypes.SET_WORKSPACE_TASK_VISIBLE_COLUMNS,
    payload: { visibleColumns, skipLSUpdate },
  };
}

export function toggleWSTasksColumnVisibility(uuid: string) {
  return {
    type: actionTypes.TOGGLE_WORKSPACE_TASK_COLUMN_VISIBILITY,
    payload: {
      uuid,
    },
  };
}

export function setWSTasksColumnRank(uuid: string, rank: string) {
  return {
    type: actionTypes.SET_WORKSPACE_TASK_COLUMN_RANK,
    payload: { uuid, rank },
  };
}

export function setWSTasksColumnRanks(ranks: [], saveConfig: boolean = false) {
  return {
    type: actionTypes.SET_WORKSPACE_TASK_COLUMN_RANKS,
    payload: { ranks, saveConfig },
  };
}
