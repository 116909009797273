import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import EntitlementsApi from '../api/EntitlementsApi';
import { EntitlementCheckResult, EntitlementFragment } from '@stigg/api-client-js/src/generated/sdk';
import {
  BooleanEntitlement,
  BooleanEntitlementOptions,
  FeatureType,
  MeteredEntitlement,
  MeteredEntitlementOptions,
  MeterType,
  NumericEntitlement,
  NumericEntitlementOptions,
} from '../models';
import { ModelMapper } from '../utils/ModelMapper';
import CachedEntitlement from './cachedEntitlement';
import { CacheService } from './cacheService';
import { EntitlementDecisionService } from './entitlementDecisionService';
import { EntitlementCheckReportingService } from './entitlementCheckReportingService';
import { LoggerService } from './loggerService';
import { EdgeApiClient } from '../api/EdgeApiClient';
import { max } from 'lodash';
import { calculateUsagePeriod } from '../utils/calculateUsagePeriod';
import { isMetered } from '../utils/isMetered';

export class EntitlementsService {
  private readonly entitlementsApi: EntitlementsApi;
  private readonly modelMapper: ModelMapper;
  private loadingEntitlements: Promise<void> | null;
  private entitlementCheckReportingService: EntitlementCheckReportingService;

  constructor(
    private readonly customerId: string,
    private readonly resourceId: string | undefined,
    private readonly cacheService: CacheService,
    client: ApolloClient<NormalizedCacheObject>,
    batchedGraphClient: ApolloClient<NormalizedCacheObject>,
    edgeApiClient: EdgeApiClient | null,
    private loggerService: LoggerService,
    private onEntitlementsUpdated: (cachedEntitlements: Map<string, CachedEntitlement>) => void,
  ) {
    this.modelMapper = new ModelMapper();
    this.loadingEntitlements = null;
    this.entitlementsApi = new EntitlementsApi(client, edgeApiClient);
    this.entitlementCheckReportingService = new EntitlementCheckReportingService(
      new EntitlementsApi(batchedGraphClient, edgeApiClient),
      customerId,
      this.loggerService,
      resourceId,
    );
  }

  async refresh(): Promise<void> {
    // the refresh is called after performing operation that change
    // the entitlements, so load entitlements from graphql instead
    // of edge-api since there is a replication lag to the edge-api
    await this.loadEntitlements(true);
  }

  get isInitialized(): boolean {
    return this.cacheService.isLoaded();
  }

  getBooleanEntitlement(
    featureId: string,
    fallbackEntitlement: BooleanEntitlement,
    options?: BooleanEntitlementOptions,
  ): BooleanEntitlement {
    const shouldTrack = options?.shouldTrack || false;
    const entitlement = this.getEntitlement(featureId);
    const decision = EntitlementDecisionService.decideEntitlementPolicy(entitlement);

    if (
      entitlement?.calculatedEntitlement.feature &&
      entitlement.calculatedEntitlement.feature.featureType !== FeatureType.Boolean
    ) {
      this.tryTrackEntitlementCheck(
        shouldTrack,
        featureId,
        this.modelMapper.mapFallbackBooleanEntitlementResult(fallbackEntitlement, decision),
      );

      return fallbackEntitlement;
    }

    if (!entitlement) {
      const entitlementResult = this.modelMapper.mapEntitlementResult(decision);
      this.tryTrackEntitlementCheck(shouldTrack, featureId, entitlementResult);
      return { ...decision, isFallback: false };
    }

    const entitlementResult = this.modelMapper.mapEntitlementResult(decision, entitlement);
    this.tryTrackEntitlementCheck(shouldTrack, featureId, entitlementResult);

    return this.modelMapper.mapBooleanEntitlement(entitlement, decision);
  }

  getNumericEntitlement(
    featureId: string,
    fallbackEntitlement: NumericEntitlement,
    options?: NumericEntitlementOptions,
  ): NumericEntitlement {
    const shouldTrack = options?.shouldTrack || false;
    const entitlement = this.getEntitlement(featureId);
    const decision = EntitlementDecisionService.decideEntitlementPolicy(entitlement);

    if (
      entitlement?.calculatedEntitlement.feature &&
      entitlement.calculatedEntitlement.feature?.featureType !== FeatureType.Number
    ) {
      const entitlementResult = this.modelMapper.mapFallbackNumericEntitlementResult(fallbackEntitlement, decision);
      this.tryTrackEntitlementCheck(shouldTrack, featureId, entitlementResult);
      return fallbackEntitlement;
    }

    if (!entitlement) {
      const entitlementResult = this.modelMapper.mapEntitlementResult(decision);
      this.tryTrackEntitlementCheck(shouldTrack, featureId, entitlementResult);

      return { ...decision, isFallback: false, isUnlimited: false };
    }

    const entitlementResult = this.modelMapper.mapEntitlementResult(decision, entitlement);
    this.tryTrackEntitlementCheck(shouldTrack, featureId, entitlementResult);

    return this.modelMapper.mapNumericEntitlement(entitlement, decision);
  }

  getMeteredEntitlement(
    featureId: string,
    fallbackEntitlement: MeteredEntitlement,
    options?: MeteredEntitlementOptions,
  ): MeteredEntitlement {
    const shouldTrack = options?.shouldTrack || false;
    const requestedUsage = options?.requestedUsage;
    const originalEntitlement = this.getEntitlement(featureId);
    const entitlement = this.handleUsagePeriodOver(originalEntitlement);
    const decision = EntitlementDecisionService.decideEntitlementPolicy(entitlement, requestedUsage);

    if (
      entitlement?.calculatedEntitlement.feature &&
      entitlement.calculatedEntitlement.feature?.meterType !== MeterType.Fluctuating &&
      entitlement.calculatedEntitlement.feature?.meterType !== MeterType.Incremental
    ) {
      const entitlementResult = this.modelMapper.mapFallbackMeteredEntitlementResult(
        fallbackEntitlement,
        decision,
        requestedUsage,
      );
      this.tryTrackEntitlementCheck(shouldTrack, featureId, entitlementResult, requestedUsage);
      return fallbackEntitlement;
    }

    if (!entitlement) {
      const entitlementResult = this.modelMapper.mapEntitlementResult(decision, undefined, requestedUsage);
      this.tryTrackEntitlementCheck(shouldTrack, featureId, entitlementResult, requestedUsage);

      return {
        ...decision,
        currentUsage: 0,
        isFallback: false,
        isUnlimited: false,
        requestedUsage: requestedUsage || 0,
      };
    }

    const entitlementResult = this.modelMapper.mapEntitlementResult(decision, entitlement, requestedUsage);
    this.tryTrackEntitlementCheck(shouldTrack, featureId, entitlementResult, requestedUsage);
    return this.modelMapper.mapMeteredEntitlement(entitlement, decision, requestedUsage);
  }

  private handleUsagePeriodOver(entitlement: CachedEntitlement | null): CachedEntitlement | null {
    const now = new Date();
    const wasPeriodOver =
      entitlement &&
      isMetered(entitlement.calculatedEntitlement.feature) &&
      entitlement.featureUsage.usagePeriodEnd &&
      entitlement.featureUsage.usagePeriodEnd.getTime() < now.getTime();

    if (!wasPeriodOver) {
      return entitlement;
    }

    const { calculatedEntitlement, featureUsage } = entitlement;
    const { resetPeriod, usagePeriodAnchor } = featureUsage;

    let usagePeriodStart: Date | undefined;
    let usagePeriodEnd: Date | undefined;

    // Calculate new usage period
    if (usagePeriodAnchor && resetPeriod) {
      ({ usagePeriodStart, usagePeriodEnd } = calculateUsagePeriod(new Date(usagePeriodAnchor), now, resetPeriod));
    }

    return new CachedEntitlement(calculatedEntitlement, {
      ...featureUsage,
      currentUsage: 0,
      usagePeriodStart,
      usagePeriodEnd,
    });
  }

  private tryTrackEntitlementCheck(
    shouldTrack: boolean,
    featureRefId: string,
    result: EntitlementCheckResult,
    requestedUsage?: number,
  ) {
    if (!shouldTrack) {
      return;
    }
    this.entitlementCheckReportingService.reportEntitlementCheckRequested(featureRefId, result, requestedUsage);
  }

  async loadEntitlements(skipEdge?: boolean): Promise<void> {
    if (!this.loadingEntitlements) {
      this.loadingEntitlements = this.loadEntitlementsFromRemote(skipEdge);
    }

    try {
      return await this.loadingEntitlements;
    } finally {
      this.loadingEntitlements = null;
    }
  }

  private async loadEntitlementsFromRemote(skipEdge?: boolean) {
    const entitlementsResult = await this.entitlementsApi.getEntitlements(this.customerId, skipEdge, this.resourceId);
    this.storeFetchedEntitlementsInCache(entitlementsResult.data.entitlements);
  }

  protected storeFetchedEntitlementsInCache(entitlements: EntitlementFragment[]) {
    this.cacheService.updateLastChecked();

    const cacheLastUpdate = this.cacheService.getLastUpdate();
    const lastUpdate = this.getLastEntitlementsUpdate(entitlements);

    if (cacheLastUpdate && lastUpdate.getTime() < cacheLastUpdate.getTime()) {
      return;
    }

    const cachedEntitlements = this.modelMapper.mapCachedEntitlements(entitlements);
    this.cacheService.setEntitlements(cachedEntitlements, lastUpdate);
    this.onEntitlementsUpdated(cachedEntitlements);
  }

  private getLastEntitlementsUpdate(entitlements: EntitlementFragment[]): Date {
    const updatedAt = max(
      entitlements.flatMap((entitlement) => [
        new Date(entitlement.entitlementUpdatedAt),
        new Date(entitlement.usageUpdatedAt),
      ]),
    );

    // in case the entitlements is empty list, we don't have
    // timestamp, so we assume it's the latest
    return updatedAt || new Date();
  }

  private getEntitlement(featureId: string): CachedEntitlement | null {
    const entitlement = this.cacheService.getEntitlement(featureId);

    this.renewOutdatedCacheInBackground();

    return entitlement;
  }

  async getEntitlements() {
    await this.renewOutdatedCache();

    return Array.from(this.cacheService.getEntitlements().values()).map((value: CachedEntitlement) => {
      const decision = EntitlementDecisionService.decideEntitlementPolicy(value);
      return this.modelMapper.mapEntitlement(value, decision);
    });
  }

  private async renewOutdatedCache(): Promise<void> {
    const shouldRenew = !this.cacheService.isLoaded() || this.cacheService.isOutdated();
    if (shouldRenew) {
      await this.loadEntitlements();
    }
  }

  renewOutdatedCacheInBackground(): void {
    // The promise will not be awaited
    // If it gets rejected, Node.js will issue an UnhandledPromiseRejectionWarning.
    // To prevent this, we create an orphan promise chain.
    void this.renewOutdatedCache().catch((err) => {
      this.loggerService.error('Failed to renew cache in background', err);
    });
  }
}
