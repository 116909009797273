import { uuidv7 as uuid } from "uuidv7";

import { API_URLS } from "src/utils/API_URLS";
import * as actionTypes from "./mainTypes";
import {
  CreateNewProjectData,
  HowItWorksModalDataProps,
  NotificationType,
  SettingsModalDataProps,
} from "./mainReducer";

export function getTranslations() {
  const url = API_URLS.getTranslationsForLocale.replace(":lang:", "en_GB");

  return {
    type: actionTypes.GET_TRANSLATIONS,
    payload: {
      request: {
        url,
      },
    },
  };
}

export function getTranslationsForMember() {
  return {
    type: actionTypes.GET_TRANSLATIONS,
    payload: {
      request: {
        url: API_URLS.getTranslationsForMember,
      },
    },
  };
}

export function setLanguage(lang = "en_GB") {
  return {
    type: actionTypes.SET_LANGUAGE,
    payload: {
      request: {
        method: "post",
        url: API_URLS.setLanguage,
        data: {
          locale: lang,
        },
      },
    },
  };
}

export function identify(workspaceUuid?: string) {
  return {
    type: actionTypes.GET_IDENTIFY,
    payload: {
      request: {
        url: workspaceUuid
          ? `${API_URLS.getIdentity}?workspaceUuid=${workspaceUuid}`
          : API_URLS.getIdentity,
      },
    },
  };
}

export function isLoggedIn() {
  return {
    type: actionTypes.GET_IS_LOGGED_IN,
    payload: {
      request: {
        url: API_URLS.getIdentity,
      },
    },
  };
}

export function setWebSocket(
  workspaceUuid: string,
  userId: string,
  challenge: string,
) {
  return {
    type: actionTypes.SET_WEBSOCKET,
    payload: {
      workspaceUuid,
      userId,
      challenge,
    },
  };
}

export function clearWebSocket() {
  return {
    type: actionTypes.CLEAR_WEBSOCKET,
  };
}

export function setActiveWorkspaceUuid(workspaceUuid: string) {
  return {
    type: actionTypes.SET_ACTIVE_WORKSPACE_UUID,
    payload: {
      workspaceUuid,
    },
  };
}

export function setIntercomUnreadMessages(counter: number) {
  return {
    type: actionTypes.SET_INTERCOM_UNREAD_MESSAGES,
    payload: {
      counter,
    },
  };
}

export function getWorkspacesList() {
  return {
    type: actionTypes.GET_WORKSPACES_LIST,
    payload: {
      request: {
        url: API_URLS.listWorkspacesForUser,
      },
    },
  };
}

export function callReducerFunction(type: string, payload: any) {
  return {
    type,
    payload,
  };
}

export function getCreatorDiscoveryToolUrl(wsWorkspaceUuid: string) {
  return {
    type: actionTypes.GET_CREATOR_DISCOVERY_TOOL_URL,
    payload: {
      request: {
        url: API_URLS.getCreatorDiscoveryToolUrl.replace(
          ":wsWorkspaceUuid:",
          wsWorkspaceUuid,
        ),
      },
    },
  };
}

interface SendNotificationProps {
  type: NotificationType;
  message?: string;
  id?: string;
}

export function sendNotification({ type, message, id }: SendNotificationProps) {
  return {
    type: actionTypes.SEND_NOTIFICATION,
    payload: {
      id: id ?? uuid(),
      type,
      message,
    },
  };
}

export function dropNotification(id: string) {
  return {
    type: actionTypes.DROP_NOTIFICATION,
    payload: {
      id,
    },
  };
}

export function HidePdfReadyModal() {
  return {
    type: actionTypes.PDF_CLOSE_READY,
    payload: {},
  };
}

export function setSettingsModalData(
  settingsModalData: SettingsModalDataProps,
) {
  return {
    type: actionTypes.SET_SETTINGS_MODAL_DATA,
    payload: { settingsModalData },
  };
}

export function setHowItWorksModalData(
  howItWorksModalData: HowItWorksModalDataProps,
) {
  return {
    type: actionTypes.SET_HOW_IT_WORKS_MODAL_DATA,
    payload: { howItWorksModalData },
  };
}

export function setCreateNewProjectData(
  createNewProjectData: CreateNewProjectData,
) {
  return {
    type: actionTypes.SET_CREATE_NEW_PROJECT_DATA,
    payload: { createNewProjectData },
  };
}

export function clearCreateNewProjectData() {
  return {
    type: actionTypes.CLEAR_CREATE_NEW_PROJECT_DATA,
  };
}

export function setStiggPaymentErrors(stiggPaymentErrors: string[]) {
  return {
    type: actionTypes.SET_STIGG_PAYMENT_ERRORS,
    payload: { stiggPaymentErrors },
  };
}
