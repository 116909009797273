import { FeatureType, MeterType } from '@stigg/api-client-js/src/generated/sdk';
import { isNil } from 'lodash';

type Feature = {
  featureType: FeatureType;
  meterType?: MeterType | null;
};

const METERED_TYPES = [MeterType.Incremental, MeterType.Fluctuating];

export function isMetered(feature?: Feature): boolean {
  return (
    feature?.featureType === FeatureType.Number &&
    !isNil(feature.meterType) &&
    METERED_TYPES.includes(feature.meterType)
  );
}
