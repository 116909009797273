import React, { createContext, useState } from "react";
import "./Members.scss";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { ReactComponent as InfoGreyIcon } from "src/images/info-grey.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { wsAxiosPatch } from "src/helpers/wsAxios";
import { API_URLS } from "src/utils/API_URLS";
import { ClientMode } from "src/app/modals/ShareModal/ClientMode";
import { UserList } from "src/app/modals/ShareModal/UserList";
import InviteUser from "src/app/modals/ShareModal/InviteUser/InviteUser";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import classNames from "classnames";
import { RemoveUserModal } from "src/app/modals/ShareModal/RemoveUserModal/RemoveUserModal";
import {
  getMembersList,
  getProject,
  getProjectDetails,
  getProjectsList,
  getTaskDetails,
  getTasksList,
  openTrialExceededModal,
} from "src/redux";
import { showErrorToasts } from "src/utils/methods";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import responseCodes from "src/utils/responseCodes";
import { setStiggPaymentErrors } from "src/redux/main/mainActions";
import { SettingsContext } from "../../enums";
import { SettingsButtons } from "../../components/SettingsTabContent/SettingsTabContent";
import { showToast } from "../../../methods/showToast";
import { ModalRow, ModalTitle } from "../../../components/Modal/Modal";

export const MembersContext = createContext({});

export function Members(props: any) {
  const {
    onClose,
    getData,
    data: {
      openSeeAsClientInfoModal,
      openRolesInfoModal,
      projectId,
      taskId,
      permissions,
      clientMode,
      members,
      openWorkspaceRolesInfoModal,
    },
  } = props;

  const [users, setUsers] = useState([]);
  const [sendNotification, setSendNotification] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState({
    name: <IDHFormattedMessage id="ws_member" defaultMessage="Member" />,
    value: "member",
  });
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [removeUserData, setRemoveUserData] = useState(null);

  const {
    mainReducer: { activeWorkspaceUuid, workspacesList, settingsModalData },
    projectReducer: { taskType },
  } = useSelector((state: RootState) => state);

  const workspaceData = workspacesList.find(
    (workspace) => workspace.uuid === activeWorkspaceUuid,
  );

  const dispatch = useDispatch();

  const { context } = settingsModalData;

  const enableClientMode = async () => {
    await wsAxiosPatch(
      API_URLS.toggleClientMode.replace(":wsProjectUuid:", projectId),
      {
        enabled: true,
      },
      `Error occurred during switching client mode`,
    );
    getData();
  };

  const addWorkspaceMember = (emails: string[]) => {
    setLoading(true);
    axios
      .post(API_URLS.addWorkspaceMembers, {
        wsMembersInviteEmails: emails,
        wsWorkspaceUuid: workspaceData?.uuid,
        sendNotification,
      })
      .then(() => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id={`ws_member${
              emails.length > 1 ? "s_were" : "_was"
            }_invited_successfully`}
            defaultMessage={`Member${
              emails.length > 1 ? "s were" : " was"
            } invited successfully.`}
          />,
        );
        getData();
        setUsers([]);
        setLoading(false);
      })
      .then(() => {
        dispatch(getMembersList(activeWorkspaceUuid));
      })
      .catch((error) => {
        setLoading(false);

        if (
          error?.response.data.statusCode ==
          responseCodes["402_PAYMENT_REQUIRED"]
        ) {
          dispatch(openTrialExceededModal());
          dispatch(setStiggPaymentErrors(error.response.data.errors));
        } else {
          const errorMessages = error.response.data.errors;
          showErrorToasts(errorMessages);
        }
      });
  };

  const addProjectMember = (emails: string[], uuids: string[]) => {
    const addMemberUrl =
      selectedRole.value === "member"
        ? API_URLS.addProjectMembers
        : API_URLS.addProjectClients;

    setLoading(true);

    axios
      .post(addMemberUrl, {
        [selectedRole.value === "member" ? "wsMembersUuids" : "wsClientsUuids"]:
          uuids,
        [selectedRole.value === "member"
          ? "wsMembersInviteEmails"
          : "wsClientsInviteEmails"]: emails,
        wsProjectUuid: projectId,
        sendNotification,
      })
      .then(() => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id={`ws_member${
              emails.length > 1 ? "s_were" : "_was"
            }_invited_successfully`}
            defaultMessage={`Member${
              uuids.length > 1 ? "s were" : " was"
            } invited successfully.`}
          />,
        );

        if (!clientMode && selectedRole.value === "client") {
          enableClientMode();
        } else {
          getData();
        }
        setUsers([]);
        setLoading(false);
      })
      .then(() => {
        if (settingsModalData.location === AppLocation.ProjectList) {
          dispatch(getProjectsList(activeWorkspaceUuid));
        } else {
          dispatch(getProjectDetails(projectId));
          dispatch(getProject(projectId));
          dispatch(getTasksList(projectId, taskType));
        }
      })
      .catch((error) => {
        setLoading(false);

        if (
          error?.response.data.statusCode ==
          responseCodes["402_PAYMENT_REQUIRED"]
        ) {
          dispatch(openTrialExceededModal());
          dispatch(setStiggPaymentErrors(error.response.data.errors));
        } else {
          const errorMessages = error.response.data.errors;
          showErrorToasts(errorMessages);
        }
      });
  };

  const addTaskMember = (emails: string[], uuids: string[]) => {
    const addTaskMemberUrl = API_URLS.addTaskMembers;

    setLoading(true);

    axios
      .post(addTaskMemberUrl, {
        [selectedRole.value === "member" ? "wsMembersUuids" : "wsClientsUuids"]:
          uuids,
        [selectedRole.value === "member"
          ? "wsMembersInviteEmails"
          : "wsClientsInviteEmails"]: emails,
        wsTaskUuid: taskId,
        sendNotification,
      })
      .then(() => {
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id={`ws_member${
              emails.length > 1 ? "s_were" : "_was"
            }_invited_successfully`}
            defaultMessage={`Member${
              uuids.length > 1 ? "s were" : " was"
            } invited successfully.`}
          />,
        );

        if (!clientMode && selectedRole.value === "client") {
          enableClientMode();
        } else {
          getData();
        }
        setUsers([]);
        setLoading(false);
      })
      .then(() => {
        dispatch(getTaskDetails(taskId));
      })
      .catch((error) => {
        setLoading(false);

        if (
          error?.response.data.statusCode ==
          responseCodes["402_PAYMENT_REQUIRED"]
        ) {
          dispatch(openTrialExceededModal());
          dispatch(setStiggPaymentErrors(error.response.data.errors));
        } else {
          const errorMessages = error.response.data.errors;
          showErrorToasts(errorMessages);
        }
      });
  };

  const addMember = () => {
    const uuids: string[] = [];
    const emails: string[] = [];

    let breakFunction = false;

    users.forEach((member: any) => {
      if (member.value.id) {
        uuids.push(member.value.id);
      } else if (member.value.includes("@")) {
        emails.push(member.value);
      } else {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_invalid_member_email"
            defaultMessage="Invalid member e-mail!"
          />,
        );
        breakFunction = true;
      }
    });

    if (breakFunction) {
      return;
    }

    if (context === SettingsContext.Workspace) {
      addWorkspaceMember(emails);
    } else if (context === SettingsContext.Task) {
      addTaskMember(emails, uuids);
    } else {
      addProjectMember(emails, uuids);
    }
  };

  return (
    <>
      <AnimatedDiv
        className={classNames("members", {
          "members--no-client-mode-switch": !permissions?.project?.includes(
            "share_project_resources_preview",
          ),
        })}
      >
        <MembersContext.Provider
          value={{
            users,
            setUsers,
            members,
            addMember,
            onClose,
            openRolesInfoModal,
            loading,
            setLoading,
            selectedRole,
            setSelectedRole,
            permissions,
            taskId,
            projectId,
            setRemoveUserData,
            openShowRemoveUserModal: () => setShowRemoveUserModal(true),
          }}
        >
          <ModalTitle>
            <span>
              <IDHFormattedMessage id="ws_invite" defaultMessage="Invite" />
            </span>
            {context === SettingsContext.Project && (
              <div
                className="members__roles-button"
                onClick={() => {
                  openRolesInfoModal();
                  onClose();
                }}
              >
                <IDHFormattedMessage id="ws_roles" defaultMessage="Roles" />
                <InfoGreyIcon className="members__info-icon" />
              </div>
            )}
            {context === SettingsContext.Workspace && (
              <div
                className="members__roles-button"
                onClick={() => {
                  openWorkspaceRolesInfoModal();
                }}
              >
                <IDHFormattedMessage id="ws_roles" defaultMessage="Roles" />
                <InfoGreyIcon className="members__info-icon" />
              </div>
            )}
          </ModalTitle>
          {((permissions?.project?.includes("invite_operator") &&
            selectedRole.value === "member") ||
            (permissions?.project?.includes("invite_client") &&
              selectedRole.value === "client") ||
            permissions?.workspace?.includes("manage_members")) && (
            <>
              <InviteUser />
              {users.length > 0 && (
                <ModalRow className="members__notification-check">
                  <CustomCheckbox
                    id="send-notification-checkbox"
                    name="send-notification-checkbox"
                    label={
                      <IDHFormattedMessage
                        id="ws_send_notification"
                        defaultMessage="Send notification"
                      />
                    }
                    checked={sendNotification}
                    onChange={(e) => setSendNotification(e.target.checked)}
                  />
                </ModalRow>
              )}
            </>
          )}
          <UserList getData={getData} />

          {permissions?.project?.includes(
            "share_project_resources_preview",
          ) && (
            <ClientMode
              openSeeAsClientInfoModal={openSeeAsClientInfoModal}
              members={members}
              projectId={projectId}
              permissions={permissions}
              clientMode={clientMode}
              closeShareProjectModal={onClose}
              getData={getData}
            />
          )}
        </MembersContext.Provider>
      </AnimatedDiv>

      <SettingsButtons onClose={onClose} />

      {showRemoveUserModal && (
        <RemoveUserModal
          onClose={() => setShowRemoveUserModal(false)}
          removeUserData={removeUserData}
          getData={getData}
        />
      )}
    </>
  );
}
