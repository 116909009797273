import {
  getDictionaryUuidFromCurrentUrl,
  getWorkspaceUuidFromCurrentUrl,
  parseErrorMessages,
  updateSelectedTableViewInLS,
} from "src/utils/methods";
import {
  RESET_DICTIONARY_FILTERS_REDUCER,
  RESET_HIDDEN_COLUMNS,
  SET_DICTIONARY_COLUMN_RANK,
  SET_DICTIONARY_FILTER,
  SET_DICTIONARY_FILTERS,
  SET_DICTIONARY_INITIAL_COLUMN_RANKS,
  SET_DICTIONARY_SORT,
  SET_DICTIONARY_SORT_BY,
  SET_HIDDEN_DICTIONARY_COLUMN_IDS,
  SET_SELECTED_TABLE_VIEW,
  TOGGLE_DICTIONARY_COLUMN_VISIBILITY,
} from "src/app/components/DictionaryFilters/redux/dictionaryFiltersTypes";
import { valueExists } from "src/app/methods/valueExists";
import {
  generateLackingRanks,
  generateMetadataRanks,
} from "src/app/CreatorDatabase/redux/creatorDatabaseFiltersReducer";

const wsWorkspaceUuid = getWorkspaceUuidFromCurrentUrl();

const defaultSelectedTableView = {
  label: "Custom",
  value: null,
};

export const dictionaryFiltersReducerInitialState = (dictionaryColumns) => {
  const settingsData = JSON.parse(
    localStorage.getItem("ws-dictionary-settings"),
  );
  const settingsForWorkspace = settingsData
    ? settingsData[wsWorkspaceUuid]
    : null;

  const newColumns = generateMetadataRanks(dictionaryColumns);
  const defaultSettings = {
    sortBy: null,
    sort: "DESC",
    filters: [],
    hiddenColumns: [],
    dictionaryColumnRanks: newColumns,
    selectedTableView: defaultSelectedTableView,
  };

  if (!settingsForWorkspace) return defaultSettings;

  const settingsForDictionary =
    settingsForWorkspace[getDictionaryUuidFromCurrentUrl()];

  const selectedTableView = settingsForDictionary?.selectedTableView;

  if (selectedTableView?.data) {
    return {
      ...settingsForDictionary,
      ...selectedTableView.data,
      selectedTableView,
      dictionaryColumnRanks: generateLackingRanks(
        dictionaryColumns,
        settingsForDictionary?.dictionaryColumnRanks,
      ),
    };
  }

  if (settingsForDictionary?.dictionaryColumnRanks) {
    return {
      ...settingsForDictionary,
      dictionaryColumnRanks: generateLackingRanks(
        dictionaryColumns,
        settingsForDictionary?.dictionaryColumnRanks,
      ),
    };
  }

  if (settingsForDictionary) {
    return { ...settingsForDictionary, dictionaryColumnRanks: newColumns };
  }

  return defaultSettings;
};

export const save = (obj, skipLSUpdate) => {
  const previousSave = JSON.parse(
    localStorage.getItem("ws-dictionary-settings"),
  );

  const itemToSet = previousSave?.hasOwnProperty(wsWorkspaceUuid)
    ? {
        ...previousSave[wsWorkspaceUuid],
        [getDictionaryUuidFromCurrentUrl()]: obj,
      }
    : {
        [getDictionaryUuidFromCurrentUrl()]: obj,
      };

  if (!skipLSUpdate) {
    localStorage.setItem(
      "ws-dictionary-settings",
      JSON.stringify({
        ...previousSave,
        [wsWorkspaceUuid]: itemToSet,
      }),
    );
  }

  return sanitizeFilters(obj);
};

const sanitizeFilters = (obj) => {
  // stupid but it works, gets rid of keys for undefined values
  return JSON.parse(JSON.stringify(obj));
};

function dictionaryFiltersReducer(state, action) {
  if (
    action?.payload?.data?.status === "fail" ||
    action?.error?.response?.status >= 500
  ) {
    return {
      ...state,
      errorMessages: [{ message: "An unexpected error occurred." }],
    };
  }

  switch (action.type) {
    case SET_DICTIONARY_SORT_BY:
      return save(
        {
          ...state,
          selectedTableView: defaultSelectedTableView,
          sortBy: action.payload.sortBy,
        },
        action.payload.skipLSUpdate,
      );

    case SET_DICTIONARY_SORT:
      return save(
        {
          ...state,
          selectedTableView: defaultSelectedTableView,
          sort: action.payload.sort,
        },
        action.payload.skipLSUpdate,
      );

    case SET_DICTIONARY_FILTERS:
      return save(
        {
          ...state,
          selectedTableView: defaultSelectedTableView,
          filters: action.payload.filters,
        },
        action.payload.skipLSUpdate,
      );

    case SET_DICTIONARY_FILTER:
      return save({
        ...state,
        selectedTableView: defaultSelectedTableView,
        filters: {
          ...state.filters,
          [action.payload.fieldUuid]: !valueExists(action.payload.value)
            ? undefined
            : { value: action.payload.value },
        },
      });

    case RESET_DICTIONARY_FILTERS_REDUCER:
      return dictionaryFiltersReducerInitialState();

    case TOGGLE_DICTIONARY_COLUMN_VISIBILITY: {
      const hiddenColumns = state.hiddenColumns || [];
      return save({
        ...state,
        selectedTableView: defaultSelectedTableView,
        hiddenColumns: hiddenColumns?.includes(action.payload.uuid)
          ? hiddenColumns.filter((hc) => hc !== action.payload.uuid)
          : [...hiddenColumns, action.payload.uuid],
      });
    }

    case SET_HIDDEN_DICTIONARY_COLUMN_IDS:
      return save(
        {
          ...state,
          selectedTableView: defaultSelectedTableView,
          hiddenColumns: action.payload.ids,
        },
        action.payload.skipLSUpdate,
      );

    case SET_DICTIONARY_INITIAL_COLUMN_RANKS:
      return {
        ...state,
        dictionaryColumnRanks: action.payload.ranks,
      };

    case RESET_HIDDEN_COLUMNS:
      return save({
        ...state,
        selectedTableView: defaultSelectedTableView,
        hiddenColumns: [],
      });

    case SET_DICTIONARY_COLUMN_RANK: {
      const { uuid, rank } = action.payload;

      let foundRank = false;

      const newRanks = state.dictionaryColumnRanks.map((col) => {
        if (col.uuid === uuid) {
          foundRank = true;
          return {
            ...col,
            rank,
          };
        }

        return col;
      });

      if (!foundRank) newRanks.push({ uuid, rank });

      return save({
        ...state,
        dictionaryColumnRanks: newRanks,
      });
    }

    case SET_SELECTED_TABLE_VIEW:
      updateSelectedTableViewInLS(
        "ws-dictionary-settings",
        action.payload.selectedTableView,
      );
      return {
        ...state,
        selectedTableView: action.payload.selectedTableView,
      };

    default:
      if (action?.error?.response?.data && action.type.includes("_FAIL")) {
        return {
          ...state,
          errorMessages: parseErrorMessages(
            action?.error?.response?.data?.errors,
          ),
        };
      }

      return state;
  }
}

export default dictionaryFiltersReducer;
