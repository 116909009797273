import React, { Dispatch, SetStateAction } from "react";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { PublicationStatus } from "src/types/publication";
import emptyState from "src/images/empty-state-no-user-found-2.svg";
import { ReactComponent as EditIcon } from "src/images/edit.svg";
import { EmptyTabContainer } from "./EmptyTabContainer/EmptyTabContainer";
import { EmptyTabContent } from "./EmptyTabContent/EmptyTabContent";

export const publicationNotDetectedStatuses = [
  PublicationStatus.Error,
  PublicationStatus.HiddenLikesEnabled,
  PublicationStatus.NotFound,
  PublicationStatus.RestrictedByAge,
];

type PublicationNotDetectedStatus =
  | PublicationStatus.Error
  | PublicationStatus.HiddenLikesEnabled
  | PublicationStatus.NotFound
  | PublicationStatus.RestrictedByAge;

const renderErrorMessage = (status: PublicationNotDetectedStatus) => {
  switch (status) {
    case PublicationStatus.Error:
      return (
        <IDHFormattedMessage
          id="ws_publication_error"
          defaultMessage="An error has ocurred."
        />
      );
    case PublicationStatus.NotFound:
      return (
        <IDHFormattedMessage
          id="ws_publication_not_found"
          defaultMessage="Please check the link's correctness."
        />
      );
    case PublicationStatus.HiddenLikesEnabled:
      return (
        <IDHFormattedMessage
          id="ws_publication_hidden_likes_enabled_description"
          defaultMessage="Hidden likes enabled on publication."
        />
      );
    case PublicationStatus.RestrictedByAge:
      return (
        <IDHFormattedMessage
          id="ws_publication_restricted"
          defaultMessage="The publication's account is restricted."
        />
      );
    default:
      return (
        <IDHFormattedMessage
          id="ws_publication_error"
          defaultMessage="An error has ocurred."
        />
      );
  }
};

const renderErrorMessageHeader = (status: PublicationNotDetectedStatus) => {
  switch (status) {
    case PublicationStatus.Error:
      return (
        <IDHFormattedMessage
          id="ws_publication_not_detected_header"
          defaultMessage="Publication was not detected."
        />
      );
    case PublicationStatus.NotFound:
      return (
        <IDHFormattedMessage
          id="ws_publication_not_found_summary"
          defaultMessage="Publication was not detected."
        />
      );
    case PublicationStatus.HiddenLikesEnabled:
      return (
        <IDHFormattedMessage
          id="ws_publication_restricted_hidden_likes_summary"
          defaultMessage="The publication is restricted."
        />
      );
    case PublicationStatus.RestrictedByAge:
      return (
        <IDHFormattedMessage
          id="ws_publication_restricted_by_age_summary"
          defaultMessage="The publication is restricted."
        />
      );
    default:
      return (
        <IDHFormattedMessage
          id="ws_publication_not_detected_header"
          defaultMessage="Publication was not detected."
        />
      );
  }
};

interface PublicationNotDetectedProps {
  status: PublicationNotDetectedStatus;
  setShowUpdatePublicationLinkModal: Dispatch<SetStateAction<boolean>>;
}

export default function PublicationNotDetected({
  status,
  setShowUpdatePublicationLinkModal,
}: PublicationNotDetectedProps) {
  return (
    <EmptyTabContainer className="empty-social-profiles-tab">
      <EmptyTabContent
        header={renderErrorMessageHeader(status)}
        text={renderErrorMessage(status)}
        imgUrl={emptyState}
        buttonIcon={<EditIcon />}
        onButtonClick={() => setShowUpdatePublicationLinkModal(true)}
        buttonText={
          <IDHFormattedMessage
            id="ws_edit_publication_link"
            defaultMessage="Edit publication link"
          />
        }
      />
    </EmptyTabContainer>
  );
}
