import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import {
  GetCustomerPortalByRefIdDocument,
  GetCustomerPortalByRefIdQuery,
  GetCustomerPortalByRefIdQueryVariables,
  GetCustomerByIdDocument,
  GetCustomerByIdQuery,
  GetCustomerByIdQueryVariables,
  GetActiveSubscriptionsQuery,
  GetActiveSubscriptionsQueryVariables,
  GetActiveSubscriptionsDocument,
  GetActiveSubscriptionsListQuery,
  GetActiveSubscriptionsListQueryVariables,
  GetActiveSubscriptionsListDocument,
  GetSubscriptionQuery,
  GetSubscriptionDocument,
  GetSubscriptionQueryVariables,
  GetUsageHistoryQuery,
  GetUsageHistoryQueryVariables,
  GetUsageHistoryDocument,
} from '@stigg/api-client-js/src/generated/sdk';
import { FetchUsageHistory } from '../models';
import { isArray } from 'lodash';
import { EdgeApiClient } from './EdgeApiClient';

class CustomersApi {
  constructor(
    private readonly client: ApolloClient<NormalizedCacheObject>,
    private readonly edgeApiClient: EdgeApiClient | null,
  ) {}

  getCustomer(customerId: string) {
    return this.client.query<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>({
      query: GetCustomerByIdDocument,
      variables: {
        input: { customerId },
      },
      fetchPolicy: 'no-cache',
    });
  }

  async getActiveSubscriptions(customerId: string, resourceId?: string | string[]) {
    return this.client.query<GetActiveSubscriptionsQuery, GetActiveSubscriptionsQueryVariables>({
      query: GetActiveSubscriptionsDocument,
      variables: {
        // keep resourceId for back-compatability (behavior of raising an error when resourceId is not found)
        input: isArray(resourceId) ? { customerId, resourceIds: resourceId } : { customerId, resourceId: resourceId },
      },
      fetchPolicy: 'no-cache',
    });
  }

  async getActiveSubscriptionsList(customerId: string, resourceId?: string | string[]) {
    if (this.edgeApiClient) {
      return this.edgeApiClient.getActiveSubscriptionsList(customerId, resourceId);
    }

    return this.client.query<GetActiveSubscriptionsListQuery, GetActiveSubscriptionsListQueryVariables>({
      query: GetActiveSubscriptionsListDocument,
      variables: {
        // keep resourceId for back-compatability (behavior of raising an error when resourceId is not found)
        input: isArray(resourceId) ? { customerId, resourceIds: resourceId } : { customerId, resourceId: resourceId },
      },
      fetchPolicy: 'no-cache',
    });
  }

  async getSubscription(subscriptionId: string) {
    return this.client.query<GetSubscriptionQuery, GetSubscriptionQueryVariables>({
      query: GetSubscriptionDocument,
      variables: {
        input: {
          subscriptionId,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  getCustomerPortal(customerId: string, resourceId: string | undefined, productId: string | undefined) {
    return this.client.query<GetCustomerPortalByRefIdQuery, GetCustomerPortalByRefIdQueryVariables>({
      query: GetCustomerPortalByRefIdDocument,
      variables: {
        input: {
          customerId,
          resourceId,
          productId,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }

  async fetchUsageHistory({ customerId, resourceId, endDate, startDate, featureId }: FetchUsageHistory) {
    return this.client.query<GetUsageHistoryQuery, GetUsageHistoryQueryVariables>({
      query: GetUsageHistoryDocument,
      variables: {
        usageHistoryInput: {
          customerRefId: customerId,
          featureRefId: featureId,
          resourceRefId: resourceId,
          startDate: startDate,
          endDate: endDate || new Date(),
        },
      },
      fetchPolicy: 'no-cache',
    });
  }
}

export default CustomersApi;
