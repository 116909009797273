import React, { useState } from "react";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "src/app/components/Dropdown/Dropdown";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as DotsIcon } from "src/images/dots.svg";
import { ReactComponent as EditIcon } from "src/images/edit.svg";
import { ReactComponent as DeleteIcon } from "src/images/trash-can.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { RemoveModal } from "src/app/modals/RemoveModal/RemoveModal";
import { showErrorToast } from "src/utils/methods";
import { API_URLS } from "src/utils/API_URLS";
import { wsAxiosDelete } from "src/helpers/wsAxios";
import { ReactComponent as SingleSelectIcon } from "src/images/metafield-types/single-select.svg";
import { ReactComponent as MultiSelectIcon } from "src/images/metafield-types/multi-select.svg";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { tableDataType } from "src/app/components/Table/Table";
import EditSelectOptionsModal from "../../ManageFields/modals/EditSelectOptionsModal/EditSelectOptionsModal";
import { DictionaryField } from "../types";
import UpdateDictionaryFieldModal from "./UpdateDictionaryFieldModal";

interface DictionaryFieldActionDropdownProps {
  editedDictionaryUuid: string;
  field: DictionaryField;
  syncLocalDictionaryWithServer: (uuid: string) => Promise<void>;
}

const SELECT_TYPES = [MetaFieldType.SingleSelect, MetaFieldType.MultiSelect];

export default function DictionaryFieldActionDropdown({
  editedDictionaryUuid,
  field,
  syncLocalDictionaryWithServer,
}: DictionaryFieldActionDropdownProps) {
  const [isEditFieldDropdownOpen, setIsEditFieldDropdownOpen] = useState(false);
  const [isDeleteFieldModalOpen, setIsDeleteFieldModalOpen] = useState(false);
  const [showManageOptionsModal, setShowManageOptionsModal] = useState(false);

  const getSelectIcon = () => {
    switch (field.type) {
      case MetaFieldType.MultiSelect:
        return <MultiSelectIcon />;

      case MetaFieldType.SingleSelect:
        return <SingleSelectIcon />;

      default:
        return <SingleSelectIcon />;
    }
  };

  const showSelectEditButton = SELECT_TYPES.includes(field.type);

  return (
    <Dropdown toRight>
      {(isOpen, setIsOpen) => (
        <>
          <Button
            variant="light-grey"
            size="large"
            onClick={() => setIsOpen(true)}
            className="dropdown-button"
            data-qa-button="toggleWsDictionaryMetaFieldActionDropdown"
          >
            <DotsIcon />
          </Button>
          <DropdownMenu isOpen={isOpen}>
            <DropdownMenuItem
              onClick={() => setIsEditFieldDropdownOpen(true)}
              data-qa-button="editWsDictionaryMetaFieldOptions"
            >
              <EditIcon />
              <IDHFormattedMessage id="ws_edit" defaultMessage="Edit" />
            </DropdownMenuItem>
            {showSelectEditButton && (
              <DropdownMenuItem
                onClick={() => setShowManageOptionsModal(true)}
                data-qa-button="manageWsDictionaryMetaFieldOptions"
              >
                {getSelectIcon()}
                <IDHFormattedMessage
                  id="ws_manage_options"
                  defaultMessage="Manage options"
                />
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onClick={() => setIsDeleteFieldModalOpen(true)}
              className="dropdown__menu-item--danger"
              data-qa-button="removeWsDictionaryMetaField"
            >
              <DeleteIcon />
              <IDHFormattedMessage id="ws_remove" defaultMessage="Remove" />
            </DropdownMenuItem>
          </DropdownMenu>

          {isEditFieldDropdownOpen && (
            <UpdateDictionaryFieldModal
              editedDictionaryUuid={editedDictionaryUuid}
              setIsEditFieldDropdownOpen={setIsEditFieldDropdownOpen}
              editedField={field}
              syncLocalDictionaryWithServer={syncLocalDictionaryWithServer}
            />
          )}

          {showManageOptionsModal && (
            <EditSelectOptionsModal
              onClose={() => setShowManageOptionsModal(false)}
              data={field}
              dataType={tableDataType.DictionaryManager}
            />
          )}

          {isDeleteFieldModalOpen && (
            <RemoveModal
              onClose={() => setIsDeleteFieldModalOpen(false)}
              objectNames={[field.name]}
              removeFunction={() => {
                wsAxiosDelete({
                  url: API_URLS.deleteDictionaryMetaFieldItem,
                  data: {
                    metaFieldUuid: field.uuid,
                  },
                  thenCallback: () => {
                    syncLocalDictionaryWithServer(editedDictionaryUuid);
                  },
                  errorCallback: () => showErrorToast(),
                });
              }}
            />
          )}
        </>
      )}
    </Dropdown>
  );
}
