import React, { useState, useEffect } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { arrayMove } from "@dnd-kit/sortable";
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useSelector, useDispatch } from "react-redux";

import { setSelectedTasks, updateListTaskRank } from "src/redux";
import { RootState } from "src/redux/reducers";
import { generateRankString } from "src/utils/rankStrings";
import NoResultsScreen from "src/app/components/NoResultsScreen/NoResultsScreen";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { Button } from "src/app/components/Button/Button";
import {
  clearWorkspaceTaskFilters,
  setWSTasksColumnRank,
} from "src/redux/workspaceTaskFilters/workspaceTaskFiltersActions";
import noFilteredResultsImg from "src/images/empty-folder-group.svg";
import noTasksImg from "src/images/data-cloud-1.svg";
import {
  TableVirtuosoContext,
  VirtuosoTable,
  VirtuosoTableRow,
  VirtuosoTableHead,
  VirtuosoTableBody,
} from "src/app/project/SingleProject/TasksTable/components/virtuosoComponents";
import { TableContext } from "src/app/project/TableField/types";
import { TableTaskData } from "src/app/project/SingleProject/TasksTable/types";
import { ITask } from "src/redux/task/taskReducer";

interface WorkspaceTasksTableProps {
  workspaceTasks: ITask[];
  columns: any;
  tableContext: TableContext;
  loading: boolean;
}

export default function WorkspaceTasksTable({
  workspaceTasks,
  columns,
  tableContext,
  loading,
}: WorkspaceTasksTableProps) {
  const [data, setData] = useState<TableTaskData[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isDragging, setIsDragging] = useState(false);

  const dispatch = useDispatch();

  const { filters, visibleColumns } = useSelector(
    (state: RootState) => state.workspaceTaskFiltersReducer,
  );

  const activeSortingColumn = useSelector(
    (state: RootState) => state.workspaceTaskFiltersReducer.activeSortingColumn,
  );

  const selectedTasks = useSelector(
    (state: RootState) => state.taskReducer.selectedTasks,
  );

  const tableInputFocused = useSelector(
    (state: RootState) => state.taskReducer.tableInputFocused,
  );

  const {
    taskReducer: { workspaceTasksTotalCount },
  } = useSelector((state: RootState) => state);

  const columnRanks = useSelector(
    (state: RootState) => state.workspaceTaskFiltersReducer.columnRanks,
  );

  const table = useReactTable({
    data,
    columns,
    debugTable: true,
    state: {
      rowSelection,
      sorting,
      columnPinning: {
        left: [
          "drag-handle",
          "select",
          "creator",
          "content",
          "publication",
          "payment",
          "action",
        ],
        right: [" action"],
      },
    },
    enableColumnResizing: true,
    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getRowId: (row: { id: string }) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const moveRow = (oldIndex: number, newIndex: number) => {
    setData((prevData) => {
      return arrayMove(prevData, oldIndex, newIndex);
    });
  };

  const updateRowRank = (oldIndex: number, newIndex: number) => {
    if (activeSortingColumn || oldIndex === newIndex) return;

    const sourceFieldId = rows[oldIndex]?.id;

    let newRank = "";
    if (newIndex > oldIndex) {
      newRank = generateRankString(
        // @ts-ignore
        rows[newIndex + 1]?.original.rank || "",
        // @ts-ignore
        rows[newIndex]?.original.rank,
      );
    } else {
      newRank = generateRankString(
        // @ts-ignore
        rows[newIndex]?.original.rank,
        // @ts-ignore
        rows[newIndex - 1]?.original.rank || "",
      );
    }

    dispatch(updateListTaskRank(sourceFieldId, newRank));
  };

  const handleColumnDragEnd = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) {
      setIsDragging(false);
      return;
    }

    const ranks = columnRanks
      ?.filter((item) => visibleColumns.includes(item.uuid))
      ?.sort((a, b) => a.rank.localeCompare(b.rank));

    const sourceFieldId = ranks[oldIndex]?.uuid;
    const targetFieldId = ranks[newIndex]?.uuid;

    const fieldIndex = ranks.findIndex(
      (column) => column.uuid === targetFieldId,
    );

    let newRank = "";
    if (newIndex < oldIndex) {
      newRank = generateRankString(
        ranks[fieldIndex - 1]?.rank || "",
        ranks[fieldIndex]?.rank,
      );
      // move bottom
    } else {
      newRank = generateRankString(
        ranks[fieldIndex]?.rank,
        ranks[fieldIndex + 1]?.rank || "",
      );
    }

    dispatch(setWSTasksColumnRank(sourceFieldId, newRank));
    setIsDragging(false);
  };

  const handleRowDragEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      moveRow(oldIndex, newIndex);
      updateRowRank(oldIndex, newIndex);
    }
  };

  useEffect(() => {
    const selectedRowsIds = Object.keys(rowSelection);

    const newSelectedRows = rows
      .filter((row) => selectedRowsIds.includes(row.id))
      .map((row) => row.original);
    dispatch(setSelectedTasks(newSelectedRows));
  }, [rows, rowSelection]);

  useEffect(() => {
    if (isDragging || tableInputFocused) return;

    setData(
      workspaceTasks.map((el) => ({
        id: el.taskId, // id === taskId (react-table needs id)
        ...el,
        publication: null,
        publicationCopy: el.publication ?? null, // This is copied because cell.render() method tries render publication this object
      })),
    );
  }, [workspaceTasks, isDragging, tableInputFocused]);

  useEffect(() => {
    if (!selectedTasks.length && Object.keys(rowSelection).length) {
      setRowSelection({});
    }
  }, [selectedTasks]);

  if (!loading && !rows.length) {
    if (Object.keys(filters).length > 0) {
      return (
        <div className="no-results-screen-wrapper">
          <NoResultsScreen
            title={
              <IDHFormattedMessage
                id="ws_no_results_found"
                defaultMessage="No results found"
              />
            }
            subtitle={
              <IDHFormattedMessage
                id="ws_remove_filters"
                defaultMessage="No results match the filter criteria. Remove filter or clear all filters to show results."
              />
            }
            imgUrl={noFilteredResultsImg}
            bottomContent={
              <Button
                size="large"
                variant="blue"
                onClick={() => dispatch(clearWorkspaceTaskFilters())}
              >
                <IDHFormattedMessage
                  id="ws_clear_filters"
                  defaultMessage="Clear filters"
                />
              </Button>
            }
          />
        </div>
      );
    }
    return (
      <div className="no-results-screen-wrapper">
        <NoResultsScreen
          title={
            <IDHFormattedMessage
              id="ws_empty_workspace_tasks_table_title"
              defaultMessage="There is no data to display"
            />
          }
          subtitle={
            <IDHFormattedMessage
              id="ws_empty_workspace_tasks_table_description"
              defaultMessage="Start adding objects in your campaigns.They will be displayed here in dedicated tabs."
            />
          }
          imgUrl={noTasksImg}
        />
      </div>
    );
  }

  return (
    <div className="tasks-table tasks-table--sticky">
      <TableVirtuoso
        data={rows}
        context={
          {
            table,
            handleRowDragEnd,
            handleColumnDragEnd,
            setIsDragging,
            tasksLoading: loading,
            tableContext,
            totalRowCount: workspaceTasksTotalCount,
          } satisfies TableVirtuosoContext
        }
        totalCount={rows.length}
        customScrollParent={
          (document.querySelector(".workspace-tasks-table") as HTMLElement) ||
          undefined
        }
        components={{
          Table: VirtuosoTable,
          // @ts-ignore
          TableHead: VirtuosoTableHead,
          // @ts-ignore
          TableBody: VirtuosoTableBody,
          TableRow: VirtuosoTableRow,
        }}
        fixedHeaderContent={() => <></>}
      />
    </div>
  );
}
