import React, { useEffect, useRef, useState } from "react";
import "./IconSelector.scss";

import classNames from "classnames";
import Pictogram, { PictogramVariants } from "../Pictogram/Pictogram";
import useOnClickOutside from "../../methods/useOnClickOutside";

import { ReactComponent as ChevronIcon } from "../../../images/chevron-down.svg";

const icons = require.context("../../../../public/pictograms", true, /\.svg$/);

export function IconSelector(props) {
  const { selectedIcon, setSelectedIcon, selectedColor } = props;

  const [svgNames, setSvgNames] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [search, setSearch] = useState("");

  const selectorRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    const newSvgNames = icons
      .keys()
      .map((item) => item.replace("./", "").replace(".svg", ""));

    setSvgNames(newSvgNames);
  }, []);

  useEffect(() => {
    if (!showMenu) {
      setSearch("");
    } else {
      inputRef.current.focus();
    }
  }, [showMenu]);

  useOnClickOutside(selectorRef, () => setShowMenu(false));

  const selectIcon = (identifier) => {
    setSelectedIcon({
      value: identifier,
      label: identifier.split("/")[1],
    });
    setShowMenu(false);
  };

  const folder = selectedIcon?.value?.includes("/")
    ? selectedIcon.value.split("/")[0]
    : "Social";

  const name = selectedIcon?.value?.includes("/")
    ? selectedIcon.value.split("/")[1]
    : selectedIcon.value;

  const variant =
    folder === "SocialProviders"
      ? PictogramVariants.Original
      : PictogramVariants[selectedColor];

  return (
    <div ref={selectorRef} className="icon-selector">
      <div
        className={classNames("icon-selector__button", {
          "icon-selector__button--menu-open": showMenu,
        })}
        onClick={() => setShowMenu(true)}
      >
        {showMenu || !selectedIcon.value ? (
          <>
            <input
              ref={inputRef}
              className="icon-selector__button-input"
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span
              className={classNames("icon-selector__button-chevron", {
                "icon-selector__button-chevron--rotated": showMenu,
              })}
            >
              <ChevronIcon />
            </span>
          </>
        ) : (
          <>
            <span className="icon-selector__button-selected-icon">
              {selectedIcon.value && (
                <Pictogram identifier={`${folder}/${name}`} variant={variant} />
              )}
              <span className="icon-selector__button-selected-icon-name">
                {selectedIcon.label}
              </span>
            </span>
            <span
              className={classNames("icon-selector__button-chevron", {
                "icon-selector__button-chevron--rotated": showMenu,
              })}
            >
              <ChevronIcon />
            </span>
          </>
        )}
      </div>

      {showMenu && (
        <div
          className="icon-selector__menu"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="icon-selector__menu-list">
            {svgNames
              .filter((item) =>
                item.toLowerCase().includes(search.toLowerCase()),
              )
              .map((identifier) => {
                const folder = identifier.split("/")[0];
                const variant =
                  folder === "SocialProviders"
                    ? PictogramVariants.Original
                    : PictogramVariants[selectedColor];

                return (
                  <span
                    key={identifier}
                    className={classNames("icon-selector__menu-item", {
                      "icon-selector__menu-item--selected":
                        selectedIcon.label === identifier.split("/")[1],
                    })}
                    onClick={() => selectIcon(identifier)}
                    data-qa-icon={identifier}
                  >
                    <Pictogram variant={variant} identifier={identifier} />
                  </span>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
