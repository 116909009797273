import React from "react";

import { ReactComponent as InfoIcon } from "src/images/info-white.svg";
import IDHFormattedMessage from "../IDHFormattedMessage/IDHFormattedMessage";

import "./DemoModeInfoBar.scss";

export const DEMO_MODE_INFO_BAR_PADDING = 40;

function DemoModeInfoBar() {
  return (
    <div className="demo-mode-info-bar">
      <InfoIcon />
      <IDHFormattedMessage
        id="ws_demo_mode_is_enabled"
        defaultMessage="Demo mode is enabled. The displayed data is for demonstration
            purposes only."
      />
    </div>
  );
}

export default DemoModeInfoBar;
