import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { TaskType } from "src/types";

export const mockTasks = {
  tasks: [
    {
      taskId: "1",
      parentTaskId: "1",
      title: "1",
      titleMetaFieldId: "1",
      ownerWsMemberUuid: "1",
      rank: "a",
      taskType: TaskType.Creator,
      subtasksCount: 0,
      hasRelatedActionTasks: false,
      hasRelatedContentTasks: false,
      hasRelatedPublicationTasks: false,
      hasSubtasks: false,
      metadata: [],
      members: [],
      cover: null,
      shared: false,
      wsGlobalTaskUuid: null,
      socialProfiles: [],
      wsProjectUuid: "",
      created: "",
    },
    {
      taskId: "2",
      parentTaskId: "2",
      title: "2",
      titleMetaFieldId: "2",
      ownerWsMemberUuid: "2",
      rank: "b",
      taskType: TaskType.Creator,
      subtasksCount: 0,
      hasRelatedActionTasks: false,
      hasRelatedContentTasks: false,
      hasRelatedPublicationTasks: false,
      hasSubtasks: false,
      metadata: [],
      members: [],
      cover: null,
      shared: false,
      wsGlobalTaskUuid: null,
      socialProfiles: [],
      wsProjectUuid: "",
      created: "",
    },
    {
      taskId: "3",
      parentTaskId: "3",
      title: "3",
      titleMetaFieldId: "3",
      ownerWsMemberUuid: "3",
      rank: "b",
      taskType: TaskType.Creator,
      subtasksCount: 0,
      hasRelatedActionTasks: false,
      hasRelatedContentTasks: false,
      hasRelatedPublicationTasks: false,
      hasSubtasks: false,
      metadata: [],
      members: [],
      cover: null,
      shared: false,
      wsGlobalTaskUuid: null,
      socialProfiles: [],
      wsProjectUuid: "",
      created: "",
    },
  ],
  manyTasks: [
    {
      taskId: "1",
      parentTaskId: "1",
      title: "1",
      titleMetaFieldId: "1",
      ownerWsMemberUuid: "1",
      rank: "a",
      taskType: TaskType.Creator,
      subtasksCount: 0,
      hasRelatedActionTasks: false,
      hasRelatedContentTasks: false,
      hasRelatedPublicationTasks: false,
      hasSubtasks: false,
      metadata: [],
      members: [],
      cover: null,
      shared: false,
      wsGlobalTaskUuid: null,
      socialProfiles: [],
      wsProjectUuid: "",
      created: "",
    },
    {
      taskId: "2",
      parentTaskId: "2",
      title: "2",
      titleMetaFieldId: "2",
      ownerWsMemberUuid: "2",
      rank: "b",
      taskType: TaskType.Creator,
      subtasksCount: 0,
      hasRelatedActionTasks: false,
      hasRelatedContentTasks: false,
      hasRelatedPublicationTasks: false,
      hasSubtasks: false,
      metadata: [],
      members: [],
      cover: null,
      shared: false,
      wsGlobalTaskUuid: null,
      socialProfiles: [],
      wsProjectUuid: "",
      created: "",
    },
    {
      taskId: "3",
      parentTaskId: "3",
      title: "3",
      titleMetaFieldId: "3",
      ownerWsMemberUuid: "3",
      rank: "b",
      taskType: TaskType.Creator,
      subtasksCount: 0,
      hasRelatedActionTasks: false,
      hasRelatedContentTasks: false,
      hasRelatedPublicationTasks: false,
      hasSubtasks: false,
      metadata: [],
      members: [],
      cover: null,
      shared: false,
      wsGlobalTaskUuid: null,
      socialProfiles: [],
      wsProjectUuid: "",
      created: "",
    },
    {
      taskId: "4",
      parentTaskId: "4",
      title: "4",
      titleMetaFieldId: "4",
      ownerWsMemberUuid: "4",
      rank: "d",
      taskType: TaskType.Creator,
      subtasksCount: 0,
      hasRelatedActionTasks: false,
      hasRelatedContentTasks: false,
      hasRelatedPublicationTasks: false,
      hasSubtasks: false,
      metadata: [],
      members: [],
      cover: null,
      shared: false,
      wsGlobalTaskUuid: null,
      socialProfiles: [],
      wsProjectUuid: "",
      created: "",
    },
    {
      taskId: "5",
      parentTaskId: "5",
      title: "5",
      titleMetaFieldId: "5",
      ownerWsMemberUuid: "5",
      rank: "e",
      taskType: TaskType.Creator,
      subtasksCount: 0,
      hasRelatedActionTasks: false,
      hasRelatedContentTasks: false,
      hasRelatedPublicationTasks: false,
      hasSubtasks: false,
      metadata: [],
      members: [],
      cover: null,
      shared: false,
      wsGlobalTaskUuid: null,
      socialProfiles: [],
      wsProjectUuid: "",
      created: "",
    },
    {
      taskId: "6",
      parentTaskId: "6",
      title: "6",
      titleMetaFieldId: "6",
      ownerWsMemberUuid: "6",
      rank: "f",
      taskType: TaskType.Creator,
      subtasksCount: 0,
      hasRelatedActionTasks: false,
      hasRelatedContentTasks: false,
      hasRelatedPublicationTasks: false,
      hasSubtasks: false,
      metadata: [],
      members: [],
      cover: null,
      shared: false,
      wsGlobalTaskUuid: null,
      socialProfiles: [],
      wsProjectUuid: "",
      created: "",
    },
  ],
  tasksColumns: [
    {
      metaFieldId: "1",
      metaFieldName: "",
      metaFieldKey: "1",
      metaFieldType: "text",
      metaFieldVisible: true,
      metaFieldRank: "a",
      metaFieldTaskType: "creator",
      metaFieldIsRequired: false,
      data: {
        singleSelectOptions: [],
      },
      metaFieldValueSource: null,
      metaFieldSource: null,
      isMocked: true,
    },
    {
      metaFieldId: "2",
      metaFieldName: "",
      metaFieldKey: "2",
      metaFieldType: "text",
      metaFieldVisible: true,
      metaFieldRank: "b",
      metaFieldTaskType: "creator",
      metaFieldIsRequired: false,
      data: {
        singleSelectOptions: [],
      },
      metaFieldValueSource: null,
      metaFieldSource: null,
      isMocked: true,
    },
    {
      metaFieldId: "3",
      metaFieldName: "",
      metaFieldKey: "3",
      metaFieldType: "text",
      metaFieldVisible: true,
      metaFieldRank: "c",
      metaFieldTaskType: "creator",
      metaFieldIsRequired: false,
      data: {
        singleSelectOptions: [],
      },
      metaFieldValueSource: null,
      metaFieldSource: null,
      isMocked: true,
    },
    {
      metaFieldId: "4",
      metaFieldName: "",
      metaFieldKey: "4",
      metaFieldType: "text",
      metaFieldVisible: true,
      metaFieldRank: "d",
      metaFieldTaskType: "creator",
      metaFieldIsRequired: false,
      data: {
        singleSelectOptions: [],
      },
      metaFieldValueSource: null,
      metaFieldSource: null,
      isMocked: true,
    },
    {
      metaFieldId: "5",
      metaFieldName: "",
      metaFieldKey: "5",
      metaFieldType: "text",
      metaFieldVisible: true,
      metaFieldRank: "e",
      metaFieldTaskType: "creator",
      metaFieldIsRequired: false,
      data: {
        singleSelectOptions: [],
      },
      metaFieldValueSource: null,
      metaFieldSource: null,
      isMocked: true,
    },
    {
      metaFieldId: "6",
      metaFieldName: "",
      metaFieldKey: "6",
      metaFieldType: "text",
      metaFieldVisible: true,
      metaFieldRank: "f",
      metaFieldTaskType: "creator",
      metaFieldIsRequired: false,
      data: {
        singleSelectOptions: [],
      },
      metaFieldValueSource: null,
      metaFieldSource: null,
      isMocked: true,
    },
    {
      metaFieldId: "7",
      metaFieldName: "",
      metaFieldKey: "7",
      metaFieldType: "text",
      metaFieldVisible: true,
      metaFieldRank: "g",
      metaFieldTaskType: "creator",
      metaFieldIsRequired: false,
      data: {
        singleSelectOptions: [],
      },
      metaFieldValueSource: null,
      metaFieldSource: null,
      isMocked: true,
    },
    {
      metaFieldId: "8",
      metaFieldName: "",
      metaFieldKey: "8",
      metaFieldType: "text",
      metaFieldVisible: true,
      metaFieldRank: "h",
      metaFieldTaskType: "creator",
      metaFieldIsRequired: false,
      data: {
        singleSelectOptions: [],
      },
      metaFieldValueSource: null,
      metaFieldSource: null,
      isMocked: true,
    },
  ],
};

export const mockMyTasks = [
  {
    completed: false,
    completedMetaFieldUuid: null,
    dueDate: null,
    dueDateMetaFieldUuid: "mock1",
    hasProjectAccess: true,
    wsProjectCover: null,
    wsProjectName: "mock1",
    wsProjectUuid: "mock1",
    wsTaskCover: null,
    wsTaskName: "mock1",
    wsTaskType: "action",
    wsTaskUuid: "mock1",
    loading: true,
  },
  {
    completed: false,
    completedMetaFieldUuid: null,
    dueDate: null,
    dueDateMetaFieldUuid: "mock2",
    hasProjectAccess: true,
    wsProjectCover: null,
    wsProjectName: "mock2",
    wsProjectUuid: "mock2",
    wsTaskCover: null,
    wsTaskName: "mock2",
    wsTaskType: "action",
    wsTaskUuid: "mock2",
    loading: true,
  },
  {
    completed: false,
    completedMetaFieldUuid: null,
    dueDate: null,
    dueDateMetaFieldUuid: "mock3",
    hasProjectAccess: true,
    wsProjectCover: null,
    wsProjectName: "mock3",
    wsProjectUuid: "mock3",
    wsTaskCover: null,
    wsTaskName: "mock3",
    wsTaskType: "action",
    wsTaskUuid: "mock3",
    loading: true,
  },
];

export const mockWorkspaceTasks = {
  tasks: mockTasks.tasks,
  manyTasks: mockTasks.manyTasks,
  columns: [
    {
      name: "",
      rank: "a",
      data: {},
      type: MetaFieldType.Text,
      uuid: "1",
      valueSource: null,
      accessLevel: null,
      isSystem: false,
    },
    {
      name: "",
      rank: "b",
      data: {},
      type: MetaFieldType.Text,
      uuid: "2",
      valueSource: null,
      accessLevel: null,
      isSystem: false,
    },
    {
      name: "",
      rank: "c",
      data: {},
      type: MetaFieldType.Text,
      uuid: "3",
      valueSource: null,
      accessLevel: null,
      isSystem: false,
    },
    {
      name: "",
      rank: "d",
      data: {},
      type: MetaFieldType.Text,
      uuid: "4",
      valueSource: null,
      accessLevel: null,
      isSystem: false,
    },
    {
      name: "",
      rank: "e",
      data: {},
      type: MetaFieldType.Text,
      uuid: "5",
      valueSource: null,
      accessLevel: null,
      isSystem: false,
    },
    {
      name: "",
      rank: "f",
      data: {},
      type: MetaFieldType.Text,
      uuid: "6",
      valueSource: null,
      accessLevel: null,
      isSystem: false,
    },
    {
      name: "",
      rank: "g",
      data: {},
      type: MetaFieldType.Text,
      uuid: "7",
      valueSource: null,
      accessLevel: null,
      isSystem: false,
    },
    {
      name: "",
      rank: "h",
      data: {},
      type: MetaFieldType.Text,
      uuid: "8",
      valueSource: null,
      accessLevel: null,
      isSystem: false,
    },
  ],
};
