import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GlobalData } from "src/app/components/GlobalTaskDetails/tabs/GlobalData/GlobalData";
import {
  clearGlobalTaskDetails,
  getGlobalTaskDetails,
} from "src/redux/creator-database/creatorDatabaseActions";
import { convertToLocalTimezone } from "src/app/methods/convertToLocalTimezone";
import { IMember } from "src/app/project/ProjectTypes";
import { RootState } from "src/redux/reducers";

interface GeneralTabProps {
  taskId: string;
}

export function GeneralTab({ taskId }: GeneralTabProps) {
  const dispatch = useDispatch();
  const {
    creatorDatabaseReducer: { globalTaskDetails },
    projectReducer: { membersList },
  } = useSelector((state: RootState) => state);

  const taskOwner = membersList?.find(
    (member: IMember) => member.id === globalTaskDetails?.ownerWsMemberUuid,
  );

  let dateCreated;
  if (globalTaskDetails?.created) {
    const dateValue = globalTaskDetails.created.date;
    if (dateValue !== null) {
      dateCreated = convertToLocalTimezone(dateValue);
    }
  }

  useEffect(() => {
    dispatch(getGlobalTaskDetails(taskId));
  }, [taskId]);

  useEffect(() => {
    return () => {
      dispatch(clearGlobalTaskDetails());
    };
  }, []);

  return (
    <GlobalData
      taskId={taskId}
      metadata={globalTaskDetails.metadata}
      taskOwner={taskOwner}
      dateCreated={dateCreated}
    />
  );
}
