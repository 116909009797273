import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import Tabs from "src/app/components/Tabs/Tabs";
import Tab from "src/app/components/Tabs/Tab";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { Button } from "src/app/components/Button/Button";
import { ReactComponent as PlusIcon } from "src/images/plus-white.svg";
import {
  clearDictionary,
  clearDictionaryMetaFieldSelectOptions,
  getDictionary,
} from "src/redux";
import EditedDictionaryTitle from "./components/EditedDictionaryTitle";
import ExtensionView from "../Extensions/ExtensionView";
import { Dictionary } from "./types";
import DictionaryManagerGeneralTab from "./tabs/DictionaryManagerGeneralTab";
import DictionaryManagerFieldsTab from "./tabs/DictionaryManagerFieldsTab";

enum EditedDictionaryTabs {
  GENERAL,
  FIELDS,
}

interface EditedDictionaryProps {
  editedDictionary: Dictionary;
  setEditedDictionary: Dispatch<SetStateAction<Dictionary | null>>;
}

function EditedDictionary({
  editedDictionary,
  setEditedDictionary,
}: EditedDictionaryProps) {
  const [activeTab, setActiveTab] = useState(EditedDictionaryTabs.GENERAL);
  const [isAddFieldManagerModalOpen, setIsAddFieldManagerModalOpen] =
    useState(false);

  const dispatch = useDispatch();

  const syncLocalDictionaryWithServer = async (uuid: string) => {
    const dictionaryDetails = await dispatch(getDictionary(uuid));

    if (dictionaryDetails?.payload?.data?.content) {
      setEditedDictionary(dictionaryDetails.payload.data.content);
    }
  };

  const renderTabContent = () => {
    if (activeTab === EditedDictionaryTabs.GENERAL) {
      return (
        <DictionaryManagerGeneralTab
          editedDictionary={editedDictionary}
          setEditedDictionary={setEditedDictionary}
          syncLocalDictionaryWithServer={syncLocalDictionaryWithServer}
        />
      );
    }
    if (activeTab === EditedDictionaryTabs.FIELDS) {
      return (
        <DictionaryManagerFieldsTab
          editedDictionary={editedDictionary}
          setEditedDictionary={setEditedDictionary}
          syncLocalDictionaryWithServer={syncLocalDictionaryWithServer}
          isAddFieldManagerModalOpen={isAddFieldManagerModalOpen}
          setIsAddFieldManagerModalOpen={setIsAddFieldManagerModalOpen}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (editedDictionary.url) {
      syncLocalDictionaryWithServer(editedDictionary.uuid);
    }
  }, [editedDictionary.uuid, editedDictionary.url]);

  useEffect(() => {
    return () => {
      dispatch(clearDictionaryMetaFieldSelectOptions());
      dispatch(clearDictionary());
    };
  }, []);

  return (
    <ExtensionView
      title={
        <EditedDictionaryTitle
          editedDictionary={editedDictionary}
          setEditedDictionary={setEditedDictionary}
          syncLocalDictionaryWithServer={syncLocalDictionaryWithServer}
        />
      }
      handleGoBack={() => setEditedDictionary(null)}
      goBackButtonLabel={
        <IDHFormattedMessage
          id="ws_dictionary_manager"
          defaultMessage="Dictionary Manager"
        />
      }
    >
      <AnimatedDiv>
        <Tabs>
          <div className="extensions__tabs">
            <Tab
              tabText={
                <IDHFormattedMessage id="ws_general" defaultMessage="General" />
              }
              onClick={() => setActiveTab(EditedDictionaryTabs.GENERAL)}
              active={activeTab === EditedDictionaryTabs.GENERAL}
            />
            <Tab
              tabText={
                <IDHFormattedMessage id="ws_fields" defaultMessage="Fields" />
              }
              onClick={() => setActiveTab(EditedDictionaryTabs.FIELDS)}
              active={activeTab === EditedDictionaryTabs.FIELDS}
            />
          </div>
          {activeTab === EditedDictionaryTabs.FIELDS &&
            editedDictionary.metaFields.length > 0 && (
              <Button
                size="small"
                variant="blue"
                onClick={() => setIsAddFieldManagerModalOpen(true)}
                data-qa-button="addWsDictionaryMetaField"
              >
                <PlusIcon />
                <IDHFormattedMessage
                  id="ws_add_field"
                  defaultMessage="Add field"
                />
              </Button>
            )}
        </Tabs>
        {renderTabContent()}
      </AnimatedDiv>
    </ExtensionView>
  );
}

export default EditedDictionary;
