import { AnyAction } from "redux";
import {
  GET_COUPONS_LIST_SUCCESS,
  CREATE_COUPON,
  CREATE_COUPON_FAIL,
  DELETE_COUPON,
  DELETE_COUPON_FAIL,
  RENAME_COUPON,
  RENAME_COUPON_FAIL,
  GET_COUPONS_LIST_FAIL,
  GET_COUPONS_LIST,
  GET_COUPON_GROUPS,
  GET_COUPON_GROUPS_SUCCESS,
  GET_COUPON_GROUP_DETAILS_SUCCESS,
  GET_COUPON_GROUP_DETAILS,
  CREATE_COUPON_GROUP_SUCCESS,
  SET_SHOULD_CLOSE_MODAL,
  CREATE_COUPON_SUCCESS,
  SET_LOADING,
  GET_COUPON_REDEMPTION_LOG_STRATEGY_SUCCESS,
} from "./couponTypes";

export interface Coupon {
  uuid: string;
  code: string;
  wsProject: any;
  wsTask: any;
  isAssigned: boolean;
  hasAccess: boolean;
}

export interface CouponGroup {
  uuid: string;
  name: string;
  promoCodesCounter: number;
}

export interface CouponRedemptionLogImportStrategies {
  uuid: string;
  name: string;
  description: string;
  executeClassname: number;
}

interface CouponReducerState {
  couponGroups: any[];
  couponGroupDetails: any;
  couponsList: Coupon[];
  couponsList_backup: Coupon[];
  listLoading: boolean;
  detailsLoading: boolean;
  couponRedemptionLogImportStrategies: CouponRedemptionLogImportStrategies[];
  shouldCloseModal: boolean;
}

const initialState: CouponReducerState = {
  couponGroups: [],
  couponGroupDetails: {},
  couponsList: [],
  couponsList_backup: [],
  couponRedemptionLogImportStrategies: [],
  listLoading: true,
  detailsLoading: true,
  shouldCloseModal: false,
};

function couponReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_COUPON_GROUPS:
      return {
        ...state,
        listLoading: !action.payload.disableLoading,
      };
    case GET_COUPONS_LIST:
    case GET_COUPON_GROUP_DETAILS:
      return {
        ...state,
        detailsLoading: true,
      };

    case GET_COUPON_GROUPS_SUCCESS: {
      return {
        ...state,
        couponGroups: action.payload.data.content,
        listLoading: false,
      };
    }

    case GET_COUPON_REDEMPTION_LOG_STRATEGY_SUCCESS: {
      return {
        ...state,
        couponRedemptionLogImportStrategies: action.payload.data.content,
      };
    }

    case CREATE_COUPON_SUCCESS:
    case CREATE_COUPON_GROUP_SUCCESS: {
      return {
        ...state,
        shouldCloseModal: true,
      };
    }

    case GET_COUPON_GROUP_DETAILS_SUCCESS: {
      const { coupons, ...couponGroupDetails } = action.payload.data.content;
      return {
        ...state,
        couponsList: coupons,
        couponGroupDetails,
        detailsLoading: false,
      };
    }

    case GET_COUPONS_LIST_SUCCESS: {
      return {
        ...state,
        couponsList: action.payload.data.content,
        detailsLoading: false,
      };
    }

    case GET_COUPONS_LIST_FAIL: {
      return {
        ...state,
        detailsLoading: false,
      };
    }

    case CREATE_COUPON: {
      const newCouponList = [
        ...action.payload.couponsData.reverse(),
        ...state.couponsList,
      ];

      return {
        ...state,
        couponsList: newCouponList,
        couponsList_backup: state.couponsList,
      };
    }

    case RENAME_COUPON: {
      const { code, uuid } = action.payload;
      return {
        ...state,
        couponsList: state.couponsList.map((coupon) => {
          if (coupon.uuid === uuid) {
            return {
              ...coupon,
              code,
            };
          }

          return coupon;
        }),
        couponsList_backup: state.couponsList,
      };
    }

    case DELETE_COUPON: {
      const { couponUuid } = action.payload;
      return {
        ...state,
        couponsList: state.couponsList.filter(
          (coupon) => coupon.uuid !== couponUuid,
        ),
        couponsList_backup: state.couponsList,
      };
    }

    case CREATE_COUPON_FAIL:
    case RENAME_COUPON_FAIL:
    case DELETE_COUPON_FAIL: {
      return {
        ...state,
        couponsList: state.couponsList_backup,
        couponsList_backup: [],
      };
    }

    case SET_SHOULD_CLOSE_MODAL: {
      const { shouldCloseModal } = action.payload;
      return {
        ...state,
        shouldCloseModal,
      };
    }

    case SET_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        detailsLoading: loading,
      };
    }

    default:
      return state;
  }
}

export default couponReducer;
