import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";

import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "src/app/methods/translateMessage";
import { ReactComponent as TickIconBlue } from "src/images/tick-blue.svg";
import { ReactComponent as CrossIcon } from "src/images/cross-grey.svg";
import { ReactComponent as EditIcon } from "src/images/edit-gray.svg";
import { Dictionary } from "../types";
import { handleUpdateDictionary } from "../utils";

interface EditedDictionaryTitleProps {
  editedDictionary: Dictionary;
  setEditedDictionary: Dispatch<SetStateAction<Dictionary | null>>;
  syncLocalDictionaryWithServer: (uuid: string) => Promise<void>;
}

function EditedDictionaryTitle({
  editedDictionary,
  setEditedDictionary,
  syncLocalDictionaryWithServer,
}: EditedDictionaryTitleProps) {
  const [isEditingName, setIsEditingName] = useState(false);
  const [dictionaryName, setDictionaryName] = useState(editedDictionary.name);

  const intl = useIntl();

  const handleEnableDictionary = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setEditedDictionary({ ...editedDictionary, enabled: checked });
    handleUpdateDictionary({
      data: {
        ...editedDictionary,
        enabled: checked,
      },
      catchCallback: () => syncLocalDictionaryWithServer(editedDictionary.uuid),
    });
  };

  const handleUpdateDictionaryName = () => {
    const dictionaryNameTrimmed = dictionaryName.trim();
    setIsEditingName(false);

    if (
      dictionaryNameTrimmed.length > 0 &&
      dictionaryNameTrimmed !== editedDictionary.name
    ) {
      setEditedDictionary((prevDictionary) => {
        if (!prevDictionary) return null;

        handleUpdateDictionary({
          data: {
            ...editedDictionary,
            name: dictionaryNameTrimmed,
          },
          catchCallback: () =>
            syncLocalDictionaryWithServer(editedDictionary.uuid),
        });

        return { ...prevDictionary, name: dictionaryNameTrimmed };
      });
    } else {
      setDictionaryName(editedDictionary.name);
    }
  };

  useEffect(() => {
    setDictionaryName(editedDictionary.name);
  }, [editedDictionary.name]);

  return (
    <div className="settings-modal__view-header">
      <div className="settings-modal__view-heading">
        {isEditingName ? (
          <div className="dictionary-manager__dictionary-name">
            <input
              type="text"
              onBlur={() => handleUpdateDictionaryName()}
              placeholder={translateMessage({
                intl,
                id: "ws_enter_dictionary_name",
                defaultMessage: "Enter dictionary name",
              })}
              value={dictionaryName}
              onChange={(e) => setDictionaryName(e.target.value)}
              data-qa-input="inputWsDictionaryName"
            />
            <div className="dictionary-manager__dictionary-name-controls">
              <button
                className="button-style-reset"
                type="button"
                onClick={() => handleUpdateDictionaryName()}
                data-qa-button="confirmWsDictionaryNameChange"
              >
                <TickIconBlue />
              </button>
              <button
                className="button-style-reset"
                data-qa-button="abortWsDictionaryNameChange"
                type="button"
                onClick={() => {
                  setIsEditingName(false);
                  setDictionaryName(editedDictionary.name);
                }}
              >
                <CrossIcon />
              </button>
            </div>
          </div>
        ) : (
          <>
            <span>{dictionaryName}</span>
            <button
              data-qa-button="editWsDictionaryName"
              onClick={() => setIsEditingName(true)}
              className="button-style-reset"
              type="button"
            >
              <EditIcon />
            </button>
          </>
        )}
      </div>
      <CustomSwitch
        className="dictionary-manager__switch"
        checked={editedDictionary.enabled}
        dataAttributes={{ "data-qa-input": "toggleWsDictionaryActive" }}
        label={
          editedDictionary.enabled ? (
            <IDHFormattedMessage id="ws_active" defaultMessage="Active" />
          ) : (
            <IDHFormattedMessage id="ws_disabled" defaultMessage="Disabled" />
          )
        }
        onChange={handleEnableDictionary}
      />
    </div>
  );
}

export default EditedDictionaryTitle;
