import React, { forwardRef, CSSProperties, ReactNode, Dispatch } from "react";
import classNames from "classnames";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Header, HeaderGroup } from "@tanstack/react-table";

import { TableContext } from "src/app/project/TableField/types";
import { SkeletonText } from "src/app/components/Skeleton/Skeleton";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ProjectFieldPreviewSwitch } from "src/app/project/TableField/components/ProjectFieldPreviewSwitch";
import { HeaderCell, SKELETON_BLACKLIST } from "../HeaderCell";
import getCommonPinningStyles from "../functions";
import Cell from "../Cell";

export interface TableVirtuosoContext {
  table: any;
  handleRowDragEnd: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => void;
  handleColumnDragEnd: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => Promise<void>;
  setIsDragging: Dispatch<React.SetStateAction<boolean>>;
  tasksLoading: boolean;
  tableContext?: TableContext;
  totalRowCount?: number;
}

interface DraggableRowProps {
  children: ReactNode;
  ["data-index"]: number;
  ["data-item-index"]: number;
  ["data-known-size"]: number;
  item: any;
  row: any;
  rowIndex: number;
  style: CSSProperties;
  context: TableVirtuosoContext;
}

export const DraggableRow = SortableElement((props: DraggableRowProps) => {
  const { row, rowIndex, context, item, ...rest } = props;

  const visibleCells = row.getVisibleCells();
  const cellsCount = visibleCells.length;

  return (
    // connect row ref to dnd-kit, apply important styles
    <tr {...rest} data-task-title={row?.original?.title}>
      {visibleCells.map((cell: any, cellIndex: any) => {
        return (
          <td
            className={classNames("tasks-table__td", {
              "tasks-table__td--in-draggable-column":
                cellIndex > 1 && cellIndex < cellsCount - 3,
              "tasks-table__td--action": cell.column.id === " action",
            })}
            style={{
              ...getCommonPinningStyles(cell.column),
            }}
          >
            {cell.column.id === "filler" ? null : (
              <Cell
                key={cell.id}
                cell={cell}
                rowData={cell.row.original}
                rowId={row.id}
                rowIndex={rowIndex}
                tableContext={context.tableContext}
                tasksLoading={context.tasksLoading}
              />
            )}
          </td>
        );
      })}
    </tr>
  );
});

export function VirtuosoTable(props: any) {
  const { style, context, ...rest } = props;
  return (
    <table
      {...rest}
      style={{
        ...style,
        width: "100%",
        tableLayout: "auto",
        borderCollapse: "collapse",
        borderSpacing: 1,
      }}
    />
  );
}

const DraggableHeaderCell = SortableElement(
  (props: {
    header: Header<any, any>;
    tasksLoading: boolean;
    tableContext: TableContext;
  }) => {
    const { header, tasksLoading, tableContext } = props;
    return (
      <HeaderCell
        key={header.id}
        header={header}
        columnIndex={header.index}
        tasksLoading={tasksLoading}
        tableContext={tableContext}
        draggable
      />
    );
  },
);

const TableHeadRowContext = SortableContainer(({ ...props }: any) => {
  const {
    headerGroup,
    tasksLoading,
    totalRowCount,
    currentRowCount,
    tableContext,
  }: {
    headerGroup: HeaderGroup<any>;
    tasksLoading: boolean;
    totalRowCount: number | undefined;
    currentRowCount: number;
    tableContext: TableContext;
  } = props;

  const headersFirstThree = headerGroup.headers.slice(0, 3);
  const headersRemaining = headerGroup.headers.slice(
    3,
    headerGroup.headers.length,
  );

  const hasAnyTotalRowObjects = headersFirstThree
    .concat(headersRemaining)
    .some((header: any) => Boolean(header.column.columnDef.totalRow));

  return (
    <>
      <tr className="tasks-table__column-dragging-context" key="header-group">
        {headersFirstThree.map((header: Header<any, any>) => {
          return (
            <HeaderCell
              key={header.id}
              header={header}
              columnIndex={header.index}
              tasksLoading={tasksLoading}
              totalRowCount={totalRowCount}
              currentRowCount={currentRowCount}
              tableContext={tableContext}
            />
          );
        })}

        {headersRemaining.map((header: Header<any, any>, index: number) => {
          const indexDiff = tableContext === "workspaceTasksList" ? 3 : 4;

          if (index + indexDiff >= headerGroup.headers.length) {
            return (
              <HeaderCell
                key={header.id}
                header={header}
                columnIndex={header.index}
                tasksLoading={tasksLoading}
                tableContext={tableContext}
              />
            );
          }

          return (
            <DraggableHeaderCell
              key={`header-${header.id}`}
              index={index}
              header={header}
              tasksLoading={tasksLoading}
              tableContext={tableContext}
            />
          );
        })}
      </tr>
      {hasAnyTotalRowObjects ? (
        <tr style={{ zIndex: "-1" }}>
          {headersFirstThree.map((header: Header<any, any>, index: number) => {
            if (index === headersFirstThree.length - 1) {
              return (
                <th
                  key={header.id}
                  className="tasks-table__th"
                  style={{
                    ...getCommonPinningStyles(header.column),
                  }}
                >
                  {tasksLoading ? (
                    !SKELETON_BLACKLIST.includes(header?.column.id) && (
                      <>
                        <span style={{ visibility: "hidden" }} />
                        <SkeletonText width={60} height={15} />
                      </>
                    )
                  ) : (
                    <div
                      className="tasks-table__header-wrapper"
                      data-column-name="total"
                      data-column-key="total"
                    >
                      <IDHFormattedMessage
                        id="ws_total"
                        defaultMessage="Total"
                      />
                    </div>
                  )}
                </th>
              );
            }

            return (
              <th
                className="tasks-table__th"
                key={header.id}
                style={{
                  ...getCommonPinningStyles(header.column),
                }}
              >
                <div className="tasks-table__header-wrapper">&nbsp;</div>
              </th>
            );
          })}

          {headersRemaining.map((header: Header<any, any>) => {
            // @ts-expect-error
            if (header.column.columnDef.totalRow) {
              const projectFieldPreviewSwitchProps = {
                // @ts-expect-error
                data: header.column.columnDef.totalRow,
                // @ts-expect-error
                fieldType: header.column.columnDef.totalRow.type,
                // @ts-expect-error
                value: header.column.columnDef.totalRow.value,
                // @ts-expect-error
                prefix: header.column.columnDef.totalRow.data?.currencyCode,
              };
              return (
                <th
                  key={header.id}
                  className="tasks-table__th tasks-table__th--total-row"
                  style={{
                    ...getCommonPinningStyles(header.column),
                  }}
                >
                  {tasksLoading ? (
                    <>
                      <span style={{ visibility: "hidden" }} />
                      <SkeletonText width={60} height={15} />
                    </>
                  ) : (
                    <ProjectFieldPreviewSwitch
                      {...projectFieldPreviewSwitchProps}
                    />
                  )}
                </th>
              );
            }
            return (
              <th
                key={header.id}
                className="tasks-table__th"
                style={{
                  ...getCommonPinningStyles(header.column),
                }}
              >
                &nbsp;
              </th>
            );
          })}
        </tr>
      ) : null}
    </>
  );
});

export const VirtuosoTableHead = forwardRef((props: any, ref: any) => {
  const { context, style, ...rest } = props;

  const {
    handleColumnDragEnd,
    setIsDragging,
    table,
    tasksLoading,
    tableContext,
    totalRowCount,
  }: TableVirtuosoContext = context;

  const headerGroup = table.getHeaderGroups()[0];

  return (
    <thead
      ref={ref}
      style={{
        ...style,
        top: tableContext === "workspaceTasksList" ? 213 : 74,
      }}
      {...rest}
    >
      <TableHeadRowContext
        axis="x"
        lockAxis="x"
        helperClass="tasks-table-column-dragged-item"
        helperContainer={
          (document.querySelector(
            ".tasks-table__column-dragging-context",
          ) as HTMLElement) || undefined
        }
        onSortStart={() => setIsDragging(true)}
        onSortEnd={handleColumnDragEnd}
        headerGroup={headerGroup}
        tasksLoading={tasksLoading}
        totalRowCount={totalRowCount}
        currentRowCount={table.getRowCount()}
        tableContext={tableContext}
        useDragHandle
      />
    </thead>
  );
});

const TableBodyContext = SortableContainer(({ listRef, ...props }: any) => {
  return <tbody ref={listRef} {...props} />;
});

export const VirtuosoTableBody = forwardRef((props: any, ref: any) => {
  const { context, ...rest } = props;

  const { handleRowDragEnd } = context;

  return (
    <TableBodyContext
      {...rest}
      listRef={ref}
      onSortEnd={handleRowDragEnd}
      useDragHandle
    />
  );
});

export function VirtuosoTableRow(props: any) {
  const { item: row } = props;

  const index = props["data-index"];

  return (
    <DraggableRow
      key={row.id}
      index={index}
      rowIndex={index}
      row={row}
      {...props}
    />
  );
}
