import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import axios from "axios";

import { CustomInputLabel } from "src/app/components/CustomInput/CustomInput";
import CustomSelect from "src/app/components/CustomSelect/CustomSelect";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { translateMessage } from "src/app/methods/translateMessage";
import { currencySearchFunction } from "src/app/components/CustomSelect/functions/currencySearchFunction";
import { showErrorToast } from "src/utils/methods";
import { API_URLS } from "src/utils/API_URLS";
import { DictionaryField } from "src/app/SettingsModal/tabs/DictionaryManager/types";
import {
  Currency,
  CurrencyOption,
  FieldManagerFormDataKeys,
  FieldManagerFormData,
} from "../types";

interface CurrencyFieldTypeFieldProps {
  handleUpdateFormData: (
    key: FieldManagerFormDataKeys,
    value: FieldManagerFormData[FieldManagerFormDataKeys],
  ) => void;
  editedField?: DictionaryField;
  formData: FieldManagerFormData;
}

export default function CurrencyField({
  editedField,
  handleUpdateFormData,
  formData: { selectedCurrency },
}: CurrencyFieldTypeFieldProps) {
  const [currencies, setCurrencies] = useState<Array<Currency>>([]);

  const intl = useIntl();

  const currencyOptions = useMemo(() => {
    return Object.entries(currencies).map(([code, name]) => ({
      key: code,
      label: (
        <span className="currency-option">
          <span className="currency-code">{code}</span>
          {name}
        </span>
      ),
      value: code,
    }));
  }, [currencies]);

  useEffect(() => {
    const getCurrencies = async () => {
      try {
        const { data } = await axios.get<{ content: Array<Currency> }>(
          API_URLS.getCurrencies,
        );
        setCurrencies(data.content);
      } catch (error) {
        console.error(error);
        showErrorToast();
      }
    };

    getCurrencies();
  }, []);

  useEffect(() => {
    if (editedField) {
      const foundCurrency = currencyOptions.find(
        (option) => option.key === editedField.data.currencyCode,
      );
      if (foundCurrency) {
        handleUpdateFormData("selectedCurrency", foundCurrency);
      }
    }
  }, [editedField, currencyOptions]);

  return (
    <div className="currency-wrapper">
      <div className="currency-wrapper__row">
        <div className="currency-select">
          <CustomInputLabel htmlFor="currency">
            <IDHFormattedMessage id="ws_currency" defaultMessage="Currency" />
          </CustomInputLabel>
          <CustomSelect
            id="currency"
            value={selectedCurrency}
            options={currencyOptions}
            placeholder={`${translateMessage({
              intl,
              id: "ws_select_currency",
              defaultMessage: "Select currency",
            })}...`}
            isSearchable
            onChange={(newValue: CurrencyOption) =>
              handleUpdateFormData("selectedCurrency", newValue)
            }
            filterOption={currencySearchFunction}
            disabled={!!editedField}
          />
        </div>
      </div>
    </div>
  );
}
