import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";

export const DICTIONARY_SUB_FIELDS_TYPE_WHITELIST = [
  MetaFieldType.Date,
  MetaFieldType.Member,
  MetaFieldType.Text,
  MetaFieldType.Number,
  MetaFieldType.Percent,
  MetaFieldType.Currency,
  MetaFieldType.DictionaryElement,
  MetaFieldType.SingleSelect,
  MetaFieldType.MultiSelect,
  MetaFieldType.AggregatedSelect,
];

export const FILTER_DICTIONARY_META_FIELDS_WHITELIST = [
  ...DICTIONARY_SUB_FIELDS_TYPE_WHITELIST,
  MetaFieldType.GlobalCreator,
];
