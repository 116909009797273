import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import "./SortingTasksArrow.scss";
import { ReactComponent as ArrowIcon } from "src/images/arrow-down.svg";
import { setSortingDirection } from "src/redux";
import { TableContext } from "src/app/project/TableField/types";
import { RootState } from "src/redux/reducers";
import { setWSTaskSortingDirection } from "src/redux/workspaceTaskFilters/workspaceTaskFiltersActions";
import { TaskSortingDirection } from "src/redux/taskFilters/types";

interface SortingTasksArrowProps {
  tableContext: TableContext;
}

export default function SortingTasksArrow({
  tableContext,
}: SortingTasksArrowProps) {
  const dispatch = useDispatch();

  const { sortingDirection: taskSortingDirection } = useSelector(
    (state: RootState) => state.taskFiltersReducer,
  );

  const { sortingDirection: workspaceSortingDirection } = useSelector(
    (state: RootState) => state.workspaceTaskFiltersReducer,
  );

  const sortingDirection =
    tableContext === "workspaceTasksList"
      ? workspaceSortingDirection
      : taskSortingDirection;

  return (
    <div className="sortingTasksArrow__wrapper">
      <ArrowIcon
        className={classNames(
          "sortingTasksArrow",
          `sortingTasksArrow--${sortingDirection}`,
        )}
        onClick={(e) => {
          e.preventDefault();

          const sortingDirectionToSet =
            sortingDirection === TaskSortingDirection.Descending
              ? TaskSortingDirection.Ascending
              : TaskSortingDirection.Descending;

          if (tableContext === "workspaceTasksList") {
            dispatch(setWSTaskSortingDirection(sortingDirectionToSet));
          } else {
            dispatch(setSortingDirection(sortingDirectionToSet));
          }
        }}
      />
    </div>
  );
}
